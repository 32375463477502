import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Gostree.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { FooterSection } from "components/LandingPage/Gostree/FooterSection";
import Container from "@material-ui/core/Container";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import InsuranceSearchResult from "pages/module/insurance/InsuranceResult";
import InsurancePaxDetails from "pages/module/insurance/InsuranceDetails";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import InsuranceSearchProgressPage from "pages/module/insurance/InsuranceSearchProgressPage";
import InsuranceReview from "pages/module/insurance/InsuranceReview";
import ExtRedirInsuranceBookProgress from "pages/module/insurance/ExtRedirectInsuranceBookProgress";
import InsuranceBookingProcess from "pages/module/insurance/InsuranceBookingProcess";
import PaymentSuccess from "pages/module/insurance/PaymentSuccess";
import PaymentFailed from "pages/module/insurance/PaymentFailed";

import InsurancePaymentStatus from "pages/module/insurance/InsurancePaymentStatus";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import PricingPolicy from "components/LandingPage/Gostree/siteinfo/PricingPolicy";
import TermsAndConditions from "components/LandingPage/Gostree/siteinfo/TermsAndConditions";

const useStyles = makeStyles(styles);

export default function InsuranceLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [agencyId, setAgencyId] = useState(undefined);

  let defaultTab = "hotel";
  let queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, defaultTab);
  const history = useHistory();
  const sessionStartTime = useSelector(
    (state) => state.hotel.insuranceBookingInitialTime
  );

  useEffect(() => {
    if (sessionStartTime) {
      let now = new Date().getTime();
      let distance = sessionStartTime - now;

      let minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let second = Math.floor((distance % (1000 * 60)) / 1000);
      if (minute <= 0 && second <= 0) {
        history.push("/");
      }
    }
  });

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  return (
    <div className={classes.wrapper} style={{ backgroundColor: "#FFFFFF" }}>
      <PostLoginNavBar
        isTimerShow={true}
        {...rest}
        isSearchPage={true}
        showServices={true}
      />

      <Grid
        style={{
          // marginBottom: 20,
          // paddingTop: 50,
          // padding: "20px 50px",
          padding: `${isMobile ? "10px 3px" : "20px 50px"}`,
          // paddingLeft: 5,
          // paddingRight: 5,
          maxWidth: "100%",
          width: "99%",
          minHeight: "70vh",
        }}
      >
        {/* <div className={isMobile ? "" : classes.container}> */}
        <Switch>
          <Route
            path="/insurance/search"
            exact
            component={InsuranceSearchProgressPage}
          />
          <Route
            path="/insurance/result"
            exact
            component={InsuranceSearchResult}
          />
          <Route
            path="/insurance/details"
            exact
            component={InsurancePaxDetails}
          />
          <Route
            path="/insurance/booking/payment-success"
            exact
            render={(prop) => (
              <PaymentSuccess
                // setInitFlag={(flag, url) => {
                //   triggerInit(flag, url);
                // }}
                {...props}
                {...prop}
              />
            )}
          />
          <Route
            path="/insurance/booking/payment-failed"
            exact
            render={(prop) => (
              <PaymentFailed
                // setInitFlag={(flag, url) => {
                //   triggerInit(flag, url);
                // }}
                {...props}
                {...prop}
              />
            )}
          />
          <Route
            path="/insurance/booking/:tid"
            exact
            render={(prop) => (
              <InsuranceBookingProcess
                // setInitFlag={(flag, url) => {
                //   triggerInit(flag, url);
                // }}
                {...props}
                {...prop}
              />
            )}
          />

          <Route
            path="/insurance/insurancepaymentprogress/:transactionId/:status"
            component={ExtRedirInsuranceBookProgress}
          />
          <Route path="/insurance/review" exact component={InsuranceReview} />

          <Route
            path="/insurance/payment-status/:tid/:status"
            component={InsurancePaymentStatus}
          />
        </Switch>
        {/* </div> */}
      </Grid>
      {/* <PageFooter white {...props} /> */}
      <FooterSection />
    </div>
  );
}
