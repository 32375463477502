import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonIcon from "@material-ui/icons/Person";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import GridContainer from "components/Grid/GridContainer.js";
import OaFormTextField from "oahoc/OaStandardTextField";
import EmailIcon from "@material-ui/icons/Email";
import OaFormAlerts from "pages/components/OaFormAlerts";
import Captcha from "demos-react-captcha";
import OaPasswordToggleInput from "oahoc/OaPasswordToggleInput";
import OaAcceptTermsConditions from "oahoc/OaAcceptTermsConditions";
import { useHistory } from "react-router-dom";

const Register = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [acceptTnc, setAcceptTnc] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const history = useHistory();
  const onChange = (value) => {
    setCaptcha(value);
    return value;
  };
  const formikRef = React.useRef();

  const onChangeAcceptTnc = (e) => {
    setAcceptTnc(e.target.checked);
  };

  const onChangePassword = (event) => {
    if (formikRef.current) {
      formikRef.current.setFieldValue("newPassword", event.target.value);
    }
  };

  return (
    <div
      className={classes.root}
      style={{
        marginBottom: 60,
        paddingLeft: isMobile ? 10 : "0",
        paddingRight: isMobile && 10,
      }}
    >
      <Grid
        container
        /*spacing={isMobile?0:3}*/ style={{ width: "100%" }}
        justifyContent="center"
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            container
            xs={12}
            md={11}
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12} xs={12}>
              <Typography
                variant="h5"
                style={{ fontSize: isMobile ? 20 : 25, color: "#000" }}
              >
                Desire more for your travel?
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography
                variant="h5"
                style={{ fontSize: "14px", color: "#000" }}
              >
                Create an account with us!
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
            </Grid>
            <Grid container>
              <Formik
                innerRef={formikRef}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  newPassword: "",
                  username: "",
                }}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string()
                    .required("First name is required")
                    .matches(
                      /^[A-Za-z]+$/,
                      "First name can contain alphabets only"
                    ),
                  lastName: Yup.string()
                    .required("Last name is required")
                    .matches(
                      /^[A-Za-z]+$/,
                      "Last name can contain alphabets only"
                    ),
                  email: Yup.string()
                    .email("Invalid email")
                    .required("Email is required"),
                  newPassword: Yup.string().required(
                    "Please enter your password"
                  ),
                  username: Yup.string()
                    .required("User name is required")
                    .matches(
                      /^[A-Za-z]+$/,
                      "Invalid Username (please use alphabets only)"
                    ),
                })}
                onSubmit={(
                  searchParams,
                  { setSubmitting, resetForm, enableReinitialize }
                ) => {
                  setIsSaving(true);
                  apiCall(WebApi.b2cRegister, searchParams, (response) => {
                    setIsError(!response.success);
                    setIsSuccess(response.success);
                    setSubmitting(false);
                    setIsSaving(false);
                    setInfoText(response.message);
                    if (response.success === true) {
                      enableReinitialize = true;
                      resetForm(true);
                    }
                  });
                }}
              >
                {({
                  values,
                  setFieldValue,
                  enableReinitialize,
                  resetForm,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: 50,
                      }}
                    >
                      <GridContainer xs={12} spacing={isMobile ? 0 : 1}>
                        <Grid item md={12} xs={12} className="form-item-grid">
                          <OaFormTextField
                            name="firstName"
                            label="First Name"
                            adornPosition="right"
                            adornmentIcon={<PersonIcon />}
                          />
                          <p className="error-typ">{errors?.firstName}</p>
                          {/* <ErrorMessage
                            name="firstName"
                            component="div"
                            className="error"
                          /> */}
                        </Grid>

                        <Grid item md={12} xs={12} className="form-item-grid">
                          <OaFormTextField
                            name="lastName"
                            label="Last Name"
                            adornPosition="right"
                            adornmentIcon={<PersonIcon />}
                          />
                          <p className="error-typ">{errors?.lastName}</p>
                          {/* <ErrorMessage
                            name="lastName"
                            component="div"
                            className="error"
                          /> */}
                        </Grid>

                        <Grid item md={12} xs={12} className="form-item-grid">
                          <OaFormTextField
                            name="username"
                            label="Username"
                            adornPosition="right"
                            adornmentIcon={<PersonIcon />}
                          />
                          <p className="error-typ">{errors?.username}</p>
                          {/* <ErrorMessage
                            name="username"
                            component="div"
                            className="error"
                          /> */}
                        </Grid>

                        <Grid item md={12} xs={12} className="form-item-grid">
                          <OaFormTextField
                            name="email"
                            label="Email"
                            adornPosition="right"
                            adornmentIcon={<EmailIcon />}
                          />
                          <p className="error-typ">{errors?.email}</p>
                          {/* <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          /> */}
                        </Grid>

                        <Grid item md={12} xs={12} className="form-item-grid">
                          {/* <OaFormTextField
                            required={true}
                            name="newPassword"
                            label="Password"
                            adornPosition="right"
                            isPasswordField={true}
                            adornmentIcon={<VpnKeyIcon />}
                          /> */}
                          <OaPasswordToggleInput
                            name="newPassword"
                            label="Password"
                            value={values.newPassword}
                            onChange={onChangePassword}
                          />
                          <p className="error-typ">{errors?.newPassword}</p>
                          {/* <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="error"
                          /> */}
                        </Grid>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          className={
                            classes.captchaGrid + " form-item-grid m10"
                          }
                        >
                          <Grid container>
                            <Grid item md={6}>
                              <Captcha
                                onChange={onChange}
                                placeholder="Enter captcha"
                              />
                            </Grid>
                            <Grid item md={6} className="captcha-message">
                              <p style={{ color: captcha ? "green" : "red" }}>
                                {captcha
                                  ? "Captcha Valid! please proceed to submit"
                                  : "please enter the characters displayed in the image correctly"}
                              </p>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* {captcha &&   <Grid item md={12} container justifyContent="flex-start">
                          <Button
                            type="submit"
                            className={classes.Button}
                            style={{ fontFamily: "Montserrat" }}
                          >
                            Submit
                          </Button>
                        </Grid>} */}

                        <Grid item md={12} sm={12} xs={12}>
                          {/* <Typography variant="body2" gutterBottom>
                            By signing up, you acknowledge that you have read
                            and agree with the{" "}
                            <span className={classes.span}>
                              Terms & Conditions
                            </span>{" "}
                            and the{" "}
                            <span className={classes.span}>Privacy Policy</span>
                          </Typography> */}
                          <OaAcceptTermsConditions
                            type="b2c_register"
                            name="acceptTnc"
                            termsAndConditions={props.tnc}
                            privacyPolicy={props.ppc}
                            value={acceptTnc}
                            onChange={onChangeAcceptTnc}
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            showPrivacyPolicy
                          />
                        </Grid>
                        <Grid
                          item
                          md={12}
                          container
                          justifyContent="flex-start"
                          className="form-item-grid m10"
                        >
                          <button
                            type="submit"
                            className={classes.Button}
                            style={{ color: "#fff" }}
                            disabled={captcha ? "" : "disabled"}
                          >
                            Submit
                          </button>
                        </Grid>
                      </GridContainer>
                    </div>
                    <div style={{ padding: 10 }}>
                      {/* <p style={{wordWrap: "wrap"}}>ERRORS:<br/>{JSON.stringify(errors)}</p>
                    <br/>
                    <p style={{wordWrap: "wrap"}}>TOUCHED:<br/>{JSON.stringify(touched)}</p> */}
                    </div>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={5}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid
            container
            spacing={1}
            className={`${classes.firstCard} ${classes.Grid}`}
          >
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h5"
                style={{ fontSize: isMobile ? 20 : 24, color: "#000" }}
                gutterBottom
              >
                Enjoy more benefits!
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Enter contact details only once
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Access your booking history and upcoming trips
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Print tickets and invoices
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2">
                Faster and easier checkouts
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography variant="body2" gutterBottom>
                Be among the first to know about our offers and discounts
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Divider />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                variant="h4"
                style={{
                  fontSize: isMobile ? 20 : 24,
                  color: "#000",
                  lineHeight: 1,
                }}
                gutterBottom
              >
                Already have an Account?
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Button
                className={classes.Button}
                onClick={() => history.push("/b2c/signin")}
              >
                Sign in now!
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Register;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "66vh",
    "& .MuiInputBase-input": {
      minHeight: 35,
    },
    "& .form-item-grid": {
      [theme.breakpoints.down(960)]: {
        margin: "5px 0",
        "&.m10": {
          margin: "15px 0",
        },
      },
    },
    "& .oapt-btn": {
      "& i": {
        color: "#000",
      },
    },
    "& .error-typ": {
      color: "red",
    },
  },

  Grid: {
    background: "#fff",
    marginTop: "50px",
    // boxShadow: "0px 0px 15px -1px #989898",
    padding: 30,
    borderRadius: 20,
  },
  firstCard: {
    "& .MuiTypography-root": {
      color: "#000",
    },
  },
  Button: {
    padding: ".5rem 1.5rem",
    border: "none" /* borderRadius: '30px', */,
    background: theme.palette.buttons.secondary,
    letterSpacing: "1px",
    color: theme.palette.secondary.contrastText,
    cursor: "pointer",
    textTransform: "none",
    textShadow: "none",
    "&:hover": {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.sub,
    },
    "&:disabled": {
      color: "#000",
      backgroundColor: "rgba(232, 232, 232, 0.69) !important",
    },
  },
  span: {
    color: "#23527c",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
    "&:focus": {
      textDecoration: "underline",
    },
  },
  captchaGrid: {
    "& canvas": {
      position: "relative !important",
    },
    "& .captcha-message": {
      display: "flex",
      alignItems: "center",
      "& p": {
        fontWeight: 500,
      },
    },
  },
}));
