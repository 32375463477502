import React, { useRef, useState } from 'react'
import {
    Box,
    Menu,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    Grid,
    IconButton,
    Button
  } from "@material-ui/core";
  import { FaTimes } from "react-icons/fa";
  
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import styled from "styled-components";
import minus from "../../../assets/icons/minus.png"
import plus from "../../../assets/icons/plus.png"


const InsurancePaxDuration = ({props, setFieldValue, formikRef}) => {
    const [dropdownAge, setDropdownAge] = useState(false);
//   const formikRef = useRef();

    const diffDays = (date1, date2) => {
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays + 1;
      };

      
  return (
    <Root className="input-element input-element--dropdown age-input">
                      <div
                        className="input-element--textField"
                        onClick={() => setDropdownAge(true)}
                      >
                        <p>
                          <span>{props.values.paxCount}</span>{" "}
                          {props.values.paxCount > 1 ? "Passengers" : "Passenger "} &{" "}
                          <br></br>
                          Duration of{" "}
                          <span>
                            {diffDays(
                              props.values.travelStartDate,
                              props.values.travelEndDate
                            )}
                          </span>{" "}
                          {diffDays(
                            props.values.travelStartDate,
                            props.values.travelEndDate
                          ) > 1
                            ? "days "
                            : "day  "}
                          <i className="fas fa-chevron-down"></i>
                          {/* <ErrorMessage
                            name="paxAge"
                            component="div"
                            className="error"
                          /> */}
                        </p>
                      </div>

                      {dropdownAge && (
                        <div
                          className="ageModal"
                          container
                          direction="column"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={2}
                        >
                      
                          <div className="ageModal__paxCountContainer">
                            <div className="ageModal__paxCountLabel">
                              Pax Count
                            </div>
                            <div className="ageModal__paxCountInput">
                              <div className="ageModal__inputButton"    onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;
                                    if (value === 0) return;
                                    if (value > 1) value--;

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}>
                                {/* <IconButton
                                  aria-label="decrement"
                                  onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;
                                    if (value === 0) return;
                                    if (value > 1) value--;

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}
                                >
                                  <RemoveIcon
                                    className="icon"
                                    fontSize="small"
                                  /> */}
                                {/* </IconButton> */}
                                <img src={minus} className='addsubtractIcon' />
                               
                              </div>
                              <div className="ageModal__inputValue">
                                <div variant="h6">
                                  {formikRef.current.values.paxCount}
                                </div>
                              </div>
                              <div className="ageModal__inputButton"     onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;

                                    if (value > 0 && value < 6) {
                                      // console.log(value);
                                      value++;
                                    }

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}>
                                {/* <IconButton
                                  aria-label="increament"
                                  onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;

                                    if (value > 0 && value < 6) {
                                      // console.log(value);
                                      value++;
                                    }

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}
                                >
                                  <AddIcon />
                                </IconButton> */}
                                     <img  className='addsubtractIcon'  src={plus} />
                              </div>
                            </div>
                          </div>

                          <form
                            // onSubmit={handleSubmit}
                            className="ageModal__form"
                          >
                            <div className="ageModal__ageLabel">
                              Age(0.5-70)
                            </div>
                            {Array.from({
                              length: formikRef.current.values.paxCount,
                            }).map((inputField, ind) => (
                              <div
                                key={ind + 1}
                                className="ageModal__passengerContainer"
                              >
                                <label>Pax {ind + 1} </label>
                                {/* <TextField
                                  name={`paxAge[${ind}]`}
                                  className="passengerInput"
                                  type="number"
                                  defaultValue={props.values.paxAge[ind] || ""}
                                  InputProps={{
                                    max: 70,
                                    min: 1,
                                    maxLength: 2,
                                    disableUnderline: true,
                                  }}
                                  onChange={(e) => {
                                 
                                  }}
                                /> */}
                                <input         value={props.values.paxAge[ind] || ""}
        className='insurance-age-input'
        onChange={(e) => {
            if (!e.target.value) return;
            var value = parseInt(e.target.value);
            if (value > 70) value = 70;
            if (value < 0.5) value = 1;

            setFieldValue(`paxAge[${ind}]`, value);
        }} />
                              </div>
                            ))}
                            <Button
                              className="ageModal__submitBtn"
                              variant="contained"
                              color="primary"
                              type="submit"
                              // endIcon={<Icon>send</Icon>}
                              onClick={() => {
                                setDropdownAge(false);
                              }}
                            >
                              Save
                            </Button>
                          </form>
                        </div>
                      )}
                    </Root>
  )
}

export default InsurancePaxDuration

const Root = styled.div`
position: relative;
// font-family: poppins;
.insurance-age-input {
  height: 20px;
  font-size: 14px !important;
  width: 40px;
//   margin-top: 10px;

}
.addsubtractIcon {
    width: 17px;
    height: 17px;
}


  
`;
