import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import WebApi from "api/ApiConstants";
import MantraRdService from "pages/module/aadhaarpay/MantraRdService";
import { TextField, useTheme } from "@material-ui/core";

const EkycForm = (props) => {
    const theme = useTheme();
    const formikRef = useRef(null);
    const [pIDData, setPIDData] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [fingerPrintCaptured, setFingerPrintCaptured] = useState(false);
    const [avdmActive, setAvdmActive] = useState(false);
    const [captureAvdm, setCaptureAvdm] = useState(false);
    const [latLong, setLatLong] = useState(null);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");

    const [severitySnackbar, setSeveritySnackbar] = useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const [severity, setSeverity] = useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const initialValues = {
        aadhaarNumber: "",
        mobile: props.mobile,
    };

    const validationSchema = Yup.object().shape({
        mobile: Yup.string().required("Mobile number cannot be blank"),
        aadhaarNumber: Yup.string().required("Aadhaar number cannot be blank"),
    });

    const getLocation = () => {
        // setLatLong({
        //     longitude: 91.7085933,
        //     latitude: 26.1157917
        // });
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setLatLong({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, showError);
        } else {
            console.log("Geolocation permission denied")
            alert("Geolocation is not supported by this browser.");
        }
    };

    const showError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                props.setInfoText("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                props.setInfoText("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                props.setInfoText("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                props.setInfoText("An unknown error occurred.");
                break;
        }
        props.setIsError(true);
    };

    const reInitDevice = () => {
        setIsLoading(true);
        setAvdmActive(false);
    };

    const onChangeMobileNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobile", e.target.value);
        }
    };

    const onChangeAadhaarNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("aadhaarNumber", e.target.value);
        }
    };

    const onSubmitEkyc = (searchParams, { setSubmitting }) => {
        setIsLoading(true);
        
        if(latLong && pIDData) {
            searchParams.lat = latLong.latitude;
            searchParams.lon = latLong.longitude;
            searchParams.data = pIDData;
            console.log(searchParams)

            WebApi.sendRemitterEkyc(searchParams, (response) => {
                if(response.success) {
                    console.log("Response", response);
                    if(response.data.status && response.data.response_code == 1) {
                        props.setEkycData({
                            kycId: response.data.data.kyc_id,
                            stateresp: response.data.data.stateresp,
                            ...latLong
                        });
                        props.closeEkycModal();
                    } else {
                        props.setInfoText(response.data.message);
                        props.setIsError(true);
                    }
                } else {
                    props.setInfoText("failed to send remitter kyc");
                    props.setIsError(true);
                }
                setIsLoading(false);
            }, (error) => {
                console.error("Error", error);
                setIsLoading(false);
            });
        }
    };

    useEffect(() => {
        getLocation();
    }, []);

    return(
        <RootContainer theme={theme}>
            <div className="form-title-wrapper">
                <p className="form-title">Send Remitter E-KYC</p>
            </div>
            <div className="form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitEkyc}
                >
                    {({ values, isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            <div className="form-wrapper">
                                <div className="form-row">
                                    <MantraRdService
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        setInfoText={(val) => setInfoText(val)}
                                        severity={severity}
                                        setSeverity={setSeverity}
                                        severitySnackbar={severitySnackbar}
                                        setSeveritySnackbar={setSeveritySnackbar}
                                        fingerPrintCaptured={fingerPrintCaptured}
                                        setFingerPrintCaptured={setFingerPrintCaptured}
                                        captureAvdm={captureAvdm}
                                        setCaptureAvdm={setCaptureAvdm}
                                        pIDData={pIDData}
                                        setPIDData={setPIDData}
                                        avdmActive={avdmActive}
                                        setAvdmActive={setAvdmActive}
                                        reInitDevice={reInitDevice}
                                        mode="auth"
                                    />
                                </div>
                                <div className="form-row justify-center mb-30">
                                    <div className="form-element-wrap">
                                        <label>Aadhaar Number</label>
                                        <TextField
                                            name="aadhaarNumber"
                                            value={values.aadhaarNumber}
                                            onChange={onChangeAadhaarNo}
                                        />
                                        <ErrorMessage name="aadhaarNumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap">
                                        <label>Mobile</label>
                                        <TextField
                                            name="mobile"
                                            value={values.mobile}
                                            onChange={onChangeMobileNo}
                                        />
                                        <ErrorMessage name="mobile" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap submit-wrap">
                                        <button type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </RootContainer>
    );
};

export default EkycForm;

const RootContainer = styled.div`
min-height: 200px;
.form-container {
    padding: 0 20px 5px;
}
.form-wrapper {
    text-align: center;
}
.form-title-wrapper {
    padding: 15px 20px 0;
    p {
        text-align: center;
        margin-block-start: 0.3em;
        margin-block-end: 0.3em;
    }
    .form-title {
        font-weight: 500;
        font-size: 1.2em;
        font-family: ${prope => prope.theme.palette.font.secondary};
    }
    .status-response {
        padding-top: 5px;
    }
}
.form-row {
    margin: 10px 0;
    display: flex;
    gap: 20px;
}
.form-element-wrap {
    &.submit-wrap {
        padding-top: 21px;
    }
    button {
        background: ${prop => prop.theme.palette.primary.main};
        color: ${prop => prop.theme.palette.primary.contrastText};
        font-size: 12px;
        padding: 6px 15px;
        height: 40.5px;
        border: solid 1px ${prop => prop.theme.palette.primary.main};
        border-radius: 4px
    }
    label {
        font-size: 12px;
        font-weight: 500;
    }
}
.justify-center {
    justify-content: center;
}
.mb-30 {
    margin-bottom: 30px;
}
input {
    &:focus {
        outline: none;
    }
}
`;
