import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormControl from "@material-ui/core/FormControl";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import {Formik, Form } from 'formik';
import OaFormFieldAgentList from 'components/OaFormFieldAgentList';
import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
import { withRouter } from 'react-router-dom';

const FlightSearchForm = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [flightBookingStatusList, setFlightBookingStatusList] = useState([]);

    const [agencyId, setAgencyId] = useState(props.agencyId);

    useEffect(() => {
        if(isLoading && flightBookingStatusList.length === 0){
            apiCall(WebApi.getFlightBookingStatusList, {}, (response) => {
                let data= [{value: ' ', label: 'All'}];
                response.data.map (row => {
                    data.push({value: row.key, label: row.description})
                });
                setFlightBookingStatusList(data);
                setIsLoading(false);
                setIsError(response.success===false);
            });
        }
    }, [isLoading]);

    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return(        
    <>
        {isLoading && <OaLoading />}
        {!isLoading && 
        <>
        <h4 className={"oa-form-header"}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >Search Flight Report</h4>
        <Formik 
            initialValues={{
                flightBookingFromDateDisplay: props.searchParams.flightBookingFromDateDisplay,
                flightBookingToDateDisplay: props.searchParams.flightBookingToDateDisplay,
                flightTicketStatus: props.searchParams.flightTicketStatus,
                flightBookingFromDate: props.searchParams.flightBookingFromDate,
                flightBookingToDate: props.searchParams.flightBookingToDate,
                agencyId: props.searchParams.agencyId,
                flightPNR: props.searchParams.flightPNR,
            }}
            onSubmit= {(searchParams, {setSubmitting, resetForm}) => {
                props.setIsLoading(true);
                searchParams.flightBookingFromDate = dateFnsFormat(searchParams.flightBookingFromDateDisplay, 'dd-MM-yyyy');
                searchParams.flightBookingToDate = dateFnsFormat(searchParams.flightBookingToDateDisplay, 'dd-MM-yyyy');
                props.target(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, isSubmitting, setFieldValue, handleChange }) => (
                <Form>
                    <GridContainer>
                        <OaFormTextField name="flightApplicationReference" label="Application Reference" md={4}/>
                        <OaFormTextField name="flightPNR" label="PNR" md={4}/>
                        <OaFormTextField name="flightClientEmail" label="Email" md={4}/>

                        <OaFormSelectField 
                            label="Status"
                            name="flightTicketStatus"
                            value={values.flightTicketStatus}
                            options={flightBookingStatusList}
                            md={4}
                        />

                        <OaFormDateField 
                            value={values.flightBookingFromDateDisplay}
                            selected={values.flightBookingFromDateDisplay}
                            onChange={date => setFieldValue('flightBookingFromDateDisplay', date)}
                            label="Booked from"
                            name="flightBookingFromDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />


                        <OaFormDateField 
                            value={values.flightBookingToDateDisplay}
                            selected={values.flightBookingToDateDisplay}
                            onChange={date => setFieldValue('flightBookingToDateDisplay', date)}
                            label="Booked to"
                            name="flightBookingToDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />

                        {/* {!agencyId &&
                        <GridItem xs={12} sm={12} md={4}> 
                        <FormControl fullWidth={true}>
                            <OaFormFieldAgentList label="Booked by" setFieldValue ={setFieldValue} />
                        </FormControl>
                        </GridItem>
                        } */}
                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </>
        }
    </>
    )
}

export default withRouter(FlightSearchForm);
