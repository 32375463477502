import React from "react";
import cx from "classnames";
import { AppStateContext } from "layouts/AppStateProvider";
import {
  localforageSetItem,
  localforageGetItem,
  localforageClear,
} from "oautils/oaForageUtils";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { makeStyles, useTheme, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import companyLogo from "assets/Gostree/images/logo.svg";
import { MenuOutlined } from "@material-ui/icons";
import { RefreshRounded, ExpandLess, ExpandMore } from "@material-ui/icons";
import { currencyFormatter } from "oautils/oaCommonUtils";
import OaTimer from "oahoc/OaTimer";
import TopSection from "components/LandingPage/Gostree/TopSection";
import { AppConstant } from "appConstant";
import MailIcon from "assets/triumphTravel/images/icons/mail_icon.svg";
import PhoneIcon from "assets/triumphTravel/images/icons/phone_icon.svg";

const HomePageNavbar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(960));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const [corpName, setCorpName] = React.useState("");
  const [corpIdentity, setCorpIdentity] = React.useState("");
  const [accountBalance, setAccountBalance] = React.useState("");
  const [showServices, setShowServices] = React.useState(
    props.hideServices ? props.hideServices : props.showServices
  );
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const { secLogo } = React.useContext(AppStateContext);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const isMenuOpen = Boolean(anchorEl);
  const isAccountMenuOpen = Boolean(accountAnchorEl);
  const [showSupport, setShowSupport] = React.useState(false);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [userType, setUserType] = React.useState(null);
  const [anchorElLog, setAnchorElLog] = React.useState(null);
  const openLog = Boolean(anchorElLog);
  const { setSecLogo } = React.useContext(AppStateContext);
  const idLog = openLog ? "simple-popover" : undefined;
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [corporateType, setCorporateType] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
  const [mobile24Support, setMobile24Support] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const oaNavBarRootRef = React.useRef();
  const oaNavBarBgRef = React.useRef();
  const oaNavMenuRef = React.useRef();
  const isMounted = React.useRef(false);
  const breakpoint = 960;
  const width = window.innerWidth;
  const goToHomePage = () => {
    history.push("/");
  };

  const refreshBalance = () => {
    setAccountBalance(0);
    apiCall(WebApi.getDashboardData, {}, function(response) {
      let mainAccountBalance = response.data.mainAccountBalance;
      setAccountBalance(mainAccountBalance);
      localforageGetItem("user-details", function(err, value) {
        localforageSetItem("user-details", {
          ...value,
          cashBalance: mainAccountBalance,
        });
      });
    });
  };

  const logOut = (event) => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            const loginPagePath =
              corporateType && corporateType != "corporate"
                ? "/auth/login-page"
                : "/corporate_login";

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const logOutB2C = () => {
    localforageClear(() => {
      WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
          localforageClear(() => {
            localforageSetItem("user-id", 0);

            localforageSetItem("user-details", response.data, () => {
              localforageSetItem("access-key", response.accesskey, () => {
                history.push("/flights");
                window.location.reload();
              });
            });
          });
        }
      });
    });
  };

  const openDashboard = (event) => {
    history.push("/admin/dashboard");
  };

  const openDashboardB2c = () => {
    history.push("/b2c/mybookings");
  };

  const onScroll = () => {
    if (window.scrollY >= 80) {
      oaNavBarRootRef &&
        oaNavBarRootRef.current &&
        oaNavBarRootRef.current.classList.add("oa-navbar-sticky");
      // oaNavBarBgRef && oaNavBarBgRef.current && oaNavBarBgRef.current.classList.add("oa-primary-bg");
    } else {
      oaNavBarRootRef &&
        oaNavBarRootRef.current &&
        oaNavBarRootRef.current.classList.remove("oa-navbar-sticky");
      // oaNavBarBgRef && oaNavBarBgRef.current && oaNavBarBgRef.current.classList.remove("oa-primary-bg");
    }
  };

  React.useEffect(() => {
    if (mobileMenuOpen) {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.add("oa-mobile-menu-show");
    } else {
      oaNavMenuRef &&
        oaNavMenuRef.current &&
        oaNavMenuRef.current.classList.remove("oa-mobile-menu-show");
    }
  }, [mobileMenuOpen]);

  React.useEffect(() => {
    isMounted.current = true;
    window.addEventListener("scroll", onScroll);

    if (userType === "agent" || userType === "direct_agent") {
      refreshBalance();
    }

    return () => {
      isMounted.current = false;
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  React.useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (isMounted.current && value) {
        if (value.userTypeAbv == "R") {
          setIsLoggedIn(true);
        }

        setShowServices(
          value.userType == "distributor"
            ? false
            : showServices != null
            ? showServices
            : true
        );
        setCorpName(value.corpname);
        setCorpIdentity(value.userIdentity);
        setAccountBalance(value.cashBalance);
        setServiceOptions(value.serviceOption);

        // console.log("serviceOptions", value.serviceOption);

        setUserType(value.userType);
        setUserTypeb2c(value.userType);
        // setCorporateType(value.corporateType);
      }
    });
  }, [isMounted.current]);
  //console.log("history.location.pathname ",history.location.pathname);
  const toggleMobile24Support = () => {
    setMobile24Support(!mobile24Support);
  };
  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
  };

  return (
    <>
      <TopSection />
      <div className={cx(classes.root, "oa-primary-bg")} ref={oaNavBarRootRef}>
        <div className="oa-navbar-container" ref={oaNavBarBgRef}>
          <div className="oa-navbar">
            <div
              className="oa-navbar-brand-wrapper"
              style={{
                height: !props.hidelogo ? "auto" : 53,
              }}
            >
              {!props.hidelogo && (
                <a onClick={goToHomePage}>
                  <img src={companyLogo} />
                </a>
              )}
            </div>

            <div className="oa-mobile-menu-toggler-wrapper">
              <a
                className="oa-mobile-menu-toggler"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <MenuOutlined />
              </a>
            </div>

            <div className="oa-navbar-menu-wrapper">
              <ul className="oa-navbar-menu" ref={oaNavMenuRef}>
                {userType === "b2c_user" && width < breakpoint && (
                  <li className="app-bar-link-list-item dropdown-on-hover-list-item">
                    <a
                      className="app-bar-link no-user dropdown-on-hover"
                      onClick={toggleProfile}
                    >
                      {corpName}({corpIdentity}) &nbsp;
                      {profileOpen ? <ExpandLess /> : <ExpandMore />}
                    </a>
                    <ul
                      className={cx(
                        "mobile-dropdown-menu",
                        profileOpen ? "expanded" : ""
                      )}
                    >
                      <li>
                        <a
                          className="mu-li-item"
                          onClick={(event) => openDashboardB2c(event)}
                        >
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a
                          className="mu-li-item"
                          onClick={(event) => logOutB2C(event)}
                        >
                          Sign out
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
                {userType === "b2c_user" && width > breakpoint && (
                  <li className="mobile-user-li">
                    <a className="mu-li-item">
                      {corpName}({corpIdentity})
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={(event) => openDashboardB2c(event)}
                    >
                      Dashboard
                    </a>
                    <a
                      className="mu-li-item"
                      onClick={(event) => logOutB2C(event)}
                    >
                      Sign out
                    </a>
                  </li>
                )}
                {(userType === "agent" || userType === "direct_agent") &&
                  width < breakpoint && (
                    <li className="app-bar-link-list-item dropdown-on-hover-list-item">
                      <a
                        className="app-bar-link no-user dropdown-on-hover"
                        onClick={toggleProfile}
                      >
                        {corpName}({corpIdentity}) &nbsp;
                        {profileOpen ? <ExpandLess /> : <ExpandMore />}
                      </a>
                      <ul
                        className={cx(
                          "mobile-dropdown-menu",
                          profileOpen ? "expanded" : ""
                        )}
                      >
                        <li>
                          <span
                            className="mu-li-item"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            Balance:&nbsp;&nbsp;
                            <a
                              onClick={(event) => refreshBalance()}
                              className="balance-link"
                            >
                              <RefreshRounded />
                              &nbsp;{currencyFormatter(accountBalance)}
                            </a>
                          </span>
                        </li>
                        <li>
                          <a
                            className="mu-li-item"
                            onClick={(event) => openDashboard(event)}
                          >
                            Dashboard
                          </a>
                        </li>
                        <li>
                          <a
                            className="mu-li-item"
                            onClick={(event) => logOut(event)}
                          >
                            Sign out
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}
                {(userType === "agent" || userType === "direct_agent") &&
                  width > breakpoint && (
                    <li className="mobile-user-li">
                      <a className="mu-li-item">
                        {corpName}({corpIdentity})
                      </a>
                      <span className="mu-li-item">
                        Balance:&nbsp;&nbsp;
                        <a
                          onClick={(event) => refreshBalance()}
                          className="balance-link"
                        >
                          <RefreshRounded />
                          &nbsp;{currencyFormatter(accountBalance)}
                        </a>
                      </span>
                      <a
                        className="app-bar-link no-user"
                        onClick={(event) => openDashboard(event)}
                      >
                        Dashboard
                      </a>
                      <a
                        className="app-bar-link no-user"
                        onClick={(event) => logOut(event)}
                      >
                        Sign out
                      </a>
                    </li>
                  )}
                {!userType && (
                  <>
                    <li className="mobile-user-li">
                      <a
                        className="app-bar-link no-user"
                        onClick={() => history.push("/b2c/printeticket")}
                      >
                        Print E ticket
                      </a>
                    </li>
                    <li className="mobile-user-li">
                      <a
                        onClick={() => history.push("/auth/login-page")}
                        className="app-bar-link no-user"
                      >
                        Agent
                      </a>
                    </li>
                    <li className="mobile-user-li">
                      <a
                        onClick={() => history.push("/b2c/signin")}
                        className="app-bar-link no-user"
                      >
                        Sign In
                      </a>
                    </li>
                    <li className="mobile-user-li">
                      <a
                        onClick={() => {
                          history.push(
                            (userType === "agent" || userType === "direct_agent") ?
                             "/prelogin/registration" : "/b2c/register"
                          );
                        }}
                        className="app-bar-link no-user"
                      >
                        Register
                      </a>
                    </li>

                    {/* <li className="mobile-user-li">
                                        <a className="mu-li-item" onClick={() => history.push("/b2c/cancelticket")}>
                                            Cancel E ticket
                                        </a>
                                    </li> */}
                  </>
                )}
                {(userType === "agent" || userType === "direct_agent") &&
                  serviceOptions &&
                  serviceOptions.map((val, ind) => (
                    <li key={ind} className="vert-border">
                      <a
                        className={`iconed-menu ${
                          history.location.pathname == "/" + val.serviceValue ||
                          (val.serviceValue === "flights" &&
                            history.location.pathname.startsWith("/flight")) ||
                          (val.serviceValue === "hotels" &&
                            history.location.pathname.startsWith("/hotel")) ||
                          (val.serviceValue === "buses" &&
                            history.location.pathname.startsWith("/bus")) ||
                          (val.serviceValue === "insurances" &&
                            history.location.pathname.startsWith("/insurance"))||
                          (val.serviceValue === "aeps" && history.location.pathname.includes("aadhaarpay")) ||
                          (history.location.pathname.includes(val.serviceValue))
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          history.push("/" + getServicePath(val.serviceValue))
                        }
                      >
                        {val.serviceName}
                      </a>
                    </li>
                  ))}

                {(!userType || userType == "b2c_user") &&
                  serviceOptions &&
                  serviceOptions.map((val, ind) => (
                    <li key={ind} className="vert-border">
                      <a
                        className={`iconed-menu ${
                          history.location.pathname == "/" + val.serviceValue ||
                          (val.serviceValue === "flights" &&
                            history.location.pathname.startsWith("/flight")) ||
                          (val.serviceValue === "hotels" &&
                            history.location.pathname.startsWith("/hotel")) ||
                          (val.serviceValue === "buses" &&
                            history.location.pathname.startsWith("/bus")) ||
                          (val.serviceValue === "insurances" &&
                            history.location.pathname.startsWith("/insurance"))
                            ? "active"
                            : ""
                        }`}
                        onClick={() =>
                          history.push("/" + getServicePath(val.serviceValue))
                        }
                      >
                        {val.serviceName}
                      </a>
                    </li>
                  ))}

                {(userType === "agent" || userType === "direct_agent") && (
                  <>
                    {/* <li>
                                        <a onClick={() => history.push("/fd/hotdeals")}>Hot deals</a>
                                    </li> */}
                  </>
                )}
                {/* <li>
                                    <a onClick={() => history.push("/b2c/contactus")}>Contact</a>
                                </li> */}

                {width < breakpoint && (
                  <li className="app-bar-link-list-item dropdown-on-hover-list-item">
                    <a
                      className="app-bar-link no-user dropdown-on-hover"
                      onClick={toggleMobile24Support}
                    >
                      24x7 helpline &nbsp;
                      {mobile24Support ? <ExpandLess /> : <ExpandMore />}
                    </a>
                    <ul
                      className={cx(
                        "mobile-dropdown-menu",
                        mobile24Support ? "expanded" : ""
                      )}
                    >
                      <li>
                        <a
                          href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContactView}`}
                        >
                          <img src={PhoneIcon} />+{AppConstant.countryDialCode}
                          &nbsp;
                          {AppConstant.supportContactView}
                        </a>
                      </li>
                      <li>
                        <a href={`mailto:${AppConstant.supportEmail}`}>
                          <img src={MailIcon} /> {AppConstant.supportEmail}
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>

              <ul className="navbar-account">
                {userType === "b2c_user" ? (
                  <>
                    {/* <li>
                                        <div className="my_ac">
                                            <span><i className="far fa-user"></i></span>
                                            My Account
                                            <span><i className="fas fa-chevron-down"></i></span>
                                            <div className="show_ac">
                                                <div className="ac_avatar" >
                                                    <IconButton><span><i className="fas fa-user"></i></span></IconButton>
                                                </div>
                                                <Button>
                                                    Login or Sign in
                                                </Button>
                                                <ul className="ac_ul">
                                                    <li><span> {">"} </span> My Booking</li>
                                                    <li><span> {">"} </span> Print/Cancel Booking</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </li> */}
                    {/* <li>
                                        <a onClick={openDashboardB2c} className="app-bar-link no-user">Dashboard</a>
                                    </li>
                                    <li>
                                        <a onClick={openDashboardB2c} className="app-bar-link no-user">{corpName}</a>
                                    </li>
                                    <li>
                                        <a onClick={logOutB2C} className="app-bar-link no-user">Logout</a>
                                    </li> */}
                  </>
                ) : (
                  userType !== "b2c_user" &&
                  userType !== "agent" &&
                  userType !== "direct_agent" && (
                    <>
                      {/* <li>24x7 helpline</li> */}
                      <li>
                        <a
                          onClick={() => history.push("/b2c/printeticket")}
                          className="app-bar-link no-user"
                        >
                          Print E ticket
                        </a>
                      </li>
                      {/* <li>
                                            <a onClick={() => history.push("/b2c/cancelticket")} className="app-bar-link no-user">
                                                Cancel E ticket
                                            </a>
                                        </li> */}
                      <li>
                        <a
                          onClick={() => history.push("/auth/login-page")}
                          className="app-bar-link no-user"
                        >
                          Agent
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => history.push("/b2c/signin")}
                          className="app-bar-link no-user"
                        >
                          Sign In
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => {
                            history.push(
                              (userType === "agent" || userType === "direct_agent") ?
                               "/prelogin/registration" : "/b2c/register"
                            );
                          }}
                          className="app-bar-link no-user"
                        >
                          Register
                        </a>
                      </li>
                    </>
                  )
                )}
                <li className="app-bar-link-list-item dropdown-on-hover-list-item">
                  <a className="app-bar-link no-user dropdown-on-hover">
                    24x7 helpline
                  </a>
                  <ul className="dropdown-on-hover-menu">
                    <li>
                      <a
                        href={`tel:${AppConstant.countryDialCode}${AppConstant.supportContactView}`}
                      >
                        <img src={PhoneIcon} />+{AppConstant.countryDialCode}
                        &nbsp;
                        {AppConstant.supportContactView}
                      </a>
                    </li>
                    <li>
                      <a href={`mailto:${AppConstant.supportEmail}`}>
                        <img src={MailIcon} /> {AppConstant.supportEmail}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <OaTimer isTimerShow={props.isTimerShow} />
        </div>
      </div>
    </>
  );
};

export default HomePageNavbar;

const getServicePath = (serviceName) => {
  let servicePath = "";
  switch (serviceName) {
    case "flights": {
      servicePath = "flights";
      break;
    }

    case "buses": {
      servicePath = "buses";
      break;
    }

    case "hotels": {
      servicePath = "hotels";
      break;
    }

    case "recharge": {
      servicePath = "recharges";
      break;
    }

    case "insurance": {
      servicePath = "insurances";
      break;
    }

    case "insurances": {
      servicePath = "insurances";
      break;
    }

    case "moneytransfer": {
      servicePath = "dmt";
      break;
    }

    case "aeps":
    case "aadhaarpay":
    case "aadhaar_services": {
      servicePath = "aadhaar_services";
      break;
    }

    case "train": {
      servicePath = "trains";
      break;
    }

    default: {
      servicePath = "flights";
      break;
    }
  }
  return servicePath;
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    margin: 0,
    // position: "fixed",
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 9,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.background.defaultContrast,
    boxShadow: "0px 5px 3px -6px rgba(0,0,0,0.75)",
    "& a": {
      cursor: "pointer",
    },
    [theme.breakpoints.down(960)]: {
      top: 0,
      backgroundColor: theme.palette.primary.lightText,
    },
    "& .oa-navbar-container": {
      padding: "0 30px 0 80px",
      margin: 0,
      height: "75px",
      position: "relative",
      [theme.breakpoints.down(1441)]: {
        padding: "0 80px 0 80px",
      },
      [theme.breakpoints.down(768)]: {
        padding: "0 20px",
      },
    },
    "& .oa-navbar": {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    "&.oa-navbar-sticky": {
      top: "0 !important",
      backgroundColor: theme.palette.background.default,
      color: theme.palette.background.defaultContrast,
    },
    "& .oa-primary-bg": {
      backgroundColor: theme.palette.background.default,
      transition: "ease-in-out .8s",
      color: theme.palette.background.defaultContrast,
    },
    "& .oa-navbar-brand-wrapper": {
      color: theme.palette.primary.disabled,
      // marginRight: "auto",
      // color:"#000",
      marginTop: 8,
      marginBottom: 8,
      "& a": {
        color: theme.palette.primary.disabled,
        // color:"#000",
        textDecoration: "none",
      },
      "& img": {
        height: 53,
      },
    },
    "& .oa-navbar-menu-wrapper": {
      // marginLeft: "auto",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .navbar-account": {
      margin: "0 20px 0 10px",
      display: "flex",
      // flexWrap: "wrap",
      gap: "1rem",
      [theme.breakpoints.down(960)]: {
        display: "none",
      },
      listStyle: "none",
      "& li": {
        cursor: "pointer",
        fontSize: "13px",
      },

      "& .user_ac": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "0.3rem",
        // position: "relative",
        textAlign: "center",
        "& .my_ac": {
          position: "relative",
          display: "flex",
          gap: "0.3rem",
          "&:hover,:focus": {
            "& .show_ac": {
              display: "block",
              visibility: "visible",
              height: "200px",
              transitionDelay: "1s",
            },
          },
        },
        "& .show_ac": {
          position: "absolute",
          top: "4vh",
          left: "-6.6vw",
          width: "200px",
          height: "0px",
          zIndex: "111",
          background: "#fff",
          padding: "0.5rem",
          // display:'none',
          overflow: "hidden",
          visibility: "hidden",
          transition: "0.6s",
          boxShadow: "0 0 7px rgb(0 0 0 / 40%)",
          "& .ac_avatar": {
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            border: "1px solid rgba(255, 255, 255,0.6)",
            margin: "1rem auto",
          },

          "& ul": {
            listStyle: "none",
            marginTop: "1rem",
            padding: 0,
            "& li": {
              textAlign: "left",
              "& span": {
                margin: "0 0.5rem",
              },
            },
          },
        },

        "& .country": {
          background: "#eaeaea",
          padding: "0.13rem 0.5rem",
          borderRadius: "50px",
          marginBottom: "0.5rem",
        },
      },
    },

    "& .oa-navbar-menu": {
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
      margin: 0,
      position: "relative",
      "& li": {
        position: "relative",
        margin: "0 0.5rem",
        textTransform: "uppercase",
        // paddingBottom: "6px",
        transition: "0.3s",
        padding: "0 0.2rem",
        // paddingBottom:'10px',
        // borderBottom: `2.3px solid ${theme.palette.secondary.darkOrange}`,
        "&:hover,:focus": {
          // borderBottom: `3px solid ${theme.palette.secondary.darkOrange}`,
          "& a": {
            color: theme.palette.secondary.darkOrange,
          },
        },
        // "&:before":{
        //     content:"",
        //     position:"absolute",
        //     width:"300px",
        //     height:"10px",
        //     backgroundColor: theme.palette.secondary.darkOrange,
        //     bottom:0,
        //     left:0,
        //     zIndex: 11,
        // },
      },
      "& a": {
        color: theme.palette.secondary.defaultText,
        textTransform: "uppercase",
        cursor: "pointer",
        [theme.breakpoints.down(1441)]: {
          fontSize: 12,
        },

        "&.active": {
          borderBottom: `2px solid ${theme.palette.secondary.darkOrange}`,
          color: theme.palette.secondary.darkOrange,
        },
      },
      "&.oa-mobile-menu-show": {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.primary.LightGraySec,
        position: "absolute",
        left: 0,
        right: 0,
        top: 65,
        padding: "0 0 15px",
        "& .mobile-user-li": {
          display: "flex",
          flexWrap: "wrap",
          color: theme.palette.primary.shadedLightText,
          justifyContent: "space-evenly",
          alignItems: "center",
          // border: `solid 1px ${theme.palette.secondary.main}`,
          margin: "0 0 8px",
          "&:hover,:focus": {
            background: theme.palette.background.darkDefault,
          },
          "& .mu-li-item": {
            // margin: 10,
            "&:hover,:focus, a:hover, a:focus": {
              color: theme.palette.secondary.main,
              [theme.breakpoints.down(960)]: {
                color: theme.palette.text.default,
              },
            },
          },
        },
        "& .MuiSvgIcon-root": {
          fontSize: 12,
        },
      },
      [theme.breakpoints.down(960)]: {
        display: "none",
        "& li": {
          margin: "8px 0",
          textAlign: "center",
        },
      },
    },
    "& .oa-mobile-menu-toggler-wrapper": {
      display: "none",
      position: "absolute",
      right: 80,
      [theme.breakpoints.down(960)]: {
        display: "block",
      },
      [theme.breakpoints.down(768)]: {
        right: 20,
      },
    },
    "& .oa-mobile-menu-toggler": {
      cursor: "pointer",
      color: theme.palette.primary.defaultText,
    },
    "& .mobile-user-li": {
      [theme.breakpoints.up(991)]: {
        display: "none",
      },
    },
    "& .app-bar-link.no-user": {
      color: theme.palette.primary.darkText,
      "&:hover": {
        color: theme.palette.primary.active,
        [theme.breakpoints.down(960)]: {
          color: theme.palette.primary.darkText,
        },
      },
    },
    "& li": {
      "&.vert-border:not(:last-child)": {
        borderRight: `solid 1px ${theme.palette.primary.lightGray}`,
        paddingRight: 15,
        [theme.breakpoints.down(960)]: {
          borderRight: "none",
          paddingRight: "unset",
        },
      },
    },
    "& .app-bar-link-list-item": {
      "&.dropdown-on-hover-list-item": {
        position: "relative",
        "&:hover": {
          "& .dropdown-on-hover-menu": {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down(960)]: {
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              left: 0,
              zIndex: 1,
            },
          },
        },
        "& .dropdown-on-hover-menu": {
          display: "none",
          position: "absolute",
          top: 21,
          right: -10,
          // width: 230,
          background: theme.palette.text.defaultContrast,
          boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.75)",
          padding: 10,
          "& li": {
            listStyle: "none",
            margin: "3px 0",
          },
          "& a": {
            display: "flex",
            gap: 5,
            color: theme.palette.text.default,
          },
        },
        "& .mobile-dropdown-menu": {
          display: "none",
          height: 0,
          width: "100%",
          background: theme.palette.text.defaultContrast,
          boxShadow: "0px 0px 10px -5px rgba(0,0,0,0.75)",
          padding: 10,
          transition: "height 0.1s",
          "&.expanded": {
            display: "flex",
            flexDirection: "column",
            height: "auto",
            "& a": {
              color: theme.palette.text.default,
            },
            "& .balance-link": {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
          },
          "& li": {
            listStyle: "none",
          },
        },
      },
    },
  },
}));
