import React, {useState, useEffect} from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import {Formik, Form } from 'formik';
import OaLoading from 'pages/components/OaLoading';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormDateField from 'oahoc/OaFormDateField';
import GridItem from "components/Grid/GridItem.js";
import OaFormSelectField from 'oahoc/OaFormSelectField';

const IRCTCPGReportSearchForm = (props) => {
    const [isLoading] = useState(false);
    const [] = useState(true);
    const [agencyId, setAgencyId] = useState(props.agencyId);

    useEffect(() => {
        if(isLoading ){
         
        }
    }, [isLoading]);

    useEffect(() => {
        setAgencyId(props.agencyId);
    }, [props.agencyId])

    return(        
    <>
        {isLoading && <OaLoading />}
        {!isLoading && 
        <>
        <h4 className={"oa-form-header"}  style={{transform: ' scale(0.85)', transformOrigin: 'left'}} >Search IRCTCPG Report</h4>
        <Formik     
            initialValues={{
                irctcpgBookingFromDateDisplay: props.searchParams.irctcpgBookingFromDateDisplay,
                irctcpgBookingToDateDisplay: props.searchParams.irctcpgBookingToDateDisplay,
                irctcpgBookingFromDate: props.searchParams.irctcpgBookingFromDate,
                irctcpgBookingToDate: props.searchParams.irctcpgBookingToDate,
                agencyId: props.searchParams.agencyId,
                bookingStatus:props.searchParams.bookingStatus
            }}
            onSubmit= {(searchParams, {}) => {
                props.setIsLoading(true);
                searchParams.irctcpgBookingFromDate = dateFnsFormat(searchParams.irctcpgBookingFromDateDisplay, 'dd-MM-yyyy');
                searchParams.irctcpgBookingToDate = dateFnsFormat(searchParams.irctcpgBookingToDateDisplay, 'dd-MM-yyyy');
                props.target(searchParams);
                props.setIsLoading(false);
            }}>
            {({ values, setFieldValue }) => (
                <Form>
                    <GridContainer>
                        <OaFormTextField name="irctcpgApplicationReference" label="Reservation Id" md={4}/>
                       


                        <OaFormDateField 
                            value={values.irctcpgBookingFromDateDisplay}
                            selected={values.irctcpgBookingFromDateDisplay}
                            onChange={date => setFieldValue('irctcpgBookingFromDateDisplay', date)}
                            label="IRCTCPG From Date"
                            name="irctcpgBookingFromDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />


                        <OaFormDateField 
                            value={values.irctcpgBookingToDateDisplay}
                            selected={values.irctcpgBookingToDateDisplay}
                            onChange={date => setFieldValue('irctcpgBookingToDateDisplay', date)}
                            label="IRCTCPG To Date"
                            name="irctcpgBookingToDateDisplay"
                            minDate={values.dateFromDisplay}
                            md={4}
                        />

                        <GridItem  xs={12} sm={12} md={4}>
                            <OaFormSelectField 
                                  label="Status" 
                                  name="bookingStatus" 
                                  value={values.bookingStatus} 
                                  options={[
                                      {value: 'All', label: 'All'},
                                      {value: 'successful', label: 'Successful'},
                                      {value: 'failed', label: 'Failed'}
                                  ]}
                              />
                          </GridItem>

                    </GridContainer>
                    <Button color="primary" type="submit">Search</Button>
                </Form>
            )}
        </Formik>
        </>
        }
    </>
    )
}

export default IRCTCPGReportSearchForm;