import React, { useState, useEffect } from 'react';

import { Formik, Form, Field, FieldArray } from 'formik';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Collapse from '@material-ui/core/Collapse';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button.js";

import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormTextField from 'oahoc/OaFormTextField';
import OaFormAlerts from 'pages/components/OaFormAlerts';
import OaFormRadio from 'oahoc/OaFormRadio';
import OaCard from 'oahoc/OaCard';

import WebApi from "api/ApiConstants";
import { apiCall } from 'oautils/oaDataUtils';
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import { getQueryVar } from 'oautils/oaCommonUtils';

import * as Yup from 'yup';
import _ from "lodash";
import "./style.css";


export class Commission {
  constructor(id, supplierId, serviceId, categoryId, subCategoryId,
    userId, chargePercentage, chargeFixed,
    chargePercentageMinimum, chargeFixedMinimum, subCategoryName) {
    this.id = id;

    this.chargePercentage = chargePercentage;
    this.chargeFixed = chargeFixed;

    this.chargePercentageMinimum = chargePercentageMinimum;
    this.chargeFixedMinimum = chargeFixedMinimum;

    this.supplierId = supplierId;
    this.serviceId = serviceId;
    this.categoryId = categoryId;
    this.subCategoryId = subCategoryId;
    this.userId = userId;
    this.subCategoryName = subCategoryName;
  }
}

export const validateSchema = Yup.object().shape({
  commission: Yup.array()
    .of(
      Yup.object().shape({
        chargePercentage: Yup.number()
          .nullable(true)
          .when('chargePercentageMinimum', {
            is: (chargePercentage) => chargePercentage != null,
            then: Yup.number().required('Charge variable is required').typeError('You must specify a number'),
            otherwise: Yup.number().typeError('You must specify a number')
          })
        // .test(
        //     "min_charge_percentage_test",
        //     "Invalid charge",
        //     function() {
        //       const { chargePercentage,chargePercentageMinimum } = this.parent;
        //       return chargePercentage >= chargePercentageMinimum  || chargePercentageMinimum === null;
        // })
        ,

        chargeFixed: Yup.number()
          .nullable(true)
          .when('chargeFixedMinimum', {
            is: true, is: (chargeFixedMinimum) => chargeFixedMinimum != null,
            then: Yup.number().required('Charge fixed is required').typeError('You must specify a number'),
            otherwise: Yup.number().typeError('You must specify a number')
          })
        // .test(
        //     "min_charge_fixed_test",
        //     "Invalid charge",
        //     function() {
        //       const { chargeFixed,chargeFixedMinimum } = this.parent;
        //       return chargeFixed >= chargeFixedMinimum  || chargeFixedMinimum === null;
        // })
        ,
      })
    )
    .min(1, "Need at least a commission")
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: 50,
    backgroundColor: theme.palette.background.paper,
  },
}));


const CommissionAndChargeForm = (props) => {

  const [collapsed, setCollapsed] = useState(true);
  const [supplier, setSupplier] = useState([]);
  const [service, setService] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [status, setStatus] = useState(true);
  const [value, setValue] = useState(0);
  const [commission, setCommission] = useState([]);
  const [supplierId, setSupplierId] = useState(0);
  const [serviceId, setServiceId] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [userId, setUserId] = useState(props.agentId == undefined ? 0 : props.agentId);
  //const [currentTab, setCurrentTab] = useState(props.currentTab == undefined ? 'distributor':props.currentTab);

  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  useEffect(() => {
    setIsLoading(true);
    // console.log('props.currentTab');
    // console.log(props.currentTab);

    apiCall(WebApi.getSupplier, {}, (response) => {
      // console.log(response)
      if (response.success) {
        setSupplier(response.data);
        populateAllDD(response.data);
      }
      setIsLoading(false);
      setIsError(response.success === false);
    });
  }, []);


  const populateAllDD = (data) => {

    let supplier = data, service;
    for (let i = 0; i < supplier.length; i++) {
      if (supplier[i].id == 2) {
        setService(supplier[i].service);

        setSupplierId(supplier[i].id);
        service = supplier[i].service;

        for (let j = 0; j < service.length; j++) {
          if (service[j].id == 21) {
            setServiceId(service[j].id);
            service[j].category.sort(function (a, b) {
              if (a.id < b.id) return -1;
              if (a.id > b.id) return 1;
              return 0;
            });

            setCategoryId(service[j].category[0].id);
            setCategory(service[j].category);
            setSubCategoryData(service[j].category, 0, supplier[i].id, service[j].id, service[j].category[0].id);
          }
        }

      }
    }
  }

  const supplierOnChange = function (value) {
    for (let i = 0; i < supplier.length; i++) {
      if (supplier[i].id == value) {
        setService(supplier[i].service);

        setSupplierId(supplier[i].id);
      }
    }
  }

  const serviceOnChange = function (value) {
    for (let i = 0; i < service.length; i++) {
      if (service[i].id == value) {
        setServiceId(service[i].id);
        service[i].category.sort(function (a, b) {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
        setCategoryId(service[i].category[0].id);
        setCategory(service[i].category);
        setSubCategoryData(service[i].category, 0, supplierId, service[i].id, service[i].category[0].id);
      }
    }
  }

  const categoryOnChange = function (event, newTabIndex) {
    setValue(newTabIndex);

    setCategoryId(category[newTabIndex].id);

    setSubCategoryData(category, newTabIndex, supplierId, serviceId, category[newTabIndex].id);
  }

  const setSubCategoryData = (category, newTabIndex, supplierId, serviceId, categoryId) => {
    setIsLoading(true);

    apiCall(WebApi.getDistributorCommissionCharge, {
      "categoryId": category[newTabIndex].id,
      "serviceId": serviceId,
      "supplierId": supplierId,
      "userId": userId
    }, (response) => {

      if (response.success) {
        let subCategories = category[newTabIndex].subCategory;

        subCategories.sort(function (a, b) {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });

        let commissionCharge = [];

        for (let index = 0; index < subCategories.length; index++) {
          let commissionIndex = response.data.findIndex((obj => obj.subCategoryId == subCategories[index].id))
          let companyToDistributor = response.data[commissionIndex];
          subCategories[index].companyToDistributor = companyToDistributor;
          if (companyToDistributor)
            commissionCharge.push(new Commission(companyToDistributor.id, supplierId, serviceId, categoryId, subCategories[index].id, userId, companyToDistributor.chargePercentage, companyToDistributor.chargeFixed, companyToDistributor.chargePercentageMinimum, companyToDistributor.chargeFixedMinimum, subCategories[index].subCategoryName));
          else
            commissionCharge.push(new Commission(null, supplierId, serviceId, categoryId, subCategories[index].id, userId, null, null, null, null, subCategories[index].subCategoryName));
        }

        // console.log(subCategories)
        setCommission(commissionCharge);
        setSubCategory(subCategories);

        setIsLoading(false);
      }
    });
  }

  return (
    <>
      <OaCard style={{ display: 'none' }}>
        <h4 className={"oa-form-header"} onClick={collapse} style={{ transform: ' scale(0.85)', transformOrigin: 'left' }} >Search commission</h4>
        <Collapse in={collapsed}>

          <Formik>

            <GridContainer>
              <OaFormSelectField
                label="Supplier"
                name="supplierId"
                options={supplier}
                optionValue={'id'}
                optionLabel={'supplierName'}
                onChange={(value) => {
                  supplierOnChange(value)
                }}
                md={4}
              />
              {service.length > 0 &&
                <OaFormSelectField
                  label="Service"
                  name="serviceId"
                  options={service}
                  optionValue={'id'}
                  optionLabel={'serviceDescription'}
                  onChange={(value) => {
                    serviceOnChange(value)
                  }}
                  md={4}
                />
              }
            </GridContainer>
          </Formik>
        </Collapse>
      </OaCard>

      {category.length > 0 &&
        <OaCard>


          <AppBar position="static" color="default" className="oa-tabs-primary">
            <Tabs
              value={value}
              onChange={categoryOnChange}
              indicatorColor="secondary"
              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {category.map((category, index) => {
                return (<Tab icon={<img className="plane-icon" src={GET_FILE_UPLOAD + "?fileName=" + category.icon + "&type=EMAIL_IMAGES"} />}
                  label={category.categoryName}   {...a11yProps(index)} />)
              })}
            </Tabs>
          </AppBar>

          {category.map((category, index) => {

            return (<TabPanel value={value} index={index}>
              {subCategory.length > 0 && <>
                <OaFormAlerts
                  isSaving={isSaving}
                  isSuccess={isSuccess}
                  isError={isError}
                  infoText={infoText}
                  setIsError={setIsError}
                  setIsSuccess={setIsSuccess}
                />
                <GridContainer style={{ borderBottom: 'solid #444444', padding: 4 }}>

                  <GridItem md={4}  ></GridItem>



                  <GridItem md={8}>
                    <GridContainer >
                      <GridItem md={12} align={'center'} className="oa-head-primary">Markup<span style={{ paddingBottom: 10 }}>*</span></GridItem>
                    </GridContainer>
                    <GridContainer >
                      <GridItem md={6} align={'center'} className="oa-head-primary">Variable(%)</GridItem>
                      <GridItem md={6} align={'center'} className="oa-head-primary">Fixed(&#x20b9;)</GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>


                {!isLoading &&
                  <Formik
                    initialValues={{ commission: commission }}
                    validationSchema={validateSchema}
                    onSubmit={(values) => {
                      apiCall(WebApi.updateDistributorCommissionCharge, values, (response) => {
                        setTimeout(() => {
                          setIsError(false);
                          setIsSuccess(false);
                        }, 5000);
                        setIsError(!response.data.success);
                        setIsSuccess(response.data.success);
                        setInfoText(response.data.message);
                      });

                    }}
                    render={({ values, errors, touched, handleReset }) => {
                      return (
                        <Form>

                          <input type="text" name="testHidden" hidden />

                          <FieldArray
                            name="commission"
                            render={({ }) => (
                              <>
                                {values.commission.length > 0 &&
                                  values.commission.map((commission, index) => (

                                    <GridContainer key={index} style={{ backgroundColor: index % 2 === 0 ? '#efefef' : '#ffffff', padding: "10px" }} className="vertical-center-commision">
                                      <GridItem md={4} align={'left'} >
                                        {commission.subCategoryName}

                                        <Field
                                          className="form-control"
                                          name={`commission.${index}.id`}
                                          placeholder="id"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />

                                        <Field
                                          className="form-control"
                                          name={`commission.${index}.supplierId`}
                                          placeholder="supplierId"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />

                                        <    Field
                                          className="form-control"
                                          name={`commission.${index}.serviceId`}
                                          placeholder="serviceId"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />


                                        <Field
                                          className="form-control"
                                          name={`commission.${index}.categoryId`}
                                          placeholder="categoryId"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />


                                        <Field
                                          className="form-control"
                                          name={`commission.${index}.subCategoryId`}
                                          placeholder="subCategoryId"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />


                                        <Field
                                          className="form-control"
                                          name={`commission.${index}.userId`}
                                          placeholder="userId"
                                          type="input"
                                          style={{ display: 'none' }}
                                        />
                                      </GridItem>



                                      <GridItem md={8}>
                                        <GridContainer >
                                          <GridItem md={6} >
                                            <GridContainer >
                                              {/* <GridItem md={2} className="vertical-center-commision" align="right" style={{display:values.commission[index].chargePercentageMinimum === null ?'none':''}}>
                                                {(values.commission[index].chargePercentageMinimum !=null ? values.commission[index].chargePercentageMinimum : 0)} +
                                              </GridItem> */}
                                              <GridItem md={12} >
                                                <OaFormTextField name={`commission.${index}.chargePercentage`}
                                                  style={{ display: values.commission[index].chargePercentageMinimum === null ? 'none' : '' }}
                                                  placeholder='Percentage'
                                                />

                                                {errors &&
                                                  errors.commission &&
                                                  errors.commission[index] &&
                                                  errors.commission[index].chargePercentage &&
                                                  touched &&
                                                  touched.commission &&
                                                  touched.commission[index] &&
                                                  touched.commission[index].chargePercentage && (
                                                    <div className="field-error">
                                                      {errors.commission[index].chargePercentage}
                                                    </div>
                                                  )}
                                              </GridItem>
                                            </GridContainer >

                                          </GridItem>
                                          <GridItem md={6} >


                                            <GridContainer >
                                              {/* <GridItem md={2} className="vertical-center-commision" align="right" style={{display:values.commission[index].chargeFixedMinimum === null ?'none':''}}>
                                        {(values.commission[index].chargeFixedMinimum !=null ? values.commission[index].chargeFixedMinimum : 0)} +
                                        </GridItem> */}
                                              <GridItem md={12} >
                                                <OaFormTextField name={`commission.${index}.chargeFixed`}
                                                  style={{ display: values.commission[index].chargeFixedMinimum === null ? 'none' : '' }}
                                                  placeholder='Fixed'
                                                />

                                                {errors &&
                                                  errors.commission &&
                                                  errors.commission[index] &&
                                                  errors.commission[index].chargeFixed &&
                                                  touched &&
                                                  touched.commission &&
                                                  touched.commission[index] &&
                                                  touched.commission[index].chargeFixed && (
                                                    <div className="field-error">
                                                      {errors.commission[index].chargeFixed}
                                                    </div>
                                                  )
                                                }
                                              </GridItem>
                                            </GridContainer >
                                          </GridItem>
                                        </GridContainer>
                                      </GridItem>
                                    </GridContainer>

                                  ))}

                              </>
                            )}
                          />


                          <div style={{ marginLeft: -15, marginTop: 10 }}>
                            <Button variant="contained" color="primary"
                              onClick={(event) => {
                                event.preventDefault();
                                handleReset();
                              }}
                            >
                              Reset
                            </Button>
                            <Button variant="contained" color="primary" type="submit" className="btn btn-block btn-primary">
                              Submit
                            </Button>
                            <br />*When fixed markup and variable markup both are given, we prefer fixed markup in place of variable.
                          </div>
                          {/* {errors &&
                    _.isString(errors.commission) &&
                    touched &&
                    _.isArray(touched.commission) && (
                      <div className="field-error">{errors.commission}</div>
                    )}
                  <div className={"row"}>
                    <div className={"col-12"}>
                      <code>
                        <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                      </code>
                    </div>
                    <div className={"col-12"}>
                      <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
                    </div>
                    <div className={"col-12"}>
                      <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
                    </div>
                  </div> */}

                        </Form>
                      );
                    }}
                  />
                }

              </>}
            </TabPanel>)
          })}
        </OaCard>}
    </>)
}

export default CommissionAndChargeForm;