import {
    Grid,
    Typography,
    FormControlLabel,
    Checkbox,
    FormControl,
    RadioGroup,
    Radio,
    Button,
    useMediaQuery,
  } from "@material-ui/core";
  import GridContainer from "components/Grid/GridContainer";
  import CloseIcon from "@material-ui/icons/Close";
  import IconButton from "@material-ui/core/IconButton";
  import React, { useContext, useEffect, useState } from "react";
  import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
  import Menu from "pages/b2c/component/Menu";
  import Flight from "assets/img/6E.png";
  import Profile from "./component/Profile";
  import Accordion from "@material-ui/core/ExpansionPanel";
  import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
  import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
  import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
  import moment from "moment";
  import { withRouter } from "react-router-dom";
  import { apiCall } from "oautils/oaDataUtils";
  import WebApi from "api/ApiConstants";
  import OaFormAlerts from "pages/components/OaFormAlerts";
  import { AppStateContext } from "layouts/AppStateProvider";
  import { connect } from "react-redux";
  import ExpandLess from "@material-ui/icons/ExpandLess";
  import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
  import PrintIcon from "@material-ui/icons/Print";
  import DescriptionIcon from "@material-ui/icons/Description";
  import CancelIcon from "@material-ui/icons/Cancel";
  import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
  import { ArrowBack } from "@material-ui/icons";
  import { GET_FILE_UPLOAD } from "api/ApiConstants";
  import { localforageGetItem } from "oautils/oaForageUtils";
  
  const MuiAccordion = withStyles({
    root: {
      width: "100%",
      border: "0px",
      boxShadow: "none",
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&:before": {
        display: "none",
      },
      "&$expanded": {
        margin: "auto",
      },
    },
    expanded: {},
  })(Accordion);
  
  const MuiAccordionSummary = withStyles({
    root: {
      backgroundColor: "white",
      padding: 0,
      margin: 0,
      minHeight: "auto",
    },
    expanded: {},
  })(AccordionSummary);
  
  const MuiAccordionDetails = withStyles((theme) => ({
    root: {
      padding: 0,
      background: "white",
    },
  }))(AccordionDetails);
  
  const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: "100%",
      margin: "auto",
      paddingTop: "40px",
      paddingBottom: "40px",
      //marginTop: 100,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    rightGrid: {
      background: "#fff",
      paddingBottom: "6px",
      textAlign: "center",
      border: `1px solid ${theme.palette.primary.disabled}`,
      padding: "20px",
      borderRadius: "5px",
      alignSelf: "center",
      [theme.breakpoints.down("sm")]: {
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        padding: 5,
      },
    },
    typo1: {
      fontSize: "11px",
      fontWeight: 500,
      lineHeight: "1.5",
      color: theme.palette.primary.darkText,
      marginLeft: 5,
      letterSpacing: "0.5px !important",
    },
    typo2: {
      fontSize: "13px",
      paddingRight: "10px",
      paddingLeft: "10px",
      fontWeight: 700,
      color: theme.palette.primary.darkText,
      letterSpacing: "0.5px !important",
      borderRight: "1px solid",
      [theme.breakpoints.down(500)]: {
        borderRight: "0px solid",
      },
    },
    typo3: {
      fontSize: "13px",
      fontWeight: 700,
      color: theme.palette.primary.darkText,
      letterSpacing: "0.5px !important",
      paddingLeft: "10px",
    },
    typo4: {
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.palette.primary.darkText,
    },
    typo5: {
      fontSize: 12,
      color: theme.palette.primary.darkText,
      fontWeight: 500,
    },
    fai: {},
    typo6: {
      textAlign: "center",
      color: theme.palette.primary.darkText,
      fontSize: "12px",
      padding: "0px 10px",
    },
    img: {
      width: "26px",
      marginTop: "5px",
      verticalAlign: " baseline",
    },
    typo7: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.primary.darkText,
    },
    typo8: {
      color: "gray",
      fontSize: "13px",
    },
  
    typo9: {
      lineHeight: 1.5,
      color: theme.palette.primary.darkText,
      fontSize: "12px",
    },
    typo10: {
      fontSize: 12,
      color: theme.palette.primary.darkText,
      fontWeight: 400,
    },
    heading2: {
      fontSize: 18,
      fontWeight: 500,
      marginTop: 10,
      marginBottom: 10,
    },
    table: {
      width: "100%",
      marginBottom: "1rem",
      color: theme.palette.primary.darkText,
      borderCollapse: "collapse",
  
      "& thead": {
        "& tr": {
          "& th": {
            border: `1px solid ${theme.palette.primary.disabled} !important`,
            fontSize: "12px !important",
            fontWeight: "500 !important",
  
            padding: ".75rem",
            color: theme.palette.primary.darkText,
            [theme.breakpoints.down("sm")]: {
              padding: "0px",
              fontSize: "14px !important",
            },
          },
        },
      },
      "& tbody": {
        "& tr": {
          "& td": {
            border: `1px solid ${theme.palette.primary.disabled} !important`,
            fontSize: "12px !important",
            fontWeight: "400 !important",
            padding: ".75rem",
            [theme.breakpoints.down("sm")]: {
              padding: "0px",
              fontSize: "14px !important",
            },
          },
        },
      },
    },
  
    bookingGrid: {
      marginLeft: "60%",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },
    Grid1: {
      borderRight: `1px solid ${theme.palette.primary.gray}`,
      marginTop: 10,
      [theme.breakpoints.down("xs")]: {
        borderRight: "none",
      },
    },
    Grid2: {
      paddingLeft: 15,
      paddingBottom: 25,
      paddingRight: 25,
      [theme.breakpoints.down("xs")]: {
        paddingRight: 15,
      },
    },
    backButton: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.primary.main,
      "& .MuiSvgIcon-root": {
        fontSize: 16,
        marginRight: 5,
      },
      "&:hover": {
        color: theme.palette.primary.sub,
      },
    },
  }));
  
  const HotelFareDetailsB2C = (props) => {
    const classes = useStyles();
    console.log(props.hotel);
    const hotelData = props.hotel
    // console.log("ticket");
  
    const [onwardCancelPassengers, setOnwardCancelPassengers] = useState([]);
    const [returnCancelPassengers, setReturnCancelPassengers] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [onwardExpanded, setOnwardExpanded] = useState(false);
    const [returnExpanded, setReturnExpanded] = useState(false);
    const [value, setValue] = useState("");
    const [valueReturn, setValueReturn] = useState("");
    const [cancellationType, setCancellationType] = useState("");
    const [cancellationTypeReturn, setCancellationTypereturn] = useState("");
  
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const { setIsCancelled } = useContext(AppStateContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
    // console.log(props.location.state.index);
    //let userType = "";
    // console.log("itin props",props);
    // if (props.history.location.state && props.history.location.state.userType) {
    //  userType = props.history.location.state.userType;
    // }
  
    const handleChangeRadio = (event) => {
      setValue(event.target.value);
    };
  
    const handleChangeRadioReturn = (event) => {
      setValueReturn(event.target.value);
    };
  
    const handleChangeCancellation = (event) => {
      setCancellationType(event.target.value);
    };
    const handleChangeCancellationReturn = (event) => {
      setCancellationTypereturn(event.target.value);
    };
  
    // const ticket = props.flightList[props.location?.state?.index];
    const ticket = props.location?.state?.ticket;
  
    //const userType=props.location?.state?.userType;
    const { setUserTypeb2c } = React.useContext(AppStateContext);
    const [userTypeAbv, setUserTypeAbv] = React.useState(null);
  
    // console.log("ticket", ticket);
  
    const handleChange = (ev, passenger) => {
      const temp = [...onwardCancelPassengers];
      const index = temp.findIndex((a) => a === passenger);
      if (index !== -1) {
        temp.splice(temp.indexOf(index), 1);
      } else {
        temp.push(passenger);
      }
  
      // if (ev.target.checked) {
      //   setOnwardCancelPassengers([...onwardCancelPassengers, passenger]);
      // } else {
      //   setOnwardCancelPassengers(value => value.filter(obj => { return obj.uid !== passenger.uid }));
      // }
  
      setOnwardCancelPassengers(temp);
    };
    const handleReturnChange = (passenger) => {
      const temp = [...returnCancelPassengers];
      const index = temp.findIndex((a) => a === passenger);
      if (index !== -1) {
        temp.splice(temp.indexOf(index), 1);
      } else {
        temp.push(passenger);
      }
      // console.log("returnCancelPassengers", temp);
      setReturnCancelPassengers(temp);
    };
  
    let userType = props?.location?.state?.userType;
    
  
    return (
      <div className={classes.root}>
          <Grid container spacing={isMobile ? 0 : 2} md={12}justifyContent="center">
          {/* {userType != "GUEST" && userType != "agent" && userType != "direct_agent" &&  */}
  
          {/* {userType === "R" && (
            <>
              <Grid item md={3} xs={12} style={{ padding: 0 }}>
                <Menu />
                <div style={{ marginLeft: 15, marginRight: 15, marginTop: 10 }}>
                  <Profile />
                </div>
              </Grid>
            </>
          )} */}
  
          {/* } */}
  
          <Grid item md={12} xs={12} style={{ padding: isMobile && 15 }}>
            {/* <Grid
              container
              item
              md={12}
              xs={12}
              sm={12}
              style={{
                background: "#fff",
                border: `1px solid ${theme.palette.primary.disabled}`,
              // border: "1px solid #eee",
                borderRadius: "5px",
                boxShadow: `0px 0px 6px -1px ${theme.palette.primary.disabled}`,
              }}
            >
              <Grid item md={12}>
                <OaFormAlerts
                  isSaving={isSaving}
                  isSuccess={isSuccess}
                  isError={isError}
                  infoText={infoText}
                  setIsError={setIsError}
                  setIsSuccess={setIsSuccess}
                />
              </Grid>
           
            </Grid> */}
  
          {/* <div>Hottel fre details</div> */}<Grid
            item
            md={12}
            sx={12}
            sm={12}
            spacing={2}
            style={{
              background: "#fff",
              paddingBottom: "6px",
              textAlign: "center",
              border: "1px solid #bfb6b6",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <Grid
              container
              item
              md={12}
              style={{
                background: "#fff",
                border: "1px solid #dcdcdc",
                borderRadius: "5px",
                boxShadow: "0px 0px 6px -1px #b3b3b3",
                marginBottom: 20,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  background: "#e2e2e2",
                  padding: "10px",
                }}
              >
                <Grid item container md={12} xs={12}>
                  <Grid item>
                    <i
                      className="fa fa-plane"
                      aria-hidden="true"
                      style={{ opacity: 0.5, fontSize: 22 }}
                    ></i>
                  </Grid>
                  <div item>
                    <Typography className={classes.typo1}>
                      Total Guest(s):     {`${hotelData.guestDetails.length}`}
                      {/* {ticket.onwardFlightDetails.summary.adult +
                        ticket.onwardFlightDetails.summary.child +
                        ticket.onwardFlightDetails.summary.infant} */}
                    </Typography>
                  
                  </div>
                </Grid>
                <Grid item container md={12} xs={12}>
                  <Grid item>
                    <Grid container >
                      <Typography className={classes.typo2}>
                        Check:{" "}
                        {moment(
                                              hotelData.roomDetails.details[0].checkinDate
                        ).format("DD MMM'YY")}
                      </Typography>
                      <Typography className={classes.typo2}>
                      {" "} CheckOut :{" "}
                        {moment(
                                              hotelData.roomDetails.details[0].checkoutDate
                        ).format("DD MMM'YY")}
                      </Typography>
                      <Typography className={classes.typo2}>
                      Status : {hotelData?.status}
                      </Typography>
                    </Grid>
                    
                
                <Grid
                  continer
                  item
                  xs={12}
                  md={4}
                  alignItems="center"
                  style={{ marginTop: 15 }}
                >
                 
                </Grid>
        
                  </Grid>
                </Grid>
              </Grid>
           
            </Grid>
            <div style={{overflowX: "scroll"}}>
            <table className={classes.table}>
                <thead>
                  <tr>
                    <th scope="col">Passenger Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Age</th>
                    <th scope="col">ID Type</th>
                    <th scope="col">ID</th>
                    {/* <th scope="col">Others</th> */}
                    {/* <th scope="col">Cancel</th> */}
                  </tr>
                </thead>
                <tbody>
                  {hotelData?.guestDetails.map((passenger, index) => (
                    <tr key={index}>
                      <td>{`${passenger.title}. ${passenger.givenname} ${passenger.surname}`}</td>
                      <td>
                        {hotelData?.status}
                      </td>
                      <td>{passenger?.age}</td>
                      <td>{passenger?.idType}</td>
                      <td>{passenger?.idNumber}</td>
                 
                    </tr>
                  ))}
                </tbody>
              </table>
              
            </div>
            Total Amount: {hotelData.bookingAmount}
                 </Grid>
      
          </Grid>
        </Grid>
      </div>
    );
  };
  
  function mapStateToProps(state, props) {
    return {
      flightList: state.flightList.flightList,
    };
  }
  export default withRouter(connect(mapStateToProps)(HotelFareDetailsB2C));
  