import React, { useState, useEffect } from 'react';
import bus from 'assets/img/emailbus.png';
import dateFnsFormat from 'date-fns/format';
import { apiCall } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import 'assets/css/BusTicket.css'
import { GET_FILE_UPLOAD } from "api/ApiConstants";

const BusTicketResult = (props) => {
  const [basefare, setBasefare] = useState(0.00);
  const [taxes, setTaxes] = useState(0.00);
  const [gst, setGst] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  const [discount, setDiscount] = useState(0.0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = props.state;

  useEffect(() => {
    setRenderData(props.data);
  }, [props])

  const setRenderData = (data) => {

    let _basefare = 0;
    let _taxes = 0;
    let _gst = 0;
    let _total = 0;
    let _discount = 0;

    if (!isPerPassenger) {
      data.GetBookingDetailResult.Itinerary.Passenger.map((passenger, index) => {
        _basefare = _basefare + passenger.Seat.Price.PublishedPrice;
        _gst = _gst + passenger.Seat.Price.GST.CGSTAmount + passenger.Seat.Price.GST.CessAmount + passenger.Seat.Price.GST.IGSTAmount + passenger.Seat.Price.GST.SGSTAmount;
        _taxes = _taxes + passenger.Seat.Price.CommissionCharge.grossProductPrice - passenger.Seat.Price.PublishedPrice - (passenger.Seat.Price.GST.CGSTAmount + passenger.Seat.Price.GST.CessAmount + passenger.Seat.Price.GST.IGSTAmount + passenger.Seat.Price.GST.SGSTAmount);
        _total = _total + passenger.Seat.Price.CommissionCharge.grossProductPrice;
      });
    } else {
      _basefare = _basefare + props.passenger.Seat.Price.PublishedPrice;
      _gst = _gst + props.passenger.Seat.Price.GST.CGSTAmount + props.passenger.Seat.Price.GST.CessAmount + props.passenger.Seat.Price.GST.IGSTAmount + props.passenger.Seat.Price.GST.SGSTAmount;
      _taxes = _taxes + props.passenger.Seat.Price.CommissionCharge.grossProductPrice - props.passenger.Seat.Price.PublishedPrice - (props.passenger.Seat.Price.GST.CGSTAmount + props.passenger.Seat.Price.GST.CessAmount + props.passenger.Seat.Price.GST.IGSTAmount + props.passenger.Seat.Price.GST.SGSTAmount);
      _total = _total + props.passenger.Seat.Price.CommissionCharge.grossProductPrice;
    }
    _discount = data.GetBookingDetailResult.discountVoucherValue;

    setBasefare(_basefare);
    setTaxes(_taxes + parseFloat(isPerPassenger ? props.markup / data.GetBookingDetailResult.Itinerary.Passenger.length : props.markup));
    setGst(_gst);
    setTotal(_total + (parseFloat(isPerPassenger ? props.markup / data.GetBookingDetailResult.Itinerary.Passenger.length : props.markup)) - _discount);
    setDiscount(_discount);
  }

  return (
    <div style={{ borderBottom: `2px solid ${theme.palette.background.dark}` }} className="print-view-style">
      <section style={{ paddingBottom: '0px ', padding: '2rem 1rem', display: 'block' }}>
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', boxSizing: 'border-box' }} className="top-section-wrapper">
            <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }} className='brand-logo-wrapper'>
              {/* <img src={logo} style={{height: '50px', float: 'left', verticalAlign: 'middle', borderStyle: 'none'}} /> */}
              {props?.data?.GetBookingDetailResult?.agencyInfo?.logo !== null && props.data.GetBookingDetailResult.agencyInfo?.agencyId &&
              props?.data?.GetBookingDetailResult?.agencyInfo?.logo !== "" && props?.data?.GetBookingDetailResult?.agencyInfo?.logo !== "NA" && 
                props?.data?.GetBookingDetailResult?.agencyInfo?.logo !== "N/A" && props?.data?.GetBookingDetailResult?.agencyInfo?.logo !== "n/a" ?
                <img src={GET_FILE_UPLOAD + "?fileName=" + props.data.GetBookingDetailResult.agencyInfo?.logo + "&type=AGENT_LOGO&loggedInUserId=" + props.data.GetBookingDetailResult.agencyInfo.agencyId} alt="fight icon" style={{ height: '50px', float: 'left', verticalAlign: 'middle', borderStyle: 'none' }} />
                :
                <img src={props.companylogo} style={{ height: '50px', float: 'left', verticalAlign: 'middle', borderStyle: 'none' }} />
              }
            </div>
            <div style={{ textAlign: 'center', flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }} className='service-text-title-wrapper'>
              <h3 style={{ fontSize: '1.75rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ verticalAlign: 'middle', fontWeight: 'bolder', boxSizing: 'border-box' }} className="Header">Bus Ticket</b></h3>
            </div>
            {!isAddressHide && props.data.GetBookingDetailResult && props.data.GetBookingDetailResult.agencyInfo &&
              <div style={{ textAlign: 'right', flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }} className='ticket-info-outer-wrapper'>
                <div style={{ float: 'right', boxSizing: 'border-box' }} className='ticket-info-text-wrapper'><h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder' }}>{props.data.GetBookingDetailResult.agencyInfo.agencyName}</b></h5>
                  <span style={{ color: theme.palette.primary.darkText, float: 'right ', fontWeight: 'bolder', display: 'block' }}>{props.data.GetBookingDetailResult.agencyInfo.address}, {props.data.GetBookingDetailResult.agencyInfo.city}, {props.data.GetBookingDetailResult.agencyInfo.state}</span>
                  <span style={{ color: theme.palette.primary.darkText, float: 'right ', fontWeight: 'bolder', display: 'block' }}>{props.data.GetBookingDetailResult.agencyInfo.email}</span>
                  <span style={{ color: theme.palette.primary.darkText, float: 'right ', fontWeight: 'bolder', display: 'block' }}>{props.data.GetBookingDetailResult.agencyInfo.mobileNo}</span>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
      <div style={{ paddingBottom: '0px ', paddingTop: '1rem ', boxSizing: 'border-box' }}>
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <div style={{ backgroundColor: theme.palette.background.ghostWhite, boxSizing: 'border-box' }}>

            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', padding: '1rem ', boxSizing: 'border-box' }}>
              <div style={{ flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
                <span style={{ fontSize: '80%', fontWeight: 400 }}>Supplier PNR</span><br />
                <h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder' }} className="pnrinfo">{props.data.GetBookingDetailResult.Itinerary.TravelOperatorPNR}</b></h5>
              </div>
              <div style={{ flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }}>
                <span style={{ fontSize: '80%', fontWeight: 400 }}>Booking Reference</span><br />
                <h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="pnrinfo">{props.data.GetBookingDetailResult.referenceno}</b></h5>
              </div>
              <div style={{ flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'right', boxSizing: 'border-box' }}>
                <span style={{ fontSize: '80%', fontWeight: 400 }}>Ticket Number</span><br />
                <h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder' }} className="pnrinfo">{props.data.GetBookingDetailResult.Itinerary.TicketNo}</b></h5>
              </div>
              <div style={{ flex: '0 0 25%', maxWidth: '25%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'right', boxSizing: 'border-box' }}>
                <span style={{ fontSize: '80%', fontWeight: 400 }}>Issue Date</span><br />
                <h5 style={{ fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder' }} className="pnrinfo">{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.InvoiceCreatedOn), 'do MMM yyyy')}</b></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingBottom: '0px ', paddingTop: '0px ' }}>
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <div className="bg-box" style={{ backgroundColor: theme.palette.primary.main, boxSizing: 'border-box' }}>
            <div style={{ padding: '1rem ', display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', boxSizing: 'border-box' }}>
              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }}>
                <b style={{ fontSize: '80%', fontWeight: 400, color: theme.palette.primary.contrastText }}>Departure</b><br />
                <h4 style={{ color: theme.palette.primary.contrastText, fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="depAndDest">{props.data.GetBookingDetailResult.Itinerary.Origin}</b></h4>
                <h5 style={{ color: theme.palette.primary.contrastText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }} className="depAndDest">{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.DepartureTime), 'hh:mm a')}</h5>
                <span style={{ color: theme.palette.primary.contrastText, fontSize: '80%', fontWeight: 400 }}>{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.DepartureTime), 'do MMM yyyy')}</span>
              </div>
              <div style={{ textAlign: 'center', flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }}>
                <img src={bus} style={{ height: '117px' }} />
              </div>
              <div style={{ textAlign: 'right', flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }}>
                <b style={{ fontSize: '80%', fontWeight: 'bolder', color: theme.palette.primary.contrastText, boxSizing: 'border-box' }}>Arrival</b><br />
                <h4 style={{ color: theme.palette.primary.contrastText, fontSize: '1.5rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="depAndDest">{props.data.GetBookingDetailResult.Itinerary.Destination}</b></h4>
                <h5 style={{ color: theme.palette.primary.contrastText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }} className="depAndDest">{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.ArrivalTime), 'hh:mm a')}</h5>
                <span style={{ color: theme.palette.primary.contrastText, fontSize: '80%', fontWeight: 400, boxSizing: 'border-box' }}>{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.ArrivalTime), 'do MMM yyyy')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: '0px ', paddingBottom: '1rem ' }}>
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <div style={{ backgroundColor: theme.palette.background.primary, boxSizing: 'border-box' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px', padding: '1rem ', boxSizing: 'border-box' }}>

              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
                <span style={{ color: theme.palette.primary.contrastText, fontSize: '80%', fontWeight: 400 }}>ID Card Type</span><br />
                <h5 style={{ color: theme.palette.primary.contrastText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="pnrinfo">{props.data.GetBookingDetailResult.Itinerary.Passenger[0].IdType}</b></h5>
              </div>
              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'center', boxSizing: 'border-box' }}>
                <span style={{ color: theme.palette.primary.contrastText, fontSize: '80%', fontWeight: 400 }}>ID Card Number</span><br />
                <h5 style={{ color: theme.palette.primary.contrastText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="pnrinfo">{props.data.GetBookingDetailResult.Itinerary.Passenger[0].IdNumber}</b></h5>
              </div>
              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'right', boxSizing: 'border-box' }}>
                <span style={{ color: theme.palette.primary.contrastText, fontSize: '80%', fontWeight: 400 }}>Service ID</span><br />
                <h5 style={{ color: theme.palette.primary.contrastText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }} className="pnrinfo">{props.data.GetBookingDetailResult.Itinerary.InvoiceNumber}</b></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: '0px ', paddingBottom: '1rem ' }} className="bus-detail-div">

        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <h5 style={{ color: theme.palette.primary.darkText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>Bus Details</b></h5>

          <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: '-15px', marginLeft: '-15px' }}>
            <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', boxSizing: 'border-box' }}>
              <b style={{ fontSize: '80%', fontWeight: 400 }}>Coach Name</b><br />
              <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{props.data.GetBookingDetailResult.Itinerary.TravelName}</b><br /><span style={{ fontSize: '80%', fontWeight: 400 }}>{props.data.GetBookingDetailResult.Itinerary.BusType}</span></h6>
            </div>
            <div style={{ flex: '0 0 66.666667%', maxWidth: '66.666667%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
              <b style={{ fontSize: '80%', fontWeight: 400 }}>Boarding Point</b><br />
              <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointLocation}</b></h6>
            </div>
            <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
              <b style={{ fontSize: '80%', fontWeight: 400 }}>Pickup Time</b><br />
              <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{dateFnsFormat(new Date(props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointTime), 'hh:m a')}</b></h6>
            </div>

            {props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointLandmark &&
              <div style={{ flex: '0 0 66.666667%', maxWidth: '66.666667%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
                <b style={{ fontSize: '80%', fontWeight: 400 }}>Landmark</b><br />
                <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointLandmark}</b></h6>
              </div>
            }

            {props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointContactNumber &&
              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
                <b style={{ fontSize: '80%', fontWeight: 400 }}>Helpline Number</b><br />
                <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointContactNumber}</b></h6>
              </div>
            }
            {props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointAddress &&
              <div style={{ flex: '0 0 33.333333%', maxWidth: '33.333333%', position: 'relative', width: '100%', paddingRight: '15px', paddingLeft: '15px', textAlign: 'left', boxSizing: 'border-box' }}>
                <b style={{ fontSize: '80%', fontWeight: 400 }}>Address</b><br />
                <h6 style={{ fontSize: '1rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>{props.data.GetBookingDetailResult.Itinerary.BoardingPointdetails.CityPointAddress}</b></h6>
              </div>
            }
          </div>
        </div>
      </div>
      {
        !isGSTHide && props.data.GetBookingDetailResult.gstDetail &&
        <div style={{ paddingBottom: '0px ', paddingTop: '0px ' }} >
          <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
            <h5 style={{ color: theme.palette.primary.aqua, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 'bolder', boxSizing: 'border-box' }}>GST Details</b></h5>
            <table style={{ border: `1px solid ${theme.palette.background.dark}`, width: '100%', marginBottom: '1rem', color: theme.palette.primary.darkText, borderCollapse: 'collapse', borderBottomWidth: '2px' }}>
              <thead style={{ borderBottomWidth: '2px', verticalAlign: 'bottom', borderBottom: `2px solid ${theme.palette.background.dark}` }}>
                <tr>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>GST Number</th>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Company Name</th>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Contact Number</th>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Company Email</th>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Company Address</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.data.GetBookingDetailResult.gstDetail.gstNumber}</th>
                  <td style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.data.GetBookingDetailResult.gstDetail.gstCompany}</td>
                  <td style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.data.GetBookingDetailResult.gstDetail.gstMobile}</td>
                  <td style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.data.GetBookingDetailResult.gstDetail.gstEmail}</td>
                  <td style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.data.GetBookingDetailResult.gstDetail.gstAddress}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }
      <div style={{ paddingBottom: '0px ', paddingTop: '0px ' }} className="tableDiv">
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <h5 style={{ color: theme.palette.primary.aqua, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 500, boxSizing: 'border-box' }}>Traveller Details</b></h5>
          <table style={{ border: `1px solid ${theme.palette.background.dark}`, width: '100%', marginBottom: '1rem', color: theme.palette.background.dark, borderCollapse: 'collapse', borderBottomWidth: '2px' }} className="tableDiv">
            <thead style={{ borderBottomWidth: '2px', verticalAlign: 'bottom', borderBottom: `2px solid ${theme.palette.background.dark}` }}>
              <tr>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>#</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Name</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Age</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Gender</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Seat Number</th>
              </tr>
            </thead>
            <tbody>

              {!isPerPassenger ?
                <>
                  {props.data.GetBookingDetailResult.Itinerary.Passenger && props.data.GetBookingDetailResult.Itinerary.Passenger.map((passenger, passengerIndex) =>
                    <tr key={passengerIndex}>
                      <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>{passengerIndex + 1}</th>
                      <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{passenger.Title} {passenger.FirstName} {passenger.LastName}</td>
                      <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{passenger.Age}</td>
                      <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{passenger.Gender === 1 ? 'Male' : passenger.Gender === 2 ? 'Female' : 'Transgender'}</td>
                      <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{passenger.Seat.SeatName}</td>
                    </tr>
                  )}
                </>
                : <>
                  <tr>
                    <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>{1}</th>
                    <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.passenger.Title} {props.passenger.FirstName} {props.passenger.LastName}</td>
                    <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.passenger.Age}</td>
                    <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.passenger.Gender === 1 ? 'Male' : props.passenger.Gender === 2 ? 'Female' : 'Transgender'}</td>
                    <td scope="row" style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem' }}>{props.passenger.Seat.SeatName}</td>
                  </tr>

                </>
              }

              {!isMobile && <tr>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', padding: '.75rem', textAlign: 'inherit' }}>Mobile No:</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', padding: '.75rem', textAlign: 'inherit' }}>{props.data.GetBookingDetailResult.Itinerary.Passenger[0].Phoneno}</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', padding: '.75rem', textAlign: 'inherit' }}>Email:</th>
                <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', padding: '.75rem', textAlign: 'inherit' }} colSpan={2}>{props.data.GetBookingDetailResult.Itinerary.Passenger[0].Email}</th>
              </tr>}
            </tbody>
          </table>
          {isMobile && <div>
            <div style={{ display: "flex" }}>
              <p style={{ color: theme.palette.primary.aqua }}>Mobile No</p>
              <p>{props.data.GetBookingDetailResult.Itinerary.Passenger[0].Phoneno}</p>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: theme.palette.primary.aqua }}>Email</p>
              <p>{props.data.GetBookingDetailResult.Itinerary.Passenger[0].Email}</p>
            </div>

          </div>}


        </div>
      </div>

      {!isFareHide &&

        <div style={{ paddingTop: '0px ' }}>
          <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
            <h5 style={{ color: theme.palette.primary.darkText, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box' }}><b style={{ fontWeight: 500, boxSizing: 'border-box' }}>Fare Details</b></h5>
            <table style={{ border: `1px solid ${theme.palette.background.dark}`, width: '100%', marginBottom: '1rem', color: theme.palette.primary.darkText, borderCollapse: 'collapse', borderBottomWidth: '2px' }}>
              <tbody>
                <tr>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>Base Fare</th>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'right' }}>{basefare.toFixed(2)}/-</th>
                </tr>
                <tr>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>Other Charges</th>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'right' }}>{taxes.toFixed(2)}/-</th>
                </tr>
                <tr>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>GST</th>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'right' }}>{gst.toFixed(2)}/-</th>
                </tr>
                <tr>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'left' }}>Discount</th>
                  <th style={{ border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'right' }}>{discount.toFixed(2)}/-</th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit' }}>Total Fare</th>
                  <th style={{ color: theme.palette.primary.lightText, backgroundColor: theme.palette.background.dark, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'right' }}>{total.toFixed(2)}/-</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      }

      {/* <div style={{paddingTop: '0px ', paddingBottom: '3rem '}}>
          <div style={{maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box'}}>
            <h5 style={{color:theme.palette.primary.aqua, fontSize: '1.25rem', marginBottom: '.5rem', fontWeight: 500, lineHeight: '1.2', marginTop: 0, boxSizing: 'border-box'}}><b style={{fontWeight: 'bolder', boxSizing: 'border-box'}}>Cancellation Policy</b></h5>
            <table style={{border: `1px solid ${theme.palette.background.dark}`, width: '100%', marginBottom: '1rem', color: theme.palette.primary.darkText, borderCollapse: 'collapse', borderBottomWidth: '2px'}}>
              <thead style={{borderBottomWidth: '2px', verticalAlign: 'bottom', borderBottom:  `2px solid ${theme.palette.background.dark}`}}>
                <tr>
                  <th style={{color: theme.palette.primary.lightText, backgroundColor: theme.palette.primary.aqua, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit'}}>Cancellation Time</th>
                  <th style={{color: theme.palette.primary.lightText, backgroundColor: theme.palette.primary.aqua, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit'}}>Charge Type</th>
                  <th style={{color: theme.palette.primary.lightText, backgroundColor: theme.palette.primary.aqua, borderBottomWidth: '2px', verticalAlign: 'bottom', border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem', textAlign: 'inherit'}}>Cancellation Charge</th>
                </tr>
              </thead>
              <tbody>
                {props.data.GetBookingDetailResult.Itinerary.CancelPolicy && props.data.GetBookingDetailResult.Itinerary.CancelPolicy.map((policy, policyIndex)=>
                  <tr key={policyIndex}>
                    <td style={{border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem'}}>{policy.PolicyString}</td>
                    <td style={{border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem'}}>{policy.CancellationChargeType === 1 ? 'Amount': policy.CancellationChargeType === 2 ? 'Percentage':'Nights'}</td>
                    <td style={{border: `1px solid ${theme.palette.background.dark}`, padding: '.75rem'}}>{policy.CancellationCharge}</td>
                  </tr>
                )}
              </tbody>
            </table>
            <b style={{fontWeight: 'bolder', boxSizing: 'border-box'}}>Note: For Special Vehicle / Service / Long Week-end/ Festival Time / Strike / Others, Cancellation Policy May or May Not be Applicable</b>
          </div>
        </div> */}
      <div style={{ paddingTop: '0px ', paddingBottom: '3rem ' }} className="bus-terms-condition">
        <div style={{ maxWidth: '1140px', width: '100%', paddingRight: '15px', paddingLeft: '15px', marginRight: 'auto', marginLeft: 'auto', boxSizing: 'border-box' }}>
          <b style={{ color: theme.palette.primary.aqua, fontWeight: 'bolder', boxSizing: 'border-box' }}>Terms And Conditions</b><br />
          {/* <p>a. This is all dumy text we have to update this.</p> */}
          <p>a. Refund policy mentioned above is indicative. The actual cancellation charges are determinedby  bus  operators  and  bus  providers  at  the  actual  time  of  cancellation.  Paytm  has  no  role  ingoverning cancellation charges</p>
          <p>b. Cancellation charges are calculated on the actual fare of the ticket, if any discount couponsare used while purchasing the ticket, the discounted value would be used to calculate the refundamount when a ticket is cancelled</p>
        </div>
      </div>
    </div>
  )
}


export default BusTicketResult
