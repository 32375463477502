/* eslint-disable no-undef */

/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import image1 from "assets/rive/hotelanimation.riv";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import OaFormAlerts from "pages/components/OaFormAlerts";

import $ from "jquery";
import Button from "components/CustomButtons/Button.js";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import image from "assets/img/MFS100.png";
import image1 from "assets/img/completed.png";

const AadhaarpayProgress = props => {
  const [isSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [isLoading] = useState(true);

  const [pIDData, setPIDData] = useState("");

  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [tid, setTid] = useState(0);
  useEffect(() => {
    getLocation();
    discoverAvdm();
    // apiCall(WebApi.getHotelSearch, props.hotelSearch, (response) => {
    //   if (response.success === true) {
    //     props.setHotelSearchResult(response.data.hotelSearchResult);
    //     props.setTimerInitialMinute(14);
    //     props.setTimerInitialSecond(0);
    //     // SetTimerInitialMinute(14);
    //     // SetTimerInitialSecond(0);
    //     props.history.push("/hotel/searchResult");
    //   } else {
    //     setIsError(!response.success);
    //     setInfoText(response.message);
    //   }
    // });
  }, [isLoading]);

  var GetCustomDomName = "127.0.0.1";
  var GetPIString = "";
  var GetPAString = "";
  var GetPFAString = "";
  var DemoFinalString = "";
  var select = "";
  var url = "";
  select += "<option val=0>Select</option>";
  for (var i = 1; i <= 100; i++) {
    select += "<option val=" + i + ">" + i + "</option>";
  }
  $("#drpMatchValuePI").html(select);
  $("#drpMatchValuePFA").html(select);
  $("#drpLocalMatchValue").html(select);
  $("#drpLocalMatchValuePI").html(select);

  var finalUrl = "";
  var MethodInfo = "";
  var MethodCapture = "";
  var OldPort = false;

  function test() {
    alert("I am calling..");
  }

  function reset() {
    $("#txtWadh").val("");
    $("#txtDeviceInfo").val("");
    $("#txtPidOptions").val("");
    $("#txtPidData").val("");
    $("select#ddlAVDM").prop("selectedIndex", 0);
    $("select#Timeout").prop("selectedIndex", 0);
    $("select#Icount").prop("selectedIndex", 0);
    $("select#Fcount").prop("selectedIndex", 0);
    $("select#Icount").prop("selectedIndex", 0);
    $("select#Itype").prop("selectedIndex", 0);
    $("select#Ptype").prop("selectedIndex", 0);
    $("select#Ftype").prop("selectedIndex", 0);
    $("select#Dtype").prop("selectedIndex", 0);
    $("#txtotp").val("");
    $("select#pTimeout").prop("selectedIndex", 1);
    $("select#pgCount").prop("selectedIndex", 1);
    $("#txtSSLDomName").val("");
  }
  // All New Function

  const Demo = () => {
    var GetPIStringstr = "";
    var GetPAStringstr = "";
    var GetPFAStringstr = "";

    if (GetPI() == true) {
      GetPIStringstr = "<Pi " + GetPIString + " />";
    } else {
      GetPIString = "";
    }

    if (GetPA() == true) {
      GetPAStringstr = "<Pa " + GetPAString + " />";
      //alert(GetPAStringstr);
    } else {
      GetPAString = "";
    }

    if (GetPFA() == true) {
      GetPFAStringstr = "<Pfa " + GetPFAString + " />";
      //alert(GetPFAStringstr);
    } else {
      GetPFAString = "";
    }

    if (GetPI() == false && GetPA() == false && GetPFA() == false) {
      //alert("Fill Data!");
      DemoFinalString = "";
    } else {
      DemoFinalString =
        "<Demo>" +
        GetPIStringstr +
        " " +
        GetPAStringstr +
        " " +
        GetPFAStringstr +
        " </Demo>";
      //alert(DemoFinalString)
    }
  };

  const GetPI = () => {
    var Flag = false;
    GetPIString = "";

    if ($("#txtName").val().length > 0) {
      Flag = true;
      GetPIString += "name=" + '"' + $("#txtName").val() + '"';
    }

    if ($("#drpMatchValuePI").val() > 0 && Flag) {
      Flag = true;
      GetPIString += " mv=" + '"' + $("#drpMatchValuePI").val() + '"';
    }

    if ($("#rdExactPI").is(":checked") && Flag) {
      Flag = true;
      GetPIString += " ms=" + '"E"';
    } else if ($("#rdPartialPI").is(":checked") && Flag) {
      Flag = true;
      GetPIString += " ms=" + '"P"';
    } else if ($("#rdFuzzyPI").is(":checked") && Flag) {
      Flag = true;
      GetPIString += " ms=" + '"F"';
    }
    if ($("#txtLocalNamePI").val().length > 0) {
      Flag = true;
      GetPIString += " lname=" + '"' + $("#txtLocalNamePI").val() + '"';
    }

    if (
      $("#txtLocalNamePI").val().length > 0 &&
      $("#drpLocalMatchValuePI").val() > 0
    ) {
      Flag = true;
      GetPIString += " lmv=" + '"' + $("#drpLocalMatchValuePI").val() + '"';
    }

    if ($("#drpGender").val() == "MALE") {
      Flag = true;
      GetPIString += " gender=" + '"M"';
    } else if ($("#drpGender").val() == "FEMALE") {
      Flag = true;
      GetPIString += " gender=" + '"F"';
    } else if ($("#drpGender").val() == "TRANSGENDER") {
      Flag = true;
      GetPIString += " gender=" + '"T"';
    }
    //}
    if ($("#txtDOB").val().length > 0) {
      Flag = true;
      GetPIString += " dob=" + '"' + $("#txtDOB").val() + '"';
    }

    if ($("#drpDOBType").val() != "0") {
      Flag = true;
      GetPIString += " dobt=" + '"' + $("#drpDOBType").val() + '"';
    }

    if ($("#txtAge").val().length) {
      Flag = true;
      GetPIString += " age=" + '"' + $("#txtAge").val() + '"';
    }

    if ($("#txtPhone").val().length > 0 || $("#txtEmail").val().length > 0) {
      Flag = true;
      GetPIString += " phone=" + '"' + $("#txtPhone").val() + '"';
    }
    if ($("#txtEmail").val().length > 0) {
      Flag = true;
      GetPIString += " email=" + '"' + $("#txtEmail").val() + '"';
    }

    //alert(GetPIString);
    return Flag;
  };

  const GetPA = () => {
    var Flag = false;
    GetPAString = "";

    if ($("#txtCareOf").val().length > 0) {
      Flag = true;
      GetPAString += "co=" + '"' + $("#txtCareOf").val() + '"';
    }
    if ($("#txtLandMark").val().length > 0) {
      Flag = true;
      GetPAString += " lm=" + '"' + $("#txtLandMark").val() + '"';
    }
    if ($("#txtLocality").val().length > 0) {
      Flag = true;
      GetPAString += " loc=" + '"' + $("#txtLocality").val() + '"';
    }
    if ($("#txtCity").val().length > 0) {
      Flag = true;
      GetPAString += " vtc=" + '"' + $("#txtCity").val() + '"';
    }
    if ($("#txtDist").val().length > 0) {
      Flag = true;
      GetPAString += " dist=" + '"' + $("#txtDist").val() + '"';
    }
    if ($("#txtPinCode").val().length > 0) {
      Flag = true;
      GetPAString += " pc=" + '"' + $("#txtPinCode").val() + '"';
    }
    if ($("#txtBuilding").val().length > 0) {
      Flag = true;
      GetPAString += " house=" + '"' + $("#txtBuilding").val() + '"';
    }
    if ($("#txtStreet").val().length > 0) {
      Flag = true;
      GetPAString += " street=" + '"' + $("#txtStreet").val() + '"';
    }
    if ($("#txtPOName").val().length > 0) {
      Flag = true;
      GetPAString += " po=" + '"' + $("#txtPOName").val() + '"';
    }
    if ($("#txtSubDist").val().length > 0) {
      Flag = true;
      GetPAString += " subdist=" + '"' + $("#txtSubDist").val() + '"';
    }
    if ($("#txtState").val().length > 0) {
      Flag = true;
      GetPAString += " state=" + '"' + $("#txtState").val() + '"';
    }
    if ($("#rdMatchStrategyPA").is(":checked") && Flag) {
      Flag = true;
      GetPAString += " ms=" + '"E"';
    }
    //alert(GetPIString);
    return Flag;
  };

  const GetPFA = () => {
    var Flag = false;
    GetPFAString = "";

    if ($("#txtAddressValue").val().length > 0) {
      Flag = true;
      GetPFAString += "av=" + '"' + $("#txtAddressValue").val() + '"';
    }

    if (
      $("#drpMatchValuePFA").val() > 0 &&
      $("#txtAddressValue").val().length > 0
    ) {
      Flag = true;
      GetPFAString += " mv=" + '"' + $("#drpMatchValuePFA").val() + '"';
    }

    if ($("#rdExactPFA").is(":checked") && Flag) {
      Flag = true;
      GetPFAString += " ms=" + '"E"';
    } else if ($("#rdPartialPFA").is(":checked") && Flag) {
      Flag = true;
      GetPFAString += " ms=" + '"P"';
    } else if ($("#rdFuzzyPFA").is(":checked") && Flag) {
      Flag = true;
      GetPFAString += " ms=" + '"F"';
    }

    if ($("#txtLocalAddress").val().length > 0) {
      Flag = true;
      GetPFAString += " lav=" + '"' + $("#txtLocalAddress").val() + '"';
    }

    if (
      $("#drpLocalMatchValue").val() > 0 &&
      $("#txtLocalAddress").val().length > 0
    ) {
      Flag = true;
      GetPFAString += " lmv=" + '"' + $("#drpLocalMatchValue").val() + '"';
    }
    //alert(GetPIString);
    return Flag;
  };

  $("#ddlAVDM").change(function() {
    //alert($("#ddlAVDM").val());
    discoverAvdmFirstNode($("#ddlAVDM").val());
  });

  $("#chkHttpsPort").change(function() {
    if ($("#chkHttpsPort").prop("checked") == true) {
      OldPort = true;
    } else {
      OldPort = false;
    }
  });

  const discoverAvdmFirstNode = PortNo => {
    $("#txtWadh").val("");
    $("#txtDeviceInfo").val("");
    $("#txtPidOptions").val("");
    $("#txtPidData").val("");

    //alert(PortNo);

    var primaryUrl = "http://" + GetCustomDomName + ":";
    // eslint-disable-next-line no-undef
    url = "";
    var verb = "RDSERVICE";
    var err = "";
    var res;
    $.support.cors = true;
    var httpStaus = false;
    var jsonstr = "";
    var data = new Object();
    var obj = new Object();

    $.ajax({
      type: "RDSERVICE",
      async: false,
      crossDomain: true,
      url: primaryUrl + PortNo,
      contentType: "text/xml; charset=utf-8",
      processData: false,
      cache: false,
      async: false,
      crossDomain: true,
      success: function(data) {
        httpStaus = true;
        res = {
          httpStaus: httpStaus,
          data: data
        };
        //alert(data);

        //debugger;

        $("#txtDeviceInfo").val(data);

        var $doc = $.parseXML(data);

        //alert($($doc).find('Interface').eq(1).attr('path'));

        if (
          $($doc)
            .find("Interface")
            .eq(0)
            .attr("path") == "/rd/capture"
        ) {
          MethodCapture = $($doc)
            .find("Interface")
            .eq(0)
            .attr("path");
        }
        if (
          $($doc)
            .find("Interface")
            .eq(1)
            .attr("path") == "/rd/capture"
        ) {
          MethodCapture = $($doc)
            .find("Interface")
            .eq(1)
            .attr("path");
        }

        if (
          $($doc)
            .find("Interface")
            .eq(0)
            .attr("path") == "/rd/info"
        ) {
          MethodInfo = $($doc)
            .find("Interface")
            .eq(0)
            .attr("path");
        }
        if (
          $($doc)
            .find("Interface")
            .eq(1)
            .attr("path") == "/rd/info"
        ) {
          MethodInfo = $($doc)
            .find("Interface")
            .eq(1)
            .attr("path");
        }

        alert("RDSERVICE Discover Successfully");
      },
      error: function(jqXHR, ajaxOptions, thrownError) {
        $("#txtDeviceInfo").val("");
        //alert(thrownError);
        res = {
          httpStaus: httpStaus,
          err: getHttpError(jqXHR)
        };
      }
    });

    return res;
  };

  const discoverAvdm = () => {
    // New

    GetCustomDomName = "127.0.0.1";
    if (
      $("#txtSSLDomName")
        .val()
        .trim().length > 0
    ) {
      GetCustomDomName = $("#txtSSLDomName")
        .val()
        .trim();
    }

    openNav();

    $("#txtWadh").val("");
    $("#txtDeviceInfo").val("");
    $("#txtPidOptions").val("");
    $("#txtPidData").val("");

    //

    var SuccessFlag = 0;
    var primaryUrl = "http://" + GetCustomDomName + ":";

    try {
      var protocol = window.location.href;
      if (protocol.indexOf("https") >= 0) {
        primaryUrl = "https://" + GetCustomDomName + ":";
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    // eslint-disable-next-line no-undef
    url = "";
    $("#ddlAVDM").empty();
    //alert("Please wait while discovering port from 11100 to 11120.\nThis will take some time.");
    SuccessFlag = 0;
    for (var i = 11100; i <= 11105; i++) {
      if (
        primaryUrl == "https://" + GetCustomDomName + ":" &&
        OldPort == true
      ) {
        i = "8005";
      }
      $("#lblStatus1").text("Discovering RD service on port : " + i.toString());

      var verb = "RDSERVICE";
      var err = "";

      var res;
      $.support.cors = true;
      var httpStaus = false;
      var jsonstr = "";
      var data = new Object();
      var obj = new Object();

      $.ajax({
        type: "RDSERVICE",
        async: false,
        crossDomain: true,
        url: primaryUrl + i.toString(),
        contentType: "text/xml; charset=utf-8",
        processData: false,
        cache: false,
        crossDomain: true,

        success: function(data) {
          httpStaus = true;
          res = {
            httpStaus: httpStaus,
            data: data
          };
          //alert(data);
          $("#txtDeviceInfo").val(data);
          finalUrl = primaryUrl + i.toString();
          var $doc = $.parseXML(data);
          var CmbData1 = $($doc)
            .find("RDService")
            .attr("status");
          var CmbData2 = $($doc)
            .find("RDService")
            .attr("info");
          if (RegExp("\\b" + "Mantra" + "\\b").test(CmbData2) == true) {
            closeNav();
            $("#txtDeviceInfo").val(data);

            if (
              $($doc)
                .find("Interface")
                .eq(0)
                .attr("path") == "/rd/capture"
            ) {
              MethodCapture = $($doc)
                .find("Interface")
                .eq(0)
                .attr("path");
            }
            if (
              $($doc)
                .find("Interface")
                .eq(1)
                .attr("path") == "/rd/capture"
            ) {
              MethodCapture = $($doc)
                .find("Interface")
                .eq(1)
                .attr("path");
            }
            if (
              $($doc)
                .find("Interface")
                .eq(0)
                .attr("path") == "/rd/info"
            ) {
              MethodInfo = $($doc)
                .find("Interface")
                .eq(0)
                .attr("path");
            }
            if (
              $($doc)
                .find("Interface")
                .eq(1)
                .attr("path") == "/rd/info"
            ) {
              MethodInfo = $($doc)
                .find("Interface")
                .eq(1)
                .attr("path");
            }

            $("#ddlAVDM").append(
              "<option value=" +
                i.toString() +
                ">(" +
                CmbData1 +
                "-" +
                i.toString() +
                ")" +
                CmbData2 +
                "</option>"
            );
            SuccessFlag = 1;
            //alert("RDSERVICE Discover Successfully");
            //return;
          }

          //alert(CmbData1);
          //alert(CmbData2);
        },
        error: function(jqXHR, ajaxOptions, thrownError) {
          if (i == "8005" && OldPort == true) {
            OldPort = false;
            i = "11099";
          }
          //$('#txtDeviceInfo').val("");
          //alert(thrownError);

          //res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
        }
      });

      if (SuccessFlag == 1) {
        //break;
      }

      //$("#ddlAVDM").val("0");
    }

    if (SuccessFlag == 0) {
      alert("Scanner Connection failed Please try again.");
    } else {
      alert("Scanner RDSERVICE Discover Successfully");
    }

    $("select#ddlAVDM").prop("selectedIndex", 0);

    closeNav();
    return res;
  };

  const openNav = () => {
    document.getElementById("myNav").style.width = "100%";
  };

  const closeNav = () => {
    document.getElementById("myNav").style.width = "0%";
  };

  const deviceInfoAvdm = () => {
    //alert($("#ddlAVDM").val());

    // eslint-disable-next-line no-undef
    url = "";

    // $("#lblStatus").text("Discovering RD Service on Port : " + i.toString());
    //Dynamic URL

    finalUrl = "http://" + GetCustomDomName + ":" + $("#ddlAVDM").val();

    try {
      var protocol = window.location.href;
      if (protocol.indexOf("https") >= 0) {
        finalUrl = "https://" + GetCustomDomName + ":" + $("#ddlAVDM").val();
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    //
    var verb = "DEVICEINFO";
    //alert(finalUrl);

    var err = "";

    var res;
    $.support.cors = true;
    var httpStaus = false;
    var jsonstr = "";
    $.ajax({
      type: "DEVICEINFO",
      async: false,
      crossDomain: true,
      url: finalUrl + MethodInfo,
      contentType: "text/xml; charset=utf-8",
      processData: false,
      success: function(data) {
        //alert(data);
        httpStaus = true;
        res = {
          httpStaus: httpStaus,
          data: data
        };

        $("#txtDeviceInfo").val(data);
      },
      error: function(jqXHR, ajaxOptions, thrownError) {
        alert(thrownError);
        res = {
          httpStaus: httpStaus,
          err: getHttpError(jqXHR)
        };
      }
    });

    return res;
  };

  const CaptureAvdm = () => {
    var strWadh = "";
    var strOtp = "";
    Demo();

    if ($("#txtWadh").val() != "") {
      strWadh = ' wadh="' + $("#txtWadh").val() + '"';
    }
    if ($("#txtotp").val() != "") {
      strOtp = ' otp="' + $("#txtotp").val() + '"';
    }
    var XML =
      '<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="' +
      $("#Fcount").val() +
      '" fType="' +
      $("#Ftype").val() +
      '" iCount="' +
      $("#Icount").val() +
      '" pCount="' +
      $("#Pcount").val() +
      '" pgCount="' +
      $("#pgCount").val() +
      '"' +
      strOtp +
      ' format="' +
      $("#Dtype").val() +
      '"   pidVer="' +
      $("#Pidver").val() +
      '" timeout="' +
      $("#Timeout").val() +
      '" pTimeout="' +
      $("#pTimeout").val() +
      '"' +
      strWadh +
      ' posh="UNKNOWN" env="' +
      $("#Env").val() +
      '" /> ' +
      DemoFinalString +
      '<CustOpts><Param name="mantrakey" value="' +
      $("#txtCK").val() +
      '" /></CustOpts> </PidOptions>';

    finalUrl = "http://" + GetCustomDomName + ":" + $("#ddlAVDM").val();

    try {
      var protocol = window.location.href;
      if (protocol.indexOf("https") >= 0) {
        finalUrl = "https://" + GetCustomDomName + ":" + $("#ddlAVDM").val();
      }
    } catch (e) {}

    var verb = "CAPTURE";

    var err = "";

    var res;
    $.support.cors = true;
    var httpStaus = false;
    var jsonstr = "";
    $.ajax({
      type: "CAPTURE",
      async: false,
      crossDomain: true,
      url: finalUrl + MethodCapture,
      data: XML,
      contentType: "text/xml; charset=utf-8",
      processData: false,
      success: function(data) {
        //alert(data);
        httpStaus = true;
        res = {
          httpStaus: httpStaus,
          data: data
        };

        $("#txtPidData").val(data);
        setPIDData(data);
        $("#txtPidOptions").val(XML);

        var $doc = $.parseXML(data);
        var Message = $($doc)
          .find("Resp")
          .attr("errInfo");

        alert(Message);
      },
      error: function(jqXHR, ajaxOptions, thrownError) {
        //$('#txtPidOptions').val(XML);
        alert(thrownError);
        res = {
          httpStaus: httpStaus,
          err: getHttpError(jqXHR)
        };
      }
    });

    return res;
  };

  const getHttpError = jqXHR => {
    var err = "Unhandled Exception";
    if (jqXHR.status === 0) {
      err = "Service Unavailable";
    } else if (jqXHR.status == 404) {
      err = "Requested page not found";
    } else if (jqXHR.status == 500) {
      err = "Internal Server Error";
    } else if (thrownError === "parsererror") {
      err = "Requested JSON parse failed";
    } else if (thrownError === "timeout") {
      err = "Time out error";
    } else if (thrownError === "abort") {
      err = "Ajax request aborted";
    } else {
      err = "Unhandled Error";
    }
    return err;
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = position => {
    alert(
      "Latitude: " +
        position.coords.latitude +
        " Longitude: " +
        position.coords.longitude
    );

    setLatitude(position.coords.longitude);
    setLongitude(position.coords.latitude);
  };

  const showError = error => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
    }
  };

  const submitData = () => {
    if (!latitude && !longitude) {
      alert("Please enable location and try again");
      getLocation();
      return;
    }

    if (!pIDData) {
      alert("Please capturing Fingerprints and try again");

      return;
    }
    const params = {
      ...props.location.state,
      data: pIDData,
      is_iris: false,
      transactiontype: "M",
      latitude: latitude,
      longitude: longitude
    };
    console.log(params);

    apiCall(WebApi.aadhaarPayTransact, params, response => {
      console.log(response);
      if (response.success === true) {
        console.log(response.data);

        setIsSuccess(response.success);
        setInfoText(response.message);
        setTid(response.extraData);
       setTimeout(()=>{
        goToInvoice();
       }, 4000);
      } else {
        setIsError(!response.success);
        setInfoText(response.message);
      }
    });
  };

  const goToInvoice = () => {
    props.history.push(
      "/aadhaarpay/voucher/aadhaarpay-user/" + tid
    );
  }

  return (
    <GridContainer
    direction="column"
    alignItems="center"
    justifyContent="center"
      // style={{
      //   height: "60vh"
      //   // ,overflow: "scroll"
      // }}
    >
      <GridItem md={12}>
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          alertTitle={"Error"}
        />
      </GridItem>

      <GridItem md={12}>
        {tid > 0 && <span>Please wait redirecting for user invoice</span>}

        <Button onClick={goToInvoice}>User Invoice</Button>
      </GridItem>
      <GridItem
        md={12}
      >
        
          {! pIDData ? (
            <GridContainer
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              
              <GridItem md={12}>
                
                <h5
                  style={{
                    padding: 10,
                    fontWeight: 500
                  }}
                >
                  Click capture and place your thumbs once red light starts on your scanner
                </h5>
              </GridItem>
              <GridItem>
                
                <img src={image} width="200" />
              </GridItem>
            </GridContainer>
          ) : (
            
            <GridContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            
            <GridItem md={12}>
              
              <h5
                style={{
                  padding: 10,
                  fontWeight: 500
                }}
              >
                Scanning done, Please submit for transaction
              </h5>
            </GridItem>
            <GridItem>
              
              <img src={image1} width="200" />
            </GridItem>
          </GridContainer>
                  
                  
               
          )}
        </GridItem>

        <GridItem
        md={12}
      >
        <Button
          color="primary"
          type="button"
          value="Capture"
          onClick={CaptureAvdm}
        >
          
          Capture / Recapture
        </Button>
        {/* <Button
                    color="primary"
                    type="button" value="Reset" onClick={reset} >Reset</Button> */}
        <Button
          color="primary"
          type="button"
          value="Reset"
          onClick={submitData}
        >
          
          Submit
        </Button>
        </GridItem>
        <div
          style={{
            height: "200px",
            display: "none"
          }}
        >
          <div id="wrapper">
            <div id="myNav"> </div>
            <div className="col-md-4">
              <div className="form-group">
                <label
                  style={{
                    color: "red"
                  }}
                >
                  
                  Custom SSL Certificate Domain Name Ex: (rd.myservice.com)
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="txtSSLDomName"
                  placeholder="127.0.0.1"
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="form-group">
              <label
                style={{
                  color: "red"
                }}
              >
                
                <b>
                  
                  [After binding custom SSL certificate, add your domain name in
                  hosts file(C: \Windows\ System32\ drivers\ etc\ hosts)
                </b>
              </label>
              <label
                style={{
                  color: "red"
                }}
              >
                
                <b> Ex: 127.0 .0 .1 rd.myservice.com ] </b>
              </label>
            </div>
          </div>
          <div className="container-fluid">
            
            {/* /.row */}
            <div className="row ">
              <div className="col-lg-12 mtop ">
                <div>
                  <h4 className="col-md-2"> Initialized Framework </h4>
                  <div className="form-group">
                    <button
                      type="button"
                      onClick={discoverAvdm}
                      value="Discover AVDM"
                      className="btn btn-200 btn-primary"
                    >
                      
                      Discover AVDM
                    </button>
                    {/* <button type="button" value="Device Info" onClick={deviceInfoAvdm} className="btn btn-200 btn-primary">Device Info</button>
                                                                  <button type="button" value="Capture" onClick={CaptureAvdm} className="btn btn-200 btn-primary">Capture</button>
                                                                  <button type="button" onClick={reset} className="btn btn-200 btn-primary" value="Reset">Reset</button>
                                        
                                                                  <button type="button" onClick={submitData} value="Submit">Submit</button> */}
                    & nbsp; & nbsp;
                    {/* <input style="visibility:hidden"  name="ChkRD" id="chkHttpsPort" type="checkbox">Custome Port For HTTPS</input> */}
                    <input
                      style={{
                        visibility: "hidden"
                      }}
                      name="ChkRD"
                      id="chkHttpsPort"
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 ">
                <div className="panel panel-default box-shadow">
                  <div className="panel-heading">Select Option to Capture </div>
                  <div className="panel-body ">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label> AVDM </label>
                          <select id="ddlAVDM" className="form-control">
                            
                            <option value={11100}>
                              
                              (READY - 11100) Secure Mantra Authentication
                              Vendor Device Manager
                            </option>
                          </select>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> Timeout </label>
                              <select id="Timeout" className="form-control">
                                <option> 10000 </option>
                                <option> 10000 </option>
                                <option> 20000 </option>
                                <option> 30000 </option>
                                <option> 40000 </option>
                                <option> 50000 </option>
                                <option> 60000 </option>
                                <option> 70000 </option>
                                <option> 80000 </option>
                                <option> 90000 </option>
                                <option> 100000 </option> <option> 0 </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> PidVer </label>
                              <select id="Pidver" className="form-control">
                                <option> 2.0 </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> Env </label>
                              <select id="Env" className="form-control">
                                <option> S </option> <option> PP </option>
                                <option selected="true"> P </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> PTimeout </label>
                              <select id="pTimeout" className="form-control">
                                <option> 10000 </option>
                                <option selected="selected"> 20000 </option>
                                <option> 30000 </option>
                                <option> 40000 </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> PGCount </label>
                              <select id="pgCount" className="form-control">
                                <option> 1 </option>
                                <option selected="selected"> 2 </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        <div className="form-group">
                          <label> DataType </label>
                          <select id="Dtype" className="form-control">
                            <option value={0}> X </option>
                            <option value={1}> P </option>
                          </select>
                        </div>
                        <div className="form-group">
                          <label> Client Key </label>
                          <input
                            id="txtCK"
                            className="form-control"
                            type="text"
                            placeholder="Enter text"
                          />
                        </div>
                        <div className="form-group">
                          <label> OTP </label>
                          <input
                            id="txtotp"
                            className="form-control"
                            type="text"
                            placeholder="Enter text"
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="form-group">
                          <label> Wadh </label>
                          <textarea
                            id="txtWadh"
                            rows={3}
                            className="form-control"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> Finger Count </label>
                              <select id="Fcount" className="form-control">
                                <option> 0 </option>
                                <option selected="selected"> 1 </option>
                                <option> 2 </option> <option> 3 </option>
                                <option> 4 </option> <option> 5 </option>
                                <option> 6 </option> <option> 7 </option>
                                <option> 8 </option> <option> 9 </option>
                                <option> 10 </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label> Iris Count </label>
                              <select id="Icount" className="form-control">
                                <option> 0 </option> <option> 1 </option>
                                <option> 2 </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> Face Count </label>
                              <select id="Pcount" className="form-control">
                                <option> 0 </option> <option> 1 </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label> Finger Type </label>
                              <select id="Ftype" className="form-control">
                                <option value={0}> FMR </option>
                                <option value={1}> FIR </option>
                                <option value={2}> BOTH </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label> Iris Type </label>
                              <select id="Itype" className="form-control">
                                <option> SELECT </option> <option> ISO </option>
                              </select>
                            </div>
                            <div className="form-group">
                              <label> Face Type </label>
                              <select id="Ptype" className="form-control">
                                <option> SELECT </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">AVDM / Device Info </div>
                    <div className="panel-body ">
                      <div className="form-group">
                        <textarea
                          rows={5}
                          id="txtDeviceInfo"
                          className="form-control"
                          defaultValue={""}
                        />
                      </div>
                      {/* /.row (nested) */}
                    </div>
                    {/* /.panel-body */}
                  </div>
                  {/* /.panel */}
                </div>
                <div className="col-lg-4 ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">Pid Options </div>
                    <div className="panel-body ">
                      <div className="form-group">
                        <textarea
                          id="txtPidOptions"
                          rows={5}
                          className="form-control"
                          defaultValue={""}
                        />
                      </div>
                      {/* /.row (nested) */}
                    </div>
                    {/* /.panel-body */}
                  </div>
                  {/* /.panel */}
                </div>
                <div className="col-lg-12 ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">Pid Data </div>
                    <div className="panel-body ">
                      <div className="form-group">
                        <textarea
                          id="txtPidData"
                          rows={7}
                          className="form-control"
                          defaultValue={""}
                        />
                      </div>
                      {/* /.row (nested) */}
                    </div>
                    {/* /.panel-body */}
                  </div>
                  {/* /.panel */}
                </div>
                <div className="col-lg-6  ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">PERSONAL IDENTITY(PI) </div>
                    <div className="panel-body ">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-horizontal">
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Name
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtName"
                                  placeholder="Enter Your Name"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Local Name:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtLocalNamePI"
                                  placeholder="Local Name"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Gender
                              </label>
                              <div className="col-xs-7">
                                <select id="drpGender" className="form-control">
                                  <option value={0}> Select </option>
                                  <option> MALE </option>
                                  <option> FEMALE </option>
                                  <option> TRANSGENDER </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                DOB
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtDOB"
                                  placeholder="DOB"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Phone
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtPhone"
                                  placeholder="Phone"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                DOB Type:
                              </label>
                              <div className="col-xs-7">
                                <select
                                  id="drpDOBType"
                                  className="form-control"
                                >
                                  <option value={0}> select </option>
                                  <option> V </option> <option> D </option>
                                  <option> A </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div role="form" className="form-horizontal">
                            <div className="form-group">
                              <label
                                className="control-label col-xs-4"
                                style={{
                                  fontSize: "13px"
                                }}
                              >
                                
                                Match Strategy
                              </label>
                              <div className="col-xs-8">
                                <label className="radio-inline">
                                  <input
                                    type="radio"
                                    name="RDPI"
                                    id="rdExactPI"
                                    defaultChecked="true"
                                  />
                                  Exact
                                </label>
                                <label className="radio-inline">
                                  <input
                                    type="radio"
                                    name="RDPI"
                                    id="rdPartialPI"
                                  />
                                  Partial
                                </label>
                                <label className="radio-inline">
                                  <input
                                    type="radio"
                                    name="RDPI"
                                    id="rdFuzzyPI"
                                  />
                                  Fuzzy
                                </label>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-4">
                                
                                Match Value:
                              </label>
                              <div className="col-xs-8">
                                <select
                                  id="drpMatchValuePI"
                                  className="form-control"
                                >
                                  
                                  <option val={0}> Select </option>
                                  <option val={1}>1</option>
                                  <option val={2}> 2 </option>
                                  <option val={3}>3</option>
                                  <option val={4}> 4 </option>
                                  <option val={5}>5</option>
                                  <option val={6}> 6 </option>
                                  <option val={7}>7</option>
                                  <option val={8}> 8 </option>
                                  <option val={9}>9</option>
                                  <option val={10}> 10 </option>
                                  <option val={11}>11</option>
                                  <option val={12}> 12 </option>
                                  <option val={13}>13</option>
                                  <option val={14}> 14 </option>
                                  <option val={15}>15</option>
                                  <option val={16}> 16 </option>
                                  <option val={17}>17</option>
                                  <option val={18}> 18 </option>
                                  <option val={19}>19</option>
                                  <option val={20}> 20 </option>
                                  <option val={21}>21</option>
                                  <option val={22}> 22 </option>
                                  <option val={23}>23</option>
                                  <option val={24}> 24 </option>
                                  <option val={25}>25</option>
                                  <option val={26}> 26 </option>
                                  <option val={27}>27</option>
                                  <option val={28}> 28 </option>
                                  <option val={29}>29</option>
                                  <option val={30}> 30 </option>
                                  <option val={31}>31</option>
                                  <option val={32}> 32 </option>
                                  <option val={33}>33</option>
                                  <option val={34}> 34 </option>
                                  <option val={35}>35</option>
                                  <option val={36}> 36 </option>
                                  <option val={37}>37</option>
                                  <option val={38}> 38 </option>
                                  <option val={39}>39</option>
                                  <option val={40}> 40 </option>
                                  <option val={41}>41</option>
                                  <option val={42}> 42 </option>
                                  <option val={43}>43</option>
                                  <option val={44}> 44 </option>
                                  <option val={45}>45</option>
                                  <option val={46}> 46 </option>
                                  <option val={47}>47</option>
                                  <option val={48}> 48 </option>
                                  <option val={49}>49</option>
                                  <option val={50}> 50 </option>
                                  <option val={51}>51</option>
                                  <option val={52}> 52 </option>
                                  <option val={53}>53</option>
                                  <option val={54}> 54 </option>
                                  <option val={55}>55</option>
                                  <option val={56}> 56 </option>
                                  <option val={57}>57</option>
                                  <option val={58}> 58 </option>
                                  <option val={59}>59</option>
                                  <option val={60}> 60 </option>
                                  <option val={61}>61</option>
                                  <option val={62}> 62 </option>
                                  <option val={63}>63</option>
                                  <option val={64}> 64 </option>
                                  <option val={65}>65</option>
                                  <option val={66}> 66 </option>
                                  <option val={67}>67</option>
                                  <option val={68}> 68 </option>
                                  <option val={69}>69</option>
                                  <option val={70}> 70 </option>
                                  <option val={71}>71</option>
                                  <option val={72}> 72 </option>
                                  <option val={73}>73</option>
                                  <option val={74}> 74 </option>
                                  <option val={75}>75</option>
                                  <option val={76}> 76 </option>
                                  <option val={77}>77</option>
                                  <option val={78}> 78 </option>
                                  <option val={79}>79</option>
                                  <option val={80}> 80 </option>
                                  <option val={81}>81</option>
                                  <option val={82}> 82 </option>
                                  <option val={83}>83</option>
                                  <option val={84}> 84 </option>
                                  <option val={85}>85</option>
                                  <option val={86}> 86 </option>
                                  <option val={87}>87</option>
                                  <option val={88}> 88 </option>
                                  <option val={89}>89</option>
                                  <option val={90}> 90 </option>
                                  <option val={91}>91</option>
                                  <option val={92}> 92 </option>
                                  <option val={93}>93</option>
                                  <option val={94}> 94 </option>
                                  <option val={95}>95</option>
                                  <option val={96}> 96 </option>
                                  <option val={97}>97</option>
                                  <option val={98}> 98 </option>
                                  <option val={99}>99</option>
                                  <option val={100}> 100 </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-4">
                                
                                Age
                              </label>
                              <div className="col-xs-8">
                                <input
                                  type="number"
                                  className="form-control"
                                  id="txtAge"
                                  placeholder="Age"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-4">
                                
                                LocalMatchValue:
                              </label>
                              <div className="col-xs-8">
                                <select
                                  className="form-control"
                                  id="drpLocalMatchValuePI"
                                >
                                  
                                  <option val={0}> Select </option>
                                  <option val={1}>1</option>
                                  <option val={2}> 2 </option>
                                  <option val={3}>3</option>
                                  <option val={4}> 4 </option>
                                  <option val={5}>5</option>
                                  <option val={6}> 6 </option>
                                  <option val={7}>7</option>
                                  <option val={8}> 8 </option>
                                  <option val={9}>9</option>
                                  <option val={10}> 10 </option>
                                  <option val={11}>11</option>
                                  <option val={12}> 12 </option>
                                  <option val={13}>13</option>
                                  <option val={14}> 14 </option>
                                  <option val={15}>15</option>
                                  <option val={16}> 16 </option>
                                  <option val={17}>17</option>
                                  <option val={18}> 18 </option>
                                  <option val={19}>19</option>
                                  <option val={20}> 20 </option>
                                  <option val={21}>21</option>
                                  <option val={22}> 22 </option>
                                  <option val={23}>23</option>
                                  <option val={24}> 24 </option>
                                  <option val={25}>25</option>
                                  <option val={26}> 26 </option>
                                  <option val={27}>27</option>
                                  <option val={28}> 28 </option>
                                  <option val={29}>29</option>
                                  <option val={30}> 30 </option>
                                  <option val={31}>31</option>
                                  <option val={32}> 32 </option>
                                  <option val={33}>33</option>
                                  <option val={34}> 34 </option>
                                  <option val={35}>35</option>
                                  <option val={36}> 36 </option>
                                  <option val={37}>37</option>
                                  <option val={38}> 38 </option>
                                  <option val={39}>39</option>
                                  <option val={40}> 40 </option>
                                  <option val={41}>41</option>
                                  <option val={42}> 42 </option>
                                  <option val={43}>43</option>
                                  <option val={44}> 44 </option>
                                  <option val={45}>45</option>
                                  <option val={46}> 46 </option>
                                  <option val={47}>47</option>
                                  <option val={48}> 48 </option>
                                  <option val={49}>49</option>
                                  <option val={50}> 50 </option>
                                  <option val={51}>51</option>
                                  <option val={52}> 52 </option>
                                  <option val={53}>53</option>
                                  <option val={54}> 54 </option>
                                  <option val={55}>55</option>
                                  <option val={56}> 56 </option>
                                  <option val={57}>57</option>
                                  <option val={58}> 58 </option>
                                  <option val={59}>59</option>
                                  <option val={60}> 60 </option>
                                  <option val={61}>61</option>
                                  <option val={62}> 62 </option>
                                  <option val={63}>63</option>
                                  <option val={64}> 64 </option>
                                  <option val={65}>65</option>
                                  <option val={66}> 66 </option>
                                  <option val={67}>67</option>
                                  <option val={68}> 68 </option>
                                  <option val={69}>69</option>
                                  <option val={70}> 70 </option>
                                  <option val={71}>71</option>
                                  <option val={72}> 72 </option>
                                  <option val={73}>73</option>
                                  <option val={74}> 74 </option>
                                  <option val={75}>75</option>
                                  <option val={76}> 76 </option>
                                  <option val={77}>77</option>
                                  <option val={78}> 78 </option>
                                  <option val={79}>79</option>
                                  <option val={80}> 80 </option>
                                  <option val={81}>81</option>
                                  <option val={82}> 82 </option>
                                  <option val={83}>83</option>
                                  <option val={84}> 84 </option>
                                  <option val={85}>85</option>
                                  <option val={86}> 86 </option>
                                  <option val={87}>87</option>
                                  <option val={88}> 88 </option>
                                  <option val={89}>89</option>
                                  <option val={90}> 90 </option>
                                  <option val={91}>91</option>
                                  <option val={92}> 92 </option>
                                  <option val={93}>93</option>
                                  <option val={94}> 94 </option>
                                  <option val={95}>95</option>
                                  <option val={96}> 96 </option>
                                  <option val={97}>97</option>
                                  <option val={98}> 98 </option>
                                  <option val={99}>99</option>
                                  <option val={100}> 100 </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-4">
                                
                                Email
                              </label>
                              <div className="col-xs-8">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="txtEmail"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6  ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">PERSONAL ADDRESS(PA) </div>
                    <div className="panel-body ">
                      <div className="row">
                        <div className="col-md-6">
                          <form role="form" className="form-horizontal">
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Care Of:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtCareOf"
                                  placeholder="Care Of:"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Landmark:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtLandMark"
                                  placeholder="Landmark"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Locality:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtLocality"
                                  placeholder="Locality"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                City:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtCity"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                District:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtDist"
                                  placeholder="Email"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                PinCode:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtPinCode"
                                  placeholder="PinCode"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-md-6">
                          <form role="form" className="form-horizontal">
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Building:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtBuilding"
                                  placeholder="Building"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Street:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtStreet"
                                  placeholder="Street"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                PO Name:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtPOName"
                                  placeholder="PO Name"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Sub Dist:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtSubDist"
                                  placeholder="Sub Dist"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                State:
                              </label>
                              <div className="col-xs-7">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txtState"
                                  placeholder="State"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="control-label col-xs-5">
                                
                                Match Strategy:
                              </label>
                              <div className="col-xs-7">
                                <label className="radio-inline">
                                  <input
                                    type="radio"
                                    name="optionsRadiosInline"
                                    id="rdMatchStrategyPA"
                                    defaultChecked="true"
                                    defaultValue="option1"
                                  />
                                  Exact
                                </label>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div className="panel panel-default box-shadow">
                    <div className="panel-heading">
                      PERSONAL FULL ADDRESS(PFA)
                    </div>
                    <div className="panel-body ">
                      <div className="row">
                        <div className="col-lg-4">
                          <form role="form">
                            <div className="form-group">
                              <label> Email </label>
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  name="RD"
                                  id="rdExactPFA"
                                  defaultChecked="true"
                                />
                                Exact
                              </label>
                              <label className="radio-inline">
                                <input
                                  type="radio"
                                  name="RD"
                                  id="rdPartialPFA"
                                />
                                Partial
                              </label>
                              <label className="radio-inline">
                                <input type="radio" name="RD" id="rdFuzzyPFA" />
                                Fuzzy
                              </label>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label> Match Value: </label>
                                  <select
                                    className="form-control"
                                    id="drpMatchValuePFA"
                                  >
                                    
                                    <option val={0}> Select </option>
                                    <option val={1}>1</option>
                                    <option val={2}> 2 </option>
                                    <option val={3}>3</option>
                                    <option val={4}> 4 </option>
                                    <option val={5}>5</option>
                                    <option val={6}> 6 </option>
                                    <option val={7}>7</option>
                                    <option val={8}> 8 </option>
                                    <option val={9}>9</option>
                                    <option val={10}> 10 </option>
                                    <option val={11}>11</option>
                                    <option val={12}> 12 </option>
                                    <option val={13}>13</option>
                                    <option val={14}> 14 </option>
                                    <option val={15}>15</option>
                                    <option val={16}> 16 </option>
                                    <option val={17}>17</option>
                                    <option val={18}> 18 </option>
                                    <option val={19}>19</option>
                                    <option val={20}> 20 </option>
                                    <option val={21}>21</option>
                                    <option val={22}> 22 </option>
                                    <option val={23}>23</option>
                                    <option val={24}> 24 </option>
                                    <option val={25}>25</option>
                                    <option val={26}> 26 </option>
                                    <option val={27}>27</option>
                                    <option val={28}> 28 </option>
                                    <option val={29}>29</option>
                                    <option val={30}> 30 </option>
                                    <option val={31}>31</option>
                                    <option val={32}> 32 </option>
                                    <option val={33}>33</option>
                                    <option val={34}> 34 </option>
                                    <option val={35}>35</option>
                                    <option val={36}> 36 </option>
                                    <option val={37}>37</option>
                                    <option val={38}> 38 </option>
                                    <option val={39}>39</option>
                                    <option val={40}> 40 </option>
                                    <option val={41}>41</option>
                                    <option val={42}> 42 </option>
                                    <option val={43}>43</option>
                                    <option val={44}> 44 </option>
                                    <option val={45}>45</option>
                                    <option val={46}> 46 </option>
                                    <option val={47}>47</option>
                                    <option val={48}> 48 </option>
                                    <option val={49}>49</option>
                                    <option val={50}> 50 </option>
                                    <option val={51}>51</option>
                                    <option val={52}> 52 </option>
                                    <option val={53}>53</option>
                                    <option val={54}> 54 </option>
                                    <option val={55}>55</option>
                                    <option val={56}> 56 </option>
                                    <option val={57}>57</option>
                                    <option val={58}> 58 </option>
                                    <option val={59}>59</option>
                                    <option val={60}> 60 </option>
                                    <option val={61}>61</option>
                                    <option val={62}> 62 </option>
                                    <option val={63}>63</option>
                                    <option val={64}> 64 </option>
                                    <option val={65}>65</option>
                                    <option val={66}> 66 </option>
                                    <option val={67}>67</option>
                                    <option val={68}> 68 </option>
                                    <option val={69}>69</option>
                                    <option val={70}> 70 </option>
                                    <option val={71}>71</option>
                                    <option val={72}> 72 </option>
                                    <option val={73}>73</option>
                                    <option val={74}> 74 </option>
                                    <option val={75}>75</option>
                                    <option val={76}> 76 </option>
                                    <option val={77}>77</option>
                                    <option val={78}> 78 </option>
                                    <option val={79}>79</option>
                                    <option val={80}> 80 </option>
                                    <option val={81}>81</option>
                                    <option val={82}> 82 </option>
                                    <option val={83}>83</option>
                                    <option val={84}> 84 </option>
                                    <option val={85}>85</option>
                                    <option val={86}> 86 </option>
                                    <option val={87}>87</option>
                                    <option val={88}> 88 </option>
                                    <option val={89}>89</option>
                                    <option val={90}> 90 </option>
                                    <option val={91}>91</option>
                                    <option val={92}> 92 </option>
                                    <option val={93}>93</option>
                                    <option val={94}> 94 </option>
                                    <option val={95}>95</option>
                                    <option val={96}> 96 </option>
                                    <option val={97}>97</option>
                                    <option val={98}> 98 </option>
                                    <option val={99}>99</option>
                                    <option val={100}> 100 </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label> Local Match Value: </label>
                                  <select
                                    className="form-control"
                                    id="drpLocalMatchValue"
                                  >
                                    
                                    <option val={0}> Select </option>
                                    <option val={1}>1</option>
                                    <option val={2}> 2 </option>
                                    <option val={3}>3</option>
                                    <option val={4}> 4 </option>
                                    <option val={5}>5</option>
                                    <option val={6}> 6 </option>
                                    <option val={7}>7</option>
                                    <option val={8}> 8 </option>
                                    <option val={9}>9</option>
                                    <option val={10}> 10 </option>
                                    <option val={11}>11</option>
                                    <option val={12}> 12 </option>
                                    <option val={13}>13</option>
                                    <option val={14}> 14 </option>
                                    <option val={15}>15</option>
                                    <option val={16}> 16 </option>
                                    <option val={17}>17</option>
                                    <option val={18}> 18 </option>
                                    <option val={19}>19</option>
                                    <option val={20}> 20 </option>
                                    <option val={21}>21</option>
                                    <option val={22}> 22 </option>
                                    <option val={23}>23</option>
                                    <option val={24}> 24 </option>
                                    <option val={25}>25</option>
                                    <option val={26}> 26 </option>
                                    <option val={27}>27</option>
                                    <option val={28}> 28 </option>
                                    <option val={29}>29</option>
                                    <option val={30}> 30 </option>
                                    <option val={31}>31</option>
                                    <option val={32}> 32 </option>
                                    <option val={33}>33</option>
                                    <option val={34}> 34 </option>
                                    <option val={35}>35</option>
                                    <option val={36}> 36 </option>
                                    <option val={37}>37</option>
                                    <option val={38}> 38 </option>
                                    <option val={39}>39</option>
                                    <option val={40}> 40 </option>
                                    <option val={41}>41</option>
                                    <option val={42}> 42 </option>
                                    <option val={43}>43</option>
                                    <option val={44}> 44 </option>
                                    <option val={45}>45</option>
                                    <option val={46}> 46 </option>
                                    <option val={47}>47</option>
                                    <option val={48}> 48 </option>
                                    <option val={49}>49</option>
                                    <option val={50}> 50 </option>
                                    <option val={51}>51</option>
                                    <option val={52}> 52 </option>
                                    <option val={53}>53</option>
                                    <option val={54}> 54 </option>
                                    <option val={55}>55</option>
                                    <option val={56}> 56 </option>
                                    <option val={57}>57</option>
                                    <option val={58}> 58 </option>
                                    <option val={59}>59</option>
                                    <option val={60}> 60 </option>
                                    <option val={61}>61</option>
                                    <option val={62}> 62 </option>
                                    <option val={63}>63</option>
                                    <option val={64}> 64 </option>
                                    <option val={65}>65</option>
                                    <option val={66}> 66 </option>
                                    <option val={67}>67</option>
                                    <option val={68}> 68 </option>
                                    <option val={69}>69</option>
                                    <option val={70}> 70 </option>
                                    <option val={71}>71</option>
                                    <option val={72}> 72 </option>
                                    <option val={73}>73</option>
                                    <option val={74}> 74 </option>
                                    <option val={75}>75</option>
                                    <option val={76}> 76 </option>
                                    <option val={77}>77</option>
                                    <option val={78}> 78 </option>
                                    <option val={79}>79</option>
                                    <option val={80}> 80 </option>
                                    <option val={81}>81</option>
                                    <option val={82}> 82 </option>
                                    <option val={83}>83</option>
                                    <option val={84}> 84 </option>
                                    <option val={85}>85</option>
                                    <option val={86}> 86 </option>
                                    <option val={87}>87</option>
                                    <option val={88}> 88 </option>
                                    <option val={89}>89</option>
                                    <option val={90}> 90 </option>
                                    <option val={91}>91</option>
                                    <option val={92}> 92 </option>
                                    <option val={93}>93</option>
                                    <option val={94}> 94 </option>
                                    <option val={95}>95</option>
                                    <option val={96}> 96 </option>
                                    <option val={97}>97</option>
                                    <option val={98}> 98 </option>
                                    <option val={99}>99</option>
                                    <option val={100}> 100 </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="col-lg-4">
                          <form role="form">
                            <div className="form-group">
                              <label> Address Value: </label>
                              <textarea
                                rows={2}
                                id="txtAddressValue"
                                className="form-control"
                                defaultValue={""}
                              />
                            </div>
                          </form>
                        </div>
                        <div className="col-lg-4">
                          <form role="form">
                            <div className="form-group">
                              <label> Local Address: </label>
                              <textarea
                                rows={2}
                                id="txtLocalAddress"
                                className="form-control"
                                defaultValue={""}
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label id="lblstatus"></label>
                </div>
                {/* /.col-lg-12 */}
              </div>
              {/* /.row */}
            </div>
            {/* /#page-wrapper */}
          </div>
        </div>
        {/* <button onClick={CaptureAvdm}> Capture </button> */}
        {/* <img src={image1} width="200" /> {/* <Rive src={image1} /> } */}
     
      {/* <GridItem
        md={12}
        style={{
          height: "20vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          backgroundColor: "transparent"
        }}
      ></GridItem> */}
      {/* {props.hotelSearch.departureCityId} {props.hotelSearch.destinationCityId} */}
    </GridContainer>
  );
};

export default withRouter(AadhaarpayProgress);
