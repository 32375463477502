import React from 'react';
import GridItem from 'components/Grid/GridItem.js';
import { FormControl, InputAdornment } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import DateFnsUtils from '@date-io/date-fns';
import { Field } from 'formik';

const OaFormDateField = (props) => {
  let useGrid = props.md !== undefined;

  function field() {
    return (
      <FormControl fullWidth={true}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Field
            component={DatePicker}
            disableToolbar={false}
            // disableFuture={true}
            autoOk={true}
            format={props.format || 'dd-MM-yyyy'}
            inputVariant='outlined'
            className={'oa-input-white'}
            InputProps={{
              startAdornment: (
                <InputAdornment position={props.iconPos || 'start'}>
                  <CalendarTodayIcon />
                </InputAdornment>
              ),
            }}
            {...props}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    );
  }

  return (
    <>
      {!useGrid && field()}
      {useGrid && (
        <GridItem xs={12} sm={12} md={props.md}>
          {field()}
        </GridItem>
      )}
    </>
  );
};

export default OaFormDateField;
