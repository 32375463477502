import React from 'react';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OaCard from 'oahoc/OaCard';
import { BASE_URL_IMAGE_VIEW } from 'api/ApiConstants';
import styled from "styled-components";

const BankAccountCard = (props) => {

  const bankList = props.bankList === undefined ? [] : props.bankList;
  const error = props.isError;

  function AOBankCard(props) {
    return (
      <OaCard>
        <GridContainer>
          <GridItem xs={12} sm={12} md={9}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Account Name</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.accountName}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Account Number</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.accountNumber}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Bank Address</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.bankAddress}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Bank Name</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.bankName}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Branch Name</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.branchName}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>IFSC</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.ifscCode}</GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>Remarks</GridItem>
              <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>{props.remarks}</GridItem>
            </GridContainer>
            {
              props.bankQRCode &&
              <GridContainer>
                <GridItem xs={12} sm={12} md={4} style={{ marginTop: 5, marginBottom: 5, fontWeight: 'bold' }}>QR Code</GridItem>
                <GridItem xs={12} sm={12} md={8} style={{ marginTop: 5, marginBottom: 5 }}>
                  <img
                    alt="bankQRCode"
                    src={
                      BASE_URL_IMAGE_VIEW +
                      `agency/view-file?fileName=${props.bankQRCode}&type=BANK_LOGO_QRCODE`
                    }
                    height={150}
                    style={{ borderRadius: 5 }}
                  />
                </GridItem>
              </GridContainer>
            }
          </GridItem>

          <GridItem xs={12} sm={12} md={3}>
            <GridContainer style={{ height: '100%' }}>
              <GridItem xs={12} sm={12} md={12} style={{ marginTop: "6px" }}>
                {props.isActive ? (
                  <div
                    style={{
                      float: "right",
                      background: "green",
                      borderRadius: 50,
                      padding: 5,
                      color: "white",
                    }}
                  >
                    Active
                  </div>
                ) : (
                  <div
                    style={{
                      float: "right",
                      background: "lightgrey",
                      borderRadius: 50,
                      padding: 5,
                    }}
                  >
                    Inactive
                  </div>
                )}
                {props.bankLogo &&
                  <BankLogoContainer>
                    <img
                      alt="bankLogo"
                      src={
                        BASE_URL_IMAGE_VIEW +
                        `agency/view-file?fileName=${props.bankLogo}&type=BANK_LOGO_QRCODE`
                      }
                    />
                  </BankLogoContainer>
                }
              </GridItem>
            </GridContainer>

          </GridItem>
        </GridContainer>
      </OaCard>
    );
  }

  return (
    <div>
      {error ? (<OaCard>An error occured, please tray again later</OaCard>) : (
        bankList.length === 0 ? (<OaCard>No results</OaCard>) : (
          <GridContainer>
            {bankList.map(row =>
              <GridItem xs={12} sm={12} md={4} key={row.bankName} >
                <AOBankCard
                  accountLogo={row.accountLogo}
                  accountName={row.accountName}
                  remarks={row.remarks}
                  bankName={row.bankName}
                  branchName={row.branchName}
                  accountNumber={row.accountNumber}
                  ifscCode={row.ifscCode}
                  isActive={row.isActive}
                  bankAddress={row.bankAddress}
                  bankId={row.bankId}
                  bankQRCode={row.bankQRCode}
                  bankLogo={row.bankLogo}
                />
              </GridItem>
            )}
          </GridContainer>
        )
      )}
    </div>
  )
}

export default BankAccountCard;

const BankLogoContainer = styled.div`
img {
  float: right;
  border-radius: 5px;
  height: 70px;
  max-width: 300px;
}
`;
