import React, { useEffect } from 'react'
import styled from "styled-components";

const InsuranceTab = ({values, setFieldValue}) => {

  return (
    <Root className="input-element">
    <div className='insurance-tabs'>
        <div onClick={() => setFieldValue("planType", 1)} className={`insurance-tab ${values.planType === 1 && "active"}`}>Single Trip</div>
        <div onClick={() => setFieldValue("planType", 2)} className={`insurance-tab ${values.planType === 2 && "active"}`}>Annual Multi Trip</div>
    </div>
  </Root>
  )
}

export default InsuranceTab


const Root = styled.div`
.insurance-tabs {
    display: flex;
}
.insurance-tab {
    
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 700;
}
.active {
    background: white;
}
  
`;
