import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { TextField, useTheme, Button, Box, Dialog } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import WebApi from "api/ApiConstants";
import MantraRdService from "./MantraRdService";
import OaSnackbar from "oahoc/OaSnackbar";
import { AppConstant } from "appConstant";
// import TestEncryptionBody from "./TestEncryptionBody";
// import dateFnsFormat from "date-fns/format";
// import { BASE_URL_v2 } from "api/ApiConstants";
// import axios from "axios";
// import { localforageGetItem } from "oautils/oaForageUtils";

const AadhaarRegisterTfa = props => {
    const theme = useTheme();
    const formikRef = React.useRef(null);
    const [twoFaType, setTwoFaType] = React.useState("registration_2fa_bank2");
    const [pIDData, setPIDData] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [fingerPrintCaptured, setFingerPrintCaptured] = React.useState(false);
    const [avdmActive, setAvdmActive] = React.useState(false);
    const [captureAvdm, setCaptureAvdm] = React.useState(false);
    const [agentDetails, setAgentDetails] = React.useState(null);
    const [formTitle, setFormTitle] = React.useState("Authenticate two factor authentication");

    const [severitySnackbar, setSeveritySnackbar] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const initialValues = {
        adhaarnumber: "",
        mobilenumber: "",
        type: twoFaType
    };

    const validationSchema = Yup.object().shape({
        mobilenumber: Yup.string().required("Mobile number cannot be blank"),
        adhaarnumber: Yup.string().required("Aadhaar number cannot be blank"),
    });

    const onChangeMobileNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobilenumber", e.target.value);
        }
    };

    const onChangeAadhaarNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("adhaarnumber", e.target.value);
        }
    };

    const onSubmitTwoFa = (searchParams, { setSubmitting }) => {
        if (twoFaType && pIDData) {
            searchParams.accessmodetype = "SITE";
            searchParams.submerchantid = props.data?.merchant_code;
            searchParams.latitude = props.trnParams?.latitude;
            searchParams.longitude = props.trnParams?.longitude;
            searchParams.remarks = props.trnParams?.requestremarks;
            searchParams.type = twoFaType;
            searchParams.data = pIDData;
            
            setSubmitting(true);
            setIsLoading(true);
    
            WebApi.aepsTwoFactorAuthentication(searchParams, response => {
                // console.log(response);
                if (response.success) {
                    console.log(response.data);
    
                    props.setInfoText(response.message);
                    props.setSeverity({
                        ...props.severity,
                        issuccess: true
                    });
                    setFingerPrintCaptured(false);
                } else {
                    props.setInfoText(response.message);
                    setFingerPrintCaptured(false);
                    props.setSeverity({
                        ...props.severity,
                        iserror: true
                    });
                }
                setIsLoading(false);
                props.setTwoFaRegAttempted(true);
            }, error => {
                console.error("aadhaarPay2Factor Auth error", error) 
                setIsLoading(false);
                setFingerPrintCaptured(false);
            });
            setSubmitting(false);
        }
    };

    const reInitDevice = () => {
        setIsLoading(true);
        setAvdmActive(false);
    };

    const getTwoFaRegDetails = () => {
        WebApi.getAepsTwoFactorRegistrationDetails({
            merchantCode: props.data?.merchant_code,
            regType: AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1" ? 
            "registration_bank2" : "registration_bank3"
        }, response => {
            if(response.success) {
                // console.log("Response twofa", response);
                if(response.data.twofa_reg_status === "success") {
                    setFormTitle("Login to AEPS using two factor authentication");
                    setTwoFaType(AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1" ? 
                        "authenticate_2fa_bank2" : "authenticate_2fa_bank3");
                } else {
                    setFormTitle("Register two factor authentication");
                    setTwoFaType(AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1" ? "registration_bank2" : "registration_bank3");
                    // setTwoFaType("authenticate_2fa_bank2")
                }

                if(formikRef.current) {
                    formikRef.current.setFieldValue("mobilenumber", response.data?.mobileNo);
                    // formikRef.current.setFieldValue("adhaarnumber", response.data?.aadhaarNo);
                }
            } else {
                console.log("failed to get twofa reg details")
            }
            props.setIsLoading(false);
        }, error => {
            console.error("getTwoFaRegDEtails error", error);
            setIsLoading(false);
        });
    };

    React.useEffect(() => {
        getTwoFaRegDetails();
        // console.log("props 2fa reg", props)
        // console.log("props data", props.data)
    }, []);

    React.useEffect(() => {
        if(avdmActive) {
            props.setIsLoading(false);
        }
    }, [avdmActive]);

    // needed for testing start
    // const [tfaTestParams, setTfaTestParams] = React.useState();
    // const [crdOpen, setCrdOpen] = React.useState(false);

    // const consoleRawData = () => {
    //     if(formikRef.current && pIDData) {
    //         const tempParams = formikRef.current.values;
    //         tempParams.accessmodetype = "SITE";
    //         tempParams.submerchantid = props.data?.merchant_code;
    //         tempParams.latitude = props.trnParams?.latitude;
    //         tempParams.longitude = props.trnParams?.longitude;
    //         tempParams.remarks = "Two Factor Authentication KYC registration";
    //         tempParams.type = twoFaType;
    //         tempParams.data = pIDData;
            
    //         console.log("tempParams", tempParams);
    //         setTfaTestParams(tempParams);
    //         setCrdOpen(true);
    //         // localforageGetItem("access-key", function(errr, accessKey) {
    //         //     axios.post(BASE_URL_v2+'module/aeps/get-2fa-body-os', tempParams, { headers: {
    //         //         "Content-Type": "application/json",
    //         //         "Access-Control-Allow-Origin": "*",
    //         //         "Authorization": "Bearer "+accessKey
    //         //     }}).then(response => {
    //         //         console.log("body os response", response);
    //         //         if(response.data.success) {
    //         //             sendTokenlessEncryptedWithOutStream(accessKey, response.data.data, response.data.extraData);
    //         //         } else {
    //         //             setIsLoading(false);
    //         //         }
    //         //     }).catch(error => {console.error("Body error", error); setIsLoading(false)});
    //         // });
    //     } else {
    //         props.setInfoText("Please capture fingerprint!");
    //         props.setSeverity({
    //             ...props.severity,
    //             iserror: true
    //         });
    //     }
    // };

    // const sendTokenlessEncryptedWithOutStream = (jwtAccessKey, bodyStringOs, tokenStringOs) => {
    //     if (jwtAccessKey && bodyStringOs && tokenStringOs) {
    //         axios.post(BASE_URL_v2 + 'module/aeps/send-2fa-kyc-body-internal-token', {body: bodyStringOs}, {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Access-Control-Allow-Origin": "*",
    //                 "Authorization": "Bearer " + jwtAccessKey
    //             }
    //         }).then(response => {
    //             console.log("2fa kyc response", response);
    //             if(response.data.success) {
    //                 props.setInfoText(response.data.message);
    //                 props.setSeverity({
    //                     ...props.severity,
    //                     issuccess: true
    //                 });
    //                 setFingerPrintCaptured(false);
    //             } else {
    //                 props.setInfoText(response.data.message);
    //                 setFingerPrintCaptured(false);
    //                 props.setSeverity({
    //                     ...props.severity,
    //                     iserror: true
    //                 });
    //             }
    //             setIsLoading(false);
    //         }).catch(error => { console.error("Body error", error); setIsLoading(false) });
    //     }
    // };
    // needed for testing end

    return (
        <RootContainer theme={theme}>
            <div className="form-title-wrapper">
                <p className="form-title">{formTitle}</p>
            </div>
            {/** needed for testing start */}
            {/* <Dialog open={crdOpen} onClose={() => setCrdOpen(false)} fullScreen>
                <TestEncryptionBody
                    setCrdOpen={setCrdOpen}
                    tfaTestParams={tfaTestParams}
                />
            </Dialog>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: 10}}>
                <a style={{cursor: "pointer"}} onClick={consoleRawData}>Get Raw Data</a>
            </div> */}
            {/** needed for testing end */}
            <div className="form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitTwoFa}
                    // onSubmit={consoleRawData}
                >
                    {({ values, isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            <div className="form-wrapper">
                                <div className="form-row">
                                    <MantraRdService
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        setInfoText={(val) => props.setInfoText(val)}
                                        severity={props.severity}
                                        setSeverity={props.setSeverity}
                                        severitySnackbar={severitySnackbar}
                                        setSeveritySnackbar={setSeveritySnackbar}
                                        fingerPrintCaptured={fingerPrintCaptured}
                                        setFingerPrintCaptured={setFingerPrintCaptured}
                                        captureAvdm={captureAvdm}
                                        setCaptureAvdm={setCaptureAvdm}
                                        pIDData={pIDData}
                                        setPIDData={setPIDData}
                                        avdmActive={avdmActive}
                                        setAvdmActive={setAvdmActive}
                                        reInitDevice={reInitDevice}
                                        mode="auth"
                                    />
                                </div>
                                <div className="form-row justify-center mb-30">
                                    <div className="form-element-wrap">
                                        <label>Aadhaar Number</label>
                                        <TextField
                                            value={values.adhaarnumber}
                                            onChange={onChangeAadhaarNo}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <ErrorMessage name="adhaarnumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap">
                                        <label>Mobile</label>
                                        <TextField
                                            value={values.mobilenumber}
                                            onChange={onChangeMobileNo}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <ErrorMessage name="mobilenumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap submit-wrap">
                                        <Button type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            <OaSnackbar
                alertmessage={props.infoText}
                severity={severitySnackbar}
                setseverity={setSeveritySnackbar}
                vertical="top"
                horizontal="left"
                autoHideDuration={4000}
            />
        </RootContainer>
    );
};

export default AadhaarRegisterTfa;

const RootContainer = styled.div`
min-height: 200px;
.form-container {
    padding: 0 20px 5px;
}
.form-wrapper {
    text-align: center;
}
.form-title-wrapper {
    padding: 20px 20px 0;
    p {
        text-align: center;
    }
    .form-title {
        font-weight: 500;
        font-size: 1.2em;
    }
    .status-response {
        padding-top: 5px;
    }
}
.form-row {
    margin: 10px 0;
    display: flex;
    gap: 20px;
}
.form-element-wrap {
    &.submit-wrap {
        padding-top: 25.5px;
    }
    button {
        background: ${prop => prop.theme.palette.buttons.primary};
        color: ${prop => prop.theme.palette.buttons.primaryContrastText};
        font-size: 12px;
        padding: 6px 15px;
        height: 40.5px;
    }
}
.justify-center {
    justify-content: center;
}
.mb-30 {
    margin-bottom: 30px;
}
`;
