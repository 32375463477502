import { FooterSection } from "components/LandingPage/Gostree/FooterSection";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Gostree";
import AllOffers from "pages/module/view-offers/AllOffers";
import OfferDetails from "pages/module/view-offers/OfferDetails";
import React from "react";
import { Switch, Route } from "react-router-dom";

const ExclusiveOffersLayout = (props) => {
  const { ...rest } = props;

  return (
    <div>
      <PostLoginNavBar
        {...rest}
        isSearchPage={true}
        showServices={true}
        isTimerShow={true}
      />

      <div>
        {/* <AllOffers/> */}
        <Switch>
          <Route path="/exclusive-offers/view-all" component={AllOffers} />
          <Route
            path="/exclusive-offers/offer-details"
            component={OfferDetails}
          />
        </Switch>
      </div>
      {/* <PageFooter white {...props} /> */}
      <FooterSection />
    </div>
  );
};

export default ExclusiveOffersLayout;
