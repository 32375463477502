import React from "react";
// import "assets/airParadise/css/vendor.css";
// import "assets/airParadise/css/inner-footer.css";

import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import RoundLogo from "assets/airParadise/images/logo/round-logo.png";
import PinterestIcon from "assets/airParadise/images/pinterest.png";
import FacebookIcon from "assets/airParadise/images/facebook.png";
import LinkedinIcon from "assets/airParadise/images/linkedin.png";
import TwitterIcon from "assets/airParadise/images/twitter.png";
import { AppConstant } from "appConstant";

const useStyles = makeStyles(theme => ({

    root: {
        "& .menu a": {
            cursor: "pointer",
        }
    },

}));

const PageFooter = (props) => {

    const classes = useStyles();
    
    return (
        <div className={"home "+classes.root}>
            <div className="innerwrapper" style={{ marginTop: 150 }}>
                <div className="bottom-left">
                    <svg className="shapes2 layer2 ">
                        <use xlinkHref="./svg/shapes.svg#shapes2" />
                    </svg>

                    <svg className="shapes3 layer3">
                        <use xlinkHref="./svg/shapes.svg#shapes3" fill="url(#dots-4)" />
                    </svg>
                </div>

                <footer>
                    <div className="container-fluid" data-rellax-speed="1" data-rellax-percentage=".7">
                        <div className="ctrl-wraper2" style={{width: "95%"}}>
                            <div className="ftr-main-sec">
                                <div className="cus-review">
                                    <h5 className="ftr-headng">customer <br /> review</h5>
                                    <div className="vertical">
                                        <div className="csreview-item">
                                            <div className="cs-thumb">
                                                &nbsp;
                                            </div>    
                                            <div className="cs-content">
                                                <strong>Vimal lad</strong>
                                                <p>We have written example text for over 40 industry sectors, this is an ongoing project and we will be adding more copy if there is a demand.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="menu">
                                    <h5 className="ftr-headng">menu</h5>
                                    <ul>
                                        <li><a onClick={() => props.history.push("/b2c/aboutus")}>About us</a></li>
                                        <li><a onClick={() => props.history.push("/b2c/contactus")}>Contact us</a></li>
                                        <li><a onClick={() => props.history.push("/pricing_policy")}>Pricing Policy</a></li>
                                        <li><a onClick={() => props.history.push("/cancellation_and_refund")}>Cancellation & Refund Policy</a></li>
                                        <li><a href="flightbooking.html">flight booking</a></li>
                                        <li><a href="trainbooking.html">hotel booking</a></li>
                                    </ul>
                                </div>
                                {/*
                                <div className="news">
                                    <h5 className="ftr-headng">news</h5>
                                     <ul className="newslist">
                                        <li>
				                      <a href="blognews.html">
				                        <span className="nsthumb"><img src="./images/footer-news-thumb1.jpg" alt="img"/></span>
				                        <p>News title goes here  with bold text</p>
				                        <span className="date">15 june</span>
				                      </a>
				                    </li>
				                    <li>
				                      <a href="blognews.html">
				                        <span className="nsthumb"><img src="./images/footer-news-thumb2.jpg" alt="img"/></span>
				                        <p>News title goes here  with bold text</p>
				                        <span className="date">15 june</span>
				                      </a>
				                    </li>
				                    <li>
				                      <a href="blognews.html">
				                        <span className="nsthumb"><img src="./images/footer-news-thumb3.jpg" alt="img"/></span>
				                        <p>News title goes here  with bold text</p>
				                        <span className="date">15 june</span>
				                      </a>
				                    </li>
				                    <li>
				                      <a href="blognews.html">
				                        <span className="nsthumb"><img src="./images/footer-news-thumb4.jpg" alt="img"/></span>
				                        <p>News title goes here  with bold text</p>
				                        <span className="date">15 june</span>
				                      </a>
				                    </li>
                                    </ul> 
                                </div> 
                                */}
                                <div className="social">
                                    {/* <div className="instagram">
                                        <h5 className="ftr-headng">instagram</h5>
                                        <ul className="insta-thumbgal">
                                            <li><a href="#"><img src="./images/insta-thumb1.jpg" alt="img" /></a></li>
                                            <li><a href="#"><img src="./images/insta-thumb2.jpg" alt="img" /></a></li>
                                            <li><a href="#"><img src="./images/insta-thumb3.jpg" alt="img" /></a></li>
                                            <li><a href="#"><img src="./images/insta-thumb4.jpg" alt="img" /></a></li>
                                            <li><a href="#"><img src="./images/insta-thumb5.jpg" alt="img" /></a></li>
                                            <li><a href="#"><img src="./images/insta-thumb6.jpg" alt="img" /></a></li>
                                        </ul>
                                    </div> */}

                                    <div className="connect-with-us">
                                        <h5 className="ftr-headng">social with us</h5>
                                        <ul className="social-links">
                                            <li><a href="#" ><img className="height-40" src={PinterestIcon} /></a></li>
                                            <li><a href="#"><img className="height-40" src={TwitterIcon} /></a></li>
                                            {/* <!-- <li><a href="#" className="gt-uniE910 ft-behance"></a></li>
                                            <li><a href="#" className="gt-internet ft-dribbble"></a></li> --> */}
                                            <li><a href="#"><img className="height-40" src={FacebookIcon} /></a></li>
                                            <li><a href="#"><img className="height-40 " src={LinkedinIcon} /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="footernote-area">
                                <div className="footer-logo-box">
                                    <a className="footer-logo" href="index.html">
                                        <img src={RoundLogo} alt="logo" />
                                        {/* <!-- <span className="logo animated-logo"></span> --> */}
                                    </a>
                                    <div className="ftr-logo-txt">
                                        <strong>Travel the world</strong>
                                        <span>The one and only stop for every traveler</span>
                                    </div>
                                </div>
                                <ul className="footer-dtls">
                                    <li><a href="#"><i className="fas fa-globe glob-icon"></i>we have worldwide travel operators</a></li>
                                    {/* <li><a href="#"><span className="gt-package"></span>free shipping</a></li> */}
                                    <li><a href="#"><i className="far fa-arrow-alt-circle-left glob-icon" ></i>hassle free cancel policies</a></li>
                                </ul>
                            </div>

                            <div className="copyright">
                                <p>{AppConstant.name} - All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>);
}

export default withRouter(PageFooter);
