import React, { useEffect, useRef, useState } from "react";
// import './Autocomplete.css';
import WebApi from "api/ApiConstants";
import styled from "styled-components";
import cross from "assets/icons/cross.png";

const FlightLocationInput = ({ props, index, type, showDesc }) => {
  const [inputValue, setInputValue] = useState(
    props?.values?.sectors?.[index]?.[`${type}Obj`]?.cityName
  );

  const [inputDesc, setInputDesc] = useState(
    props?.values?.sectors?.[index]?.[`${type}Obj`]?.airportDesc
  );
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [airportList, setAirportList] = React.useState(popularCities);
  const [isOpen, setIsOpen] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const autocompleteRef = useRef(null);

  // console.log(p)

  useEffect(() => {
    setInputValue(
      props?.values?.sectors?.[index]?.[`${type}Obj`]?.cityName || ""
    );
    setInputDesc(
      props?.values?.sectors?.[index]?.[`${type}Obj`]?.airportDesc || ""
    );
  }, [props]);
  //
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    fetchAirports(event.target.value.trim());
    setIsOpen(true);
    setInputValue(value);
    const filtered = airportList.filter(
      (option) =>
        option.cityName.toLowerCase().includes(value.toLowerCase()) ||
        option.airportCode.toLowerCase().includes(value.toLowerCase())
    );
    // console.log(filtered)
    setFilteredOptions(filtered);
  };

  const fetchAirports = (searchParams) => {
    // setFetchingAirports(true);
    if (searchParams.length >= 2) {
      WebApi.fetchAirportList(
        {
          airportCode: searchParams.trim(),
        },
        (response) => {
          if (response.success == true) {
            console.log(response.data.airports);
            setAirportList(response.data.airports);
          }
        },
        (error) => console.log("fetchAirportList error", error)
      );
    }
  };

  const handleSelectOption = (value) => {
    if (props.setFieldValue) {
      props.setFieldValue(`sectors[${index}][${type}Obj]`, value);
      props.setFieldValue(`sectors[${index}].${type}`, value.airportCode);
    } else {
      props.setfieldvalue(`sectors[${index}][${type}Obj]`, value);
      props.setfieldvalue(`sectors[${index}].${type}`, value.airportCode);
    }

    setInputValue(value.cityName);
    setInputDesc(value.airportDesc);
    setFilteredOptions([]);
  };

  const showClearBtn = () => {
    setShowClear(true);
  };

  const hideClearBtn = () => {
    setShowClear(false);
  };

  const clearInputHandler = (value) => {
    setInputValue("");
    autocompleteRef.current.focus();
  };

  return (
    <Root
      ref={autocompleteRef}
      className="flight-location-input-container"
      onMouseEnter={showClearBtn}
      onMouseLeave={hideClearBtn}
    >
      <input
        type="text"
        value={inputValue}
        className="flight-location-input"
        onChange={handleInputChange}
        placeholder="Search."
      />
      {showDesc && <div className="flight-location-desc">{inputDesc}</div>}
      {showClear && (
        <img
          src={cross}
          className="clear-icon"
          onClick={() => clearInputHandler()}
        />
      )}
      {isOpen && filteredOptions.length > 0 && (
        <ul className="flight-location-lists">
          {filteredOptions.map((option, index) => (
            <li
              className="flight-location-list"
              key={index}
              onClick={() => handleSelectOption(option)}
            >
              <div>
                <div className="city-input-popup">
                  <div>{option.cityName}</div>
                  <div>{option.countryCode}</div>
                </div>
                <div className="airport-input-popup">{option.airportDesc}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Root>
  );
};

export default FlightLocationInput;

const popularCities = [
  {
    airportId: 5669,
    cityName: "Mumbai",
    airportCode: "BOM",
    countryCode: "IN",
    citySearch: "MUMBAI,BOMBAY",
    airportDesc: "Chhatrapati Shivaji International Airport",
  },
  {
    airportId: 5877,
    cityName: "New Delhi",
    airportCode: "DEL",
    countryCode: "IN",
    citySearch: "NEW DELHI,DELHI",
    airportDesc: "Indira Gandhi International Airport",
  },
  {
    airportId: 682,
    cityName: "Bangkok",
    airportCode: "BKK",
    countryCode: "TH",
    citySearch: "",
    airportDesc: "Bangkok",
  },
  {
    airportId: 679,
    cityName: "Bangalore",
    airportCode: "BLR",
    countryCode: "IN",
    citySearch: "BANGALORE,BANGLURU,BANGLORE,BENGALURU",
    airportDesc: "Bengaluru International Airport",
  },
  {
    airportId: 6926,
    cityName: "Pune",
    airportCode: "PNQ",
    countryCode: "IN",
    citySearch: "PUNE,PUNA",
    airportDesc: "Pune Airport",
  },
  {
    airportId: 3454,
    cityName: "Hyderabad",
    airportCode: "HYD",
    countryCode: "IN",
    citySearch: "HYDERABAD,HYDRABAD",
    airportDesc: "Rajiv Gandhi International Airport",
  },
  {
    airportId: 4229,
    cityName: "Kolkata",
    airportCode: "CCU",
    countryCode: "IN",
    citySearch: "KOLKATA,CALCUTTA,CALCUTA",
    airportDesc: "Netaji Subhash Chandra Bose International Airport",
  },
  {
    airportId: 1585,
    cityName: "Chennai",
    airportCode: "MAA",
    countryCode: "IN",
    citySearch: "CHENNAI,MADRAS",
    airportDesc: "Chennai International Airport",
  },
  {
    airportId: 2935,
    cityName: "Goa",
    airportCode: "GOI",
    countryCode: "IN",
    citySearch: "GOA",
    airportDesc: "Dabolim Goa International Airport",
  },
  {
    airportId: 2257,
    cityName: "Dubai",
    airportCode: "DXB",
    countryCode: "AE",
    citySearch: "",
    airportDesc: "Dubai International",
  },
];

const Root = styled.div`
  position: relative;
  font-family: poppins;
  .flight-location-input {
    height: 45px;
    padding: 10px;
    border-radius: 10px;
    border: 0px;
    font-size: 17px;
    font-family: poppins;
    color: #838383;
    font-weight: 500;
    width: 100%;
    position: relative;
    /* padding-bottom: 5px; */
  }
  .flight-location-desc {
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    /* padding-top: 0px; */
  }
  .clear-icon {
    // display: none;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 10px;
    top: 12px;
    opacity: 50%;
    cursor: pointer;
    // @media all and (max-width: 500px) {
    //  display: none;
    // }
  }
  .flight-location-lists {
    position: absolute;
    width: 100%;
    background-color: #fff;
    margin: 0px;
    list-style: none;
    z-index: 10;
    padding: 0px;
    overflow-y: scroll;
    max-height: 250px;
  }
  .flight-location-list {
    font-family: "poppins";
    padding: 10px;
    font-size: 14px;
    font-family: "poppins";
    color: #838383;
    font-weight: 500;

    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  .city-input-popup {
    display: flex;
    justify-content: space-between;
  }
  .airport-input-popup {
    font-size: 12px;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
