import React, { useState } from "react";
import LeftAdornedDatePicker from "components/DatePickers/LeftAdornedDatePicker";
import dateFnsFormat from "date-fns/format";
import calendarIcon from "assets/Tickat/images/home_pix/calendar_icon.png";
import { TextField } from "@material-ui/core";

const HotelDateInput = ({
  values,
  showDay,
  type,
  setArrivalDateDialogOpen,
  setDepartureDateDialogOpen,
  setFieldValue,
}) => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div>
      <LeftAdornedDatePicker
        showDisabledMonthNavigation
        name={`${type}DateDisplay`}
        className="date-picker"
        minDate={startDate}
        customInput={<TextField id="standard-basic" variant="standard" />}
        value={values[`${type}DateDisplay`]}
        onChange={(e, v) => {
          if (type === "checkIn") {
            setFieldValue(`checkInDateDisplay`, e);
            setFieldValue("checkInDate", dateFnsFormat(e, "dd/MM/yyyy"));
            setFieldValue(
              "checkOutDateDisplay",
              new Date(e.getTime() + 86400000)
            );
            setFieldValue(
              "checkOutDate",
              dateFnsFormat(new Date(e.getTime() + 86400000), "dd/MM/yyyy")
            );
            if (setArrivalDateDialogOpen && setDepartureDateDialogOpen) {
              setDepartureDateDialogOpen(false);
              setArrivalDateDialogOpen(true);
            }
          } else {
            // console.log('first')
            setFieldValue("checkOutDateDisplay", e);
            setFieldValue("checkOutDate", dateFnsFormat(e, "dd/MM/yyyy"));
            if (setArrivalDateDialogOpen && setDepartureDateDialogOpen) {
              setArrivalDateDialogOpen(false);
            }

            // setEndDate(e);
          }
          // setEndDate(dateFnsFormat(e, "dd/MM/yyyy"));
        }}
        KeyboardButtonProps={{
          onFocus: (e) => {
            setDepartureDateDialogOpen(true);
          },
        }}
        PopoverProps={{
          disableRestoreFocus: true,
          onClose: () => {
            setDepartureDateDialogOpen(false);
          },
        }}
        InputProps={{
          onFocus: () => {
            setDepartureDateDialogOpen(true);
          },
        }}
        monthsShown={2}
        dateFormat="dd MMM yyyy"
        labeltxt="CHECK-IN"
        selected={values[`${type}DateDisplay`]}
        // adnicon={calendarIcon}
      />
      {showDay && (
        <div className="day-label">
          {days[values[`${type}DateDisplay`].getDay()]}
        </div>
      )}
    </div>
  );
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default HotelDateInput;
