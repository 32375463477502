import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import OaCard from "oahoc/OaCard";
import { makeStyles } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Gostree";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter.LookMyTicket";
import BannerArea from "components/BannerArea/BannerArea";
import Container from "@material-ui/core/Container";
import OaActionButton from "oahoc/OaActionButton";
import AddBeneficiary from "pages/module/dmt/AddBeneficiary";
import AddSender from "pages/module/dmt/AddSender";
import BeneficiaryList from "pages/module/dmt/BeneficiaryList";
import TransactionList from "pages/module/dmt/TransactionList";
import VerifyOtp from "pages/module/dmt/VerifyOtp";
import Transaction from "pages/module/dmt/Transaction";
import CommissionManager from "pages/module/dmt/CommissionManager";
import Refund from "pages/module/dmt/Refund";
import MobileSearch from "pages/module/dmt/MobileSearch";
import TransactionResponseList from "pages/module/dmt/TransactionResponseList";

const useStyles = makeStyles(styles);

export default function DmtLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />

      <div style={{minHeight: "90vh"}}>
        <Container fixed style={{ marginBottom: 20 }}>
          <OaCard className={"oa-card-search"} style={{ marginTop: 30 }}>
            <div>
              <Switch>
                <Route
                  path="/moneytransfer/add-beneficiary"
                  exact
                  component={AddBeneficiary}
                />
                <Route
                  path="/moneytransfer/add-sender"
                  exact
                  component={AddSender}
                />
                <Route
                  path="/moneytransfer/beneficiary-list"
                  exact
                  component={BeneficiaryList}
                />
                <Route
                  path="/moneytransfer/verify-otp"
                  exact
                  component={VerifyOtp}
                />
                <Route
                  path="/moneytransfer/transaction"
                  exact
                  component={Transaction}
                />
                <Route
                  path="/moneytransfer/commission"
                  exact
                  component={CommissionManager}
                />
                <Route
                  path="/moneytransfer/transaction-list"
                  exact
                  component={TransactionList}
                />
                <Route path="/moneytransfer/refund" exact component={Refund} />
                <Route
                  path="/moneytransfer/search-sender"
                  exact
                  component={MobileSearch}
                />
                <Route
                  path="/moneytransfer/transaction/:serialNo"
                  exact
                  component={TransactionResponseList}
                />
              </Switch>
            </div>
          </OaCard>
        </Container>
      </div>
      <PageFooter white />
      <style>{`
      .oa-card-search {
        background: white;
      }
      `}</style>
    </div>
  );
}
