import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import OaCard from "oahoc/OaCard";
import { Formik, Form, ErrorMessage } from "formik";
import OaFormTextField from "oahoc/OaFormTextField";
import OaFormRadio from "oahoc/OaFormRadio";
import Collapse from "@material-ui/core/Collapse";
import OaFormSelectField from "oahoc/OaFormSelectField";
import OaFormFileUpload from "oahoc/OaFormFileUploadNew";
import OaTabBar from "oahoc/OaTabBar";
import { scrollToTop } from "oautils/oaCommonUtils";
import dateFnsFormat from "date-fns/format";
import OaFormSaveButton from "oahoc/OaFormSaveButton";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { makeStyles } from "@material-ui/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid } from "@material-ui/core";
import AgentServices from "./AgentServices";

import axios from "axios";
import * as Yup from "yup";
// check

import { red } from "@material-ui/core/colors";
import { AppConstant } from "appConstant";
import DatePickerWithCustomHeader from "oahoc/DatePickerCustomHeader ";
import { TextField } from "@material-ui/core";

const AgentRegistration = (props) => {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [gstCities, setGstCities] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isIATARegister, setIsIATARegister] = useState(false);
  const [isGSTAvailable, setIsGSTAvailable] = useState(false);

  const [imageReinitialize, setImageReinitialize] = useState(false);

  const [showFormFlag, setShowFormFlag] = useState(true);
  const [showFormSubmitMessageFlag, setShowFormSubmitMessageFlag] = useState(
    false
  );

  const [isPanCardImage, setIsPanCardImage] = useState(false);
  const [isAadhaarCardImage, setIsAadhaarCardImage] = useState(false);

  const [uploadedPanImageName, setUploadedPanImageName] = useState("");
  const [uploadedAddressImageName, setUploadedAddressImageName] = useState("");
  const [uploadedPanImageNameBack, setUploadedPanImageNameBack] = useState("");
  const [
    uploadedAddressImageNameBack,
    setUploadedAddressImageNameBack,
  ] = useState("");
  const [uploadedLogoImageName, setUploadedLogoImageName] = useState("");

  const [uploadedGSTImageName, setUploadedGSTImageName] = useState("");
  const [uploadedIATAImageName, setUploadedIATAImageName] = useState("");
  const [refAgentsList, setRefAgentsList] = useState(null);
  const [fetchingRefs, setFetchingRefs] = useState(true);

  let tabs = [
    {
      name: "agent-registration",
      label: "Agent Registeration",
      url: "/user/agent-registration",
    },
  ];
  let currentTab = "agent-registration";

  const [collapsed, setCollapsed] = useState(true);
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const getReqDate = (daySpan) => {
    const reqDate = new Date();

    if (daySpan > 365 * 4) {
      daySpan += Math.floor(daySpan / 365 / 4);
    } else {
      if (daySpan < 0) {
        var tempDs = daySpan * -1;

        if (tempDs > 365 * 4) {
          tempDs += Math.floor(tempDs / 365 / 4);
        }

        daySpan = tempDs * -1;
      }
    }

    reqDate.setTime(reqDate.valueOf() + daySpan * 24 * 3600 * 1000);

    return reqDate;
  };

  const initialValues = {
    username: "",
    pannumber: "",
    firstname: "",
    lastname: "",
    gender: "",
    dob: dateFnsFormat(getReqDate(-(365 * 18)), "dd-MM-yyyy"),
    dobDisplay: getReqDate(-(365 * 18)),
    corpname: "",
    address: "",
    statename: "",
    cityname: "",
    pincode: "",
    landlinenumber: "",
    mobilenumber: "",
    website: "",
    email: "",
    confirmEmail: "",
    currentTurnover: "",
    expectedVolume: "",
    officeSpaceType: "",
    agentReference: 0,
    agentRefObj: { agentId: 0, firstName: "", lastName: "" },
    agentServices: {
      flightService: false,
      hotelService: false,
      busService: false,
      dmtService: false,
      rechargeService: false,
      aepsService: false,
      aadhaarpayService: false,
      irctcService: false,
      panService: false,
      insuranceService: false,
      islandHopperService: false
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("User Name is required")
      .matches(/^[A-Za-z]+$/, "Invalid Username"),
    pannumber: Yup.string()
      .required("PAN is required")
      .matches(
        /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,
        "Invalid PAN Number"
      ),
    firstname: Yup.string()
      .required("First Name is required")
      .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid First Name"),
    lastname: Yup.string()
      .required("Last Name is required")
      .matches(/^[a-zA-Z]( ?[a-zA-Z])*$/, "Invalid Last Name"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Date of Birth is required"),
    corpname: Yup.string().required("Company name is required"),
    address: Yup.string().required("Office address is required"),
    statename: Yup.string().required("State is required"),
    cityname: Yup.string().required("City is required"),
    landlinenumber: Yup.string().matches(
      phoneRegex,
      "Invalid phone number"
    ),
    mobilenumber: Yup.string()
      .required("Mobile Number is required")
      .matches(phoneRegex, "Invalid mobile number"),
    currentTurnover: Yup.number()
      .typeError("Amount must be a number")
      .positive("Turnover can't be negative"),
    pincode: Yup.string()
      .required("PIN code is required")
      .matches(/^[0-9]{6}$/, "Invalid PIN code"),
    website: Yup.string().url("Invalid website address"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid Email"),
    confirmEmail: Yup.string()
      .required("Confirmation email is required")
      .email("Invliad Email")
      .oneOf(
        [Yup.ref("email"), null],
        "Confirm email doesn't match"
      ),
    expectedVolume: Yup.number()
      .required("Expected business volume is required")
      .typeError("Amount must be a number")
      .positive("Must not be a negative value"),
    officeSpaceType: Yup.string().required(
      "Office space type is required"
    ),
  });
  
  function collapse() {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  }

  // function getAllStates(){
  //   let allStates = []
  //  return allStates
  // }

  // function onStateSelected(state){
  //   console.log(state);
  //   axios.get("https://indian-cities-api-nocbegfhqg.now.sh/cities?District=Dharwad")
  //     .then(function (response) {
  //         console.log(response)
  //     })
  // }

  const stateOnChange = function (value) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateName == value) {
        setCities(states[i].cities);
      }
    }
  };

  const stateOnChangeGST = function (value) {
    for (let i = 0; i < states.length; i++) {
      if (states[i].stateId == value) {
        setGstCities(states[i].cities);
      }
    }
  };

  const getRefAgentsList = () => {
    WebApi.getAgentsRefList({ type: "" }, (response) => {
      console.log("Response", response);
      if (response.success) {
        setRefAgentsList(response.data);
      } else {
        console.error("Failed to get references list");
      }
      setFetchingRefs(false);
    }, (error) => {
      console.error("Failed to get references list", error);
      setFetchingRefs(false);
    });
  };

  const getStatesCitiesList = () => {
    apiCall(WebApi.getStateCity, {}, (response) => {
      if (response.success) {
        setStates(response.data);
        setIsLoading(false);
      } else {
        setIsError(true);
      }
    });
  };

  const handleSubmit = (
    searchParams,
    { setSubmitting, resetForm, enableReinitialize }
  ) => {
    setIsPanCardImage(false);
    setIsAadhaarCardImage(false);
    if (!uploadedPanImageName) {
      setIsPanCardImage(true);
      return false;
    }

    if (!uploadedAddressImageName) {
      setIsAadhaarCardImage(true);
      return false;
    }
    setIsError(false);
    setIsSuccess(false);
    setSubmitting(true);

    scrollToTop(0);
    searchParams.dob = dateFnsFormat(
      searchParams.dobDisplay,
      "dd-MM-yyyy"
    );
    searchParams.country = "India";
    searchParams.logoImage = uploadedLogoImageName;
    searchParams.panImage = uploadedPanImageName;
    searchParams.aadhaarCardImage = uploadedAddressImageName;
    searchParams.panImageBack = uploadedPanImageNameBack;
    searchParams.aadhaarCardImageBack = uploadedAddressImageNameBack;
    searchParams.isIATARegister = isIATARegister;

    searchParams.gstImage = uploadedGSTImageName;
    searchParams.iataImage = uploadedIATAImageName;

    //console.log("The search params are ", searchParams);
    setIsSaving(true);
    apiCall(
      WebApi.registerDirectAgent,
      searchParams,
      (response) => {
        setIsError(!response.success);
        setIsSuccess(response.success);
        setSubmitting(false);
        setIsSaving(false);
        setInfoText(response.message);
        if (response.success === true) {
          setShowFormFlag(false);
          setShowFormSubmitMessageFlag(true);
          setInfoText(
            "Agent successfully created, you will get a confirmation mail on activation. Thank you. Your Username : " +
            response.data.username +
            ", Password : " +
            response.data.password
          );
          enableReinitialize = true;
          resetForm(true);
          setImageReinitialize(true);
        }
      }
    );
  }

  useEffect(() => {
    if (isLoading) {
      getRefAgentsList();
      getStatesCitiesList();
    }
  }, [isLoading]);

  return (
    <>
      <GridItem xs={12} sm={12} md={12} className={classes.root}>
        <OaCard
          className={"oa-card-primary"}
          style={{ boxShadow: "none", background: "transparent" }}
        >
          <OaTabBar tabs={tabs} currentTab={currentTab} type="primary" />

          <Collapse in={showFormSubmitMessageFlag}>
            <OaCard>
              <h4>A BIG Thank You!!</h4>
              <div>We thank you for choosing to work with us.</div>
              <div style={{ marginTop: "10px" }}>
                We appreciate your business and promise you a complete secure
                honest and transparent business deal. We have received you
                request, our operations team will evaluate the contents of the
                form and respond back. You may receive a call from us to verify
                a few details, should the team feel the need for the same. A
                mail has also been shared on the email id provided by you during
                the registration process. We will keep you updated with the
                progress of your application. Should you have any queries
                meanwhile you can choose to reach out to us on the contact
                details provided on the main page.
              </div>
              <div style={{ marginTop: "10px" }}>
                Thanks once again, and hope to be working soon
              </div>
              <div style={{ fontWeight: "bold", marginTop: "10px" }}>
                Team {AppConstant.name}
              </div>
            </OaCard>
          </Collapse>
          <Collapse in={showFormFlag}>
            <OaCard className="root-card">
              <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
              />
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(
                  searchParams,
                  { setSubmitting, resetForm, enableReinitialize }
                ) => handleSubmit(
                  searchParams,
                  { setSubmitting, resetForm, enableReinitialize }
                )}
              >
                {({
                  values,
                  isSubmitting,
                  setFieldValue,
                  enableReinitialize,
                  resetForm,
                }) => (
                  <Form>
                    <OaCard className="inner-card">
                      <h4
                        className={"oa-form-header"}
                        onClick={collapse}
                        style={{
                          transform: " scale(0.85)",
                          transformOrigin: "left",
                        }}
                      >
                        Login Details
                      </h4>

                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                          className="form-element"
                        >
                          <OaFormTextField name="username" label="User Name*" />
                          <ErrorMessage
                            name="username"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                          className="form-element"
                        >
                          <OaFormTextField name="pannumber" label="PAN*" />
                          <ErrorMessage
                            name="pannumber"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                    </OaCard>

                    <OaCard className="inner-card">
                      <h4
                        className={"oa-form-header"}
                        onClick={collapse}
                        style={{
                          transform: " scale(0.85)",
                          transformOrigin: "left",
                        }}
                      >
                        Ownership Details
                      </h4>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                          className="form-element"
                        >
                          <OaFormTextField
                            label="First Name*"
                            name="firstname"
                          />
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6} sm={12} xs={12} className="form-element">
                          <OaFormTextField label="Last Name*" name="lastname" />
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={3} sm={12} xs={12} className="form-element">
                          <OaFormSelectField
                            label="Gender*"
                            name="gender"
                            value={values.gender}
                            options={[
                              { value: "male", label: "Male" },
                              { value: "female", label: "Female" },
                              { value: "transgender", label: "Transgender" },
                            ]}
                          />
                          <ErrorMessage
                            name="gender"
                            component="div"
                            className="error"
                          />
                        </GridItem>

                        <GridItem md={3} sm={12} xs={12} className="form-element dob-container">
                          {/* <OaFormDateField
                            value={values.dobDisplay}
                            selected={values.dobDisplay}
                            onChange={(date) =>
                              setFieldValue("dobDisplay", date)
                            }
                            label="Date of Birth*"
                            name="dobDisplay"
                          /> */}
                          {/* {
                            console.log("ashjg",values)
                          } */}
                          <label> Date of Birth </label>
                          <DatePickerWithCustomHeader
                            dateFormat="dd-MM-yyyy"
                            maxDate={getReqDate(-(365 * 18))}
                            // label="Date of Birth*"
                            name="dobDisplay"
                            className="pax-info-date-picker"
                            selected={values.dobDisplay}
                            autoComplete="off"
                            disableClearable
                            onChange={(date) => {
                              setFieldValue("dobDisplay", date)
                              setFieldValue("dob", dateFnsFormat(date, "dd-MM-yyyy"))
                            }}
                          />
                          <ErrorMessage
                            name="dob"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                    </OaCard>

                    <OaCard className="inner-card">
                      <h4
                        className={"oa-form-header"}
                        onClick={collapse}
                        style={{
                          transform: " scale(0.85)",
                          transformOrigin: "left",
                        }}
                      >
                        Communication Details
                      </h4>
                      <GridContainer>
                        <OaFormTextField
                          label="Company Name*"
                          name="corpname"
                          md={12}

                        />
                        <GridItem md={5}>
                          <ErrorMessage
                            name="corpname"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <OaFormTextField
                          label="Office Address*"
                          name="address"
                          md={12}
                        />
                        <GridItem md={5}>
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormSelectField
                            label="State*"
                            name="statename"
                            value={values.gstState}
                            options={states}
                            optionValue={"stateName"}
                            optionLabel={"stateName"}
                            onChange={(value) => {
                              setFieldValue("statename", value);
                              stateOnChange(value);
                            }}
                          />
                          <ErrorMessage
                            name="statename"
                            component="div"
                            className="error"
                          />
                        </GridItem>

                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormSelectField
                            label="City*"
                            name="cityname"
                            value={values.gstCity}
                            options={cities}
                            // optionValue={"cityId"}
                            optionValue={"cityName"}
                            optionLabel={"cityName"}
                          />
                          <ErrorMessage
                            name="cityname"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField label="PIN Code*" name="pincode" />
                          <ErrorMessage
                            name="pincode"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            label="Phone Number"
                            name="landlinenumber"
                          />
                          <ErrorMessage
                            name="landlinenumber"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            label="Mobile Number*"
                            name="mobilenumber"
                          />
                          <ErrorMessage
                            name="mobilenumber"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            label="Website(E.g..https://www.google.com)"
                            name="website"
                          />
                          <ErrorMessage
                            name="website"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField label="Email*" name="email" />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            label="Confirm Email*"
                            name="confirmEmail"
                          />
                          <ErrorMessage
                            name="confirmEmail"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            name="currentTurnover"
                            label="Current Turnover"
                          />
                          <ErrorMessage
                            name="currentTurnover"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormTextField
                            label="Expected Business Volume(Rs.)*"
                            name="expectedVolume"
                          />
                          <ErrorMessage
                            name="expectedVolume"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormSelectField
                            label="Office Space*"
                            name="officeSpaceType"
                            value={values.officeSpaceType}
                            options={[
                              { value: "rented", label: "Rented" },
                              { value: "owned", label: "Owned" },
                            ]}
                          />
                          <ErrorMessage
                            name="officeSpaceType"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormRadio
                            label="IATA Registration"
                            name="isIATARegister"
                            value="isIATARegister"
                            checked={isIATARegister}
                            onChange={() => {
                              setIsIATARegister(!isIATARegister);
                            }}
                          />
                          <ErrorMessage
                            name="isIATARegister"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      {isIATARegister && (
                        <GridContainer>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              label="IATA Registeration Number"
                              name="iataRegisterationNumber"
                            />
                            <ErrorMessage
                              name="iataRegisterationNumber"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <OaFormFileUpload
                            label={"IATA DOCUMENT"}
                            type="IATA_IMAGES"
                            setUploadedImageName={setUploadedIATAImageName}
                            imageReinitialize={imageReinitialize}
                          />
                        </GridContainer>
                      )}
                    </OaCard>

                    <OaCard className="inner-card">
                      <h4
                        className={"oa-form-header"}
                        onClick={collapse}
                        style={{
                          transform: " scale(0.85)",
                          transformOrigin: "left",
                        }}
                      >
                        GST Details
                      </h4>
                      <GridContainer>
                        <GridItem md={6}
                          sm={12} xs={12}
                        >
                          <OaFormRadio
                            label="GST Details Available "
                            name="isGSTAvailable"
                            value="isGSTAvailable"
                            checked={isGSTAvailable}
                            onChange={() => {
                              setIsGSTAvailable(!isGSTAvailable);
                            }}
                          />
                          <ErrorMessage
                            name="isGSTAvailable"
                            component="div"
                            className="error"
                          />
                        </GridItem>
                      </GridContainer>
                      {isGSTAvailable && (
                        <GridContainer>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              required={true}
                              label="GSTIN"
                              name="gstNumber"
                            />
                            <ErrorMessage
                              name="gstNumber"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              required={true}
                              label="GST Name"
                              name="gstName"
                            />
                            <ErrorMessage
                              name="gstName"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              required={true}
                              label="GST Phone Number"
                              name="gstMobile"
                            />
                            <ErrorMessage
                              name="gstMobile"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              required={true}
                              label="GST Email"
                              name="gstEmail"
                            />
                            <ErrorMessage
                              name="gstEmail"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormSelectField
                              label="Agency GST State"
                              name="gstState"
                              value={values.gstState}
                              options={states}
                              optionValue={"stateId"}
                              optionLabel={"stateName"}
                              onChange={(value) => {
                                setFieldValue("gstState", value);
                                stateOnChangeGST(value);
                              }}
                            />
                            <ErrorMessage
                              name="gstState"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormSelectField
                              label="Agency GST City"
                              name="gstCity"
                              value={values.gstCity}
                              options={gstCities}
                              optionValue={"cityId"}
                              optionLabel={"cityName"}
                            />
                            <ErrorMessage
                              name="gstCity"
                              component="div"
                              className="error"
                            />
                          </GridItem>
                          {/* <ErrorMessage name="gstEmail" component="div" className="gstCity"/> */}
                          <GridItem md={6}>
                            <OaFormTextField
                              required={true}
                              label="GST Pincode"
                              name="gstPincode"
                            />
                            <ErrorMessage
                              name="gstPincode"
                              component="div"
                              classname="error"
                            />
                          </GridItem>
                          <GridItem md={6}
                            sm={12} xs={12}
                          >
                            <OaFormTextField
                              required={true}
                              label="GST Address"
                              name="gstAddress"
                              multiline={true}
                              rows="5"
                            />
                            <ErrorMessage
                              name="gstAddress"
                              component="div"
                              classname="error"
                            />
                          </GridItem>

                          <OaFormFileUpload
                            label={"GST DOCUMENT"}
                            type="GST_IMAGES"
                            setUploadedImageName={setUploadedGSTImageName}
                            imageReinitialize={imageReinitialize}
                          />
                        </GridContainer>
                      )}
                    </OaCard>

                    <OaCard className="inner-card">
                      <GridContainer>
                        <Grid item className="file-upload-item">
                          <OaFormFileUpload
                            label={"PAN CARD FRONT*"}
                            type="PAN_CARD"
                            setUploadedImageName={setUploadedPanImageName}
                            imageReinitialize={imageReinitialize}
                          />
                          <Collapse in={isPanCardImage}>
                            <div name="panImage" className="error">
                              PAN Card is required for KYC
                            </div>
                          </Collapse>
                        </Grid>
                        {/* <GridItem md={6}
                        sm={12} xs={12}
                        >
                          <OaFormFileUpload
                            label={"PAN CARD BACK"}
                            type="PAN_CARD"
                            setUploadedImageName={setUploadedPanImageNameBack}
                            imageReinitialize={imageReinitialize}
                          />
                        </GridItem> */}
                        <Grid item className="file-upload-item">
                          <OaFormFileUpload
                            label={"ADDRESS PROOF FRONT*"}
                            type="AADHAR_CARD"
                            setUploadedImageName={setUploadedAddressImageName}
                            imageReinitialize={imageReinitialize}
                          />
                          <Collapse in={isAadhaarCardImage}>
                            <div name="aadhaarCardImage" className="error">
                              Address Proof is required for KYC
                            </div>
                          </Collapse>
                        </Grid>
                        <Grid item className="file-upload-item">
                          <OaFormFileUpload
                            label={"ADDRESS PROOF BACK"}
                            type="AADHAR_CARD"
                            setUploadedImageName={
                              setUploadedAddressImageNameBack
                            }
                            imageReinitialize={imageReinitialize}
                          />
                          <Collapse in={isAadhaarCardImage}>
                            <div name="aadhaarCardImage" className="error">
                              Address Proof is required for KYC
                            </div>
                          </Collapse>
                        </Grid>
                        <Grid item className="file-upload-item">
                          <OaFormFileUpload
                            label={"LOGO IMAGE"}
                            type="AGENT_LOGO"
                            setUploadedImageName={setUploadedLogoImageName}
                            imageReinitialize={imageReinitialize}
                          />
                        </Grid>
                      </GridContainer>
                    </OaCard>
                    <OaCard className="inner-card">
                      <h4
                        className={"oa-form-header"}
                      >
                        Services
                      </h4>
                      <AgentServices
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </OaCard>
                    <OaCard className="inner-card">
                      <GridContainer>
                        <GridItem md={6} sm={12} xs={12} className="form-element ref-container">
                          <Autocomplete
                            name="agentRefObj"
                            options={refAgentsList || []}
                            value={values?.agentRefObj}
                            onChange={(event, selectedObject) => {
                              if (selectedObject?.agentId) {
                                setFieldValue("agentRefObj", selectedObject)
                                setFieldValue("agentReference", selectedObject?.agentId)
                              }
                            }}
                            getOptionSelected={(option, value) => option.agentId === value.agentId}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            renderOption={(option, propes) =>
                              <li selected={propes.selected} className="ac-list">
                                <span className="ac-list-options">{option.firstName} {option.lastName}</span>
                              </li>
                            }
                            loading={fetchingRefs}
                            renderInput={params =>
                              <TextField
                                {...params}
                                label="Reference (optional)"
                                fullWidth={true}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autocomplete: "new-password",
                                }}
                              />
                            }
                          />
                        </GridItem>
                        <GridItem className="submit-btn-item">
                          <OaFormSaveButton
                            isSaving={isSaving}
                            exReset={enableReinitialize}
                            label={"Register"}
                            inReset={resetForm}
                          />
                        </GridItem>
                      </GridContainer>
                    </OaCard>

                  </Form>
                )}
              </Formik>
            </OaCard>
          </Collapse>
        </OaCard>
      </GridItem>
    </>
  );
};

export default AgentRegistration;



const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },


    "& .inner-card": {
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        boxShadow: "none",
      },
    },

    "& .oa-card-body": {
      [theme.breakpoints.down("sm")]: {
        padding: "6px 6px",
        // boxShadow: "none",
      },
    },



    "& .dob-container": {
      position: 'relative',
      "& .pax-info-date-picker": {
        background: "#fff",
        border: `solid 1px ${theme.palette.secondary.grandmaGray}`,
        borderRadius: 3,
        marginTop: 10,
        "&:hover": {
          border: `solid 1px ${theme.palette.background.dark}`,
        },
      },
      "& .react-datepicker__input-container": {
        "& input": {
          width: '100%',
        }
      },
      "& label": {
        background: "#fff",
        color: "#000",
        position: 'absolute',
        // left:10,
        fontSize: 12,
        marginLeft: 6,
        zIndex: 999,
        marginTop: 3,

      },
      "& .react-datepicker-wrapper": {
        width: "100%"
      }
    },
    "& .ref-container": {
      paddingTop: 5,
      "& .MuiInputBase-root": {
        height: 40
      }
    },
    "& .submit-btn-item": {
      paddingTop: 5
    },
    "& .file-upload-item": {
      width: 320,
      "& .MuiCardContent-root": {
        width: "100%"
      },
      "& .MuiButtonBase-root": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-start",
      },
      "& label": {
        display: "block",
        width: "100%",
        maxWidth: "100%",
        marginBottom: 5
      },
      "& .preview-uploaded-image": {
        height: 130,
        width: "auto",
        maxWidth: "100%"
      }
    }
  }
}));
