import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  Button,
  Fade,
  Backdrop,
  Modal,
  withWidth,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import RoomModal from "./RoomModal";

import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";

import * as Yup from "yup";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  setHotelCountry,
  setHotelCity,
  setHotelSearch,
  setHotelBookingInitialTime,
} from "redux/action";
import _ from "lodash";
import differenceInDays from "date-fns/differenceInDays";
import dateFnsFormat from "date-fns/format";
import { AppStateContext } from "layouts/AppStateProvider";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import OaOutlinedAutoComplete from "oahoc/OaOutlinedAutoComplete";
import cx from "classnames";
import HotelLocationCityInput from "components/CommonSearchComponents/Hotel/HotelLocationCityInput";

import HotelLocationCountryInput from "components/CommonSearchComponents/Hotel/HotelLocationCountryInput";
import HotelDateInput from "components/CommonSearchComponents/Hotel/HotelDateInput";

export class RoomObj {
  constructor() {
    this.noOfAdults = 1;
    this.noOfChild = 0;
    this.childAge = [];
  }
}

function HotelSection(props) {
  const classes = useStyles();

  const today = new Date();

  const locationAutocompleteRef = useRef(null);
  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  const [arrivalDateDialogOpen, setArrivalDateDialogOpen] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [childError, setChildError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [roomModalOpen, setRoomModalOpen] = useState(false);
  const formikRef = React.useRef();
  const { setSecLogo } = useContext(AppStateContext);
  const [refreshLocation, setRefreshLocation] = useState(false);
  const [userType, setUserType] = useState(null);
  const [corporateType, setCorporateType] = useState(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [callingApi, setCallingApi] = useState(false);
  const isMounted = React.useRef(false);

  let baseUrl = "/";

  let optionsHotel = [
    {
      name: "searchHotel",
      label: <Typography className={classes.typo1}>Search Hotel</Typography>,
      url: baseUrl + "hotels",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "hotelReport",
      label: <Typography className={classes.typo1}>Hotel Report</Typography>,
      url: "/admin/booking-report/hotel",
      icon: <i className="fas fa-dollar-sign"></i>,
    },
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    isMounted.current = true;
    props.setHotelCity(popularCities);
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current) {
      localforageGetItem("user-details", function(err, details) {
        if (details) {
          setUserType(details.userType);
          setCorporateType(details.corporateType);
        }
      });
    }
  }, [isMounted.current]);

  useEffect(() => {
    if (isLoading) {
      // console.log(0);
      const length = props.hotelCountry
        ? Object.keys(props.hotelCountry).length
        : 0;

      const lengthCity = props.hotelCity
        ? Object.keys(props.hotelCity).length
        : 0;

      if (length > 0 && lengthCity > 0) {
        const countryCodeObj = {
          name: props.hotelCountry?.filter(
            (country) => country.code == props.hotelCity[0].countryCode
          )[0].name,
          code: props.hotelCity[0].countryCode,
        };

        formikRef.current.setFieldValue("countryCode", countryCodeObj.code);
        formikRef.current.setFieldValue("countryCodeObj", countryCodeObj);
        formikRef.current.setFieldValue("guestNationality", "IN");
        formikRef.current.setFieldValue("guestNationalityObj", countryCodeObj);

        setIsLoading(false);
      } else {
        // console.log(1);
        WebApi.getHotelCountry(
          {},
          (response) => {
            if (response.success === true) {
              setIsLoading(false);
              props.setHotelCountry(response.data.countryList.country);
              // setHotelCountry(response.data.countryList.country);
              // formikRef.current.setFieldValue("countryCode", {
              //   name: "India",
              //   code: "IN",
              // });
              // formikRef.current.setFieldValue("guestNationality", {
              //   name: "India",
              //   code: "IN",
              // });
              cityHotelChangeHandler(1, {
                name: "India",
                code: response.data.countryList.country, //"IN",
              });
            } else {
              setIsError(!response.success);
              setInfoText(response.message);
            }
          },
          (error) => console.log("getHotelCountry error", error)
        );
      }
    }
  }, [isLoading]);

  const cityHotelChangeHandler = (searchType, obj) => {
    let countryCode = "";
    if (obj != null) {
      countryCode = obj.code;
    } else {
      const countryCodeObj = formikRef.current.values.countryCode;
      countryCode = countryCodeObj?.code;
    }
  };

  const fetchMatchingCities = (searchType, searchParam, countryCode) => {
    console.log("hotel city search");

    if (searchParam.length < 3) {
      return;
    }

    setCallingApi(true);
    WebApi.getMatchingHotelCity(
      {
        searchType: searchType,
        countryCode: countryCode || "IN",
        hotelCity: searchParam,
      },
      (response) => {
        if (response.success === true) {
          // console.log("hotel city search", searchParam);
          // console.log("matching cities response", response);
          props.setHotelCity(response.data.destinations);
        } else {
          setIsError(!response.success);
          setInfoText(response.message);
        }
        setCallingApi(false);
      },
      (error) => {
        setCallingApi(false);
        console.log("getMatchingHotelCity error", error);
      }
    );
  };

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  };
  const validateChildAge = (rooms) => {
    return rooms.every((room) => {
      if (room.noOfChild > 0) {
        return room.childAge.every((age) => age > 0);
      } else return true;
    });
  };
  return (
    <Box
      className={classes.root}
      // style={{ overflow: roomModalOpen && "hidden" }}
    >
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          countryCodeObj: {
            name: "India",
            code: "IN",
          },
          countryCode: "IN",
          cityObj: popularCities[0],
          cityId: popularCities[0].destinationId,
          checkInDate: dateFnsFormat(new Date(), "dd/MM/yyyy"),
          checkInDateDisplay: new Date(),
          isIslandHopper: "false",
          checkOutDate: dateFnsFormat(
            new Date(new Date().setDate(new Date().getDate() + 1)),
            "dd/MM/yyyy"
          ),
          checkOutDateDisplay: new Date(
            new Date().setDate(new Date().getDate() + 1)
          ),

          preferredCurrency: "INR",
          roomGuests: [new RoomObj()],
          guestNationality: "IN",
          guestNationalityObj: {
            name: "India",
            code: "IN",
          },
        }}
        validationSchema={Yup.object().shape({
          countryCode: Yup.string().required("country Field is Required"),
          cityId: Yup.string().required("Location Field is Required"),
          checkInDate: Yup.string().required("Check In Date Field is Required"),
          checkOutDate: Yup.string().required(
            "Check Out Date Field is Required"
          ),
          // preferredCurrency: Yup.string().required(
          //   "Preferred Currency Field is Required"
          // ),
          guestNationality: Yup.string().required(
            "Guest Nationality Field is Required"
          ),
        })}
        onSubmit={(values) => {
          // props.history.push('/hotel/payment-status/146331')
          // return;
          // values.countryCode = values.countryCode.code;
          // const cityObj = values.cityId;
          // values.cityId = cityObj.destinationId;
          if (validateChildAge(values.roomGuests)) {
            setChildError(false);
            values.city = values.cityObj.cityName;
            // values.guestNationality = values.guestNationality.code;

            values.noOfNights = differenceInDays(
              values.checkOutDateDisplay,
              values.checkInDateDisplay
            );
            values.noOfNights = values.noOfNights > 0 ? values.noOfNights : 1;
            // values.checkInDate = dateFnsFormat(values.checkInDate, "dd/MM/yyyy");
            // values.checkOutDate = dateFnsFormat(
            //   values.checkOutDate,
            //   "dd/MM/yyyy"
            // );
            values.noOfRooms = values.roomGuests.length;

            //  searchParams.resultCount = 10;
            values.maxRating = 5;
            values.minRating = 0;
            values.isTBOMapped = true;
            //  searchParams.isTBOMapped = 1;
            //  searchParams.isCompactdata = 1;
            props.setHotelSearch(values);

            props.setHotelBookingInitialTime(null);
            window.sessionStorage.removeItem("service");
            // setSecLogo(true);
            props.history.push("/hotel/search");
          } else {
            setChildError(true);
          }
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form className={classes.root1}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className="hotel-section-root-box">
                <Box className={cx(classes.HotelSection_itinDet, "form-card")}>
                  <Grid container className="itin-container">
                    <Grid item sm={2} className="location-input">
                      <label>Country</label>
                      {/* <Autocomplete
                        name={`countryCodeObj`}
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name || ""}
                        value={values.countryCodeObj}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        disableClearable
                        onChange={
                          (_, newValue) => {
                            setFieldValue("countryCodeObj", newValue);
                            setFieldValue("countryCode", newValue.code);
                            cityHotelChangeHandler(1, newValue);
                            props.setHotelCity([]);
                            setFieldValue("cityId", "");
                            setFieldValue("guestNationalityObj", newValue);
                            //setFieldValue("guestNationality", newValue.code);

                            WebApi.getHotelCityListForCountry(
                              {
                                clientId: "",
                                countryCode: newValue.code,
                                endUserIp: "",
                                hotelCity: "",
                                searchType: "",
                                tokenId: "",
                              },

                              (response) => {
                                if (response.success === true) {
                                  // console.log(response.data.destinations);
                                  props.setHotelCity(
                                    response?.data?.destinations ?? []
                                  );
                                  console.log(
                                    response.data.destinations[0].destinationId
                                  );
                                  setFieldValue(
                                    "cityObj",
                                    response.data.destinations[0]
                                  );
                                  setFieldValue(
                                    "cityId",
                                    response.data.destinations[0].destinationId
                                  );
                                  setIsLoading(false);
                                } else {
                                }
                              },
                              (error) =>
                                console.log("getHotelCountry error", error)
                            );
                          }
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   country: newValue,
                          // }))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                          // label="Country"
                          />
                        )}
                        disablePortal={true}
                      /> */}
                      <HotelLocationCountryInput
                        setFieldValue={setFieldValue}
                        props={{ props, values }}
                        formikRef={formikRef}
                      />
                      <ErrorMessage
                        name={`countryCode`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    {/* <Grid item  sm={1} className="location-input">
                      <Autocomplete
                        name="guestNationality"
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name}
                        value={values.guestNationality}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={(_, newValue) => {
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   nationality: newValue,
                          // }))

                          setFieldValue("guestNationality", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Nationality"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`guestNationality`}
                        component="div"
                        className="error"
                      />
                    </Grid> */}
                    <Grid item sm={2} className="location-input">
                      {/* <label>Location</label>
                      <Autocomplete
                       className="autocomplete"
                        name={`cityId`}
                        options={props.hotelCity || []}
                        getOptionLabel={(a) =>
                          a &&
                          a.cityName

                        }
                        value={values.cityId}
                        getOptionSelected={(option, value) =>
                          option.value == value.destinationId
                        }
                        onKeyUp={event => fetchMatchingCities(1, event.target.value, values.countryCode)}
                        onChange={(_, newValue) => {
                          setFieldValue("cityId", newValue);
                          // setDepartureDateDialogOpen(true);
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   location: newValue,
                          // }))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            // label="Location"
                            inputRef={locationAutocompleteRef}
                            autocomplete="new-password"
                            placeholder="Enter City"
                          />
                        )}
                        openOnFocus
                        disablePortal={true}
                      /> */}
                      {/* <OaOutlinedAutoComplete
                        className="autocomplete"
                        labeltxt="LOCATION"
                        secondarytxt={values.cityObj?.countryName || ""}
                        name={`cityObj`}
                        options={props.hotelCity || []}
                        getOptionLabel={(a) => (a && a.cityName) || ""}
                        value={values.cityObj}
                        loading={callingApi}
                        getOptionSelected={(option, value) =>
                          option.value == value.destinationId
                        }
                        onKeyUp={(event) =>
                          fetchMatchingCities(
                            1,
                            event.target.value,
                            values.countryCode
                          )
                        }
                        onChange={(_, newValue) => {
                          setFieldValue("cityObj", newValue);
                          setFieldValue("cityId", newValue.destinationId);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            // label="Location"
                            inputRef={locationAutocompleteRef}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                            placeholder="Enter City"
                          />
                        )}
                      /> */}
                      <label>Location</label>
                      <HotelLocationCityInput
                        setFieldValue={setFieldValue}
                        props={{ props, values }}
                        locationAutocompleteRef={locationAutocompleteRef}
                      />

                      <ErrorMessage
                        name={`cityId`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item sm={2} className="date-input">
                      <label>Check-In</label>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkInDate"
                          className={classes.checkinDatePicker}
                          fullWidth
                          disablePast
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          label="Check In Date"
                          value={values.checkInDate}
                          onChange={(e, v) => {
                            setFieldValue("checkInDate", e);
                            setDepartureDateDialogOpen(false);
                            setArrivalDateDialogOpen(true);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setDepartureDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setDepartureDateDialogOpen(true);
                            },
                          }}
                          open={departureDateDialogOpen}
                          onClose={() => setDepartureDateDialogOpen(false)}
                          onOpen={() => setDepartureDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider> */}
                      {/* <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        name="checkInDateDisplay"
                        className={classes.checkinDatePicker}
                        minDate={startDate}
                        customInput={
                          <TextField id="standard-basic" variant="standard" />
                        }
                        value={values.checkInDateDisplay}
                        onChange={(e, v) => {
                          setFieldValue("checkInDateDisplay", e);
                          setFieldValue(
                            "checkInDate",
                            dateFnsFormat(e, "dd/MM/yyyy")
                          );
                          setFieldValue(
                            "checkOutDateDisplay",
                            new Date(e.getTime() + 86400000)
                          );
                          setFieldValue(
                            "checkOutDate",
                            dateFnsFormat(
                              new Date(e.getTime() + 86400000),
                              "dd/MM/yyyy"
                            )
                          );
                          setDepartureDateDialogOpen(false);
                          setArrivalDateDialogOpen(true);
                          // setEndDate(dateFnsFormat(e, "dd/MM/yyyy"));
                        }}
                        KeyboardButtonProps={{
                          onFocus: (e) => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setDepartureDateDialogOpen(false);
                          },
                        }}
                        InputProps={{
                          onFocus: () => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        monthsShown={2}
                        dateFormat="dd MMM yyyy"
                        labeltxt="CHECK-IN"
                        selected={values.checkInDateDisplay}
                        showinput={true}
                        secondarytxt={days[values.checkInDateDisplay.getDay()]}
                      /> */}
                      <HotelDateInput
                        showDay={true}
                        type="checkIn"
                        values={values}
                        setFieldValue={setFieldValue}
                        setDepartureDateDialogOpen={setDepartureDateDialogOpen}
                        setArrivalDateDialogOpen={setArrivalDateDialogOpen}
                      />

                      <ErrorMessage
                        name={`checkInDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>

                    <Grid item sm={2} className="date-input">
                      <label>Check-Out</label>
                      {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          name="checkOutDate"
                          className={classes.checkoutDatePicker}
                          fullWidth
                          minDate={
                            new Date(
                              new Date(values.checkInDate).getTime() + 86400000
                            )
                          }
                          color="secondary"
                          disableToolbar
                          variant="inline"
                          format="dd-MM-yyyy"
                          margin="normal"
                          // label="Check Out Date"
                          value={values.checkOutDate}
                          onChange={(e, v) => {
                            setFieldValue("checkOutDate", e);
                            setArrivalDateDialogOpen(false);
                          }}
                          KeyboardButtonProps={{
                            onFocus: (e) => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          PopoverProps={{
                            disableRestoreFocus: true,
                            onClose: () => {
                              setArrivalDateDialogOpen(false);
                            },
                          }}
                          InputProps={{
                            onFocus: () => {
                              setArrivalDateDialogOpen(true);
                            },
                          }}
                          open={arrivalDateDialogOpen}
                          onClose={() => setArrivalDateDialogOpen(false)}
                          onOpen={() => setArrivalDateDialogOpen(true)}
                        />
                      </MuiPickersUtilsProvider> */}
                      {/* <OaOutlinedDatePicker
                        showDisabledMonthNavigation
                        name="checkOutDate"
                        className={classes.checkinDatePicker}
                        minDate={values.checkInDateDisplay}
                        customInput={
                          <TextField id="standard-basic" variant="standard" />
                        }
                        value={values.checkOutDateDisplay}
                        onChange={(e, v) => {
                          setFieldValue("checkOutDateDisplay", e);
                          setFieldValue(
                            "checkOutDate",
                            dateFnsFormat(e, "dd/MM/yyyy")
                          );
                          setArrivalDateDialogOpen(false);
                          setEndDate(e);
                        }}
                        KeyboardButtonProps={{
                          onFocus: (e) => {
                            setArrivalDateDialogOpen(true);
                          },
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setArrivalDateDialogOpen(false);
                          },
                        }}
                        InputProps={{
                          onFocus: () => {
                            setArrivalDateDialogOpen(true);
                          },
                        }}
                        monthsShown={2}
                        dateFormat="dd MMM yyyy"
                        labeltxt="CHECK-OUT"
                        selected={values.checkOutDateDisplay}
                        showinput={true}
                        secondarytxt={days[values.checkOutDateDisplay.getDay()]}
                      /> */}
                      <HotelDateInput
                        showDay={true}
                        type="checkOut"
                        values={values}
                        setFieldValue={setFieldValue}
                        setDepartureDateDialogOpen={setDepartureDateDialogOpen}
                        setArrivalDateDialogOpen={setArrivalDateDialogOpen}
                      />

                      <ErrorMessage
                        name={`checkOutDate`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid
                      item
                      sm={2}
                      className="location-input"
                      style={{ border: "none" }}
                    >
                      <label>Rooms & Guests</label>
                      <TextField
                        // label="Rooms"
                        color="secondary"
                        value={`${values.roomGuests.length} ${
                          values.roomGuests.length == 1 ? "Room" : "Rooms"
                        },  ${values.roomGuests.reduce(function(a, b) {
                          return (
                            Number(a) +
                            Number(b.noOfAdults) +
                            Number(b.noOfChild)
                          );
                        }, 0)} Guests`}
                        fullWidth
                        onClick={() => setRoomModalOpen(true)}
                      />

                      {roomModalOpen && (
                        <div className={classes.RoomModalOpenDev}>
                          <div className={classes.paper}>
                            <RoomModal
                              values={values}
                              setFieldValue={setFieldValue}
                              rooms={values.roomGuests}
                              childError={childError}
                              setChildError={setChildError}
                              closeModal={() => {
                                if (validateChildAge(values.roomGuests)) {
                                  setRoomModalOpen(false);
                                  setChildError(false);
                                } else {
                                  setChildError(true);
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </Grid>

                    <Grid item sm={2}>
                      <Box className={classes.HotelSection_actions}>
                        {/* <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          justifyContent={props.width === "xs" ? "center" : "flex-end"}
                          alignItems="center"
                        >
                        </Grid> */}
                        <Box className={classes.searchButtonBox}>
                          <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            <i className="fas fa-search"></i>
                            Search
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                {/* <Box className={classes.HotelSection_idenDet}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <Autocomplete
                        name="guestNationality"
                        options={props.hotelCountry || []}
                        getOptionLabel={(a) => a.name}
                        value={values.guestNationality}
                        getOptionSelected={(option, value) =>
                          option.value == value.code
                        }
                        onChange={(_, newValue) => {
                          // setData((prevState) => ({
                          //   ...prevState,
                          //   nationality: newValue,
                          // }))

                          setFieldValue("guestNationality", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            color="secondary"
                            label="Nationality"
                          />
                        )}
                        disablePortal={true}
                      />

                      <ErrorMessage
                        name={`guestNationality`}
                        component="div"
                        className="error"
                      />
                    </Grid>
                    <Grid item xs={10} sm={5} md={3} className="location-input">
                      <TextField
                        label="Rooms"
                        color="secondary"
                        value={`${values.roomGuests.length} ${values.roomGuests.length == 1 ? "Room" : "Rooms"
                          },  ${values.roomGuests.reduce(function (a, b) {
                            return (
                              Number(a) +
                              Number(b.noOfAdults) +
                              Number(b.noOfChild)
                            );
                          }, 0)} Guests`}
                        fullWidth
                        onClick={() => setRoomModalOpen(true)}
                      />
                    </Grid>

                    <Grid item xs={10} sm={5} md={3}></Grid>

                    <Grid item xs={10} sm={5} md={3}>
                      <Box className={classes.HotelSection_actions} my={2} mx={4}>
                        <Grid
                          container
                          direction="row"
                          // justifyContent="center"
                          justifyContent={props.width === "xs" ? "center" : "flex-end"}
                          alignItems="center"
                        >
                          <Box className={classes.searchButtonBox}>
                            <Button
                              size="large"
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Search
                            </Button>
                          </Box>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box> */}
              </Box>

              {userType &&
                userType == "agent" &&
                corporateType &&
                corporateType != "corporate" && (
                  <Box className={classes.HotelSection_quicklinks}>
                    {optionsHotel &&
                      optionsHotel.map((val, ind) => (
                        <div
                          item
                          className="quick-link-item"
                          key={ind}
                          onClick={() => openQuickLink(val.url)}
                        >
                          <div className="qlink-icon-wrapper">{val.icon}</div>
                          <div className="qlink-label-wrapper">{val.label}</div>
                        </div>
                      ))}
                  </Box>
                )}
            </Grid>

            {/* <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.roomModal}
              open={roomModalOpen}
              onClose={() => setRoomModalOpen(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={roomModalOpen}>
                <div className={classes.paper}>
                  <RoomModal
                    values={values}
                    setFieldValue={setFieldValue}
                    rooms={values.roomGuests}
                    closeModal={() => setRoomModalOpen(false)}
                  />
                </div>
              </Fade>
            </Modal> */}

            {/* {console.log("values: ", values)}
            {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

function mapStateToProps(state, props) {
  return {
    hotelCountry: state.hotel.hotelCountry,
    hotelCity: state.hotel.hotelCity,
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelCountry: (hotelCountry) => dispatch(setHotelCountry(hotelCountry)),
    setHotelCity: (hotelCity) => dispatch(setHotelCity(hotelCity)),
    setHotelSearch: (hotelSearch) => dispatch(setHotelSearch(hotelSearch)),

    setHotelBookingInitialTime: (hotelBookingInitialTime) =>
      dispatch(setHotelBookingInitialTime(hotelBookingInitialTime)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withWidth()(HotelSection))
);

const countryList = [
  { title: "India", value: "india" },
  { title: "Sweden", value: "sweden" },
  { title: "Japan", value: "japan" },
  { title: "Indonesia", value: "indonesia" },
];
const locationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Chennai", value: "chennai" },
  { title: "Kolkata", value: "kolkata" },
  { title: "Delhi", value: "delhi" },
];
const nationalityList = [
  { title: "Indian", value: "indian" },
  { title: "American", value: "american" },
  { title: "European", value: "european" },
  { title: "African", value: "african" },
];
const DefChildCount = 6;

const popularCities = [
  {
    cityName: "Delhi",
    countryCode: "IN",
    countryName: "India",
    destinationId: "130443",
    hotelName: null,
    stateProvince: "DL",
    type: null,
  },
  {
    cityName: "Navi Mumbai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "128734",
    hotelName: null,
    stateProvince: "MH",
    type: null,
  },
  {
    cityName: "Bengaluru",
    countryCode: "IN",
    countryName: "India",
    destinationId: "111124",
    hotelName: null,
    stateProvince: "KA",
    type: null,
  },
  {
    cityName: "Goa",
    countryCode: "IN",
    countryName: "India",
    destinationId: "119805",
    hotelName: null,
    stateProvince: "GA",
    type: null,
  },
  {
    cityName: "Chennai",
    countryCode: "IN",
    countryName: "India",
    destinationId: "127343",
    hotelName: null,
    stateProvince: "TN",
    type: null,
  },
  {
    cityName: "Jaipur",
    countryCode: "IN",
    countryName: "India",
    destinationId: "122175",
    hotelName: null,
    stateProvince: "RJ",
    type: null,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    padding: "35px 15px",
    "& .hotel-citylocation-input": {
      paddingLeft: 0,
      fontFamily: theme.palette.font.secondary,
      fontSize: 19,
      color: "#000",
      fontWeight: 700,
      paddingTop: 3,
    },
    "& .adornment-icon": {
      width: 0,
    },
    "& .date-input-container": {
      paddingLeft: 0,
      paddingTop: 0,
      height: 30,
      "& .MuiInputBase-input": {
        paddingBottom: 0,
      },
    },
    "& .day-label": {
      fontSize: 12,
    },
    "& .date-input": {
      paddingTop: 0,
    },
    "& .form-card": {
      boxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      MozBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
      WebkitBoxShadow: "2px 2px 10px 0px rgba(133,131,133,1)",
    },

    "& .MuiFormLabel-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.darkText,
    },
    // "& .MuiSvgIcon-root": {
    //   color: theme.palette.primary.defaultText,
    // },

    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      margin: "auto",
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      [theme.breakpoints.down(600)]: {
        borderBottom: `solid 1px ${theme.palette.primary.darkText} !important`,
      },
    },
    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .autocomplete": {
      // width:'200px',
      padding: 0,
      "& .MuiInputBase-input": {
        fontWeight: 700,
        fontSize: "19px !important",
        color: theme.palette.primary.darkText,
      },
    },
    "& .hotel-section-root-box": {
      "& label": {
        fontSize: 12,
        //fontFamily: theme.palette.font.primary,
        color: theme.palette.primary.lightGray,
        textTransform: "uppercase",
        fontWeight: 500,
      },
      minWidth: 860,
      // padding:'10px 0',
      background: "#fff",
      borderRadius: "4px",
      // boxShadow: "4px 4px 6px #c2d87e",
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
      },
      "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
        borderBottom: "none",
      },
    },
    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      [theme.breakpoints.down(440)]: {
        margin: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },

    "& .oa-form-element-card": {
      border: "none",
      padding: 0,
    },
    "& .oa-form-element-secondary": {
      fontSize: 12,
      fontWeight: "bold",
    },
  },

  roomModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.light,
    borderRadius: "8px",
  },

  HotelSection_actions: {},
  searchButtonBox: {
    width: "190px",
    height: "100px",
    "& .MuiButton-root": {
      width: "100%",
      marginLeft: "5px",
      height: "100%",
      borderTopLeftRadius: "20px 50px",
      borderBottomLeftRadius: "20px 50px",
      backgroundColor: theme.palette.secondary.darkOrange,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
      },
    },
    "& i": {
      marginRight: "10px",
      fontSize: 24,
    },
  },
  HotelSection_itinDet: {
    "& .itin-container": {
      justifyContent: "center",
      alignItems: "flex-start",
    },
    "& .MuiInputBase-input": {
      fontWeight: 700,
      fontSize: "18px !important",
    },
    "& .oa-form-element-secondary": {
      fontSize: 12,
      fontWeight: "bold",
    },
    "& .oa-form-element-label": {
      fontSize: 12,
      color: theme.palette.primary.lightGray,
      fontWeight: 500,
    },
    "& .location-input": {
      "& .oa-form-element-secondary": {
        fontSize: 12,
        fontWeight: "bold",
      },
      "& .oa-form-element-card": {
        border: "none",
        padding: 0,
      },
      paddingRight: 15,
      paddingLeft: 20,
      paddingTop: 15,
      borderRight: "2px solid #eee",
      position: "relative",
    },
    "& .date-input": {
      "& .MuiInputBase-input": {
        fontWeight: 700,
        fontSize: "19px !important",
      },
      paddingTop: 15,
      paddingRight: 15,
      paddingLeft: 15,
      borderRight: "2px solid #eee",
      "& .MuiSvgIcon-root": {
        // color:theme.palette.secondary.darkOrange,
        color: theme.palette.secondary.darkText,
      },
    },
  },
  HotelSection_idenDet: {
    borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
    marginTop: 15,
    "& .location-input": {
      paddingRight: 15,
      paddingLeft: 15,
    },
  },
  HotelSection_quicklinks: {
    padding: "10px 0",
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
      // boxShadow: "4px 4px 4px rgba(133,131,133,1)",
      background: theme.palette.primary.main,
      color: theme.palette.primary.lightText,

      padding: "3px 10px",
      borderRadius: 4,
      width: 90,
      cursor: "pointer",
      border: `solid 1px ${theme.palette.secondary.lightText}`,
      "&:hover": {
        backgroundColor: theme.palette.secondary.darkOrange,
        color: theme.palette.primary.lightText,
        border: `solid 1px ${theme.palette.secondary.darkOrange}`,
      },
      "& .qlink-icon-wrapper": {
        textAlign: "center",
        "& i": {
          fontSize: 15,
        },
      },
      "& .qlink-label-wrapper p": {
        fontSize: 10,
      },
    },
  },

  RoomModalOpenDev: {
    position: "absolute",
    top: "62px",
    width: "303px",
    height: "100px",
    zIndex: 999,
  },
}));
