import { Checkbox, useTheme } from "@material-ui/core";
import WebApi from "api/ApiConstants";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const AgentServices = (props) => {
    const theme = useTheme();
    const [companyServices, setCompanyServices] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleChangeValue = (e, field) => {
        props.setFieldValue(field, e.target.checked);
    };

    const getCompanyServiceList = () => {
        WebApi.getCompanyServiceList({}, (response) => {
            if(response.success) {
                setCompanyServices(response.data);
            }
            setIsLoading(false);
        }, (error) => {
            console.error("Error", error);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getCompanyServiceList();
    }, []);

    return(!isLoading && companyServices && companyServices.length > 0 &&
        <RootContainer theme={theme}>
            <div className="checkbox-group">
                {(companyServices.includes(1))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.flightService}
                                checked={props.values.agentServices?.flightService}
                                onChange={(e) => handleChangeValue(e, "agentServices.flightService")}
                            />
                            <label>Flight</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(2))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.hotelService}
                                checked={props.values.agentServices?.hotelService}
                                onChange={(e) => handleChangeValue(e, "agentServices.hotelService")}
                            />
                            <label>Hotel</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(4))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.busService}
                                checked={props.values.agentServices?.busService}
                                onChange={(e) => handleChangeValue(e, "agentServices.busService")}
                            />
                            <label>Bus</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(6))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.insuranceService}
                                checked={props.values.agentServices?.insuranceService}
                                onChange={(e) => handleChangeValue(e, "agentServices.insuranceService")}
                            />
                            <label>Insurance</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(17))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.rechargeService}
                                checked={props.values.agentServices?.rechargeService}
                                onChange={(e) => handleChangeValue(e, "agentServices.rechargeService")}
                            />
                            <label>Recharge</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(18))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.dmtService}
                                checked={props.values.agentServices?.dmtService}
                                onChange={(e) => handleChangeValue(e, "agentServices.dmtService")}
                            />
                            <label>Money Transfer</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(19))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.itctcService}
                                checked={props.values.agentServices?.itctcService}
                                onChange={(e) => handleChangeValue(e, "agentServices.irctcService")}
                            />
                            <label>IRCTC PG</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(25))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.aepsService}
                                checked={props.values.agentServices?.aepsService}
                                onChange={(e) => handleChangeValue(e, "agentServices.aepsService")}
                            />
                            <label>AEPS</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(21))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.aadhaarpayService}
                                checked={props.values.agentServices?.aadhaarpayService}
                                onChange={(e) => handleChangeValue(e, "agentServices.aadhaarpayService")}
                            />
                            <label>Aadhaarpay</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(23))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.islandHopperService}
                                checked={props.values.agentServices?.islandHopperService}
                                onChange={(e) => handleChangeValue(e, "agentServices.islandHopperService")}
                            />
                            <label>Island Hopper</label>
                        </div>
                    </div>
                }
                {(companyServices.includes(24))  &&
                    <div className="checkbox-item-container">
                        <div className="checkbox-item">
                            <Checkbox
                                className="checkbox-input-item"
                                value={props.values.agentServices?.panService}
                                checked={props.values.agentServices?.panService}
                                onChange={(e) => handleChangeValue(e, "agentServices.panService")}
                            />
                            <label>PAN</label>
                        </div>
                    </div>
                }
            </div>
        </RootContainer>
    );
};

export default AgentServices;

const RootContainer = styled.div`
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-item-container {
    width: 170px;
}

.checkbox-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    label {
        display: block;
        color: ${prope => prope.theme.palette.text.dark};
        font-weight: 500;
    }
}
`;
