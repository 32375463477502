import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import "assets/css/BusTicket.css";
import { Typography, Divider, useTheme } from "@material-ui/core";
import {
  CircularProgress,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button";
// import Card from "components/vendor/ext/Card/Card.js";

import OaCard from "oahoc/OaCard";
import OaTabBar from "oahoc/OaTabBar";
import { apiCall } from "oautils/oaDataUtils";

import OaFormAlerts from "pages/components/OaFormAlerts";
import Send from "@material-ui/icons/Send";
import Save from "@material-ui/icons/Save";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { getQueryVar } from "oautils/oaCommonUtils";
// import * as jsPDF from "jspdf";
// import * as html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";

import "assets/css/print-portrait.css";
//import 'assets/css/FlightTicket.css';

import { useHistory } from "react-router-dom";
import InsuranceTicketResult from "./InsuranceTicketResult";
import WebApi from "api/ApiConstants";
import { scrollToTop } from "oautils/oaCommonUtils";
import { useSelector, useDispatch } from "react-redux";
import { localforageGetItem } from "pages/components/oautils/oaForageUtils";

import { AppStateContext } from "layouts/AppStateProvider";
import { refreshUserSession } from "oautils/oaAuthUtils";
import OaButtonDownloadPDF from "pages/components/OaButtonDownloadPDF";
import { DOWNLOAD_INSURANCE_VOUCHER_PDF } from "api/ApiConstants";

const useStyles = makeStyles(styles);

const InsuranceTicket = (props) => {
  const history = useHistory();
  const pxToMm = (px) => {
    return Math.floor(px / document.getElementById("myMm").offsetHeight);
  };

  const mmToPx = (mm) => {
    return document.getElementById("myMm").offsetHeight * mm;
  };

  const range = (start, end) => {
    return Array(end - start)
      .join(0)
      .split(0)
      .map(function(val, id) {
        return id + start;
      });
  };

  let tabs = [
    {
      name: "Insurance",
      label: "Insurance",
      url: "/admin/booking-report/flight",
    },
  ];

  let currentTab = "Insurance";
  const theme = useTheme();
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateMarkup, setIsUpdateMarkup] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [markupTemp, setMarkupTemp] = useState(0);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState("");
  const [state, setState] = useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const ticketId = getQueryVar({ ...props, q: "ticketId" });
  if (history?.location?.state?.tid) ticketId = history?.location?.state?.tid;

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [serviceOptions, setServiceOptions] = React.useState([]);
  const { setUserTypeb2c } = React.useContext(AppStateContext);
  const [isUser, isSetUser] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState(0);

  //   const getTicketData = (transactionId) => {
  //       return new Promise((resolve, reject) => {
  //           WebApi.getTicketData({"transactionId": transactionId} , (response) => {
  //               if(response !== null && response.success){
  //                   resolve(response.data);
  //               }
  //           },(error)=>{
  //               reject([]);
  //               console.log(error);
  //           })
  //       })
  //   }

  function getIcon() {
    return !isSaving ? <Send /> : <CircularProgress size="24px" />;
  }

  function getIcon1() {
    return !isSaving ? <Save /> : <CircularProgress size="24px" />;
  }

  useEffect(() => {
    if (isLoading) {
      apiCall(
        WebApi.getInsuranceBookingDetails,
        { tid: ticketId },
        (response) => {
          setInfoText(response.message);
          if (response.success) {
            // console.log(response.data.response.itinerary);

            setIsSuccess(true);
            setData(response.data.response.itinerary);
            setMarkup(response.data.response.itinerary.paxInfo[0].price);
            setMarkupTemp(response.data.response.itinerary.paxInfo[0].price);
            setIsLoading(false);
          } else {
            setIsError(true);
            setIsLoading(false);
            // props.history.push("/b2c/printeticket/");
          }
        }
      );
      /* if (!props.location.state) {
        apiCall(
          WebApi.getInsuranceBookingDetails,
          { tid: ticketId },
          (response) => {
            console.log("insurance resp", response);
            setInfoText(response.message);
            if (response.success) {
              // console.log(response.data.response.itinerary);

              setIsSuccess(true);
              setData(response.data.response.itinerary);
              setMarkup(response.data.response.itinerary.paxInfo[0].price);
              setMarkupTemp(response.data.response.itinerary.paxInfo[0].price);
              setIsLoading(false);
            } else {
              setIsError(true);
              setIsLoading(false);
            }
          }
        );
      } else {
        setData(props.location.state);
        setMarkup(props.location.state.GetBookingDetailResult?.agentMarkup);
        setMarkupTemp(props.location.state.GetBookingDetailResult?.agentMarkup);
        setIsLoading(false);
      } */
    }
  }, [isLoading]);

  const invoiceClick = () => {
    props.history.push("/admin/voucher/insurance-user/" + ticketId);
  };

  const printClick = () => {
    window.print();
  };

  const classes = useStyles();

  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = state;

  const handleMarkupSave = () => {
    setIsSaving(true);
    const markup1 = markup == "" ? 0 : markup;
    setMarkupTemp(markup1);
    apiCall(
      WebApi.doBusMarkupUpdate,
      { markup: markup1, tid: ticketId },
      function(response) {
        if (response.success) {
          setIsError(false);
          setIsSuccess(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        } else {
          setIsError(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        }
      }
    );
  };

  function downloadETicket(e) {
    e.target.setAttribute("disabled", "disabled");
    pdfDownload("section-to-print", "p", "eticket-" + ticketId);
    e.target.removeAttribute("disabled");
  }

  function sendEmail() {
    setIsSaving(true);
    getImageData("section-to-print", "p", function(imageData) {
      apiCall(
        WebApi.emailETicket,
        {
          email: receiverEmail,
          body:
            "<!DOCTYPE html><html><body><img src='" +
            imageData +
            "'/></body></html> ",
        },
        function(response) {
          if (response.success) {
            setIsError(false);
            setIsSuccess(true);
            setIsSaving(false);
            // setInfoText("Send email request registered");
            setInfoText(`Email Sent to ${receiverEmail}`);
          } else {
            setIsError(true);
            setIsSaving(false);
            setInfoText("Email server busy try after sometime");
          }
        }
      );
    });
  }

  // const callbackRefreshToken = (flag) => {
  //   setIsLoading(flag);
  // };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        setLoggedInUserId(value.userId);
        // if (value.userTypeAbv == "S") {
        //   setIsLoggedIn(true);
        // }
        if (
          value.userType === "agent" ||
          value.userType === "direct_agent" ||
          value.corporateType == "corporate"
        ) {
          isSetUser(true);
        }
        // else{
        //   setIsLoading(false);
        // }
        // setServiceOptions(value.serviceOption);
        // setUserTypeb2c(value.userType);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  const insuranceVoucherDownloadRequest = {
    loggedInUserId: loggedInUserId,
    tid: ticketId,
  };

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
  }, [isMounted.current]);

  React.useEffect(() => {
    console.log("data", data);
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "-webkit-fill-available",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      ) : data ? (
        <OaCard className={"oa-card-primary"}>
          <OaTabBar tabs={tabs} currentTab={currentTab} type="primary" />
          {/* <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          /> */}
          <OaCard>
            <div>
              <OaCard className="section-not-to-print">
                <GridContainer>
                  <GridItem md={12}>
                    {isUser && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isAddressHide}
                              onChange={handleChange("isAddressHide")}
                              value="isAddressHide"
                            />
                          }
                          label="Hide Address"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isFareHide}
                              onChange={handleChange("isFareHide")}
                              value="isFareHide"
                            />
                          }
                          label="Hide Fare"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isGSTHide}
                              onChange={handleChange("isGSTHide")}
                              value="isGSTHide"
                            />
                          }
                          label="Hide GST"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isPerPassenger}
                              onChange={handleChange("isPerPassenger")}
                              value="isPerPassenger"
                            />
                          }
                          label="Passenger Wise"
                        />
                      </>
                    )}
                  </GridItem>
                  <GridItem md={12}>
                    <Button
                      color="primary"
                      onClick={printClick}
                      className={classes.registerButton}
                    >
                      Print ETicket
                    </Button>
                    {/* <Button
                      color="primary"
                      onClick={downloadETicket}
                      className={classes.registerButton}
                    >
                      Download ETicket
                    </Button> */}
                    <OaButtonDownloadPDF
                      btnLabel="Download EVoucher"
                      api={DOWNLOAD_INSURANCE_VOUCHER_PDF}
                      searchParams={insuranceVoucherDownloadRequest}
                      defaultFileName="insurance-evoucher"
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        setIsSendEmail(!isSendEmail);
                        setIsUpdateMarkup(false);
                      }}
                      className={classes.registerButton}
                    >
                      Email
                    </Button>
                    <Button
                      color="primary"
                      onClick={invoiceClick}
                      className={classes.registerButton}
                    >
                      Invoice
                    </Button>
                    {isUser && (
                      <>
                        <Button
                          color="primary"
                          onClick={() => {
                            setIsUpdateMarkup(!isUpdateMarkup);
                            setIsSendEmail(false);
                          }}
                          className={classes.registerButton}
                        >
                          Update Markup
                        </Button>
                      </>
                    )}
                  </GridItem>
                </GridContainer>

                {isUpdateMarkup && (
                  <GridContainer>
                    <GridContainer justify="center">
                      <GridItem>
                        <h4
                          style={{ color: theme.palette.primary.lightIndigo }}
                        >
                          UPDATE MARKUP
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem>
                        <FormControl fullWidth={true}>
                          <TextField
                            variant="outlined"
                            name="markup"
                            label="Markup Value"
                            md={4}
                            value={markup}
                            onChange={(event) => {
                              const { value } = event.target;
                              setMarkup(value);
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="primary"
                          className={classes.registerButton}
                          endIcon={getIcon1()}
                          disabled={isSaving}
                          onClick={handleMarkupSave}
                        >
                          UPDATE
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                )}

                {isSendEmail && (
                  <GridContainer>
                    <GridContainer justify="center">
                      <GridItem>
                        <h4
                          style={{ color: theme.palette.primary.lightIndigo }}
                        >
                          SEND EMAIL
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justify="center">
                      <GridItem>
                        <FormControl fullWidth={true}>
                          <TextField
                            variant="outlined"
                            name="receiverEmail"
                            label="Receiver Email"
                            md={4}
                            value={receiverEmail}
                            onChange={(event) => {
                              const { value } = event.target;
                              setReceiverEmail(value);
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="primary"
                          onClick={sendEmail}
                          className={classes.registerButton}
                          endIcon={getIcon()}
                          disabled={isSaving}
                        >
                          SEND
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                )}
              </OaCard>
              <GridContainer
                justify="center"
                id="section-to-print"
                className="section-to-print"
                style={{ width: "100%" }}
              >
                <GridItem col={6} md={12} xs={12}>
                  {isPerPassenger ? (
                    <>
                      {" "}
                      {data.GetBookingDetailResult.Itinerary.Passenger &&
                        data.GetBookingDetailResult.Itinerary.Passenger.map(
                          (passenger, passengerindex) => (
                            <InsuranceTicketResult
                              data={data}
                              state={state}
                              markup={markupTemp}
                              passenger={passenger}
                              key={passengerindex}
                            ></InsuranceTicketResult>
                          )
                        )}
                    </>
                  ) : (
                    <InsuranceTicketResult
                      data={data}
                      state={state}
                      markup={markupTemp}
                    ></InsuranceTicketResult>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          </OaCard>
        </OaCard>
      ) : (
        <OaCard>
          <OaFormAlerts
            isSaving={isSaving}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
          />
          <Button onClick={() => window.history.back()}>
            GO to previous page
          </Button>
        </OaCard>
      )}
    </>
  );
};

export default InsuranceTicket;

// const useStyles2 = makeStyles((theme) =>({
//   root:{
//     "oa-card-body":{
//         [theme.breakpoints.down("md")]: {
//           padding:0,
//         },
//     }
//   }
// }))
