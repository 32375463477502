import React, { useEffect, useState } from "react";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import {
  CheckCircle,
  CloudDownloadOutlined,
  EmailOutlined,
  ListAltOutlined,
  Print,
  PrintOutlined,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";
import { apiCall } from "oautils/oaDataUtils";
import InsuranceItinary from "./InsuranceItinary";

const PaymentSuccess = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [refId, setRefId] = React.useState(history.location.state.Tid);
  const [insuranceData, setInsuranceData] = React.useState(null);
  const [state, setState] = React.useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const [isLoading, setIsLoading] = useState(true);

  const { tid } = props.location.state;
  const { response } = props.location.state.data;

  //console.log(tid, response);

  useEffect(() => {
    if (response) {
      setInsuranceData(response.itinerary);
      setRefId(response.itinerary.bookingId);

      setIsLoading(false);
    }
  }, []);

  const printTicket = () => {
    history.push({
      pathname:
        "/flightticket/" +
        history.location.state.Tid +
        "/" +
        state?.isAddressHide +
        "/" +
        state?.isFareHide +
        "/" +
        state?.isPerPassenger +
        "/" +
        state?.isGSTHide,
    });
  };

  const downloadEticket = (event) => {
    event.target.setAttribute("disabled", "disabled");
    pdfDownload(
      "section-to-print",
      "p",
      "eticket-" + history.location.state.Tid
    );
    event.target.removeAttribute("disabled");
  };

  const emailETicket = () => {
    insuranceData &&
      getImageData("section-to-print", "p", function(imageData) {
        apiCall(
          WebApi.emailETicket,
          {
            email: insuranceData.emailId,
            body:
              "<!DOCTYPE html><html><body><img src='" +
              imageData +
              "'/></body></html> ",
          },
          function(response) {
            if (response.success === true) {
              setIsError(false);
              setIsSuccess(true);
              setIsSaving(false);
              // setInfoText("Send email request registered");
              setInfoText(`Email Sent to ${insuranceData.emailId}`);
            } else {
              setIsError(true);
              setIsSaving(false);
              setInfoText("Email server busy try after sometime");
            }
          }
        );
      });
  };

  // React.useEffect(() => {
  //   setIsLoading(true);

  // }, []);

  return (
    <div className={classes.root}>
      {isLoading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          Getting Booked ...
        </Box>
      )}
      {!isLoading && (
        <div className="section-container">
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id: <b>{tid}</b>{" "}
            </Typography>
            <Typography>
              Booking Successful.{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/print/insuranceticket/" + tid)}
              >
                Go to policy details {">"}
              </a>
            </Typography>
          </div>
          <InsuranceItinary insuranceData={insuranceData} />
        </div>
      )}

      <div className="section-container flex-start">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
        <div className="itin-section">
          <div className="action-group">
            {/* {insuranceData?.bookingStatus != "SUCCESS" &&
              insuranceData?.bookingStatus != "SUCCESSCan" && (
                <Button onClick={printTicket}>
                  <PrintOutlined /> Print
                </Button>
              )} */}

            {/* <Button
                            onClick={emailETicket}
                        >
                            <EmailOutlined/> Email
                        </Button>

                        <Button
                            onClick={downloadEticket}
                        >
                            <CloudDownloadOutlined/> Download
                        </Button> */}
          </div>

          <div className="itin-contents">
            {/* <FlightItinerary insuranceData={insuranceData} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    // },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "&.flex-start": {
        alignItems: "flex-start",
      },
    },
    "& .itin-section": {
      padding: 0,
      width: "100%",
      "& .itin-contents": {
        padding: "10px 15px",
      },
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "25px 0",

      "& .MuiSvgIcon-root": {
        fontSize: "5rem",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
        [theme.breakpoints.down(360)]: {
          fontSize: 13,
        },
      },
    },
    "& .action-group": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: 10,
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        margin: "2px 6px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: 5,
      },
    },
  },
}));
