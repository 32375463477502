import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Rowing } from "@material-ui/icons";
import { useTheme } from "@material-ui/core";

import "assets/css/FlightTicket.css";

import Button from "components/CustomButtons/Button.js";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import Send from "@material-ui/icons/Send";
import { CircularProgress } from "@material-ui/core";
import OaFormAlerts from "pages/components/OaFormAlerts";

const styles = (theme) => ({
  main: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  rowhead: {
    fontWeight: "bold",
  },
  fragment: {
    marginRight: "50px",
    display: "inline-block",
  },
  label: {
    fontWeight: "bold",
  },

  borderNone: {
    border: "none",
  },
  agency: {
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  invoice: {
    fontSize: "1.4em",
    fontWeight: "bold",
    backgroundColor: theme.palette.background.lightGray,
    padding: "10px 0",
    textAlign: "center",
  },
});
const Invoice = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  function getIcon() {
    return !isSaving ? <Send /> : <CircularProgress size="24px" />;
  }

  function sendEmail() {
    setIsSaving(true);
    var head = document.getElementsByTagName("HEAD");
    var doc = document.getElementById("section-to-email");
    var html = `<!doctype html>
        <html 4email>
        <head>
            <meta charset="utf-8">
            style={styles.borderNone}
        </head>
        <body>${doc.innerHTML}</body>
        <script type="application/ld+json">
        {
            "@context": "http://schema.org",
            "@type": "FlightReservation",
            "reservationNumber": "RXJ34P",
            "reservationStatus": "http://schema.org/Confirmed",
            "underName": {
                "@type": "Person",
                "name": "Eva Green"
            }
        }
        </script>
        </html>`;

    html.replace(/\r?\n|\r/g, " ");

    apiCall(WebApi.emailETicket, { email: "dev@oari.co", body: html }, function(
      response
    ) {
      if (response.success === true) {
        setIsError(false);
        setIsSuccess(true);
        setIsSaving(false);
        // setInfoText('Send email request registered');
        setInfoText(`Email Sent to dev@oari.co`);
      } else {
        setIsError(true);
        setIsSaving(false);
        setInfoText("Email server busy try after sometime");
      }
    });
  }

  return (
    <>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Button
        color="primary"
        endIcon={getIcon()}
        disabled={isSaving}
        onClick={sendEmail}
      >
        PRINT
      </Button>
      <TableContainer
        component={Paper}
        style={{ marginTop: "10px" } + styles.main}
        id="section-to-email"
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={styles.borderNone} colSpan={10}>
                <Typography variant="h2" style={styles.agency}>
                  Anupam Travels Agency
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={styles.borderNone} colSpan={10}>
                <div style={{ textAlign: "center" }}>
                  Singpur road, Indira chowk,Shahdol, Jabalpur - 484001
                  <br />
                  TEL:9425809994 Email:deepak2009.sdl@gmail.com
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="center" style={styles.borderNone} colSpan={10}>
                <Typography variant="h2" style={styles.invoice}>
                  INVOICE
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" style={{ border: "none" }} colSpan={10}>
                To
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" style={{ border: "none" }} colSpan={10}>
                <span style={styles.fragment}>
                  <span style={styles.label}>Ref:</span> FMNJA3BAY65KD
                </span>
                <span style={styles.fragment}>
                  <span style={styles.label}>Invoice:</span>{" "}
                  ............................
                </span>
                <span style={styles.fragment}>
                  <span style={styles.label}>Date:</span> 06/03/20
                </span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="left" width="120px" style={styles.rowhead}>
                Ticket No.
              </TableCell>
              <TableCell align="left" width="200px" colSpan="2">
                Passenger Name
              </TableCell>
              <TableCell align="left" width="100px">
                Sectors
              </TableCell>
              <TableCell align="left" width="100px" colSpan="2">
                Flight Details
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">MGJQ8H</TableCell>
              <TableCell align="left" colSpan="2">
                1.Mr. RAKESH CHHATTANI
              </TableCell>
              <TableCell align="left">BOM/JLR</TableCell>
              <TableCell align="left" colSpan="2'">
                SG-2548 02:10 PM, Sat 07 Mar
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan="10">
                <br />
              </TableCell>
            </TableRow>
            <TableRow style={{ paddingTop: "10px" }}>
              <TableCell align="left" width="100px">
                Basic
              </TableCell>
              <TableCell align="left" width="80px">
                Taxes
              </TableCell>
              <TableCell align="left" width="80px">
                Supplier GST
              </TableCell>
              <TableCell align="left" width="80px">
                VIA GST
              </TableCell>
              <TableCell align="left" width="120px">
                Transaction Charges
              </TableCell>
              <TableCell align="left" width="120px">
                Fare Amt
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">11928.00</TableCell>
              <TableCell align="left">449.00</TableCell>
              <TableCell align="left">602.00</TableCell>
              <TableCell align="left">1.80</TableCell>
              <TableCell align="left">11.0</TableCell>
              <TableCell align="left">12,991.80</TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right" colSpan={10}>
                <span style={styles.label}>Total Price: Rs. 12,991.80</span>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right" colSpan={10}>
                (Authorised Signatory)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Invoice;
