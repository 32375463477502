import React from "react";
import WebApi from "api/ApiConstants";
import styled from 'styled-components';
import OaPopperAlert from "oahoc/OaPopperAlert";
import { Autocomplete } from "@material-ui/lab";
import { Button, TextField, useTheme } from "@material-ui/core";
import { localforageGetItem } from "oautils/oaForageUtils";


const OaDiscountCouponSearch = props => {
    const theme = useTheme();
    const [couponObject, setCouponObject] = React.useState(null);
    const [couponData, setCouponData] = React.useState(null);
    const [userId, setUserId] = React.useState(0);

    const [severity, setSeverity] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const onClickApply = () => {
        return couponObject && props.applyDiscountCoupon(couponObject);
    };

    const fetchMatchingCoupons = (couponCode) => {
        if(userId) {
            WebApi.getDiscountVoucherSearch({
                allVoucher: false,
                loggedInUserId: userId,
                voucherCode: couponCode,
                service: props.service
            }, response => {
                if(response.success) {
                    setCouponData(response.data);
                }
            }, error => { console.error("error fetching coupons", error) });
        }
    };

    const onChangeCouponObject = (event, newValue, reason) => {
        if (reason === "clear") {
            setCouponData(null);
        } else {
            setCouponObject(newValue);
        }
    };

    React.useEffect(() => {
        localforageGetItem("user-details", function (err, value) {
            console.log(value, err)
            if(value) {
                setUserId(value.userId);
            } else {
                console.error("err", err);
            }
        });
    }, []);

    React.useEffect(() => {
        if(props.couponStatus?.error) {
            setSeverity({
                ...severity,
                iserror: true
            });
            props.setCouponStatus({
                ...props.couponStatus,
                error: false
            });
            setCouponObject(null);
        }
        if(props.couponStatus?.success) {
            setSeverity({
                ...severity,
                issuccess: true
            });
            props.setCouponStatus({
                ...props.couponStatus,
                success: false
            });
        }
    }, [props.couponStatus]);

    return(
        <WrapperStyles theme={theme}>
            <div className="search-input-wrapper">
                <Autocomplete
                    className="search-input-autosuggest"
                    options={couponData || []}
                    getOptionLabel={o => o ? o.voucher_code : ""}
                    value={couponObject}
                    getOptionSelected={(option, value) =>
                        option && value && option.voucher_code == value.voucher_code
                    }
                    onKeyUp={(event) => fetchMatchingCoupons(event.target.value)}
                    onChange={onChangeCouponObject}
                    // onInputChange={(event) => fetchMatchingCoupons(event.target.value)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            // fullWidth
                            color="secondary"
                            placeholder="Search coupon code"
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                            }}
                        />
                    )}
                />
            </div>
            <div className="action-item-wrapper">
                <Button
                    onClick={onClickApply}
                >Apply</Button>
            </div>
            <OaPopperAlert
                alertmessage={props.couponStatus?.message}
                severity={severity}
                setseverity={setSeverity}
            />
        </WrapperStyles>
    );
};

export default OaDiscountCouponSearch;

const WrapperStyles = styled.div`
    background: ${props => props.theme.palette.background.default};
    color: ${props => props.theme.palette.background.defaultContrastText};
    display: flex;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    .MuiFormControl-root {
        margin-top: 0;
    }
    .search-input-wrapper {
        flex-grow: 1;
    }
    .action-item-wrapper {
        & button {
            width: 48px;
            font-size: 11px;
            background: ${props => props.theme.palette.buttons.secondary};
            color: ${props => props.theme.palette.buttons.secondaryContrastText};
        }
    }
`;
