import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OaCard from 'oahoc/OaCard';
import FlightCancellationReportForm from "./FlightCancellationReportForm";
import FlightCancellationReportResults from "./FlightCancellationReportResults";
import FlightRollbackReportResults from "./FlightRollbackReportResults";
import dateFnsFormat from 'date-fns/format';
import OaPriTabs from "oahoc/OaPriTabs";
import {
    getQueryVar,
    getCurrentTab,
} from "oautils/oaCommonUtils";
import OaError from "pages/components/OaError";

const FlightCancellationReport = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    let d = new Date();
    let fromDate = d.getDate() > 1 ? new Date(d.getFullYear(), d.getMonth(), 1) : new Date(d.getFullYear(), d.getMonth() - 1, 1);
    console.log("fromDate",fromDate)

    let tabs = [
        {
            name: "cancellation",
            label: "Cancellation",
            url: "/admin/booking-report/cancellation-flight",
        },
        {
            name: "rollback",
            label: "Rollback",
            url: "/admin/booking-report/cancellation-flight/rollback",
        },
    ];

    const queryVar = getQueryVar({ ...props, q: "priTab" });
    let currentTab = getCurrentTab(queryVar, "cancellation");

    let initialParams = {
        agencyId: 0,
        dateFromDisplay: fromDate,
        dateToDisplay: new Date(),
        dateFrom: dateFnsFormat(fromDate, "dd-MM-yyyy"),
        dateTo: dateFnsFormat(new Date(), "dd-MM-yyyy"),
        type: "Pending",
        userType: ""
    };

    const [searchParams, setSearchParams] = useState(initialParams);
    const [tableKey, setTableKey] = useState(0);

    function flightReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    function renderTab(currentTab) {
        // console.log(currentTab)
        switch (currentTab) {
            case "cancellation":
                return (
                    <FlightCancellationReportResults
                        key={tableKey}
                        searchParams={searchParams}
                        {...props}
                    />
                );
            case "rollback":
                return (
                    <FlightRollbackReportResults
                        key={tableKey}
                        searchParams={searchParams}
                        {...props}
                    />
                );
            default:
                return <OaError />;
        }
    }

    useEffect(() => {
        setSearchParams({...searchParams});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return(
        <RootContainer>
            {!isLoading &&
                <OaCard className={'oa-card-primary'}>
                    <OaPriTabs tabs={tabs} currentTab={currentTab} />
                    <OaCard>
                        <FlightCancellationReportForm
                            target={flightReportLoader}
                            searchParams={searchParams}
                            setIsLoading={setIsLoading}
                        />
                    </OaCard>

                    <OaCard>{renderTab(currentTab)}</OaCard>
                </OaCard>
            }
        </RootContainer>
    );
};

export default FlightCancellationReport;

const RootContainer = styled.div`
.oa-card-body {
    min-height: 140px;
}
`;
