import React from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const OaSnackbar = (props) => {
    const [severity, setSeverity] = React.useState(null);
    const classes = useStyles();
    const vertical = props.vertical ? props.vertical : "top"; // bottom/top
    const horizontal = props.horizontal ? props.horizontal : "center"; //center/left/right

    const setOnClose = () => {
        props.setseverity({
            issuccess: false,
            iserror: false,
            iswarning: false,
            isinfo: false
        });
    };

    React.useEffect(() => {
        switch(true) {
            case props.severity.iserror: {
                setSeverity("error");
                break;
            }
            case props.severity.issuccess: {
                setSeverity("success");
                break;
            }
            case props.severity.isinfo: {
                setSeverity("info");
                break;
            }
            case props.severity.iswarning: {
                setSeverity("warning");
                break;
            }
            default: {
                setSeverity("info");
                break;
            }
        }
    }, [props.severity]);

    return(
        <Snackbar
            className={classes.root}
            open={
                props.severity?.issuccess || 
                props.severity?.iserror || 
                props.severity?.isinfo || 
                props.severity?.iswarning
            }
            anchorOrigin={{vertical, horizontal}}
            onClose={setOnClose}
            {...props}
        >
            <Alert severity={severity} sx={{ width: '100%' }} onClose={setOnClose}>
                {props.alertmessage}
            </Alert>
        </Snackbar>
    );
};

export default OaSnackbar;

const useStyles = makeStyles(theme => ({
    root: {}
}));
