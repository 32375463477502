import React, { useState, useEffect, useContext } from "react";
//import axios from "axios";

//import CircularProgress from "@material-ui/core/CircularProgress";
import { withRouter } from "react-router-dom";
//import Scheduler from "components/HotelPage/HotelComponents/Scheduler/Scheduler";
//import FilterBox from "components/HotelPage/HotelComponents/Filterbox/Filterbox";
import HotelListView from "components/HotelPage/HotelComponents/HotelListView/HotelListView";
import LeftNavFilter from "components/HotelPage/HotelComponents/LeftNavFilter/LeftNavFilter";
//import Loading from "components/HotelPage/HotelComponents/Loading/Loading";

import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  MenuItem,
  Select,
  Collapse,
  useMediaQuery,
  Button,
  Modal,
} from "@material-ui/core";
//import GridContainer from "components/Grid/GridContainer";
//import GridItem from "components/Grid/GridItem";

import { connect } from "react-redux";
import { setHotelInfo, setHotelSearchResult } from "redux/action";

//import OaFormAlerts from "pages/components/OaFormAlerts";
import HotelLeafletMap from "pages/module/hotel/hotelinfo/HotelLeafletMap";
import Grid from "@material-ui/core/Grid";
import LazyLoad from "react-lazyload";

//import PlaceholderComponent from "components/HotelPage/HotelComponents/HotelListView/Placeholder";
//import HotelDetailPage from "./HotelDetailPage";
import { AppStateContext } from "layouts/AppStateProvider";
import Switch from "@material-ui/core/Switch";
import ModifyHotelSearch from "./ModifyHotelSearch";
import ModifyIslandSearch from "./ModifyIslandSearch";

import { ErrorOutlineRounded } from "@material-ui/icons";
import funnel from "assets/icons/funnel.png";
import closeBtn from "assets/icons/closeButton.png";
import modify from "assets/icons/modify.png";
import MobileHotelSection from "components/LandingPage/for-mobile/MobileHotelSection";
import HotelSearchSort from "./HotelSearchSort";

const HotelSearchResultPage = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [errors, setErrors] = useState();
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredData, setFilteredData] = useState({ data: [] });
  const [filterChanged, setFilterChanged] = useState(false);
  const [hnf, setHnf] = useState(false);
  const { userType } = useContext(AppStateContext);
  const classes = useStyles();
  const [selectedHotelName, setSelectedHotel] = useState([]);
  const [schedulerExpand, setScheduerExpand] = useState(false);
  const [filterExpand, setFilterExpand] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  const [modifyHotelSearch, setModifyHotelSearch] = React.useState(false);
  const [modifyHotelSubmit, setModifyHotelSubmit] = React.useState(false);
  const [modifyHotel, setModifyHotel] = useState(false);
  // console.log(userType)
  const width = window.innerWidth;
  const breakpoint = 980;

  const toggleModifyHotelSearch = (flag) => {
    setModifyHotelSearch(flag);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setFilterChanged(false);
  }, [filterChanged]);

  // console.log(props.hotelSearch);
  // useEffect(() => {filteredData && console.log("filteredData", filteredData)}, [filteredData]);

  const NoResultView = () => {
    return (
      <Grid container direction="row" className={classes.noResultContainer}>
        <Grid item className={classes.noResults}>
          <ErrorOutlineRounded />
          <Typography>No Results found</Typography>
        </Grid>
      </Grid>
    );
  };
  const isResults =
    props.hotelSearchResult.hotelResults &&
    props.hotelSearchResult.hotelResults.length > 0;

  // console.log("props.hotelSearch ", props.hotelSearch);
  return (
    <div>
      {isResults ? (
        <Grid container className={classes.hotelSearchRoot}>
          {isMobile && (
            <span
              className="scheduler-toggle-mobile"
              onClick={() => setScheduerExpand(!schedulerExpand)}
            >
              <b>Itinerary {schedulerExpand == true ? "-" : "+"}</b>
            </span>
          )}
          {isMobile && (
            <span
              className="filter-toggle-mobile"
              onClick={() => setFilterExpand(!filterExpand)}
            >
              <b>Filters {filterExpand == true ? "-" : "+"}</b>
            </span>
          )}
          <Grid item md={12} xs={12}>
            <div
              className={classes.schedulerWrapper}
              style={{
                display: isMobile
                  ? !schedulerExpand
                    ? "none"
                    : "block"
                  : "block",
              }}
            ></div>
          </Grid>
          <Grid item md={12} className={classes.hotelListViewGrid}>
            <div className="search-modify-container">
              {width > breakpoint && (
                <div className="search-modify-inner-container">
                  <div className="search-modify-form">
                    {props.hotelSearch.isIslandHopper === "false" ? (
                      <ModifyHotelSearch
                        selectedData={props.hotelSearch}
                        modifyHotelSubmit={modifyHotelSubmit}
                        setModifyHotelSubmit={setModifyHotelSubmit}
                        setResultsFound={props.setResultsFound}
                      />
                    ) : (
                      <ModifyIslandSearch
                        selectedData={props.hotelSearch}
                        modifyHotelSubmit={modifyHotelSubmit}
                        setModifyHotelSubmit={setModifyHotelSubmit}
                        setResultsFound={props.setResultsFound}
                      />
                    )}
                  </div>
                  <div className="search-modify-form-actions">
                    {/* <Button onClick={() => setModifyHotelSearch(false)}
                      className="modify-search-btn modify-search-cancel"
                    >
                      Cancel
                    </Button> */}
                    <Button
                      className="modify-search-btn modify-search-submit"
                      variant="contained"
                      color="primary"
                      onClick={() => setModifyHotelSubmit(true)}
                    >
                      Modify
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>

          <Grid
            item
            md={12}
            className={classes.outerContainerSecondary}
            style={{ marginTop: 10 }}
          >
            <Grid container>
              {/* FILTER CONTAINER */}

              <Grid className={classes.filterModifyContainer}>
                <Grid>
                  <Button
                    onClick={() => setFilterExpand(true)}
                    disabled={props.disabled || false}
                    className={classes.filterButton}
                  >
                    <>
                      Filter &nbsp;&nbsp;
                      <img src={funnel} />
                    </>
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    onClick={() => setModifyHotel(true)}
                    disabled={props.disabled || false}
                    className={classes.modifyButton}
                  >
                    <>
                      <img src={modify} />
                      &nbsp;&nbsp;Modify
                    </>
                  </Button>
                </Grid>
              </Grid>

              <Grid
                item
                md={3}
                xs={12}
                className={classes.hotelFilterRoot}
                style={{
                  height: isMobile ? (!filterExpand ? 0 : "auto") : "auto",
                }}
              >
                <Grid
                  container
                  className={isMobile && filterExpand && classes.modalContainer}
                >
                  {filterExpand && (
                    <div
                      style={{
                        justifyContent: "flex-end",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <img
                        onClick={() => setFilterExpand(false)}
                        src={closeBtn}
                        style={{
                          height: 25,
                          width: 25,
                          alignSelf: "flex-end",
                          marginLeft: 20,
                          // marginBottom: 15,
                          // marginTop: 10,
                        }}
                      />
                    </div>
                  )}

                  <Grid item xs={12} className="left-nav-filter-grid">
                    <LeftNavFilter
                      setIsFiltered={setIsFiltered}
                      setFilterChanged={setFilterChanged}
                      hotelSearchResult={props.hotelSearchResult.hotelResults}
                      setFilteredData={setFilteredData}
                      filters={props.hotelFilters}
                    />
                  </Grid>
                  <Grid item xs={12} className="leaflet-map-grid">
                    {props.hotelSearchResult &&
                      props.hotelSearchResult.hotelResults && (
                        <HotelLeafletMap
                          hotels={
                            !isFiltered
                              ? props.hotelSearchResult.hotelResults
                              : filteredData.data
                          }
                        />
                      )}
                  </Grid>
                  {filterExpand && (
                    <Grid
                      onClick={() => setFilterExpand(false)}
                      style={{
                        position: "fixed",
                        bottom: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      className={classes.applyBtn}
                    >
                      APPLY
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                md={3}
                xs={12}
                className={classes.hotelmodifyRoot}
                style={{
                  height: isMobile ? (!modifyHotel ? 0 : "auto") : 0,
                  display: !isMobile && "none",
                }}
              >
                <Grid
                  container
                  className={isMobile && modifyHotel && classes.modalContainer}
                >
                  {modifyHotel && (
                    <div
                      style={{
                        justifyContent: "flex-end",

                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <img
                        onClick={() => setModifyHotel(false)}
                        src={closeBtn}
                        style={{
                          height: 30,
                          width: 30,
                          alignSelf: "flex-end",
                          marginLeft: 20,
                          marginBottom: 15,
                          marginTop: 10,
                        }}
                      />
                    </div>
                  )}

                  <MobileHotelSection />
                </Grid>
              </Grid>

              <Grid item md={9} className={classes.hotelListViewGrid}>
                <div
                  className="search-modify-container"
                  style={{
                    height: modifyHotelSearch ? "auto" : 0,
                  }}
                >
                  <div
                    className="search-modify-inner-container"
                    style={{
                      display: modifyHotelSearch ? "block" : "none",
                    }}
                  ></div>
                </div>
                <HotelSearchSort
                  data={
                    !isFiltered
                      ? props.hotelSearchResult.hotelResults
                      : filteredData.data
                  }
                  setFilteredData={setFilteredData}
                  setIsFiltered={setIsFiltered}
                  setFilterChanged={setFilterChanged}
                />
                {!(userType === null || userType === "b2c_user") && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: `100%`,
                      marginRight: 30,
                      paddingRight: 20,
                    }}
                  >
                    <span style={{ color: theme.palette.secondary.sub }}>
                      HNF
                    </span>
                    <PurpleSwitch
                      checked={hnf}
                      onChange={() => setHnf(!hnf)}
                      name="isSNF"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <span style={{ color: theme.palette.secondary.sub }}>
                      SNF
                    </span>
                  </div>
                )}
                {!isFiltered ? (
                  props.hotelSearch &&
                  props.hotelSearchResult.hotelResults &&
                  props.hotelSearchResult.hotelResults.map((value, index) => {
                    return (
                      <>
                        {value.hotelName.length >= 0 &&
                          (index < 4 ? (
                            // <LazyLoad
                            //   once={true}
                            //   key={index}
                            //   height={200}
                            //   offset={[-200, 0]}
                            // >
                            <HotelListView
                              hnf={hnf}
                              hotel={value}
                              cityId={props.hotelSearch.cityId}
                              searchData={props.hotelSearch}
                              resultIndex={value.resultIndex}
                              hotelCode={value.hotelCode}
                              traceId={props.hotelSearchResult.traceId}
                              categoryId={
                                value.hotelCategory
                                  ? value.hotelCategory
                                  : value.supplierHotelCodes
                                  ? value.supplierHotelCodes[0].categoryId
                                  : 0
                              }
                            />
                          ) : (
                            //</LazyLoad>
                            <LazyLoad
                              once={true}
                              key={index}
                              height={200}
                              offset={[-200, 0]}
                            >
                              <HotelListView
                                hnf={hnf}
                                hotel={value}
                                cityId={props.hotelSearch.cityId}
                                searchData={props.hotelSearch}
                                resultIndex={value.resultIndex}
                                hotelCode={value.hotelCode}
                                traceId={props.hotelSearchResult.traceId}
                                categoryId={
                                  value.hotelCategory
                                    ? value.hotelCategory
                                    : value.supplierHotelCodes
                                    ? value.supplierHotelCodes[0].categoryId
                                    : 0
                                }
                              />
                            </LazyLoad>
                          ))}
                      </>
                    );
                  })
                ) : props.hotelSearch &&
                  props.hotelSearchResult.hotelResults &&
                  filteredData.data.length > 0 ? (
                  filteredData.data.map((value, index) => {
                    return (
                      <>
                        {value.hotelName.length >= 0 &&
                          (index < 4 ? (
                            // <LazyLoad
                            //   once={true}
                            //   key={index}
                            //   height={200}
                            //   offset={[-200, 0]}
                            // >
                            <HotelListView
                              hnf={hnf}
                              hotel={value}
                              cityId={props.hotelSearch.cityId}
                              searchData={props.hotelSearch}
                              resultIndex={value.resultIndex}
                              hotelCode={value.hotelCode}
                              traceId={props.hotelSearchResult.traceId}
                              categoryId={
                                value.hotelCategory
                                  ? value.hotelCategory
                                  : value.supplierHotelCodes
                                  ? value.supplierHotelCodes[0].categoryId
                                  : 0
                              }
                            />
                          ) : (
                            //</LazyLoad>
                            <LazyLoad
                              once={true}
                              key={index}
                              height={200}
                              offset={[-200, 0]}
                            >
                              <HotelListView
                                hnf={hnf}
                                hotel={value}
                                cityId={props.hotelSearch.cityId}
                                searchData={props.hotelSearch}
                                resultIndex={value.resultIndex}
                                hotelCode={value.hotelCode}
                                traceId={props.hotelSearchResult.traceId}
                                categoryId={
                                  value.hotelCategory
                                    ? value.hotelCategory
                                    : value.supplierHotelCodes
                                    ? value.supplierHotelCodes[0].categoryId
                                    : 0
                                }
                              />
                            </LazyLoad>
                          ))}
                      </>
                    );
                  })
                ) : (
                  <NoResultView />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <Grid item md={12} className={classes.hotelListViewGrid}>
            <div className="search-modify-container">
              {width > breakpoint && (
                <div className="search-modify-inner-container">
                  <div className="search-modify-form">
                    <ModifyHotelSearch
                      selectedData={props.hotelSearch}
                      modifyHotelSubmit={modifyHotelSubmit}
                      setModifyHotelSubmit={setModifyHotelSubmit}
                      setResultsFound={props.setResultsFound}
                    />
                  </div>
                  <div className="search-modify-form-actions">
                    {/* <Button onClick={() => setModifyHotelSearch(false)}
                  className="modify-search-btn modify-search-cancel"
                >
                  Cancel
                </Button> */}
                    <Button
                      className="modify-search-btn modify-search-submit"
                      variant="contained"
                      color="primary"
                      onClick={() => setModifyHotelSubmit(true)}
                    >
                      Modify
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <NoResultView />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
    hotelSearchResult: state.hotel.hotelSearchResult,
    hotelFilters: state.hotel.hotelFilters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelInfo: (hotelInfo) => dispatch(setHotelInfo(hotelInfo)),
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HotelSearchResultPage)
);

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.secondary.darkGray,
    "&$checked": {
      color: theme.palette.secondary.darkGray,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.secondary.medallion,
    },
  },
  checked: {},
  track: {
    backgroundColor: theme.palette.background.fireRed,
  },
}))(Switch);

const storeParams = (params) => {
  localStorage.setItem("params", JSON.stringify(params));
};

const useStyles = makeStyles((theme) => ({
  hotelSearchRoot: {
    "& .scheduler-toggle-mobile": {
      cursor: "pointer",
      position: "absolute",
      fontSize: 20,
      top: -25,
      right: 128,
      color: theme.palette.secondary.main,
    },
    "& .filter-toggle-mobile": {
      cursor: "pointer",
      position: "absolute",
      fontSize: 20,
      top: -25,
      right: 30,
      color: theme.palette.secondary.main,
    },
  },
  outerContainerSecondary: {
    [theme.breakpoints.down(960)]: {
      overflowX: "clip",
    },
  },
  noResultContainer: {
    flexWrap: "nowrap",
    width: "100%",

    minHeight: "calc(80vh - 100px)",
    justifyContent: "center",
    alignItems: "center",
  },
  noResults: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hotelFilterRoot: {
    maxHeight: "300vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "& .leaflet-map-grid": {
      padding: "0 15px",
      marginTop: 10,
    },
    "& .left-nav-filter-grid": {
      padding: "0",
    },
  },
  hotelmodifyRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    // maxHeight: "300vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "& .leaflet-map-grid": {
      padding: "0 15px",
    },
    "& .left-nav-filter-grid": {
      padding: "0",
    },
  },
  hotelmodifyRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    // maxHeight: "300vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "& .leaflet-map-grid": {
      padding: "0 15px",
    },
    "& .left-nav-filter-grid": {
      padding: "0",
    },
  },
  hotelmodifyRoot: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    // maxHeight: "300vh",
    overflowY: "auto",
    scrollbarWidth: "thin",
    "& .leaflet-map-grid": {
      padding: "0 15px",
    },
    "& .left-nav-filter-grid": {
      padding: "0",
    },
  },
  hotelListViewGrid: {
    "& .oa-card-grid-container": {
      [theme.breakpoints.down(960)]: {
        width: "100%",
        padding: 5,
        // width: "99vw",
      },
    },
    "& .search-modify-container": {
      padding: "10px 10px",
      [theme.breakpoints.down(960)]: {
        padding: "0",
      },
    },
    "& .search-modify-inner-container": {
      // background: theme.palette.primary.main,
      background: theme.palette.buttons.default,

      padding: "8px 15px 10px",
      boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
      MozBoxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
      WebkitBoxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
      display: "flex",
      gap: 20,
    },
    "& .search-modify-form": {
      flex: 11,
    },

    "& .search-modify-form-actions": {
      flex: 1,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      // justifyContent: "flex-end",
      // gap: 10,
      // padding: "8px 0",
      // paddingBottom: 7,
    },
    "& .modify-search-btn": {
      padding: "unset",
      width: "100%",
      height: 40,
      marginTop: 27,
    },
    "& .modify-search-cancel": {
      background: theme.palette.buttons.tertiary,
      // border: `solid 1px ${theme.palette.secondary.main}`,
      color: theme.palette.buttons.tertiaryContrastText,
      "&:hover": {
        //background: theme.palette.secondary.main,
      },
    },
    "& .modify-search-submit": {
      background: theme.palette.buttons.bookNowBtn,
      // cursor: "pointer",
      color: theme.palette.primary.lightText,
      // border: `solid 1px ${theme.palette.secondary.main}`,
      "&:hover": {
        //background: theme.palette.secondary.main,
      },
    },
  },
  schedulerWrapper: {
    transition: "height 2s",
  },
  filterButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0 30px 30px 0",
    color: theme.palette.primary.contrastText,
    width: 160,
    // marginTop: 30,
    "& svg": {
      color: theme.palette.primary.contrastText,
      fontSize: "2rem",
    },
    [theme.breakpoints.up(991)]: {
      display: "none",
      borderRadius: "50%",
      width: 45,
      height: 45,
      boxShadow: `0px 0px 30px 0px rgba(140,140,140,1)`,
      backgroundColor: "rgba(255,255,255,0.6)",
      "& svg": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.9) !important",
      },
      "&.MuiButton-root": {
        minWidth: 45,
      },
    },
    [theme.breakpoints.down(400)]: {
      width: 110,
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.sub,
    },
  },
  modifyButton: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "30px 0px 0px 30px",
    color: theme.palette.primary.contrastText,
    width: 160,
    height: "100%",
    // marginTop: 30,
    "& svg": {
      color: theme.palette.primary.contrastText,
      fontSize: "2rem",
    },
    [theme.breakpoints.up(991)]: {
      display: "none",
      borderRadius: "50%",
      width: 45,
      height: 45,
      boxShadow: `0px 0px 30px 0px rgba(140,140,140,1)`,
      backgroundColor: "rgba(255,255,255,0.6)",
      "& svg": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.9) !important",
      },
      "&.MuiButton-root": {
        minWidth: 45,
      },
    },
    [theme.breakpoints.down(400)]: {
      width: 110,
      fontSize: "16px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.sub,
    },
  },
  filterModifyContainer: {
    width: "100%",
    justifyContent: "space-between",
    height: "100%",
    display: "flex",
  },
  modalContainer: {
    position: "fixed",
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    padding: 10,
    overflowY: "scroll",
  },
  // "& .applyBtn": {
  //   backgroundColor: theme.palette.primary.main,
  //   color: theme.palette.background.default,
  //   fontWeight: 700,
  //   height: 50,
  //   alignItems: "center",
  // },
  applyBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontWeight: 700,
    height: 50,
    alignItems: "center",
    left: 0,
  },
}));
