import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { TextField, useTheme, Button, Box } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import WebApi from "api/ApiConstants";
import MantraRdService from "./MantraRdService";
import OaSnackbar from "oahoc/OaSnackbar";
import OaPopperAlert from "oahoc/OaPopperAlert";
import OaLoading from "pages/components/OaLoading";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";

const AadhaarpayTransactionStatus = (props) => {
    const theme = useTheme();
    const formikRef = React.useRef(null);
    const [pIDData, setPIDData] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [fingerPrintCaptured, setFingerPrintCaptured] = React.useState(false);
    const [avdmActive, setAvdmActive] = React.useState(false);
    const [captureAvdm, setCaptureAvdm] = React.useState(false);
    const [merchant2faDet, setMerchant2FaDet] = React.useState(null);
    const [infoText, setInfoText] = React.useState("");
    const [beResponse, setBeResponse] = React.useState(null);

    const [severity, setSeverity] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const [severitySnackbar, setSeveritySnackbar] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const initialValues = {
        adhaarnumber: "",
        mobilenumber: "",
        referenceno: "",
        nationalbankidentification: ""
    };

    const validationSchema = Yup.object().shape({
        mobilenumber: Yup.string().required("Mobile number cannot be blank"),
        adhaarnumber: Yup.string().required("Aadhaar number cannot be blank"),
        // referenceno: Yup.string().required("Transaction id cannot be blank"),
        nationalbankidentification: Yup.string().required("Please select bank")
    });

    const onChangeMobileNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobilenumber", e.target.value);
        }
    };

    const onChangeAadhaarNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("adhaarnumber", e.target.value);
        }
    };

    const onChangeTransactionRef = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("referenceno", e.target.value);
        }
    };

    const onChangeBankNbin = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("nationalbankidentification", e.target.value);
        }
    };

    const onSubmitTransactionEnquiry = (searchParams, { setSubmitting }) => {
        if (!props.location.state.trnParams?.latitude && props.location.state.trnParams?.longitude) {
            setInfoText("Please enable location and try again");
            setSeverity({
                ...severity,
                iserror: true
            });
            return;
        }

        if (!fingerPrintCaptured && !pIDData) {
            setInfoText("Please re-capture fingerprints and try again");
            setSeverity({
                ...severity,
                iserror: true
            });
            return;
        }

        if (pIDData && searchParams.nationalbankidentification !== "") {
            searchParams.merchant_code = props.location.state.data?.merchant_code;
            searchParams.data = props.location.state.trnParams?.data;
            searchParams.latitude = props.location.state.trnParams?.latitude;
            searchParams.longitude = props.location.state.trnParams?.longitude;
            searchParams.requestremarks = 'AEPS transaction enquiry';
            searchParams.data = pIDData;
            // searchParams.pipe = props.location.state.trnParams?.pipe;
            searchParams.pipe = "bank1";
            searchParams.is_iris = 'No';
            searchParams.isAEPS = props.location.state.trnParams?.isAEPS;
            
            setSubmitting(true);
            setIsLoading(true);
    
            WebApi.aepsBalanceEnquiry(searchParams, response => {
                // console.log(response);
                setBeResponse(response);
                if (response.success) {
                    // console.log(response.data);
    
                    setInfoText(response.message);
                    setSeverity({
                        ...severity,
                        issuccess: true
                    });
                    setFingerPrintCaptured(false);
                } else {
                    setInfoText(response.message);
                    setFingerPrintCaptured(false);
                    setSeverity({
                        ...severity,
                        iserror: true
                    });
                }
                setIsLoading(false);
            }, error => {
                console.error("aepsBalanceEnquiry error", error) 
                setIsLoading(false);
                setFingerPrintCaptured(false);
            });
            setSubmitting(false);
        }
    };

    const reInitDevice = () => {
        setIsLoading(true);
        setAvdmActive(false);
    };

    const getTwoFaRegDetails = () => {
        setIsLoading(true);
        WebApi.getAepsTwoFactorRegistrationDetails({
            merchantCode: props.location.state.data?.merchant_code,
            regType: props.location.state.trnParams?.pipe === "bank2" ? "registration_bank2" : "registration_bank3"
        }, response => {
            if(response.success) {
                if(formikRef.current) {
                    // formikRef.current.setFieldValue("adhaarnumber", response.data?.aadhaarNo);
                    formikRef.current.setFieldValue("mobilenumber", response.data?.mobileNo);
                }
                setMerchant2FaDet(response.data);
            } else {
                console.log("failed to get twofa reg details")
            }
            setIsLoading(false);
        }, error => {
            console.error("getTwoFaRegDEtails error", error);
            setIsLoading(false);
        });
    };

    React.useEffect(() => {
        getTwoFaRegDetails();
        // console.log("props 2fa reg", props)
        // console.log("props data", props.data)
    }, []);

    return (
        <RootContainer theme={theme}>
            <div className="form-title-wrapper">
                <p className="form-title">Balance Enquiry</p>
            </div>
            <div className="form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitTransactionEnquiry}
                >
                    {({ values, isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            <div className="form-wrapper">
                                <div className="form-row">
                                    <MantraRdService
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        setInfoText={(val) => setInfoText(val)}
                                        severity={severity}
                                        setSeverity={setSeverity}
                                        severitySnackbar={severitySnackbar}
                                        setSeveritySnackbar={setSeveritySnackbar}
                                        fingerPrintCaptured={fingerPrintCaptured}
                                        setFingerPrintCaptured={setFingerPrintCaptured}
                                        captureAvdm={captureAvdm}
                                        setCaptureAvdm={setCaptureAvdm}
                                        pIDData={pIDData}
                                        setPIDData={setPIDData}
                                        avdmActive={avdmActive}
                                        setAvdmActive={setAvdmActive}
                                        reInitDevice={reInitDevice}
                                        fingerPrintOwner={"RETAILER"}
                                        mode="auth"
                                    />
                                </div>
                                <div className="form-row justify-center mb-30">
                                    <div className="form-element-wrap">
                                        <label>Aadhaar Number</label>
                                        <TextField
                                            value={values.adhaarnumber}
                                            onChange={onChangeAadhaarNo}
                                            variant="outlined"
                                            // disabled={merchant2faDet?.aadhaarNo}
                                            fullWidth
                                        />
                                        <ErrorMessage name="adhaarnumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap">
                                        <label>Mobile</label>
                                        <TextField
                                            value={values.mobilenumber}
                                            onChange={onChangeMobileNo}
                                            variant="outlined"
                                            // disabled={merchant2faDet?.mobileNo}
                                            fullWidth
                                        />
                                        <ErrorMessage name="mobilenumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap">
                                        <label>Transaction Id</label>
                                        <TextField
                                            value={values.referenceno}
                                            onChange={onChangeTransactionRef}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <ErrorMessage name="mobilenumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap select-wrap">
                                        <label>Bank</label>
                                        <OaFormSelectComboField
                                            name="nationalbankidentification"
                                            value={values.nationalbankidentification}
                                            options={props.location.state?.banks}
                                            optionValue={"iinno"}
                                            optionLabel={["bankName", "iinno"]}
                                            required={true}
                                            setFieldValue={setFieldValue}
                                        />
                                    </div>
                                    <div className="form-element-wrap submit-wrap">
                                        <Button type="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {beResponse && 
                <div className="result-container">
                    <h3>Balance Enquiry</h3>
                    <div className="details-container">
                        <p>
                            <span className="label">Status: </span> 
                            <span className="value">{beResponse?.success && beResponse.data?.status ? beResponse.data?.status+"" : "Failed"}</span>
                        </p>
                        <p>
                            <span className="label">Message: </span> 
                            <span className="value">{beResponse?.success && beResponse.data?.message ? beResponse.data?.message : beResponse?.message}</span>
                        </p>
                        <p>
                            <span className="label">Acknowledgement Number: </span> 
                            <span className="value">{beResponse?.success && beResponse.data?.ackno ? beResponse.data?.ackno : "n/a"}</span>
                        </p>
                        <p>
                            <span className="label">Amount: </span>
                            <span className="value">{beResponse?.success && beResponse.data?.amount ? beResponse.data?.amount : "0"}</span>
                        </p>
                        <p>
                            <span className="label">Balance Amount: </span>
                            <span className="value">{beResponse?.success && beResponse.data?.balanceamount ? beResponse.data?.balanceamount : "0"}</span>
                        </p>
                        <p>
                            <span className="label">Bank Reference Number: </span>
                            <span className="value">{beResponse?.success && beResponse.data?.bankrrn ? beResponse.data?.bankrrn : "n/a"}</span>
                        </p>
                        <p>
                            <span className="label">Bank Identification Number: </span>
                            <span className="value">{beResponse?.success && beResponse.data?.bankiin ? beResponse.data?.bankiin : "n/a"}</span>
                        </p>
                        <p>
                            <span className="label">Reference Number: </span>
                            <span className="value">{beResponse?.success && beResponse.data?.clientrefno ? beResponse.data?.clientrefno : "n/a"}</span>
                        </p>
                    </div>
                </div>
            }
            {isLoading && <LoaderView/>}
            <OaPopperAlert
                alertmessage={infoText}
                severity={severity}
                setseverity={setSeverity}
            />
            <OaSnackbar
                alertmessage={infoText}
                severity={severitySnackbar}
                setseverity={setSeveritySnackbar}
                vertical="top"
                horizontal="left"
                autoHideDuration={4000}
            />
        </RootContainer>
    );
};

const LoaderView = () => {
    return (
        <Box className="loader-view"
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <OaLoading loadertext="intializing RD service ..." />
        </Box>
    );
};

export default AadhaarpayTransactionStatus;

const RootContainer = styled.div`
position: relative;
min-height: 50vh;
.form-container {
    padding: 0 20px 5px;
}
.form-wrapper {
    text-align: left;
}
.form-title-wrapper {
    padding: 20px 20px 0;
    p {
        text-align: center;
    }
    .form-title {
        font-weight: 500;
        font-size: 1.2em;
    }
    .status-response {
        padding-top: 5px;
    }
}
.form-row {
    margin: 10px 0;
    display: flex;
    gap: 20px;
}
.form-element-wrap {
    &.submit-wrap {
        padding-top: 25.5px;
    }
    button {
        background: ${prop => prop.theme.palette.buttons.primary};
        color: ${prop => prop.theme.palette.buttons.primaryContrastText};
        font-size: 12px;
        padding: 0 15px;
        height: 39.5px;
    }
    .MuiInputBase-fullWidth {
        min-width: 200px;
    }
    &.select-wrap {
        .MuiFormControl-root {
            margin-top: 2.5px;
        }
        .MuiInputBase-root.MuiOutlinedInput-root {
            height: 40px;
        }
        .MuiAutocomplete-endAdornment {
            button {
                padding: 1px;
                background: none;
                color: ${prop => prop.theme.palette.buttons.primary};
                font-size: 12px;
                height: auto;
            }
        }
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
            width: 100%;
        }
    }
}
.justify-center {
    justify-content: center;
}
.mb-30 {
    margin-bottom: 30px;
}
.result-container {
    padding: 0 10vw;
    width: 100%;
    max-width: max-content;
    min-height: 50vh;
    overflow-y: auto;
    .label {
        font-weight: 500;
    }
    .value {
        font-weight: 600;
    }
    .details-container {
            margin: 0 0 25px;
    }
}
@media all and (max-width: 991px) {
    .form-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    .form-element-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .MuiTextField-root {
            width: 250px;
        }
        .MuiInputBase-fullWidth {
            width: 250px;
        }
        button {
            width: 250px;
        }
    }
}
`;
