import GridContainer from "components/Grid/GridContainer";
import React, { useState } from "react";
import GridItem from "components/Grid/GridItem";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import image1 from "assets/img/Hotelassets/Rectangle1.png";
import image2 from "assets/img/Hotelassets/Rectangle Copy 57.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import HotelLeafletMap from "./HotelLeafletMap";
import { Typography } from "@material-ui/core";
import StarRating from "components/HotelPage/HotelComponents/StarRating/StarRating";
import Button from "components/CustomButtons/Button";
import play from "assets/img/Hotelassets/play.png";
import Res from "assets/img/Hotelassets/Res.png";
import Union from "assets/img/Hotelassets/Union.png";
import bar from "assets/img/Hotelassets/bar.png";
import Viewer from "react-viewer";
// import offer from "assets/img/Hotelassets/offer.png";
import Offer from "components/HotelPage/HotelComponents/Offer/Offer";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import data from "./hotel_info.json";
import { Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Popover from "@material-ui/core/Popover";
import { AppConstant } from "appConstant";

const useStyles = makeStyles((theme) => ({
  guestRating: {
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },
  root: {
    "& .MuiGrid-spacing-xs-3": {
      padding: 8,
    },
  },

  cardmain: {
    height: "auto",
    "& .hotelRating": {
      [theme.breakpoints.down(500)]: {
        flexDirection: "column",
      },
    },
  },
  typo1: {
    fontSize: "15px",
    color: theme.palette.background.carulean,
    marginLeft: "15px",
    fontWeight: "500",
    fontFamily: theme.palette.font.primary
  },
  starstyle: {
    marginLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
    },
  },
  image1: {
    width: "100%",
    height: "100%",
  },
  image2: {
    width: "80%",
    height: "70%",
    marginLeft: "10%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0%",
    },
  },
  image3: {
    width: "87px",
    height: "66px",
    marginLeft: "14%",
    [theme.breakpoints.down("md")]: {
      width: "77px",
      marginLeft: "0%",
    },
  },
  typo2: {
    fontSize: "14px",
    fontWeight: "500",
    color: theme.palette.primary.darkText,
    fontFamily: theme.palette.font.primary
  },
  typo3: {
    color: theme.palette.primary.darkText,
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: theme.palette.font.primary
    // [theme.breakpoints.down("md")]:{
    //   marginLeft: "40px",
    // }
  },
  typo4: {
    fontSize: "12px",
    color: theme.palette.primary.darkText,
    fontFamily: theme.palette.font.primary,
    fontWeight: "500",
  },
  typo5: {
    fontSize: 22,
    color: theme.palette.background.peach,
    fontFamily: theme.palette.font.primary,
    marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: 17,

      marginLeft: "0px",
    },
  },
  gridlast: {
    marginTop: "1%",
  },
  Button: {
    width: "100%",
  },
  hotelAddress: {
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "55%",
    },
  },
  grid2: {
    marginLeft: "9%",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0%",
    },
  },
  carouseGrid: {
    "& .carousel-root .carousel": {
      "& .slide img": {
        width: "auto !important",
      },
      "& .slider-wrapper": {
        width: "75% !important",
      },
      "& button": {
        "&.control-arrow": {
          opacity: 1
        },
        "&.control-prev.control-arrow:before": {
          borderRight: `${AppConstant.hotelInfoCarouselButtonLarge ? "30px" : "8px"} solid ${theme.palette.secondary.main}`,
        },
        "&.control-next.control-arrow:before": {
          borderLeft: `${AppConstant.hotelInfoCarouselButtonLarge ? "30px" : "8px"} solid ${theme.palette.secondary.main}`,
        },
      },
      "& .slider-wrapper.axis-horizontal .slider .slide": {
        background: theme.palette.primary.lightText,
      },
    },
    "& .carousel.carousel-slider .control-arrow:hover": {
      background: "none !important"
    },
    "& .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before": {
      borderTop: `${AppConstant.hotelInfoCarouselButtonLarge ? "30px" : "8px"} solid transparent`,
      borderBottom: `${AppConstant.hotelInfoCarouselButtonLarge ? "30px" : "8px"} solid transparent`,
    }
  },
}));

const HotelInfo = ({ hotelInfo, hotel, images, hotelSearch, ...props }) => {
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hotelPriceRoundOff = AppConstant.hotelPriceRoundOff;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getFacilityIcon = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "dry_cleaning";

      case "Covered parking":
        return "local_parking";

      case "Wheelchair accessible (may have limitations)":
        return "accessible";

      case "Luggage storage":
        return "luggage";

      case "24-hour front desk":
        return "supervisor_account";

      case "Wheelchair-accessible registration desk":
        return "accessible";

      case "Wheelchair-accessible concierge desk":
        return "accessible";

      case "Breakfast available (surcharge)":
        return "free_breakfast";

      case "Free WiFi":
        return "wifi";

      case "Library":
        return "local_library";

      case "Designated smoking areas":
        return "smoking_rooms";

      case "Barbecue grill(s)":
        return "outdoor_grill";

      case "Garden":
        return "yard";

      case "In-room accessibility":
        return "room_service";

      case "Designated smoking areas (fines apply)":
        return "smoking_rooms";

      case "Stair-free path to entrance":
        return "stairs";

      case "Laundry facilities":
        return "dry_cleaning";

      case "Free newspapers in lobby":
        return "feed";

      case "Tours/ticket assistance":
        return "confirmation_number";

      case "Elevator":
        return "elevator";

      case "Free self parking":
        return "local_parking";

      case "Terrace":
        return "deck";

      case "Concierge services":
        return "child_care";

      case "Newspapers in lobby (surcharge)":
        return "feed";

      case "Bar":
        return "local_bar";

      case "gym":
        return "fitness_center";

      case "restaurant":
        return "hotel";

      case "Reception hall":
        return "meeting_room";

      case "meeting_room":
        return "meeting_room";

      case "Free breakfast":
        return "free_breakfast";

      case "Smoke-free property":
        return "smoke_free";

      case "Accessible bathroom":
        return "wc";

      case "Coffee/tea in common areas":
        return "local_cafe";

      case "Shopping mall on site":
        return "add_shopping_cart";

      case "Safe-deposit box at front desk":
        return "account_balance_wallet";

      case "Television in common areas":
        return "tv";

      default:
        return "error_outline";
    }
  };

  const getFacilityText = (description) => {
    switch (description) {
      case "Dry cleaning/laundry service":
        return "Dry Clean";
      case "Airport transportation (surcharge)":
        return "Airport Charge";
      case "Wheelchair accessible – no":
        return "No Wheelchair";

      case "Covered parking":
        return "Covered Parking";

      case "Wheelchair accessible (may have limitations)":
        return "Wheelchair";

      case "Luggage storage":
        return "Luggage";

      case "24-hour front desk":
        return "Front Desk always";

      case "Wheelchair-accessible registration desk":
        return "Wheelchair";

      case "Wheelchair-accessible concierge desk":
        return "Wheelchair";

      case "Breakfast available (surcharge)":
        return "Breakfast";

      case "Free WiFi":
        return "WiFi";

      case "Library":
        return "Library";

      case "Designated smoking areas":
        return "Smoking Areas";

      case "Barbecue grill(s)":
        return "Barbecue";

      case "Garden":
        return "Garden";

      case "In-room accessibility":
        return "Room Service";

      case "Designated smoking areas (fines apply)":
        return "Smoking Areas";

      case "Stair-free path to entrance":
        return "Stair Free";

      case "Laundry facilities":
        return "Laundry";

      case "Free newspapers in lobby":
        return "Newspapers";

      case "Tours/ticket assistance":
        return "Tours Assistance";

      case "Elevator":
        return "Elevator";

      case "Free self parking":
        return "Free Parking";

      case "Terrace":
        return "Terrace";

      case "Concierge services":
        return "Concierge services";

      case "Newspapers in lobby (surcharge)":
        return "Newspapers";

      case "Bar":
        return "Bar";

      case "gym":
        return "Gym";

      case "restaurant":
        return "Restaurant";

      case "Reception hall":
        return "Reception hall";

      case "meeting_room":
        return "Meeting Room";

      case "Free breakfast":
        return "Free Breakfast";

      case "Smoke-free property":
        return "Smoke Free";

      case "Accessible bathroom":
        return "Accessible Bathroom";

      case "Shopping mall on site":
        return "Shopping Mall";

      case "Safe-deposit box at front desk":
        return "Safe Deposite";

      case "Coffee/tea in common areas":
        return "Coffee/tea in common areas";

      case "Television in common areas":
        return "Common Television";

      default:
        return "NA";
    }
  };

  // console.log(props)
  return (
    <Grid
      container
      style={{
        paddingLeft: isMobile ? 5 : 15,
        paddingRight: isMobile ? 5 : 15,
      }}
      className={classes.root}
    >
      <Grid item xs={12}>
        {isMobile && <HotelLeafletMap hotels={[hotelInfo.hotelDetails]} />}
      </Grid>
      <Card className={classes.cardmain}>
        <CardBody>
          <Grid container>
            <Grid item md={7} xs={12}>
              <Grid
                container
                justifyContent="flex-start"
                direction="row"
                spacing={1}
                className="hotelRating"
              >
                <Grid item md={4} xs={12}>
                  <Typography
                    className={classes.typo1}
                    style={{ fontWeight: 500 }}
                  >
                    {hotelInfo.hotelDetails.hotelName}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography align="right">
                    <StarRating
                      rating={hotelInfo.hotelDetails.starRating}
                      className={classes.starstyle}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item md={5} xs={12}>
              <Grid
                container
                direction="row"
                spacing={1}
                justifyContent="flex-end"
                className={classes.guestRating}
              >
                <Grid item md={4} xs={6}>
                  <Typography className={classes.typo1}>
                    {" "}
                    Guest Rating
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography align="right">
                    <StarRating
                      rating={hotelInfo.hotelDetails.starRating}
                      className={classes.starstyle}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12} className={classes.carouseGrid}>
              <Carousel
                autoPlay
                infiniteLoop
                useKeyboardArrows
                showThumbs={false}
                stopOnHover={true}
                onClickItem={(index) => {
                  setCurrentIndex(index);
                  setVisible(true);
                }}
                showIndicators={false}
              >
                {hotelInfo.hotelDetails?.images && hotelInfo.hotelDetails.images.map((image, indx) => (
                  <div key={indx} >
                    <img src={image} height="200" />
                  </div>
                ))}
              </Carousel>
              <div
                style={{
                  fontSize: "12px",
                  color: theme.palette.secondary.sharkGray,
                  fontWeight: 500,
                  marginTop: 25,
                  fontFamily: theme.palette.font.primary
                }}
              >
                {hotelInfo.hotelDetails.address}
              </div>
              <Grid container item xs={12}>
                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: 10, textAlign: "center" }}
                >
                  {hotelInfo.hotelDetails.hotelFacilities &&
                    hotelInfo.hotelDetails.hotelFacilities.length == 1 &&
                    hotelInfo.hotelDetails.hotelFacilities[0]
                      ?.split(",")
                      .slice(0, 4)
                      .map((value, index) => {
                        // console.log(value)
                        // console.log(index)
                        return (
                          <Grid item container xs={3} key={index}>
                            <Grid
                              item
                              xs={12}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Icon>
                                {getFacilityIcon(
                                  // hotelInfo.hotelDetails.hotelFacilities[index]
                                  value
                                )}
                              </Icon>
                            </Grid>
                            {!isMobile && (
                              <Grid
                                item
                                xs={12}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p className={classes.typo2}>
                                  {getFacilityText(value) === "NA"
                                    ? value
                                    : getFacilityText(value)}
                                </p>
                                {/* toTitleCase(getFacilityIcon(facilities[index]).replace('_', ' ')) */}
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{ marginTop: 10, textAlign: "center" }}
                >
                  {hotelInfo.hotelDetails.hotelFacilities &&
                    [
                      ...Array(
                        hotelInfo.hotelDetails.hotelFacilities.length > 4
                          ? 4
                          : hotelInfo.hotelDetails.hotelFacilities.length
                      ),
                    ].map((value, index) => {
                      return (
                        <Grid item container xs={3} key={index}>
                          {/* <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Icon>
                              {getFacilityIcon(
                                hotelInfo.hotelDetails.hotelFacilities[index]
                                
                              )}
                            </Icon>
                          </Grid> */}
                          {/* {!isMobile &&  <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <p className={classes.typo2}>
                              {  getFacilityText(
                                  hotelInfo.hotelDetails.hotelFacilities[index]
                              ) === "NA"
                                ?   hotelInfo.hotelDetails.hotelFacilities[index]
                                : getFacilityText(
                                  hotelInfo.hotelDetails.hotelFacilities[index]
                                  )}
                            </p>
                          </Grid> } */}
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item md={4} xs={12} spacing={isMobile ? 1 : 0}>
              {hotelInfo.hotelDetails.images &&
                [
                  ...Array(
                    hotelInfo.hotelDetails.images.length > 24
                      ? isMobile
                        ? 12
                        : 24
                      : hotelInfo.hotelDetails.images.length - 1
                  ),
                ].map((x, i) => (
                  <Grid item md={2} key={i}>
                    <img
                      src={hotelInfo.hotelDetails.images[i]}
                      width="50"
                      height="50"
                      onClick={() => {
                        setCurrentIndex(`${i}`);
                        setVisible(true);
                      }}
                    />

                    {hotelInfo.hotelDetails.images.length > 24 && i === 23 && (
                      <div
                        style={{
                          marginTop: -35,
                          marginLeft: 10,
                          color: "white",
                          fontWeight: "500",
                        }}
                      >
                        {hotelInfo.hotelDetails.images.length - 24} +
                      </div>
                    )}
                    {/* <p className="legend">{images[i].alt}</p> */}
                  </Grid>
                ))}
            </Grid>
            <Grid item md={4} xs={12}>
              {!isMobile && (
                <Grid item xs={12}>
                  {" "}
                  <HotelLeafletMap hotels={[hotelInfo.hotelDetails]} />
                </Grid>
              )}

              <Grid container>
                <Grid item md={6}>
                  <Typography className={classes.typo2}>Recommended</Typography>
                </Grid>
                <Grid item md={6}>
                  <Typography className={classes.typo3}>
                    Original Price:{" "}
                    {hotel.price.currencyCode === "INR"
                      ? "₹"
                      : hotel.price.currencyCode}{" "}
                    {hotelPriceRoundOff
                      ? hotel.price.commissionCharge.grossProductPrice.toFixed(
                        0
                      )
                      : hotel.price.commissionCharge.grossProductPrice.toLocaleString('en-IN')
                      //hotel.price.commissionCharge.grossProductPrice.toFixed(2)
                    }
                    {/* {hotel.price.offeredPriceRoundedOff.toFixed(2)} */}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={6} xs={12}>
                  <Typography className={classes.typo4}>
                    Per Night for 1 Room -{" "}
                    {hotelPriceRoundOff
                      ? (
                        hotel.price.commissionCharge.grossProductPrice /
                        hotelSearch.noOfNights
                      ).toFixed(0)
                      : (
                        hotel.price.commissionCharge.grossProductPrice /
                        hotelSearch.noOfNights
                      ).toLocaleString('en-IN')
                      // (
                      //   hotel.price.commissionCharge.grossProductPrice /
                      //   hotelSearch.noOfNights
                      // ).toFixed(2)
                    }
                  </Typography>
                  <Typography className={classes.typo4}>
                    For{" "}
                    {hotelSearch.roomGuests.reduce((total, guest) => {
                      return total + parseInt(guest.noOfAdults);
                    }, 0)}{" "}
                    Adult &{" "}
                    {hotelSearch.roomGuests.reduce((total, guest) => {
                      return total + parseInt(guest.noOfChild);
                    }, 0)}{" "}
                    child
                  </Typography>
                </Grid>
                <Grid item md={6} xs={6}>
                  <Typography className={classes.typo5}>
                    {" "}
                    {hotel.price.currencyCode === "INR"
                      ? "₹"
                      : hotel.price.currencyCode}
                    {hotelPriceRoundOff
                      ? hotel.price.commissionCharge.grossProductPrice.toFixed(
                        0
                      )
                      : hotel.price.commissionCharge.grossProductPrice.toLocaleString('en-IN')
                      //  hotel.price.commissionCharge.grossProductPrice.toFixed(2)
                    }
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  justifyContent={isMobile ? "flex-end" : "flex-start"}
                >
                  <Offer discount={hotel.price.discount} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardBody>
        <div>
          <Viewer
            onMaskClick={(e) => {
              setVisible(false);
            }}
            activeIndex={currentIndex}
            visible={visible}
            onClose={() => {
              setVisible(false);
            }}
            images={images}
            noClose={false}
          />
        </div>
      </Card>
    </Grid>
  );
};
export default HotelInfo;
