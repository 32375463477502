import React, { useEffect, useRef, useState } from 'react';
// import './Autocomplete.css';
import WebApi from 'api/ApiConstants';
import styled from "styled-components";
import cross from "../../../assets/icons/cross.png"
import { apiCall } from "oautils/oaDataUtils";

const BusLocationInput = ({ props, type, BusCities, setFieldValue }) => {

  const [inputValue, setInputValue] = useState();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [busCityList, setBusCityList] = React.useState();
  const [isOpen, setIsOpen] = useState(false);
  const [showClear, setShowClear] = useState(false)
  const autocompleteRef = useRef(null);

//   console.log(props)

//   useEffect(() => {
//     setInputValue(props?.BusCities?.cityName || "")
//   }, [])

useEffect(() => {
let value = type === "Origin" ? {
    "CityId": 8463,
    "CityName": "Bangalore"
} : {
  "CityId": 9573,
  "CityName": "Hyderabad"
}
  if(props.value){
    console.log(props.value)
    props.setField(props.value)
    setInputValue(props.value.CityName)
  } else {
    setFieldValue(`${type}Id`, value);
      setInputValue(value.CityName)
  }

},[])

  useEffect(() => {
      console.log("first")
        apiCall(WebApi.getBusCity, {}, (response) => {
            // console.log(response.data.BusCities.splice(0, 100))
            setBusCityList(response.data.BusCities.splice(0, 20));
        //   if (response.success === true) {
          
        //     // console.log(response.data.BusCities,"BusCities");
        //     // setIsLoading(false);
        //   } else {
        //     // setIsError(response.success);
        //     // setInfoText(response.message);
        //   }
        });
    
  }, []);

// 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const value = event.target.value;
    fetchMatchingCities(event.target.value.trim())
    // return
    setIsOpen(true)
    setInputValue(value);
    // console.log(busCityList)
    // return

    const filtered = busCityList.filter(option =>
      option.CityName.toLowerCase().includes(value.toLowerCase())
    );

    console.log(filtered)
    setFilteredOptions(filtered);
  };

//   const fetchMatchingCities = (searchType, searchParam, countryCode) => {
//     // console.log("hotel city search");

//     if (searchParam.length < 3) {
//       return;
//     }

//     // setCallingApi(true);
//     WebApi.getMatchingHotelCity(
//       {
//         searchType: searchType,
//         countryCode: countryCode || "IN",
//         hotelCity: searchParam,
//       },
//       (response) => {
//         if (response.success === true) {
//           // console.log("hotel city search", searchParam);
//           // console.log("matching cities response", response);
//           setHotelCityList(response.data.destinations)
//           props.setHotelCity(response.data.destinations);
//         } else {
//         //   setIsError(!response.success);
//         //   setInfoText(response.message);
//         }
//         // setCallingApi(false);
//       },
//       (error) => {
//         // setCallingApi(false);
//         console.log("getMatchingHotelCity error", error);
//       }
//     );
//   };

  const fetchMatchingCities = (searchParams) => {
    if (searchParams.length >= 3) {
      apiCall(
        WebApi.getMatchingBusCity,
        {
          cityId: "0",
          cityName: searchParams,
        },
        (response) => {
          if (response.success === true) {
            setBusCityList(response.data.BusCities);
            console.log(response.data.BusCities)
            // console.log("props", props);
            // console.log(response.data.BusCities);
            // setIsLoading(false);
          } else {
            // setIsError(response.success);
            // setInfoText(response.message);
          }
        }
      );
    }
  };

  const handleSelectOption = (value) => {
    // console.log(`${type}Id`)
    if(setFieldValue){
      setFieldValue(`${type}Id`, value);
    } else {
      props.setField(value)
    }
    setInputValue(value.CityName)
    setFilteredOptions([]);
  };

  const clearInputHandler = (value) => {
    setInputValue("") 
    autocompleteRef.current.focus();
  };

  return (
    <Root ref={autocompleteRef} className="bus-location-input-container"   onMouseEnter={() => setShowClear(true)}
    onMouseLeave={() => setShowClear(false)}>
      <input
        type="text"
        value={inputValue}
        className='bus-location-input'
        onChange={handleInputChange}
        placeholder="Search."
      />
      {showClear && <img src={cross} className='clear-icon' onClick={() => clearInputHandler()} />}
      {isOpen && filteredOptions.length > 0 &&  <ul      className='bus-location-lists'>
        {filteredOptions.map((option, index) => (
          <li  className='bus-location-list' key={index} onClick={() => handleSelectOption(option)}>
            
            <div>
              <div className='bus-input-popup'>
                <div>{option.CityName}</div>
                
              </div>
              
            </div>
          </li>
        ))}
      </ul>}
     
    </Root>
  );
};

export default BusLocationInput;

const Root = styled.div`
position: relative;
font-family: poppins;
.bus-location-input {
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 0px;
  font-size: 17px;
  font-family: poppins;
  color: #838383;
  font-weight: 500;
  width: 100%;
  position: relative;
}
.clear-icon{
  // display: none;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  top: 12px;
  opacity: 50%;
  cursor: pointer;

}
.bus-location-lists {
  position: absolute;
  width: 100%;
  background-color: #fff;
  margin: 0px;
  list-style: none;
  z-index:10;
  padding: 0px;
  overflow-y: scroll;
  max-height: 250px;
}
.bus-location-list {
  font-family: "poppins";
  padding: 10px;
  font-size: 14px;
  font-family: "poppins";
  color: #838383;
  font-weight: 500;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
.bus-input-popup {
  display: flex;
  justify-content: space-between;
}
.airport-input-popup {
font-size: 12px;
overflow: hidden;
width: 100%;
white-space: nowrap;
text-overflow: ellipsis;
}

  
`;
