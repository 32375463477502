import React, { useEffect, useState, useContext } from "react";
//import BusHeader from "components/BusResult/BusHeader";
//import BusResult from "components/BusResult/BusResult";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
// import { purple } from '@material-ui/core/colors';
// import { GiFlexibleStar } from 'react-icons/gi';
//import Collapse from "@material-ui/core/Collapse";
import BusSearch from "./BusSearch";
import Grid from "@material-ui/core/Grid";

import BusResultShow from "components/BusResult/BusResultShow";
import { useSelector } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import closeBtn from "assets/icons/closeButton.png";
import { useMediaQuery, Typography, IconButton } from "@material-ui/core";
import BusResultFilter from "components/BusResult/BusResultFilter";
import { Close, ErrorOutlineRounded } from "@material-ui/icons";
import BusSearchSort from "./BusSearchSort";

const BusSearchResult = (props) => {
  const [isSNF, setIsSNF] = useState(false);
  const [modifyOnClick, setModifyOnClick] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const classes = useStyles();

  const userTypeInfo = useSelector((state) => state.usertype);
  const handleChange = () => {
    setIsSNF(!isSNF);
  };

  const [busType, setBusType] = useState([]);
  const [price, setPrice] = useState([0, 100]);
  const [timing, setTiming] = useState([]);
  const [sortBy, setSortBy] = useState([]);
  const [maxPrice, setMaxPrice] = useState(1);
  const [filterShow, setFilterShow] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState(
    props.location.state.BusSearchResult.BusResults
  );

  // useEffect(() => {
  //   // data.sort((a,b) => (a.ResultIndex > b.ResultIndex) ? 1 : ((b.ResultIndex > a.ResultIndex) ? -1 : 0));
  //   // busType={busType}
  //   //                 price={price}
  //   //                 timing={timing}
  //   //                 sortBy={sortBy}
  // }, [busType, price, timing, sortBy]);

  const handleBusTypeToggle = (value) => () => {
    const currentIndex = busType.indexOf(value);
    const newChecked = [...busType];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setBusType(newChecked);
  };

  const handleTimingToggle = (value) => () => {
    const currentIndex = timing.indexOf(value);
    const newChecked = [...timing];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setTiming(newChecked);
  };

  const handleSortByToggle = (value) => () => {
    const currentIndex = sortBy.indexOf(value);
    const newChecked = [...sortBy];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSortBy(newChecked);
  };

  const handlePriceChange = (value) => {
    setPrice(value);
  };
  const filterByBusType = (data) => {
    const filtered = data.filter((bus) => {
      return busType && busType.length > 0
        ? busType.indexOf(bus.BusType) !== -1 ||
            busType.some((ddValue) => {
              if (
                ddValue === "A/C" ||
                ddValue === "Non A/C" ||
                ddValue === "Seater" ||
                ddValue === "Sleeper"
              ) {
                return ddValue === "A/C"
                  ? bus.BusType.includes("A/C") &&
                      !bus.BusType.includes("Non A/C")
                  : bus.BusType.includes(ddValue);
              } else {
                return false;
              }
            })
        : true;
    });
    return filtered;
  };
  const filterByTime = (data) => {
    return data.filter((bus) =>
      timing && timing.length > 0 ? timing.indexOf(bus.timing) !== -1 : true
    );
  };
  const filterByPrice = (data) => {
    const test = data.filter(
      (bus) =>
        (price[0] * maxPrice) / 100 <=
          bus.BusPrice.CommissionCharge.grossProductPrice &&
        (price[1] * maxPrice) / 100 >=
          bus.BusPrice.CommissionCharge.grossProductPrice
    );

    return test;
  };

  const handleFilter = (data) => {
    let filteredData = data;
    if (busType.length > 0) {
      filteredData = filterByBusType(data);
    }
    if (timing.length < 0) {
      filteredData = filterByTime(filteredData);
    }
    if (maxPrice !== 1) {
      filteredData = filterByPrice(filteredData);
    }

    return filteredData;
  };

  const isResults =
    props.location.state.BusSearchResult.BusResults &&
    props.location.state.BusSearchResult.BusResults.length > 0;

  useEffect(() => {
    if (isResults) {
      const filtered = handleFilter(
        props.location.state.BusSearchResult.BusResults
      );

      setData(filtered);
    }
  }, [busType, timing, price]);

  const width = window.innerWidth;
  const breakpoint = 760;

  const NoResultView = () => {
    return (
      <Grid container direction="row" className={classes.noResultContainer}>
        <Grid item className={classes.noResults}>
          <ErrorOutlineRounded />
          <Typography>No Results found</Typography>
        </Grid>
      </Grid>
    );
  };

  return isResults ? (
    <Grid container className={classes.root}>
      <GridItem
        md={12}
        xs={12}
        align={"center"}
        className="snf-hnf-grid-item"
        style={{
          top:
            userTypeInfo.userType === "agent" ||
            userTypeInfo.userType === "direct_agent"
              ? 99
              : 75,
        }}
      >
        <Grid item md={3} xs={12} sm={12} className={classes.maingrid}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              className="filter-icon"
              onClick={() => setFilterShow(!filterShow)}
            >
              <i className="fas fa-filter"></i>
            </span>
            <span
              className="filter-icon"
              onClick={() => setShowModify(!showModify)}
            >
              <i className="fas fa-cog"></i>
            </span>
          </Grid>
        </Grid>

        {(!isMobile || showModify) && (
          <Grid className={isMobile && showModify && classes.modalContainer}>
            {showModify && (
              <div
                style={{
                  justifyContent: "flex-end",

                  display: "flex",
                  width: "100%",
                }}
              >
                <img
                  onClick={() => setShowModify(false)}
                  src={closeBtn}
                  style={{
                    height: 30,
                    width: 30,
                    alignSelf: "flex-end",
                    marginLeft: 20,
                    marginBottom: 15,
                    marginTop: 50,
                  }}
                />
              </div>
            )}
            <BusSearch
              {...props}
              busData={"yay"}
              modifyOnClick={() => {
                setModifyOnClick(!modifyOnClick);
              }}
            />
          </Grid>
        )}

        {/* <BusSearch /> */}
      </GridItem>

      <Grid container className={classes.resultBottom}>
        <Grid item md={3} xs={12} sm={12} className={classes.maingrid}>
          {/* <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <span
              className="filter-icon"
              onClick={() => setFilterShow(!filterShow)}
            >
              <i className="fas fa-filter"></i>
            </span>
            <span
              className="filter-icon"
              onClick={() => setShowModify(!showModify)}
            >
              <i className="fas fa-filter"></i>
            </span>
          </Grid> */}

          <div
            className={`BusResultFilter-compo ${
              filterShow ? "filterActive" : ""
            } `}
          >
            {isMobile ? (
              <>
                {/* <Drawer
                  open={filterShow}
                  onClose={() => setFilterShow(false)}
                  className="flight-filter-drawer-root"
                > */}
                <Grid
                  className="flight-filter-drawer-root"
                  style={{
                    display: !filterShow && "none",
                    position: "fixed",
                    height: "100vh",
                    top: 0,
                    left: 0,
                    zIndex: 100,
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    className="drawer-close-btn-holder"
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <IconButton
                      onClick={() => setFilterShow(false)}
                      className="drawer-close-button"
                    >
                      <Close />
                    </IconButton>
                  </div>
                  <BusResultFilter
                    icon={true}
                    data={data}
                    busType={busType}
                    price={price}
                    timing={timing}
                    sortBy={sortBy}
                    maxPrice={maxPrice}
                    handleBusTypeToggle={handleBusTypeToggle}
                    setPrice={handlePriceChange}
                    handleTimingToggle={handleTimingToggle}
                    handleSortByToggle={handleSortByToggle}
                    setMaxPrice={setMaxPrice}
                  />
                  <Grid
                    onClick={() => setFilterShow(false)}
                    style={{
                      position: "fixed",
                      bottom: 0,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className={classes.applyBtn}
                  >
                    APPLY
                  </Grid>
                </Grid>

                {/* </Drawer> */}
              </>
            ) : (
              <>
                <BusResultFilter
                  data={data}
                  busType={busType}
                  price={price}
                  timing={timing}
                  sortBy={sortBy}
                  maxPrice={maxPrice}
                  handleBusTypeToggle={handleBusTypeToggle}
                  setPrice={handlePriceChange}
                  handleTimingToggle={handleTimingToggle}
                  handleSortByToggle={handleSortByToggle}
                  setMaxPrice={setMaxPrice}
                />
              </>
            )}
          </div>
        </Grid>
        <Grid item md={9} xs={12} sm={12}>
          <GridContainer
            style={{ backgroundColor: "transparent", paddingBottom: 20 }}
          >
            {/* <GridItem md={12} xs={12}  align={'center'} 
                style={{position:'sticky', top:88, background:COLORS.PRIMARY_COLOR, zIndex:1000}}>
                <GridContainer>
                   
                </GridContainer>
            </GridItem> */}

            <GridItem
              md={12}
              xs={12}
              style={{ background: "transparent" }}
              className={classes.grid}
            >
              {/* <Collapse in={modifyOnClick} > */}
              {/* {modifyOnClick && (
                <BusSearch
                  style={{ paddingBottom: 10 }}
                  {...props}
                  busData={"yay"}
                />
              )} */}
              {/* </Collapse> */}
            </GridItem>
            <GridItem md={12} xs={12}>
              {/*data.map((value, index) => {
                return (
                  <div key={index}>
                    {" "}
                    {(busType && busType.length > 0
                      ? busType.indexOf(value.BusType) !== -1 ||
                        busType.some((ddValue) => {
                          if (
                            ddValue === "A/C" ||
                            ddValue === "Non A/C" ||
                            ddValue === "Seater" ||
                            ddValue === "Sleeper"
                          ) {
                            return ddValue === "A/C"
                              ? value.BusType.includes("A/C") &&
                                  !value.BusType.includes("Non A/C")
                              : value.BusType.includes(ddValue);
                          } else {
                            return false;
                          }
                        })
                      : true) &&
                      (timing && timing.length > 0
                        ? timing.indexOf(value.timing) !== -1
                        : true) &&
                      (price[0] * maxPrice) / 100 <=
                        value.BusPrice.CommissionCharge.grossProductPrice &&
                      (price[1] * maxPrice) / 100 >=
                        value.BusPrice.CommissionCharge.grossProductPrice && (
                        <BusResultShow
                          {...props}
                          ResultIndex={value.ResultIndex}
                          TravelName={value.TravelName}
                          BusType={value.BusType}
                          BoardingPointsDetails={value.BoardingPointsDetails}
                          DepartureTime={value.DepartureTime}
                          DroppingPointsDetails={value.DroppingPointsDetails}
                          ArrivalTime={value.ArrivalTime}
                          BusPrice={value.BusPrice}
                          duration={value.duration}
                          AvailableSeats={value.AvailableSeats}
                          CancellationPolicies={value.CancellationPolicies}
                          IdProofRequired={value.IdProofRequired}
                          IsDropPointMandatory={value.IsDropPointMandatory}
                          LiveTrackingAvailable={value.LiveTrackingAvailable}
                          MTicketEnabled={value.MTicketEnabled}
                          MaxSeatsPerTicket={value.MaxSeatsPerTicket}
                          PartialCancellationAllowed={
                            value.PartialCancellationAllowed
                          }
                          durationInMinute={value.durationInMinute}
                          timing={value.timing}
                          origin={props.location.state.origin}
                          destination={props.location.state.destination}
                          // params for sorting and filtering
                          isSNF={isSNF}
                          TraceId={props.location.state.BusSearchResult.TraceId}
                          RouteId={value.RouteId}
                          searchParams={props.location.state.searchParams}
                        />
                      )}
                  </div>
                );
              })*/}
              {(userTypeInfo.userType === "agent" ||
                userTypeInfo.userType === "direct_agent") && (
                <div
                  className="snf-hnf-comp"
                  style={{
                    // background: theme.palette.primary.LightGraySec,

                    borderRadius: "10px",
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: theme.palette.primary.LightGraySec,
                      padding: "0px 20px",
                      borderRadius: "10px",
                      marginBottom: 5,
                      display: "flex",
                      // justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: theme.palette.primary.defaultText }}>
                      HNF
                    </span>
                    <PurpleSwitch
                      style={{
                        color: theme.palette.primary.green,
                      }}
                      checked={isSNF}
                      onChange={handleChange}
                      name="isSNF"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <span style={{ color: theme.palette.primary.defaultText }}>
                      SNF
                    </span>
                  </div>
                </div>
              )}
              {isMobile ? "" : <BusSearchSort data={data} setData={setData} />}

              {data.length > 0 ? (
                data.map((value) => (
                  <BusResultShow
                    {...props}
                    ResultIndex={value.ResultIndex}
                    TravelName={value.TravelName}
                    BusType={value.BusType}
                    BoardingPointsDetails={value.BoardingPointsDetails}
                    DepartureTime={value.DepartureTime}
                    DroppingPointsDetails={value.DroppingPointsDetails}
                    ArrivalTime={value.ArrivalTime}
                    BusPrice={value.BusPrice}
                    duration={value.duration}
                    AvailableSeats={value.AvailableSeats}
                    CancellationPolicies={value.CancellationPolicies}
                    IdProofRequired={value.IdProofRequired}
                    IsDropPointMandatory={value.IsDropPointMandatory}
                    LiveTrackingAvailable={value.LiveTrackingAvailable}
                    MTicketEnabled={value.MTicketEnabled}
                    MaxSeatsPerTicket={value.MaxSeatsPerTicket}
                    PartialCancellationAllowed={
                      value.PartialCancellationAllowed
                    }
                    durationInMinute={value.durationInMinute}
                    timing={value.timing}
                    origin={props.location.state.origin}
                    destination={props.location.state.destination}
                    // params for sorting and filtering
                    isSNF={isSNF}
                    TraceId={props.location.state.BusSearchResult.TraceId}
                    RouteId={value.RouteId}
                    searchParams={props.location.state.searchParams}
                  />
                ))
              ) : (
                <NoResultView />
              )}
            </GridItem>
          </GridContainer>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid container className={classes.root}>
      <BusSearch
        {...props}
        busData={"yay"}
        modifyOnClick={() => {
          setModifyOnClick(!modifyOnClick);
        }}
      />
      <NoResultView />
    </Grid>
  );
};

export default BusSearchResult;

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.green,
    "&$checked": {
      color: theme.palette.primary.contrastText,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.background.secondary,
    },
  },
  "&$checked + $track": {
    backgroundColor: "#ef6614",
  },
  track: {
    backgroundColor: theme.palette.primary.contrastText,
  },
}))(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.light,
    paddingTop: "10px",
    "& .snf-hnf-grid-item": {
      // position: "sticky",
      // top: 99,
      // backgroundColor: theme.palette.background.tertiary,
      // background: theme.palette.primary.main,
      background: theme.palette.background.light,
      [theme.breakpoints.down(960)]: {
        border: "1px solid",
        borderRadius: "10px",
      },
      // zIndex: 10,
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
      "& .snf-hnf-comp": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: `100%`,
        marginRight: 15,
        padding: "0 20px",
      },
      [theme.breakpoints.down(500)]: {
        border: "solid 0.5px #bcbcbc",
      },
    },
    "& .flight-filter-drawer-root": {
      // "& .MuiPaper-root":{},
      "& .MuiBackdrop-root": {
        backgroundColor: theme.palette.background.light,
      },
      "& .MuiPaper-root": {
        [theme.breakpoints.down(963)]: {
          top: "133px !imported",
          padding: 16,
        },
      },
      "& .busFilterRoot": {
        padding: 16,
      },
    },
  },
  grid: {
    marginLeft: "15px",
    marginRight: "15px",
  },
  noResultContainer: {
    flexWrap: "nowrap",
    width: "100%",

    minHeight: "calc(80vh - 100px)",
    justifyContent: "center",
    alignItems: "center",
  },
  noResults: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  maingrid: {
    marginTop: "10px",
    "& .filter-icon": {
      cursor: "pointer",
      display: "none",
      [theme.breakpoints.down(790)]: {
        display: "block",
      },
      marginBottom: "10px",
      "& i": {
        fontSize: 30,
        color: theme.palette.secondary.main,
      },
    },
    "& .BusResultFilter-compo": {
      [theme.breakpoints.down(993)]: {
        display: "none",
      },
      "&.filterActive": {
        display: "block",
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
      paddingLeft: "11px !important",
      paddingRight: "11px !important",
    },
  },
  resultBottom: {
    // padding: '0 6rem',
    marginTop: 10,
    [theme.breakpoints.down(950)]: {
      padding: "0",
    },
  },
  modalContainer: {
    position: "fixed",
    zIndex: 10,
    backgroundColor: theme.palette.background.default,
    height: "100vh",
    width: "100%",
    top: 0,
    left: 0,
    padding: 10,
    overflowY: "scroll",
  },
  applyBtn: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontWeight: 700,
    height: 50,
    alignItems: "center",
    left: 0,
  },
}));
