import React, { useState } from "react";
import OaFormTextField from "oahoc/OaFormTextField";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";

import { Formik, Form, ErrorMessage } from "formik";

import { Link } from "react-router-dom";
import GridItem from "components/Grid/GridItem";
import * as Yup from "yup";

import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";

import FormControl from "@material-ui/core/FormControl";
import { withRouter } from "react-router";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({

  root: {
    marginBottom: 15,
    background: theme.palette.background.default,
    [theme.breakpoints.down(960)]: {
      minWidth: "100%",
    },
    "& .dmt-form-container": {
      padding: "0 10vw",
      [theme.breakpoints.down(440)]: {
        padding: 0,
      },
      "& .button-landing-page": {
        width: 80,
        height: 30,
        backgroundColor: theme.palette.primary.main,
        "& .MuiButton-label": {
          fontSize: 14,
          fontWeight: 500,
          color: theme.palette.primary.contrastText,
        },
      },
      "& .oa-input-white:focus-within": {
        backgroundColor:theme.palette.primary.lightText,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: 0,
        borderBottom: `solid 0.5px ${theme.palette.primary.defaultText}`,
        borderRadius: 0,
      },
      "& .MuiInputBase-root": {
        "& .MuiInputBase-input": {
          paddingBottom: 6,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderRadius: 0,
          borderBottom: `solid 2px ${theme.palette.primary.defaultText}`,
        },
      },
      "& .dmt-form-item-left": {
        flexGrow: 1,
        marginRight: 15,
      },
    },
  },

}));

const MobileSearchForm = (props) => {
  const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const classes = useStyles();

  const checkServiceConfig = (searchParams, setSubmitting, resetForm, enableReinitialize) => {
    apiCall(WebApi.getServiceConfig, {
      name: "moneytransfer"
    }, response => {

      let serviceActive = true;

      if (response.success === true) {
        if (response.data.isDisabled) {
          const resData = response.data
          serviceActive = false;
          setInfoText(resData.disableMessage);
          setIsError(true);
          setSubmitting(false);
        }
      }

      if (serviceActive === true) {
        submitSearch(searchParams, setSubmitting, resetForm, enableReinitialize);
      }
    });

  }

  const submitSearch = (searchParams, setSubmitting, resetForm, enableReinitialize) => {

    apiCall(WebApi.getDMTSender, searchParams, (response) => {
      setIsError(!response.success);
      setIsSuccess(response.success);
      setSubmitting(false);
      setIsSaving(false);

      setInfoText(response.message);
      setAlertTitle("Error searching");

      // console.log(response);
      // console.log(response.isLocalCopyExists);

      if (response.data) {
        setIsError(!response.data.status);
        setIsSuccess(response.data.status);
        setInfoText(response.data.message);

        // console.log(response.data.stateresp);
        // console.log(response);
        // console.log(response.isLocalCopyExists);

        if (response.data.isLocalCopyExists == "no") {
          props.history.push("/moneytransfer/add-sender", {
            mobile: searchParams.mobile,
            stateresp: "FOR_LOCAL_SAVE",
            data: response.data.data,
          });
        } else if (response.data.status === true) {
          setAlertTitle("Searched successfully");
          props.history.push("/moneytransfer/beneficiary-list", {
            data: response.data.data,
            mobile: searchParams.mobile
          });
        } else {
          // console.log(111);
          props.history.push("/moneytransfer/add-sender", {
            mobile: searchParams.mobile,
            stateresp: response.data.stateresp
          });
        }
      }
    });

  }

  return (
    <div className={classes.root}>
      <GridContainer className="dmt-form-label-container">
        <GridItem md={9} className="dmt-form-label-main">
          <h4
            className={"oa-form-header"}
            style={{ transform: " scale(0.85)", transformOrigin: "left" }}
          >
            Search Sender
          </h4>
        </GridItem>

        <GridItem md={3}></GridItem>
      </GridContainer>

      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
        alertTitle={alertTitle}
      />

      <Formik
        initialValues={{
          mobile: "",
        }}
        validationSchema={Yup.object().shape({
          mobile: Yup.string()
            .required("Mobile Number is required")
            .matches(phoneRegex, "Invalid mobile number"),
        })}
        onSubmit={(
          searchParams,
          { setSubmitting, resetForm, enableReinitialize }
        ) => {
          setSubmitting(true);
          setIsSaving(true);
          checkServiceConfig(searchParams, setSubmitting, resetForm, enableReinitialize);
        }}
      >
        {({
          values,
          isSubmitting,
          setFieldValue,
          enableReinitialize,
          resetForm,
        }) => (
          <Form>
            <Grid container className="dmt-form-container">
              <Grid item className="dmt-form-item-left">
                <OaFormTextField
                  className="input-landing-page"
                  name="mobile"
                  placeholder="Enter sender's 10 digit mobile number"
                />
                <ErrorMessage name="mobile" component="div" className="error" />
              </Grid>

              <Grid item className="dmt-form-item-right">
                <FormControl>
                  <Button
                    className="button-landing-page"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ width: 80, height: 30 }}
                  >
                    Search
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withRouter(MobileSearchForm);
