import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Button, MenuItem, Select, TextField, Dialog, useTheme } from "@material-ui/core";
import { Form, Formik } from "formik";
import WebApi from "api/ApiConstants";

const AadhaarOnboardingStatus = props => {
    const theme = useTheme();
    const formikRef = React.useRef(null);
    const [onboardingStatus, setOnboardingStatus] = React.useState({status: false, message: ""});

    const initialValues = {
        merchantcode: props?.merchantcode,
        mobile: "",
        pipe: "bank1"
    };

    const validationSchema = Yup.object().shape({
        mobile: Yup.string().required("Mobile number cannot be blank"),
    });

    const onChangeMobile = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobile", e.target.value);
        }
    };

    const onChangePipe = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("pipe", e.target.value);
        }
    };

    const onSubmitStatusCheck = (searchParams, { setSubmitting }) => {
        // console.log("searchParams", searchParams);
        setSubmitting(true);
        WebApi.aepsMerchantOnboardingStatus(searchParams, response => {
            // console.log("response", response);
            setOnboardingStatus({
                status: response.success,
                message: response.data?.is_approved ? response.message+", Approval: "+response.data?.is_approved : response.message
            });
            setSubmitting(false);
        }, error => {
            console.error("error getting onboard status", error);
            setSubmitting(false);
        });
    };

    return (
        <Dialog open={props.openstate} onClose={props.closestate}>
            <RootContainer theme={theme}>
                <div className="form-title-wrapper">
                    <p className="form-title">Onboarding Status</p>
                    <p className="status-response" style={{color: onboardingStatus.status ? theme.palette.primary.success : theme.palette.primary.danger}}>
                        {onboardingStatus?.message}
                    </p>
                </div>
                <div className="form-container">
                    <Formik
                        innerRef={formikRef}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmitStatusCheck}
                    >
                        {({ values, isSubmitting, setFieldValue, errors }) => (
                            <Form>
                                <div className="form-wrapper">
                                    <div className="form-row">
                                        <div className="form-element-wrap">
                                            <label>Mobile</label>
                                            <TextField
                                                value={values.mobile}
                                                onChange={onChangeMobile}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-element-wrap">
                                            <label>Pipe</label>
                                            <Select
                                                value={values.pipe}
                                                onChange={onChangePipe}
                                                variant="outlined"
                                                fullWidth
                                            >
                                                <MenuItem value={"bank1"}>Default</MenuItem>
                                                <MenuItem value={"bank2"}>Bank 2</MenuItem>
                                                <MenuItem value={"bank3"}>Bank 3</MenuItem>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="form-row submit-row">
                                        <div className="form-element-wrap">
                                            <Button type="submit">
                                                Check Status
                                            </Button>
                                        </div>
                                        <div className="form-element-wrap">
                                            <span className="close-button"onClick={props.closestate}>
                                                close
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </RootContainer>
        </Dialog>
    );
};

export default AadhaarOnboardingStatus;

const RootContainer = styled.div`
.form-container {
    padding: 0 20px 5px;
}
.form-title-wrapper {
    padding: 20px 20px 0;
    p {
        text-align: center;
    }
    .form-title {
        font-weight: 500;
        font-size: 1.2em;
    }
    .status-response {
        padding-top: 5px;
    }
}
.form-row {
    margin: 10px 0;
    &.submit-row {
        margin-top: 20px;
        & .form-element-wrap {
            text-align: center;
        }
    }
}
.form-element-wrap {
    & button {
        background: ${prop => prop.theme.palette.buttons.primary};
        color: ${prop => prop.theme.palette.buttons.primaryContrastText};
        font-size: 12px;
        padding: 6px 15px;
    }
}
.close-button {
    display: block;
    cursor: pointer;
    text-align: center;
    margin: 10px 0 0;
}
`;