import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { TextField, useTheme, Button } from "@material-ui/core";
import { ErrorMessage, Form, Formik } from "formik";
import WebApi from "api/ApiConstants";
import { AppConstant } from "appConstant";

const AadhaarTwoFactorAuth = props => {
    const theme = useTheme();
    const formikRef = React.useRef(null);
    const [twoFaType, setTwoFaType] = React.useState("merchant_authenticity_2fa_bank2");
    const [agentDetails, setAgentDetails] = React.useState(null);

    const initialValues = {
        adhaarnumber: "",
        mobilenumber: "",
        type: twoFaType
    };

    const validationSchema = Yup.object().shape({
        mobilenumber: Yup.string().required("Mobile number cannot be blank"),
        adhaarnumber: Yup.string().required("Aadhaar number cannot be blank"),
    });

    const onChangeMobileNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobilenumber", e.target.value);
        }
    };

    const onChangeAadhaarNo = (e) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("adhaarnumber", e.target.value);
        }
    };

    const onSubmitTwoFa = (searchParams, { setSubmitting }) => {
        
        if (twoFaType) {
            searchParams.accessmodetype = "SITE";
            searchParams.submerchantid = props.trnParams?.merchant_code;
            searchParams.data = props.data;
            searchParams.latitude = props.trnParams?.latitude;
            searchParams.longitude = props.trnParams?.longitude;
            searchParams.remarks = props.trnParams?.requestremarks;
            searchParams.type = twoFaType;
            
            setSubmitting(true);
            props.setIsLoading(true);
    
            WebApi.aepsTwoFactorAuthentication(searchParams, response => {
                if (response.success) {
                    // console.log(response.data);
    
                    props.setInfoText(response.message);
                    props.setSeverity({
                        ...props.severity,
                        issuccess: true
                    });
                    
                    props.setTfaRequired(false);
                    props.setReInitFlag(true);
                    props.setMerAuthTxnId(response.data.merAuthTxnId);
                    props.setProceedToTransact(true);
                } else {
                    props.setInfoText(response.message);
                    props.setFingerPrintCaptured(false);
                    props.setSeverity({
                        ...props.severity,
                        iserror: true
                    });
                }
                props.setIsLoading(false);
            }, error => {
                console.error("aadhaarPay2Factor Auth error", error) 
                props.setIsLoading(false);
                props.setFingerPrintCaptured(false);
            });
            setSubmitting(false);
        }
    };

    const getTwoFaRegDetails = () => {
        WebApi.getAepsTwoFactorRegistrationDetails({
            merchantCode: props.trnParams?.merchant_code,
            regType: AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1" ? "registration_bank2" : "registration_bank3"
        }, response => {
            if(response.success) {
                // console.log("Response twofa", response);
                setTwoFaType(AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1" ? "merchant_authenticity_2fa_bank2" : "merchant_authenticity_2fa_bank3");
                setAgentDetails({
                    adhaarnumber: response.data.aadhaarNo,
                    mobilenumber: response.data.mobileNo
                });
                if(formikRef?.current) {
                    // formikRef.current.setFieldValue("adhaarnumber", response.data.aadhaarNo);
                    formikRef.current.setFieldValue("mobilenumber", response.data.mobileNo);
                }
                // setTwoFaRegStatus(response.data.twofa_reg_status);
            }
            props.setIsLoading(false);
        }, error => {
            console.error("getTwoFaRegDetails error", error);
            props.setIsLoading(false);
        });
    };

    React.useEffect(() => {
        getTwoFaRegDetails();
    }, []);

    return (
        <RootContainer theme={theme}>
            <div className="form-title-wrapper">
                <p className="form-title">Get Merchant Authenticity</p>
            </div>
            <div className="form-container">
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmitTwoFa}
                >
                    {({ values, isSubmitting, setFieldValue, errors }) => (
                        <Form>
                            <div className="form-wrapper">
                                <div className="form-row">
                                    <div className="form-element-wrap">
                                        <label>Aadhaar Number</label>
                                        <TextField
                                            value={values.adhaarnumber}
                                            onChange={onChangeAadhaarNo}
                                            // value={agentDetails?.adhaarnumber}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <ErrorMessage name="adhaarnumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap">
                                        <label>Mobile</label>
                                        <TextField
                                            value={values.mobilenumber}
                                            onChange={onChangeMobileNo}
                                            // value={agentDetails?.mobilenumber}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <ErrorMessage name="mobilenumber" component="div" className="error"/>
                                    </div>
                                    <div className="form-element-wrap submit-wrap">
                                        <Button type="submit">
                                            {(twoFaType === "merchant_authenticity_2fa_bank2" || twoFaType === "merchant_authenticity_2fa_bank3") 
                                            ? "Submit" : "Authenticate"}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </RootContainer>
    );
};

export default AadhaarTwoFactorAuth;

const RootContainer = styled.div`
min-height: 50vh;
padding-top: 50px;
.form-container {
    padding: 0 20px 5px;
}
.form-title-wrapper {
    padding: 20px 20px 0;
    p {
        text-align: center;
    }
    .form-title {
        font-weight: 500;
        font-size: 1.2em;
    }
    .status-response {
        padding-top: 5px;
    }
}
.form-row {
    margin: 10px 0;
    display: flex;
    gap: 20px;
}
.form-element-wrap {
    &.submit-wrap {
        padding-top: 25.5px;
    }
    button {
        background: ${prop => prop.theme.palette.buttons.primary};
        color: ${prop => prop.theme.palette.buttons.primaryContrastText};
        font-size: 12px;
        padding: 6px 15px;
        height: 40.5px;
    }
}
`;