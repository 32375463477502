import React from "react";
import OaSnackbar from "oahoc/OaSnackbar";
import Button from "components/CustomButtons/Button.js";
import WebApi from "api/ApiConstants";
import OaPopperAlert from "oahoc/OaPopperAlert";
import { useHistory } from "react-router-dom";
import { Box, Grid, makeStyles } from "@material-ui/core";
import MantraRdService from "./MantraRdService";
import OaLoading from "pages/components/OaLoading";
import AadhaarTwoFactorAuth from "./AadhaarTwoFactorAuth";
import { minHeight } from "@material-ui/system";

const AadhaarServicesTransact = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [infoText, setInfoText] = React.useState(null);
    const [pIDData, setPIDData] = React.useState("");
    const [latitude, setLatitude] = React.useState(0);
    const [longitude, setLongitude] = React.useState(0);
    const [tid, setTid] = React.useState(0);
    const [fingerPrintCaptured, setFingerPrintCaptured] = React.useState(false);
    const [custFingerPrintCaptured, setCustFingerPrintCaptured] = React.useState(false);
    const [avdmActive, setAvdmActive] = React.useState(false);
    const [tfaRequired, setTfaRequired] = React.useState(false);
    const [trnParams, setTrnParams] = React.useState(null);
    const [tfaAuthTrnComplete, setTfaAuthTrnComplete] = React.useState(false);
    const [proceedToTransact, setProceedToTransact] = React.useState(false);
    const [merAuthTxnId, setMerAuthTxnId] = React.useState(null);
    const [reInitFlag, setReInitFlag] = React.useState(false);

    const [severity, setSeverity] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const [severitySnackbar, setSeveritySnackbar] = React.useState({
        issuccess: false,
        iserror: false,
        iswarning: false,
        isinfo: false
    });

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            // alert("Geolocation is not supported by this browser.");
            setInfoText("Geolocation is not supported by this browser.");
            setSeverity({
                ...severity,
                iserror: true
            });
        }
    };

    const showPosition = position => {
        // setInfoText(
        //     "Latitude: " +
        //     position.coords.latitude +
        //     " Longitude: " +
        //     position.coords.longitude
        // );
        // setSeveritySnackbar({
        //     ...severitySnackbar,
        //     isinfo: true
        // });
        setLatitude(position.coords.longitude);
        setLongitude(position.coords.latitude);
    };

    const showError = error => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setInfoText("User denied the request for Geolocation.");
                break;
            case error.POSITION_UNAVAILABLE:
                setInfoText("Location information is unavailable.");
                break;
            case error.TIMEOUT:
                setInfoText("The request to get user location timed out.");
                break;
            case error.UNKNOWN_ERROR:
                setInfoText("An unknown error occurred.");
                break;
        }
        setSeverity({
            ...severity,
            iserror: true
        });
    };

    const goToInvoice = () => {
        tid && tid !== 0 && history.push(
            "/aadhaarpay/voucher/aadhaarpay-user/" + tid
        );
    };

    const submitData = () => {
        // if (!latitude && !longitude) {
        //     setInfoText("Please enable location and try again");
        //     setSeverity({
        //         ...severity,
        //         iserror: true
        //     });
        //     getLocation();
        //     return;
        // }

        if (!fingerPrintCaptured && !pIDData) {
            setInfoText("Please re-capture fingerprints and try again");
            setSeverity({
                ...severity,
                iserror: true
            });
            return;
        }
        setIsLoading(true);
        setTrnParams({
            ...props.location.state,
            data: pIDData,
            is_iris: false,
            transactiontype: "M",
            // latitude: latitude,
            // longitude: longitude
        });
        setTfaRequired(true);
    };

    const reInitDevice = () => {
        setIsLoading(true);
        setAvdmActive(false);
    };

    // React.useEffect(() => {
    //     getLocation();
    // }, []);

    React.useEffect(() => {
        if(reInitFlag) {
            reInitDevice();
        }
    }, [reInitFlag]);

    React.useEffect(() => {
        if(proceedToTransact && custFingerPrintCaptured && pIDData && merAuthTxnId) {
            transactCw();
            setReInitFlag(false);
        }
        if(fingerPrintCaptured && !custFingerPrintCaptured && !merAuthTxnId && !tfaAuthTrnComplete) {
            setTfaRequired(true);
        }
        if(fingerPrintCaptured && merAuthTxnId) {
            setTfaRequired(false);
        }
        // console.log("fingerPrintCaptured", fingerPrintCaptured)
        // console.log("merAuthTxnId", merAuthTxnId)
        // console.log("custFingerPrintCaptured", custFingerPrintCaptured)
        // console.log("proceedToTransact", proceedToTransact)
        // console.log("tfaAuthTrnComplete", tfaAuthTrnComplete)
        // console.log("tfaRequired", tfaRequired)
    }, [proceedToTransact, fingerPrintCaptured, pIDData, merAuthTxnId, custFingerPrintCaptured, tfaAuthTrnComplete]);

    const transactCw = () => {
        let params = {...props.location.state};
        params.merAuthTxnId = merAuthTxnId;
        params.data = pIDData;
        params.is_iris = 'No';
        // console.log("params", params);
        setIsLoading(true);
        WebApi.aadhaarPayTransact(params, response => {
            // console.log(response);
            if (response.success) {
                // console.log(response.data);
                setMerAuthTxnId(null);
                setTfaAuthTrnComplete(true);
                setInfoText(response.message);
                setSeverity({
                    ...severity,
                    issuccess: true
                });
                setTid(response.extraData);
                setFingerPrintCaptured(false);
                setCustFingerPrintCaptured(false);
                setTimeout(() => {
                    goToInvoice();
                }, 4000);
            } else {
                setInfoText(response.message);
                setFingerPrintCaptured(false);
                setSeverity({
                    ...severity,
                    iserror: true
                });
            }
            setTfaRequired(false);
            setIsLoading(false);
        }, error => { 
            console.error("aadhaarPayTransact error", error) 
            setIsLoading(false);
            setFingerPrintCaptured(false);
        });
    };

    return(
        <div className={classes.root}>
            <Grid container className="page-container">
                {tfaRequired && !tfaAuthTrnComplete && pIDData ?
                    <AadhaarTwoFactorAuth
                        trnParams={props.location.state}
                        data={pIDData}
                        setFingerPrintCaptured={setFingerPrintCaptured}
                        setInfoText={setInfoText}
                        severity={severity}
                        setSeverity={setSeverity}
                        setIsLoading={setIsLoading}
                        setReInitFlag={setReInitFlag}
                        goToInvoice={goToInvoice}
                        setTfaRequired={setTfaRequired}
                        setMerAuthTxnId={setMerAuthTxnId}
                        setProceedToTransact={setProceedToTransact}
                    /> :
                    <Grid item>
                        <Grid container className="section-container center-text">
                            <Box className="section-content flex-box flex-dir-col justify-content-center width-100p align-items-center">
                                { tfaAuthTrnComplete && tid > 0 && <p>Please click the button below for invoice</p>}
                                <Button onClick={goToInvoice}
                                    disabled={(!tid || tid === 0) && !tfaAuthTrnComplete}
                                >User Invoice</Button>
                            </Box>
                        </Grid>
                        <Grid container className="section-container center-text">
                            <MantraRdService
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                setInfoText={(val) => setInfoText(val)}
                                severity={severity}
                                setSeverity={setSeverity}
                                severitySnackbar={severitySnackbar}
                                setSeveritySnackbar={setSeveritySnackbar}
                                fingerPrintCaptured={fingerPrintCaptured}
                                setFingerPrintCaptured={setFingerPrintCaptured}
                                custFingerPrintCaptured={custFingerPrintCaptured}
                                setCustFingerPrintCaptured={setCustFingerPrintCaptured}
                                pIDData={pIDData}
                                setPIDData={setPIDData}
                                avdmActive={avdmActive}
                                setAvdmActive={setAvdmActive}
                                reInitDevice={reInitDevice}
                                merAuthTxnId={merAuthTxnId}
                                mode="transact"
                            />
                        </Grid>
                        <Grid container className="section-container">
                            <Box className="section-content flex-box justify-content-center flex-wrap width-100p">
                                <Button
                                    type="button"
                                    onClick={submitData}
                                    disabled={!fingerPrintCaptured || (merAuthTxnId && !tfaAuthTrnComplete)}
                                >
                                    {merAuthTxnId && custFingerPrintCaptured ? "Processing ..." : !merAuthTxnId ? "Authenticate" : "Withdraw"}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                }
            </Grid>
            {isLoading && <div className="progress-loader"><OaLoading loadertext="please wait..."/></div>}
            <OaPopperAlert
                alertmessage={infoText}
                severity={severity}
                setseverity={setSeverity}
            />
            <OaSnackbar
                alertmessage={infoText}
                severity={severitySnackbar}
                setseverity={setSeveritySnackbar}
                vertical="top"
                horizontal="left"
                autoHideDuration={4000}
            />
        </div>
    );
};

export default AadhaarServicesTransact;

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: "100%",
        minHeight: "100%",
        position: "relative",
        "& .page-container": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 10,
            minHeight: "50vh"
        },
        "& .section-container": {
            width: "100%",
            "& button": {
                background: theme.palette.buttons.secondary,
                color: theme.palette.buttons.secondaryContrastText,
                border: `solid 0.5px ${theme.palette.buttons.secondary}`,
                padding: "5px 10px",
                width: 150,
                fontSize: 10,
                "&:hover": {
                    background: theme.palette.buttons.secondaryContrastText,
                    color: theme.palette.buttons.secondary,
                }
            },
        },
        "& .section-content": {
            padding: "10px 20px",
            "& h5": {
                fontSize: "0.95rem",
                marginTop: 0
            }
        },
        "& .center-text": {
            textAlign: "center"
        },
        "& .flex-box": {
            display: "flex"
        },
        "& .align-items-center": {
            alignItems: "center"
        },
        "& .justify-content-center": {
            justifyContent: "center"
        },
        "& .width-100p": {
            width: "100%"
        },
        "& .flex-wrap": {
            flexWrap: "wrap"
        },
        "& .flex-dir-col": {
            flexDirection: "column"
        },
        "& .header-uninit, .image-uninit": {
            cursor: "pointer"
        },
        "& .header-uninit": {
            color: "blue"
        },
        "& .progress-loader": {
            position: "fixed",
            top: 65,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
            paddingTop: 300,
            "& .MuiCircularProgress-svg": {
                color: theme.palette.secondary.main
            },
            "& .loading-text": {
                color: theme.palette.secondary.main
            }
        }
    }
}));
