import styled from "styled-components";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import dateFnsFormat from 'date-fns/format';
import WebApi from "api/ApiConstants";
import OaLoading from 'pages/components/OaLoading';
import OaFormSelectField from 'oahoc/OaFormSelectField';
import OaFormDateField from 'oahoc/OaFormDateField';
import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import { apiCall } from 'oautils/oaDataUtils';

const FlightCancellationReportForm = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(true);
    const [flightBookingStatusList, setFlightBookingStatusList] = useState([]);

    const initialValues = {
        dateFromDisplay: props.searchParams.dateFromDisplay,
        dateToDisplay: props.searchParams.dateToDisplay,
        type: props.searchParams.type,
        agencyId: props.searchParams.agencyId,
        userType: props.searchParams.userType
    };

    const handleSubmit = (searchParams, { setSubmitting, resetForm }) => {
        searchParams.dateFrom = dateFnsFormat(searchParams.dateFromDisplay, 'dd-MM-yyyy');
        searchParams.dateTo = dateFnsFormat(searchParams.dateToDisplay, 'dd-MM-yyyy');
        props.target(searchParams);
    };

    useEffect(() => {
        if (isLoading && flightBookingStatusList.length === 0) {
            apiCall(WebApi.getFlightBookingStatusList, {}, (response) => {
                let data = [{ value: ' ', label: 'All' }];
                response.data.map(row => {
                    data.push({ value: row.key, label: row.description })
                });
                setFlightBookingStatusList(data);
                setIsLoading(false);
                setIsError(response.success === false);
            });
        }
    }, [isLoading]);

    return (
        <>
            {isLoading && <OaLoading />}
            {!isLoading &&
                <div className="form-main-container">
                    <h4 className={"oa-form-header"} style={{ transform: ' scale(0.85)', transformOrigin: 'left' }} >Search Flight Cancellation Report</h4>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ values, isSubmitting, setFieldValue, handleChange }) => (
                            <Form>
                                <GridContainer>
                                    <OaFormDateField
                                        value={values.dateFromDisplay}
                                        selected={values.dateFromDisplay}
                                        onChange={date => setFieldValue('dateFromDisplay', date)}
                                        label="From"
                                        name="dateFromDisplay"
                                        md={3}
                                    />
                                    <OaFormDateField
                                        value={values.dateToDisplay}
                                        selected={values.dateToDisplay}
                                        onChange={date => setFieldValue('dateToDisplay', date)}
                                        label="To"
                                        name="dateToDisplay"
                                        minDate={values.dateFromDisplay}
                                        md={3}
                                    />
                                    <OaFormSelectField
                                        label="Status"
                                        name="type"
                                        value={values.type}
                                        options={[
                                            { value: ' ', label: 'All' },
                                            { value: 'Pending', label: 'Pending' },
                                            { value: 'Reject', label: 'Reject' },
                                            { value: 'processed', label: 'Processed' },
                                            { value: 'refundPending', label: 'Refund Processed' },
                                            { value: 'refundProcessed', label: 'Redund Processed' }
                                        ]}
                                        md={3}
                                    />
                                    <Button color="primary" type="submit" style={{ marginTop: 10, height: 42 }}>Search</Button>
                                </GridContainer>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        </>
    );
};

export default FlightCancellationReportForm;
