import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { localforageGetItem } from "oautils/oaForageUtils";
import { refreshUserSession } from "oautils/oaAuthUtils";

import services from "assets/Gostree/images/home/services.svg";
// import CustomButton from "../../Button/CustomButton";
// import CustomModal from "components/Modals/CustomModal.jsx";
// import CustomTextField from "../../Inputs/CustomTextField";
import * as Yup from "yup";
import { useFormik } from "formik";
import WebApi from "../../../api/ApiConstants";
import { localforageSetItem, localforageClear } from "oautils/oaForageUtils";
import LoginSection from "./LoginSection";
import LoginForm from "./forms/LoginForm";
import { TextField } from "@material-ui/core";
// import { useNotifications } from "components/NotificationContext";

const Login = (props) => {
  //   const { addNotification, removeNotificationByType } = useNotifications();
  const isMounted = React.useRef(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const currentLink = window.location.href;
  const lastValue = currentLink.substring(currentLink.lastIndexOf("/") + 1);
  // console.log(lastValue);

  useEffect(() => {
    setCurrentType(lastValue === "" ? "agent" : lastValue);
  }, [lastValue]);

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentType, setCurrentType] = useState("agent");

  const validationSchema = Yup.object({
    // password: Yup.string().required("Current Password is required"),
    // email: Yup.string().required("New Password is required"),
    // newPasswordConfirmation: Yup.string()
    //   .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    //   .required("Confirm password is required"),
    // email: Yup.string()
    //   .email("Invalid email address")
    //   .required("Email is required"),
  });

  const initialParams = {
    password: "",
    email: "",
  };

  const formik = useFormik({
    initialValues: initialParams,
    validationSchema: validationSchema,
    onSubmit: (searchParams) => {
      formSubmitHandler(searchParams);
    },
  });

  const formSubmitHandler = (searchParams) => {
    // addNotification("Logging In...", "loading");
    if (currentType === "agent") {
      WebApi.postLogin(
        {
          username: searchParams.email,
          password: searchParams.password,
        },
        (response) => {
          if (response.success) {
            localforageSetItem("user-id", response.data.userId);
            localforageSetItem("access-key", response.accesskey);
            localforageSetItem("user-details", response.data, () => {
              // navigate("/aadhaarpay");
            });
            // addNotification("Logged in Succesfully!", "success");
            // navigate("/");
            window.location.reload();
          } else {
            // addNotification("Something went wrong", "error");
          }
          setIsLoading(false);
        },
        (error) => {
          console.error("getTwoFaRegDEtails error", error);
          // setIsLoading(false);
        }
      );
    } else {
      WebApi.postDSALogin(
        {
          username: searchParams.email,
          password: searchParams.password,
        },
        (response) => {
          console.log("first");
          if (response.success) {
            localforageSetItem("user-id", response.data.userId);
            localforageSetItem("access-key", response.accesskey);
            localforageSetItem("user-details", response.data, () => {
              // navigate("/aadhaarpay");
            });
            // addNotification("Logged in Succesfully!", "success");
            // navigate("/flights");
          } else {
            // addNotification("Something went wrong", "error");
          }
          setIsLoading(false);
        },
        (error) => {
          console.error("getTwoFaRegDEtails error", error);
          // setIsLoading(false);
        }
      );
    }
  };

  const getValuesFromLocalStorage = () => {
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        if (value.userTypeAbv !== "G" && value.userId != "0") {
          setIsLoggedIn(true);
        }
      }
    });
  };

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (isMounted.current) {
      getValuesFromLocalStorage();
    }
  }, [isMounted.current]);

  React.useEffect(() => {
    if (isLoggedIn) {
      // navigate("/flights");
    } else {
      //   refreshUserSession(dispatch, callbackRefreshToken);
    }
  }, [isLoggedIn]);

  const logoutHandler = () => {
    console.log("first");
    setShowForgotPassword(false);
  };

  const resendPasswordHandler = (values) => {
    // console.log(values);
    WebApi.doResendPassword(
      {
        username: values.email,
        // password: searchParams.password,
      },
      (response) => {
        // console.log("first");
        if (response.success) {
          // localforageSetItem("user-id", response.data.userId);
          // localforageSetItem("access-key", response.accesskey);
          // localforageSetItem("user-details", response.data, () => {
          //   // navigate("/aadhaarpay");
          // });
          //   addNotification("Password Sent succesfully!", "success");
          // navigate("/flights");
        } else {
          //   addNotification("Something went wrong", "error");
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("getTwoFaRegDEtails error", error);
        // setIsLoading(false);
      }
    );
  };

  return (
    <Root>
      {/* <CustomModal show={showForgotPassword} handleClose={logoutHandler}>
        <div className="forgotPasswordContainer">
          <div className="forgotPasswordTitle">Forgot Password</div>
          <CustomTextField
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
            name="email"
            type="email"
            label={"Email"}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
          <CustomButton
            label={"Get New Password"}
            onClick={() => resendPasswordHandler(formik.values)}
          />
        </div>
      </CustomModal> */}
      <div className="welcomeBannerContainer">
        <img className="welcomeBanner" src={services} />
      </div>
      {/* <div className="welcomeBannerHeader">
        Welcome to Geebaku, <br /> your one stop
        <br /> sourcing platform <br />
        for, all bookings services.
      </div> */}
      {/* <img className="servicesImg" src={services} /> */}
      <div className="loginSection">
        <div className="loginContainer">
          <LoginSection />
          {/* <div className="loginHeader">Are you Already Geebaku Member?</div>
          <div className="loginSubheader">Login To your Account as</div> */}
          {/* <div className="loginTypes">
            <CustomButton
              onClick={() => {
                setShowLogin(true);
                setCurrentType("agent");
              }}
              label={"Agent"}
            />
            <CustomButton
              label={"Distributor"}
              onClick={() => {
                setShowLogin(true);
                setCurrentType("distributor");
              }}
            />
            <CustomButton label={"Supplier"} />
          </div> */}
        </div>

        {/* <div className="advertisement"></div> */}
      </div>
    </Root>
  );
};

export default Login;

const Root = styled.div`
  position: relative;
  display: flex;
  .close-btn {
    top: 5px;
  }
  .forgotPasswordContainer {
    .forgotPasswordTitle {
      font-weight: 700;
      margin-bottom: 10px;
    }
    .custombtn-container {
      /* padding: 20px 40px; */
    }
  }

  .welcomeBannerContainer {
    width: 50%;
    display: flex;
    justify-content: center;

    @media (max-width: 980px) {
      display: none;
    }

    .welcomeBanner {
      margin-top: 60px;
      width: 500px;
      height: 500px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .servicesImg {
    position: absolute;
    top: 125px;
    left: 70px;
    width: 300px;
    height: 300px;
  }
  .welcomeBannerHeader {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 1px;
    position: absolute;
    top: 195px;
    left: 410px;
    width: 200px;
    text-transform: uppercase;
    font-family: "SocialGothic";
    /* font-weight: 500; */
  }

  .loginSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    @media (max-width: 980px) {
      width: 100%;
    }
    padding: 20px;
    .loginContainer {
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 10px;
    }
    /* justify-content: center; */
    .loginHeader {
      font-size: 17px;
      /* margin-bottom: 10px; */
      margin-top: 40px;
      font-weight: 700;
    }
    .loginSubheader {
      font-size: 14px;
      margin-bottom: 20px;
    }
    .loginTypes {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding-bottom: 10px;
      margin-bottom: 80px;
    }
    .loginType {
      font-size: 20px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .loginModalContainer {
      margin-top: 150px;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 2px solid #eee;
      border-radius: 10px;
      box-shadow: 0px 0px 5px 0px rgba(161, 161, 161, 1);
      padding: 30px;
      .custombtn-container {
        padding: 20px 40px;
      }
      .loginTitle {
        font-size: 23px;
        font-weight: 500;
        text-transform: uppercase;
      }
      .input-container {
        width: 100%;
      }
      .resendPassword {
        text-align: right;
        font-size: 14px;
        cursor: pointer;
      }
      .resendPasswordContainer {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      /* justify-content: center; */
    }
  }
  .loginContainer {
    .custombtn-container {
      width: 60%;
      background: linear-gradient(180deg, #00b1c5 0%, #00555f 100%);
      border: 2px solid #9c9c9c;
      border-radius: 68px;

      border-radius: 50px;
      padding: 20px;
    }
  }
  .advertisement {
    width: 90%;
    height: 340px;
    background-color: #eee;
  }
  .login-form-tabs-wrapper {
    width: 100%;
  }
`;
