import React from "react";
import OaAlert from "oahoc/OaAlert";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import * as Yup from "yup";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import OaFormTextField from "oahoc/OaFormTextField";
import OaFormSelectComboField from "oahoc/OaFormSelectComboField";
import OaPopperAlert from "oahoc/OaPopperAlert";
import { useHistory } from "react-router-dom";
import { ErrorMessage, Form, Formik } from "formik";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import AadhaarServicesMerchantOnboarding from "./AadhaarServicesMerchantOnboarding";
import OaLoading from "pages/components/OaLoading";
import AadhaarOnboardingStatus from "./AadhaarOnboardingStatus";
import AadhaarRegisterTfa from "pages/module/aadhaarpay/AadhaarRegisterTfa";
import { AppConstant } from "appConstant";
import dateFnsFormat from "date-fns/format";

const AadhaarServicesForm = (props) => {
  const formikRef = React.useRef();
  const history = useHistory();
  const classes = useStyles();

  const [collapsed, setCollapsed] = React.useState(true);
  const [banks, setBanks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isSaving, setIsSaving] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [infoText, setInfoText] = React.useState("");
  const [requestremarks, setRequestremarks] = React.useState("");
  const [latlong, setLatLong] = React.useState(null);
  const [isMerchantRegistered, setMerchantRegistered] = React.useState(false);
  const [isMerchantActive, setIsMerchantActive] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [initRdService, setInitRdService] = React.useState(false);
  const [aadhaarService, setAadhaarService] = React.useState("aeps");
  const [openOnboardStatusForm, setOpenOnboardStatusForm] = React.useState(
    false
  );
  const [is2FaRegistered, setIs2FaRegistered] = React.useState(false);
  const [is2FaAgentRegistered, setIs2FaAgentRegistered] = React.useState(false);
  const [twoFaType, setTwoFaType] = React.useState(null);
  const [extraData, setExtraData] = React.useState(null);
  const [proceedToTransact, setProceedToTransact] = React.useState(false);
  const [fingerPrintCaptured, setFingerPrintCaptured] = React.useState(false);
  const [reInitFlag, setReInitFlag] = React.useState(false);
  const [twoFaRegAttempted, setTwoFaRegAttempted] = React.useState(false);
  const [twoFaLoggedIn, setTwoFaLoggedIn] = React.useState(false);

  const openOnboardingStatus = () => setOpenOnboardStatusForm(true);

  const closeOnboardingStatus = () => setOpenOnboardStatusForm(false);

  const goToTransactionEnqiury = () => {
    if (banks && banks.length > 0) {
      history.push("/aadhaarpay/transaction-enquiry", {
        trnParams: {
          ...initialValues,
          latitude: latlong?.latitude,
          longitude: latlong?.longitude,
        },
        data: data,
        banks: banks,
      });
    }
  };

  const goToMiniStatement = () => {
    if (banks && banks.length > 0) {
      history.push("/aadhaarpay/mini-statement", {
        trnParams: {
          ...initialValues,
          latitude: latlong?.latitude,
          longitude: latlong?.longitude,
        },
        data: data,
        banks: banks,
      });
    }
  };

  const [severity, setSeverity] = React.useState({
    issuccess: false,
    iserror: false,
    iswarning: false,
    isinfo: false,
  });

  const initialValues = {
    mobilenumber: "",
    adhaarnumber: "",
    confirmAdhaarnumber: "",
    nationalbankidentification: "",
    requestremarks: "",
    amount: "",
    isAEPS: true,
    pipe: AppConstant.aepsPipe,
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(
      "Amount cannot be blank. Enter 0 for balance enquiry or mini-statement"
    ),
    mobilenumber: Yup.string().required("Sender mobile number cannot be blank"),
    adhaarnumber: Yup.string()
      .required("Please enter beneficiary Aadhar Number")
      .matches(
        /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$|^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
        "Invalid Aadhar Number"
      ),
    confirmAdhaarnumber: Yup.string()
      .oneOf(
        [Yup.ref("adhaarnumber"), null],
        "Beneficiary Aadhaar Number and Confirm Aadhaar Number do not match"
      )
      .required("Beneficiary Aadhaar Number confirm is required"),
    requestremarks: Yup.string()
      .required("Please enter remarks")
      .nullable(),
    // nationalbankidentification: Yup.string().required(
    //   "Please select beneficiary bank name"
    // )
    // ifsccode: Yup.string()
    //     .required("Please enter beneficiary bank IFSC Code"),
  });

  const transactionHelper = (
    buttonText,
    alertCallbackParam,
    additionalData
  ) => {
    if (buttonText == "YES") {
      // eslint-disable-next-line react/prop-types
      history.push("/moneytransfer/beneficiary-list", additionalData);
    }

    if (buttonText == "NO") {
      setIsSaving(false);
      setIsError(false);
      setIsSuccess(false);
    }
  };

  const collapse = () => {
    collapsed ? setCollapsed(false) : setCollapsed(true);
  };

  const getLocation = () => {
    // setLatLong({
    //     longitude: 91.7085933,
    //     latitude: 26.1157917
    // });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLong({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }, showError);
    } else {
      console.log("Geolocation permission denied");
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setInfoText("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        setInfoText("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        setInfoText("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        setInfoText("An unknown error occurred.");
        break;
    }
    setSeverity({
      ...severity,
      iserror: true,
    });
  };

  const checkMerchantCode = () => {
    setIsLoading(true);
    WebApi.aadhaarPayMerchantCode(
      {
        longitude: latlong.longitude,
        latitude: latlong.latitude,
      },
      (response) => {
        if (response.success === true) {
          if (response.data && response.data.callback_status) {
            setMerchantRegistered(true);
            setIs2FaRegistered(response.data.is2faRegistered);
            getTwoFaSessionDetails(response.data.merchant_code);

            setIs2FaAgentRegistered(response.data.is2faAgentRegistered);
            response.data.is_new = response.data.isNew;
            setData(response.data);
            if (response.data.status) {
              setIsMerchantActive(true);
              getAepsBanks();
            } else {
              setInfoText("Merchant account inactive! Please contact admin");
              setSeverity({
                ...severity,
                iserror: true,
              });
            }
          } else {
            setMerchantRegistered(false);
            setData(response.data);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          setInfoText(response.message);
          setSeverity({
            ...severity,
            iserror: true,
          });
        }
      },
      (error) => console.log("merchant code check error", error)
    );
  };

  const getTwoFaSessionDetails = (retailerId) => {
    WebApi.getAepsTwoFactorSessionDetails(
      {
        merchantCode: retailerId,
        regType:
          AppConstant.aepsPipe === "bank2" || AppConstant.aepsPipe === "bank1"
            ? "authenticate_2fa_bank2"
            : "authenticate_2fa_bank3",
        date: dateFnsFormat(new Date(), "yyyy-MM-dd"),
      },
      (response) => {
        if (response.success) {
          // console.log("Response twofa", response);
          setTwoFaLoggedIn(true);
        } else {
          console.log("failed to get twofa session details");
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("getTwoFaSessionDetails error", error);
        setIsLoading(false);
      }
    );
  };

  const onChangeAadhaarService = (e) => {
    // console.log(e.target.value);
    setAadhaarService(e.target.value);
    if (formikRef.current) {
      if (e.target.value === "aeps") {
        formikRef.current.setFieldValue("isAEPS", true);
      } else {
        formikRef.current.setFieldValue("isAEPS", false);
      }
    }
  };

  const handleSubmit = (searchParams, { setSubmitting }) => {
    if (!data) {
      setInfoText("Failed to intialize merchant");
      setIsError(true);
      return;
    }

    setInitRdService(true);
    setIsError(false);
    setIsSuccess(false);
    setSubmitting(true);
    setIsSaving(true);
    searchParams.merchant_code = data?.merchant_code;
    searchParams.isNew = data?.isNew;
    searchParams.latitude = latlong?.latitude;
    searchParams.longitude = latlong?.longitude;
    setProceedToTransact(false);
    // eslint-disable-next-line react/prop-types
    history.push({
      pathname: "/aadhaarpay/progress",
      state: searchParams,
    });
    setInitRdService(false);
  };

  // React.useEffect(() =>{
  //     if(formikRef.current) {
  //         switch(props.match.path) {
  //             case "/aadhaar_services/aadhaarpay": {
  //                 formikRef.current.setFieldValue("isAEPS", false);
  //                 break;
  //             }

  //             case "/aadhaar_services/aeps": {
  //                 formikRef.current.setFieldValue("isAEPS", true);
  //                 break;
  //             }

  //             default: {
  //                 formikRef.current.setFieldValue("isAEPS", true);
  //                 break;
  //             }
  //         }
  //     }
  // }, [props.match.path]);

  const getAepsBanks = () => {
    setIsLoading(true);
    WebApi.aadhaarPayBankList(
      {},
      (response) => {
        if (response.success) {
          response.data.banklist &&
            response.data.banklist.data.sort(function(a, b) {
              var nameA = a.bankName.toUpperCase(); // ignore upper and lowercase
              var nameB = b.bankName.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0;
            });

          setBanks(response.data.banklist.data);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
      },
      (error) => {
        console.error("internal error", error);
        setIsLoading(false);
      }
    );
  };

  React.useEffect(() => {
    if (latlong) {
      checkMerchantCode();
    }
  }, [latlong]);

  React.useEffect(() => {
    getLocation();
    return () => {
      setLatLong(null);
    };
  }, []);

  React.useEffect(() => {
    // console.log("isMerchantRegistered",isMerchantRegistered)
    // console.log("isTwoFaRegistered", is2FaRegistered);
    if (isMerchantRegistered && props.location.state?.type) {
      setTwoFaType(props.location.state.type);
      setExtraData(props.location.state.extraData);
    }
    if (twoFaRegAttempted) {
      checkMerchantCode();
      setTwoFaRegAttempted(false);
    }
  }, [isMerchantRegistered, is2FaRegistered, twoFaRegAttempted]);

  return (
    <div className={classes.root}>
      {!isMerchantRegistered && data ? (
        <AadhaarServicesMerchantOnboarding {...props} data={data} />
      ) : (
        <>
          {!isLoading &&
          data &&
          ((is2FaRegistered && !twoFaLoggedIn) || !is2FaRegistered) ? (
            <div>
              <AadhaarRegisterTfa
                trnParams={{
                  ...initialValues,
                  latitude: latlong?.latitude,
                  longitude: latlong?.longitude,
                }}
                setFingerPrintCaptured={setFingerPrintCaptured}
                setInfoText={setInfoText}
                severity={severity}
                setSeverity={setSeverity}
                setIsLoading={setIsLoading}
                setReInitFlag={setReInitFlag}
                setTwoFaRegAttempted={setTwoFaRegAttempted}
                data={data}
                twoFaLoggedIn={twoFaLoggedIn}
              />
            </div>
          ) : (
            <Formik
              innerRef={formikRef}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(searchParams, { setSubmitting }) =>
                handleSubmit(searchParams, { setSubmitting })
              }
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <Form>
                  <GridContainer>
                    <GridItem md={6}>
                      <FormControl>
                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Transaction Type</FormLabel> */}
                        <RadioGroup
                          row
                          // name="isAEPS"
                          className="radio-group-aeps"
                          value={aadhaarService}
                          onChange={onChangeAadhaarService}
                        >
                          <FormControlLabel
                            value="aeps"
                            control={<Radio />}
                            label="AEPS"
                          />
                          <FormControlLabel
                            value="aadhaarpay"
                            control={<Radio />}
                            label="Aadhaar Pay"
                          />
                        </RadioGroup>
                      </FormControl>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem md={3}>
                      <OaFormTextField
                        className="input-landing-page"
                        name="mobilenumber"
                        label="Sender Mobile Number *"
                      />
                      <ErrorMessage
                        name="mobilenumber"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <OaFormTextField
                        className="input-landing-page"
                        name="adhaarnumber"
                        label="Aadhaar Number*"
                      />
                      <ErrorMessage
                        name="adhaarnumber"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <OaFormTextField
                        className="input-landing-page"
                        name="confirmAdhaarnumber"
                        label="Confirm Aadhaar Number *"
                      />
                      <ErrorMessage
                        name="confirmAdhaarnumber"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <OaFormSelectComboField
                        label="Bank *"
                        name="nationalbankidentification"
                        value={values.nationalbankidentification}
                        options={banks}
                        optionValue={"iinno"}
                        optionLabel={["bankName", "iinno"]}
                        required={true}
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage
                        name="nationalbankidentification"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={3}>
                      <OaFormTextField
                        className="input-landing-page"
                        name="amount"
                        label="Amount *"
                      />
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <OaFormTextField
                        className="input-landing-page"
                        name="requestremarks"
                        label="Remarks *"
                        value={requestremarks}
                        onChange={(e) => {
                          setRequestremarks(e.target.value);
                          setFieldValue("requestremarks", e.target.value);
                        }}
                      />
                      <ErrorMessage
                        name="requestremarks"
                        component="div"
                        className="error"
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isSubmitting}
                        classes={{
                          root: classes.submitBtn,
                        }}
                      >
                        Submit
                      </Button>
                    </GridItem>
                  </GridContainer>
                </Form>
              )}
            </Formik>
          )}
          {initRdService && <LoaderView />}
          <OaPopperAlert
            alertmessage={infoText}
            severity={severity}
            setseverity={setSeverity}
          />
        </>
      )}
      <div className="status-check-buttons-container">
        <a className="status-check-button" onClick={goToMiniStatement}>
          Mini Statement
        </a>
        <a className="status-check-button" onClick={goToTransactionEnqiury}>
          Transaction enquiry
        </a>
        <a className="status-check-button" onClick={openOnboardingStatus}>
          Check onboarding status
        </a>
      </div>
      <AadhaarOnboardingStatus
        openstate={openOnboardStatusForm}
        closestate={closeOnboardingStatus}
        merchantcode={data?.merchant_code}
      />
      {isLoading && <LoaderView />}
    </div>
  );
};

export default AadhaarServicesForm;

const LoaderView = () => {
  return (
    <Box
      className="loader-view"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <OaLoading loadertext="intializing RD service ..." />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.background.defaultContrast,
    background: theme.palette.background.default,
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: 0,
    wordWrap: "break-word",
    flexDirection: "column",
    position: "relative",
    padding: "0 0 15px",
    "& .radio-group-aeps": {
      flexWrap: "nowrap",
      "& label": {
        "& span": {
          whiteSpace: "nowrap",
        },
      },
    },
    "& .action-group": {
      "& .submit-btn": {
        fontFamily: `${theme.palette.font.primary} important`,
        color: theme.palette.buttons.secondaryContrastText,
        background: theme.palette.buttons.secondary,
        border: 0,
        height: 40,
        margin: 5,
        padding: "0 30px",
        borderRadius: 50,
        fontSize: 10,
        fontWeight: 500,
      },
    },
    "& .MuiInputBase-root": {
      color: theme.palette.text.secondary,
      background: theme.palette.background.default,
    },
    "& .status-check-buttons-container": {
      position: "absolute",
      bottom: "16px",
      right: "10px",
      display: "flex",
      flexWrap: "nowrap",
      gap: 10,
      [theme.breakpoints.down(956)]: {
        flexWrap: "wrap",
      },
      [theme.breakpoints.down(500)]: {
        position: "relative",
        justifyContent: "center",
      },
    },
    "& .status-check-button": {
      cursor: "pointer",
      fontWeight: 500,
      color: theme.palette.buttons.primary,
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  },
  submitBtn: {
    "&:hover": {
      background: theme.palette.primary.silverWhite,
    },

    background: theme.palette.primary.main,
    borderRadius: 3,
    border: 0,
    color: theme.palette.primary.contrastText,
    height: 40,
    padding: "0 30px",
    boxShadow: "0 0px 5px 2px rgba(0, 0, 0, .3)",
    marginTop: 10,
  },
}));
