import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  FlightTakeoff,
  FlightLand,
  Flight,
  Alarm,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import { FaRupeeSign } from "react-icons/fa";
import dateFnsFormat from "date-fns/format";

const FlightSearchSortInt = (props) => {
  const [sort, setSort] = useState("price");
  const requestObj = props.location.state.requestObj;
  const { mode, returnDate, isDomestic } = requestObj;
  const onwDate = props.location.state.requestObj?.sectors[0]?.departDate;
  const type = props.type;
  const dateRef = type === "return" ? new Date(returnDate) : new Date(onwDate);
  const [date, setDate] = useState(dateRef);
  const [selected, setSelected] = useState("price");
  const [isLowPrice, setIsLowPrice] = useState(true);
  const [isLowAirlines, setIsLowAirlines] = useState(true);
  const [isLowDuration, setIsLowDuration] = useState(true);
  const [isLowArrival, setIsLowArrival] = useState(true);
  const [isLowDeparture, setIsLowDeparture] = useState(true);

  const width = window.innerWidth;
  const breakpoint = 980;
  const classes = useStyles();

  const handleChange = (value) => {
    setSelected(value);
    props.setIsLoading(true);
    const label = value;
    setSort(value);
    const sortedData = sortData(label, props.data, props.tripMode);
    sortedData && props.setModifiedResponse(sortedData);
    props.setIsLoading(false);
  };

  const sortByHighPrice = (arrayData) => {
    return arrayData?.slice().sort(function (a, b) {
      return (
        b.fares[0].baseFare +
        b.fares[0].tax -
        (a.fares[0].baseFare + a.fares[0].tax)
      );
    });
    return arrayData.sort(function (a, b) {
      if (a.fares.length > 1) {
        a.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      if (b.fares.length > 1) {
        b.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      let sortKeyA = a.fares[0].baseFare + a.fares[0].tax;
      let sortKeyB = b.fares[0].baseFare + b.fares[0].tax;

      if (parseFloat(sortKeyA) < parseFloat(sortKeyB)) {
        return 1;
      } else if (parseFloat(sortKeyA) > parseFloat(sortKeyB)) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByLowPrice = (arrayData) => {
    return arrayData?.slice().sort(function (a, b) {
      return (
        a.fares[0].baseFare +
        a.fares[0].tax -
        (b.fares[0].baseFare + b.fares[0].tax)
      );
    });
    return arrayData.sort(function (a, b) {
      if (a.fares.length > 1) {
        a.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      if (b.fares.length > 1) {
        b.fares.sort((c, d) => {
          if (parseFloat(c.baseFare + c.tax) > parseFloat(d.baseFare + d.tax)) {
            return 1;
          }
          if (parseFloat(c.baseFare + c.tax) < parseFloat(d.baseFare + d.tax)) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      let sortKeyA = b.fares[0].baseFare + b.fares[0].tax;
      let sortKeyB = a.fares[0].baseFare + a.fares[0].tax;

      if (parseFloat(sortKeyA) < parseFloat(sortKeyB)) {
        return 1;
      } else if (parseFloat(sortKeyA) > parseFloat(sortKeyB)) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByStops = (arrayData, tripMode) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return" ? a.returnResp.length : a.onwardResp.length;
      let sortKeyB =
        props.type == "return" ? b.returnResp.length : b.onwardResp.length;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByLowDuration = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      return Number(a.onwardJourneyTime) - Number(b.onwardJourneyTime);
    });
  };
  const sortByHighDuration = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      return Number(b.onwardJourneyTime) - Number(a.onwardJourneyTime);
    });
  };

  const sortByHighDepTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? new Date(b.returnResp[0].departDetails.dateTime).getTime()
          : new Date(b.onwardResp[0].departDetails.dateTime).getTime();
      let sortKeyB =
        props.type == "return"
          ? new Date(a.returnResp[0].departDetails.dateTime).getTime()
          : new Date(a.onwardResp[0].departDetails.dateTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const sortByLowDepTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? new Date(a.returnResp[0].departDetails.dateTime).getTime()
          : new Date(a.onwardResp[0].departDetails.dateTime).getTime();
      let sortKeyB =
        props.type == "return"
          ? new Date(b.returnResp[0].departDetails.dateTime).getTime()
          : new Date(b.onwardResp[0].departDetails.dateTime).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByHighArrTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? new Date(
            b.returnResp[b.returnResp.length - 1].arriveDetails.dateTime
          ).getTime()
          : new Date(
            b.onwardResp[b.onwardResp.length - 1].arriveDetails.dateTime
          ).getTime();
      let sortKeyB =
        props.type == "return"
          ? new Date(
            a.returnResp[a.returnResp.length - 1].arriveDetails.dateTime
          ).getTime()
          : new Date(
            a.onwardResp[a.onwardResp.length - 1].arriveDetails.dateTime
          ).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const sortByLowArrTime = (arrayData, tripMode) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? new Date(
            a.returnResp[a.returnResp.length - 1].arriveDetails.dateTime
          ).getTime()
          : new Date(
            a.onwardResp[a.onwardResp.length - 1].arriveDetails.dateTime
          ).getTime();
      let sortKeyB =
        props.type == "return"
          ? new Date(
            b.returnResp[b.returnResp.length - 1].arriveDetails.dateTime
          ).getTime()
          : new Date(
            b.onwardResp[b.onwardResp.length - 1].arriveDetails.dateTime
          ).getTime();

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortByHighAirlines = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? b.returnResp[0].carrierDetails.carrierName
          : b.onwardResp[0].carrierDetails.carrierName;
      let sortKeyB =
        props.type == "return"
          ? a.returnResp[0].carrierDetails.carrierName
          : a.onwardResp[0].carrierDetails.carrierName;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };
  const sortByLowAirlines = (arrayData) => {
    return arrayData.slice().sort(function (a, b) {
      let sortKeyA =
        props.type == "return"
          ? a.returnResp[0].carrierDetails.carrierName
          : a.onwardResp[0].carrierDetails.carrierName;
      let sortKeyB =
        props.type == "return"
          ? b.returnResp[0].carrierDetails.carrierName
          : b.onwardResp[0].carrierDetails.carrierName;

      if (sortKeyA > sortKeyB) {
        return 1;
      } else if (sortKeyA < sortKeyB) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  const sortData = (type, arrayData, tripMode) => {
    let sortedData = null;

    if (arrayData != null)
      switch (type) {
        case "price": {
          if (isLowPrice) {
            sortedData = sortByHighPrice(arrayData);
            setIsLowPrice(false);
          } else {
            sortedData = sortByLowPrice(arrayData);
            setIsLowPrice(true);
          }

          break;
        }

        case "stops": {
          sortedData = sortByStops(arrayData, tripMode);

          break;
        }

        case "dep-time": {
          if (isLowDeparture) {
            sortedData = sortByHighDepTime(arrayData);
            //  console.log(sortedData);
            setIsLowDeparture(false);
          } else {
            sortedData = sortByLowDepTime(arrayData);
            // console.log(sortedData);
            setIsLowDeparture(true);
          }

          break;
        }
        case "duration": {
          if (isLowDuration) {
            sortedData = sortByHighDuration(arrayData);
            // console.log(sortedData);
            setIsLowDuration(false);
          } else {
            sortedData = sortByLowDuration(arrayData);
            //  console.log(sortedData);
            setIsLowDuration(true);
          }

          break;
        }

        case "arr-time": {
          if (isLowArrival) {
            sortedData = sortByHighArrTime(arrayData);
            //  console.log(sortedData);
            setIsLowArrival(false);
          } else {
            sortedData = sortByLowArrTime(arrayData);
            //  console.log(sortedData);
            setIsLowArrival(true);
          }

          break;
        }

        case "airlines": {
          if (isLowAirlines) {
            sortedData = sortByHighAirlines(arrayData);
            //  console.log(sortedData);
            setIsLowAirlines(false);
          } else {
            sortedData = sortByLowAirlines(arrayData);
            // console.log(sortedData);
            setIsLowAirlines(true);
          }

          break;
        }
        default: {
          sortedData = props.data;
          break;
        }
      }

    return sortedData;
  };

  const searchFlights = (date) => {
    if (type === "onward") {
      requestObj.sectors[0].departDate = dateFnsFormat(date, "yyyy-MM-dd");
      requestObj.sectors[0].departDateDisplay = date;
    }
    if (type === "return") {
      requestObj.returnDate = dateFnsFormat(date, "yyyy-MM-dd");
      requestObj.returnDateDisplay = date;
    }
    window.sessionStorage.setItem("fetching", true);
    props.history.push({
      pathname: "/flight/search-progress",
      state: requestObj,
    });
  };
  const handleNextDate = () => {
    const ref = date;
    ref.setDate(date.getDate() + 1);
    setDate(new Date(ref));

    searchFlights(new Date(ref));
  };
  const handlePreviousDate = () => {
    const ref = date;
    ref.setDate(date.getDate() - 1);
    setDate(new Date(ref));

    searchFlights(new Date(ref));
  };

  /* const disablePrevious =
    type === "return"
      ? date.getTime() < new Date(onwDate).getTime()
      : date.getTime() < new Date().getTime();
  const disableNext =
    type === "return"
      ? false
      : mode === "ROUND"
      ? date.getTime() > new Date(returnDate).getTime()
      : false; */

  const month = date.getMonth() + 1;
  const day = date.getDate();
  const disablePrevious =
    type === "return"
      ? day === new Date(onwDate).getDate() &&
      month === new Date(onwDate).getMonth() + 1
      : date.getTime() < new Date().getTime();
  const disableNext =
    type === "return"
      ? false
      : mode === "ROUND"
        ? day === new Date(returnDate).getDate() &&
        month === new Date(returnDate).getMonth() + 1
        : false;
  const PrevNext = () => {
    return (
      <Grid item className={classes.DateContainer}>
        {/*<IconButton onClick={handlePreviousDate} disabled={disablePrevious}>
          <NavigateBeforeOutlined />
        </IconButton>
        <p className={classes.dateLabel}>
          {date
            .toDateString()
            .split(" ")
            .slice(1)
            .join("-")}
        </p>
        <IconButton onClick={handleNextDate} disabled={disableNext}>
          <NavigateNextOutlined />
        </IconButton>*/}
        <button
          onClick={handlePreviousDate}
          disabled={disablePrevious}
          className={classes.prevButton}
          style={{ cursor: disablePrevious ? "not-allowed" : "pointer" }}
        >
          Previous
        </button>
        <button
          onClick={handleNextDate}
          disabled={disableNext}
          className={classes.nextButton}
          style={{ cursor: disableNext ? "not-allowed" : "pointer" }}
        >
          Next
        </button>
      </Grid>
    );
  };
  const GenerateButton = ({ value, icon }) => {
    const ref = {
      price: "Price",
      "dep-time": "Departure",
      duration: "Duration",
      "arr-time": "Arrival",
      airlines: "Airlines",
    };
    const label = ref[value];
    /* return (
      <Button
        variant="outlined"
        value={value}
        onClick={() => handleChange(value)}
        color={selected === value ? "secondary" : "primary"}
        startIcon={icon}
        size="small"
      >
        {label}
    </Button>
    
    ); */
    return (
      <Button
        value={value}
        onClick={() => handleChange(value)}
        color={selected === value ? "secondary" : "primary"}
        size="small"
        endIcon={icon}
      >
        {label}
      </Button>
    );
  };

  // console.log(requestObj);
  // console.log(requestObj.sectors[0].departDateDisplay);

  const SortHeader = () => {
    return (
      <Grid
        container
        className={
          mode === "ROUND"
            ? classes.sortHeaderContainerRt
            : classes.sortHeaderContainer
        }
      >
        {/* <Grid item>
                <GenerateButton value={"stops"} icon={<Stop />} />
        </Grid>*/}

        <Grid item className={classes.sortHeaderItem}>
          <GenerateButton
            value={"airlines"}
            icon={isLowAirlines ? <ArrowDownward /> : <ArrowUpward />}
          />
        </Grid>
        <Grid
          className={
            mode === "ROUND"
              ? classes.durationContainerRt
              : classes.durationContainer
          }
        >
          {" "}
          <Grid item className={classes.sortHeaderItem}>
            <GenerateButton
              value={"dep-time"}
              icon={isLowDeparture ? <ArrowDownward /> : <ArrowUpward />}
            />
          </Grid>
          <Grid item className={classes.sortHeaderItem}>
            <GenerateButton
              value={"duration"}
              icon={isLowDuration ? <ArrowDownward /> : <ArrowUpward />}
            />
          </Grid>
          <Grid item className={classes.sortHeaderItem}>
            <GenerateButton
              value={"arr-time"}
              icon={isLowArrival ? <ArrowDownward /> : <ArrowUpward />}
            />
          </Grid>
        </Grid>

        <Grid item className={[classes.sortHeaderItem, classes.priceHeader]}>
          <GenerateButton
            value={"price"}
            icon={isLowPrice ? <ArrowDownward /> : <ArrowUpward />}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid style={{ width: "100%" }}>
      {mode !== "ROUND" && width > breakpoint && (
        <Grid className={classes.prevNextContainer}>
          <div>
            {requestObj.sectors[0].origin} - {requestObj.sectors[0].destination}
          </div>
          <div>
            {requestObj.sectors[0].departDateDisplay
              .toString()
              .slice(0, 15)
              .replace(" ", ", ")}
          </div>
          {width > breakpoint && <PrevNext />}
        </Grid>
      )}
      {mode == "ROUND" && width > breakpoint && (
        <Grid className={classes.prevNextContainer}>
          <div>
            {requestObj.sectors[0].origin} - {requestObj.sectors[0].destination}{" "}
            | {requestObj.sectors[0].destination} -{" "}
            {requestObj.sectors[0].origin}
          </div>
          <div>
            {requestObj.sectors[0].departDateDisplay.toString().slice(0, 15)} -{" "}
            {requestObj.returnDateDisplay.toString().slice(0, 15)}
          </div>
          {width > breakpoint && <PrevNext />}
        </Grid>
      )}

      <Grid container className={classes.sortContainer}>
        {width > breakpoint && <SortHeader />}
        {width < breakpoint && <PrevNext />}

        {width < breakpoint && (
          <Grid item className={classes.sortDropdown}>
            <p className={classes.sortLabel}>Sort By</p>
            <select
              value={sort}
              onChange={(e) => handleChange(e.target.value)}
              className={classes.select}
            >
              {/*<option value={"stops"}>Stops</option>*/}

              <option value={"airlines"}>Airlines </option>
              <option value={"dep-time"}>Departure</option>
              <option value={"duration"}>Duration</option>
              <option value={"arr-time"}>Arrival</option>
              <option value={"price"}>Price</option>
            </select>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FlightSearchSortInt;
const useStyles = makeStyles((theme) => ({
  priceHeader: {
    [theme.breakpoints.down(1050)]: {
      width: "16% !important",
    },
  },
  prevNextContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    background: theme.palette.background.light,
    //background:theme.palette.background.rgba_tertiary,
    marginLeft: "12px",
    width: "98%",
    borderRadius: 5,
    padding: "5px 20px",
    marginBottom: 10,
    fontWeight: 700,

    //background: theme.palette.primary.main,
  },
  sortContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 5px",
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",

    background: theme.palette.background.light,
    marginLeft: "5px",
    width: "99%",
    borderRadius: 5,
    [theme.breakpoints.down(1280)]: {
      padding: "10px 5px",
    },
    [theme.breakpoints.down(400)]: {
      padding: "5px 11px 5px",
      // width: "95%",
      justifyContent: "space-between",
    },
  },
  sortContainerRt: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0 5px",
    [theme.breakpoints.down(1280)]: {
      padding: "10px 5px",
    },
    [theme.breakpoints.down(400)]: {
      padding: 0,
      justifyContent: "flex-end",
    },
  },
  sortHeaderContainer: {
    display: "flex",
    width: "72%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      marginRight: 10,
      width: "100%",
      padding: "5px 10px",
    },

    "& .MuiButton-outlinedPrimary": {
      color: theme.palette.buttons.defaultContrast,
      borderColor: theme.palette.buttons.defaultContrast,
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-outlinedSecondary": {
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-textPrimary": {
      color: theme.palette.text.default,
    },

    "& .MuiButton-label": {
      fontSize: 12,
      fontWeight: "600",
      textDecorationLine: "underline",
    },
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: 12,
      fontWeight: "400",
      textDecorationLine: "underline",
    },
  },
  sortHeaderContainerRt: {
    display: "flex",
    width: "83%",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiButton-root": {
      marginRight: 10,
      width: "100%",
      padding: "5px 10px",
    },

    "& .MuiButton-outlinedPrimary": {
      color: theme.palette.buttons.defaultContrast,
      borderColor: theme.palette.buttons.defaultContrast,
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-outlinedSecondary": {
      backgroundColor: theme.palette.buttons.default,
    },
    "& .MuiButton-textPrimary": {
      color: theme.palette.text.default,
    },

    "& .MuiButton-label": {
      fontSize: 12,
      fontWeight: "600",
      textDecorationLine: "underline",
    },
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: 12,
      fontWeight: "400",
      textDecorationLine: "underline",
    },
  },
  sortHeaderItem: { width: "20%" },
  sortDropdown: {
    marginLeft: 0,
    marginRight: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(400)]: {
      marginLeft: 0,
    },
  },
  sortLabel: {
    margin: 0,
    marginRight: 10,
  },
  select: { padding: 5 },
  dateLabel: { margin: 0 },
  nextButton: {
    width: 70,
    borderWidth: 1,
    padding: "5px 10px",
    outline: "none",
    borderColor: theme.palette.buttons.defaultContrast,
    borderRadius: "0 3px 3px 0",
    borderLeftColor: "transparent",
    borderLeftWidth: 0,
    borderStyle: "solid",
    backgroundColor: theme.palette.buttons.default,
    cursor: "pointer",
  },
  prevButton: {
    width: 70,
    padding: "5px 10px",
    borderWidth: 1,
    outline: "none",
    borderColor: theme.palette.buttons.defaultContrast,
    borderStyle: "solid",
    backgroundColor: theme.palette.buttons.default,
    borderRadius: "3px 0 0 3px",
    cursor: "pointer",
  },
  DateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiIconButton-root ": {
      padding: 0,
    },
  },
  durationContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 100,
    width: "34%",
    [theme.breakpoints.down(1050)]: {
      "& .MuiButton-endIcon": {
        marginLeft: 0,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "12px !important",
      },
    },
    [theme.breakpoints.down(1400)]: {
      marginLeft: 80,
    },
    [theme.breakpoints.down(1350)]: {
      marginLeft: 80,
    },
    [theme.breakpoints.down(1280)]: {
      marginLeft: 90,
      width: "38%",
    },
    [theme.breakpoints.down(1250)]: {
      marginLeft: 90,
      width: "38%",
    },
    [theme.breakpoints.down(1200)]: {
      marginLeft: 100,
    },
    [theme.breakpoints.down(1090)]: {
      marginLeft: 90,
      marginRight: 20,
    },
    [theme.breakpoints.down(1090)]: {
      width: "35%",
      marginLeft: 70,
      // marginLeft:
    },
    [theme.breakpoints.down(1050)]: {
      marginLeft: 45,
      // marginLeft:
    },
    [theme.breakpoints.down(950)]: {
      width: "38%",
      marginLeft: 100,
      // marginLeft:
    },
  },
  durationContainerRt: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 30,
    width: "27%",
    [theme.breakpoints.down(1380)]: {
      marginLeft: 30,
      marginRight: 30,
    },
    [theme.breakpoints.down(1350)]: {
      width: "30%",
      // marginRight: 30,
    },
    [theme.breakpoints.down(1325)]: {
      width: "30%",
      marginLeft: 10,
      // marginRight: 30,
    },
    [theme.breakpoints.down(1280)]: {
      width: "33%",
      // marginLeft: 50,
      // marginRight: 0,
    },
    [theme.breakpoints.down(1260)]: {
      width: "33%",

      // marginLeft: 50,
      // marginRight: 0,
    },
    [theme.breakpoints.down(1245)]: {
      width: "33%",

      // marginLeft: 50,
      marginRight:60,
    },
    [theme.breakpoints.down(1200)]: {
      width: "33%",
      marginLeft: 0,
      marginRight: 70,
    },
    [theme.breakpoints.down(1160)]: {
      width: "33%",
      marginLeft: 0,
      marginRight: 70,
    },
    [theme.breakpoints.down(1160)]: {
      width: "40%",
      marginLeft: 0,
      marginRight: 70,
    },
    [theme.breakpoints.down(1160)]: {
      width: "40%",
      marginLeft: 0,
      marginRight: 90,
    },
    [theme.breakpoints.down(1080)]: {
      marginRight: 30,
      width: "30%",
      marginLeft: 20,
      // marginRight: 20,
    },
    [theme.breakpoints.down(1050)]: {
      marginRight: 70,
      // width: "30%",
      // marginRight: 20,
    },
    [theme.breakpoints.down(1080)]: {
      // width: "35%",
      // marginRight: 70,
      width: "30%",
      marginRight: 10,
    },
    // [theme.breakpoints.down(1090)]: {
    //   marginLeft: 50,
    //   width: "39%",
    //   marginRight: 20,
    // },
  },
}));
