import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import image1 from "assets/img/geebaku-loading.gif";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import dateFnsFormat from "date-fns/format";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
// import { AppStateContext } from "layouts/AppStateProvider";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import image1 from "assets/rive/hotelanimation.riv";
import Rive from "rive-react";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import {
  setHotelSearchResult,
  setTimerInitialMinute,
  setTimerInitialSecond,
} from "redux/action";

import OaFormAlerts from "pages/components/OaFormAlerts";
import ajaxLoader from "../../../assets/insurance/ajax-loader.gif";
import { localforageGetItem } from "oautils/oaForageUtils";
import {
  ErrorOutlineRounded,
  CheckCircle,
  List,
  ListAltOutlined,
} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { AppConstant } from "appConstant";

const InsuranceBookingProcess = (props) => {
  const classes = useStyles();
  const [bookingProcess, setBookingProcess] = useState("blocking");
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [isLoading, setIsLoading] = useState(true);
  const [loggedInUserId, setLoggedInUserId] = useState();

  const params = useParams();
  let { tid } = params;

  console.log(tid);

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (err == null && value != null) {
        setLoggedInUserId(value.userId);
      }
    });
  }, []);

  useEffect(() => {
    // return;
    setTimeout(() => {
      WebApi.getInsuranceBookingDetails(
        {
          tid: tid,
          EndUserIp: "",
          TokenId: "",
          TraceId: "",
          bookingId: 0,
          loggedInUserId: loggedInUserId,
        },
        (response) => {
          if (response.message == "success") {
            props.history.push({
              pathname: `/insurance/payment-status/${tid}/success`,
              state: {
                // ...propState,
                data: response.data,
                tid: tid,
              },
            });
          } else {
            props.history.push({
              pathname: `/insurance/payment-status/${tid}/failed`,
              // pathname: "/insurance/booking/payment-failed",
              state: {
                // ...propState,
                data: response.data,
                tid: tid,
              },
            });
          }
        },
        (error) => console.log("error", error)
      );
    }, "7000");

    // return () => {
    //   clearInterval(intervalID);
    // };
  }, []);

  return (
    <div className={classes.root}>
      <div className="bookingStatusContainer">
        <div
          style={{
            backgroundColor: "#fffec8",
          }}
          className="bookingStatusInfoContainer"
        >
          <div className="bookStatusInfoTop">
            <div className="bookStatusHeader">Booking Status: Processing</div>
            <div className="bookStatusBookingId">Booking ID: {tid}</div>
          </div>
          <div className="bookStatusStatusInfo">
            <AccessTimeIcon style={{ color: "grey" }} />
            Thanks for transacting with {AppConstant.name}. Your e-ticket will
            be sent automatically to your registered email after getting a
            confirmation from the Insurance Provider.
          </div>
        </div>
      </div>

      <div className="bookingStatusStepContainer">
        <div className="bookingStatusProcess">
          <div className="bookingStatusStep">
            <div className="stepIcon">
              {" "}
              <CheckCircle style={{ color: "#48AB64" }} />
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">Payment Received</div>
              <div className="stepInfoText">We have received your payment</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor: "#D8D8D8",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              style={{
                backgroundColor:
                  bookingProcess === "booking" ? "#48AB64" : "#eabb31",
              }}
            >
              {bookingProcess === "blocking" ? (
                "2"
              ) : (
                <CheckCircle style={{ color: "#48AB64" }} />
              )}
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">
                Blocking a Plan{" "}
                {bookingProcess === "blocking" && (
                  <CircularProgress size={20} className="loader" />
                )}{" "}
              </div>
              <div className="stepInfoText">It will take approx few mins.</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor:
                bookingProcess === "blocking" ? "#D8D8D8" : "#eabb31",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              style={{
                backgroundColor:
                  bookingProcess === "booking" ? "#eabb31" : "#D8D8D8",
              }}
            >
              3
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">
                Booking a Plan{" "}
                {bookingProcess === "booking" && (
                  <CircularProgress size={20} className="loader" />
                )}
              </div>
              <div className="stepInfoText">It will take approx few mins.</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor: "#D8D8D8",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              style={{
                backgroundColor: "#D8D8D8",
              }}
            >
              4
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">Booking Confirmed</div>
              <div className="stepInfoText">Your Booking is Confirmed Now.</div>
            </div>
          </div>
        </div>
      </div>

      <div className="bookingStatusStepContainer">
        <div className="bookingStatusProcessContainer">
          <div className="stepInfoHeader">
            Booking Status: Processing..{" "}
            <CircularProgress size={20} className="loader" />{" "}
          </div>
          <div className="stepInfoText">
            We are yet to receive booking confirmation from Insurance Provider's
            end.
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelSearch: state.hotel.hotelSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHotelSearchResult: (hotelSearchResult) =>
      dispatch(setHotelSearchResult(hotelSearchResult)),
    setTimerInitialMinute: (timerInitialMinute) =>
      dispatch(setTimerInitialMinute(timerInitialMinute)),
    setTimerInitialSecond: (timerInitialSecond) =>
      dispatch(setTimerInitialSecond(timerInitialSecond)),
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InsuranceBookingProcess)
);

const useStyles = makeStyles((theme) => ({
  root: {
    // width: "100%",
    // paddingTop: 20,

    "& .bookingStatusContainer": {
      padding: "10px 15px",
      width: "100%",

      "& .bookingStatusInfoContainer": {
        border: "1px solid #D8D8D8",
        padding: 10,
        backgroundColor: "#E5FFDA",
      },
      "& .bookStatusInfoTop": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
      },
      "& .bookStatusHeader": {
        fontSize: 17,
        fontWeight: 700,
      },
      "& .bookStatusBookingId": {
        fontSize: 15,
        fontWeight: 500,
      },
      "& .MuiSvgIcon-root": {
        height: 40,
        width: 40,
        color: theme.palette.primary.success,
      },
      "& .bookStatusStatusInfo": {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 13,
      },
      "& .bookingStatusStep": {
        display: "flex",
      },
    },
    "& .bookingStatusStepContainer": {
      padding: "5px 15px",
      width: "100%",
    },
    "& .bookingStatusProcess": {
      display: "flex",
      border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(700)]: {
        flexDirection: "column",
        alignItems: "center",
      },
      "& .bookingStatusStep": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        [theme.breakpoints.down(700)]: {
          width: 240,
          marginBottom: 10,
        },
      },
      "& .stepIcon": {
        // backgroundColor: "#48AB64",
        height: 30,
        width: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        borderRadius: 50,
        fontWeight: 700,
        "& svg": {
          width: 35,
          height: 35,
        },
      },
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        gap: 10,
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    "& .progressBar": {
      width: 40,
      height: 5,
      borderRadius: 4,
    },
    "& .bookingStatusProcessContainer": {
      // border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      justifyContent: "space-between",
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        gap: 10,
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
  },
}));
