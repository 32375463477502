import React, { useEffect } from "react";
import baggageIcon from "assets/icons/baggage.svg";
import luggageIcon from "assets/icons/luggage.svg";
import refundIcon from "assets/icons/refund_icon.svg";
import dateFnsFormat from "date-fns/format";
import Collapse from "@material-ui/core/Collapse";
import ReactHtmlParser from "react-html-parser";
import CloseIcon from "@material-ui/icons/Close";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Dialog,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import { BookOutlined, CloseOutlined } from "@material-ui/icons";
import HTMLReactParser from "html-react-parser";
import CircularProgress from "@material-ui/core/CircularProgress";

const FlightMoreDetailsInt = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const width = window.innerWidth;
  const breakpoint = 980;
  const [onwardFlightDetails, setOnwardFlightDetails] = React.useState(null);
  const [returnFlightDetails, setReturnFlightDetails] = React.useState(null);
  const [isRoundTrip, setIsRoundTrip] = React.useState(false);
  const [openAirlineTnCOnw, setOpenAirlineTnCOnw] = React.useState(false);
  const [openAirlineTnCRet, setOpenAirlineTnCRet] = React.useState(false);

  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const propState = props.history.location.state;
  const requestObj = propState?.request;
  const onwardObj = props.flightData;
  const returnObj = props.flightData;
  const sessionId = propState?.sessionId;
  const isDomestic = props.history.location.state.requestObj.isDomestic;

  console.log(props, props.flightData, "PROPS 2525")

  // console.log(
  //   "🚀 ~ file: FlightDetails.js:41 ~ FlightDetails ~ propState:",
  //   propState
  // );

  // console.log(propState.requestObj.mode);
  const tripMode = propState.requestObj.mode;

  const oneSelectedFareId = propState?.oneSelectedFareId;
  const retSelectedFareId = propState?.retSelectedFareId;

  const [onwardFareDetail, setOnwardFareDetail] = React.useState(
    onwardObj.fares[0]
  );
  const [returnFareDetail, setReturnFareDetail] = React.useState(null);
  // const [fareRulesOnw, setFareRulesOnw] = React.useState(
  //   propState?.onwardResp?.fares[0]?.rule
  // );
  // const [fareRulesRet, setFareRulesRet] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  // console.log("flight details props", props);

  const onClickFareRuleOnw = () => {
    // if (openAirlineTnCOnw) {
    //   getFareRules();
    // }
    setOpenAirlineTnCOnw(!openAirlineTnCOnw);
  };

  const onClickFareRuleRet = () => {
    // if (openAirlineTnCRet) {
    //   getFareRules();
    // }
    setOpenAirlineTnCRet(!openAirlineTnCRet);
  };

  // useEffect(() => {
  //   const params = {
  //     onwardFareId: oneSelectedFareId,
  //     returnFareId: requestObj?.mode === "ROUND" ? retSelectedFareId : "",
  //     sessionId: sessionId,
  //   };

  //   // setLoading(true);
  //   WebApi.getFlightFareRules(
  //     params,
  //     (response) => {
  //       if (response.isSuccess == true) {
  //         //   console.log(response?.onwardResp[0]?.fares[0].rule);
  //         setFareRulesOnw(response?.onwardResp[0]?.fares[0].rule);

  //         if (requestObj?.mode === "ROUND") {
  //           setFareRulesRet(response?.returnResp[0]?.fares[0].rule);
  //         }
  //       } else {
  //         console.log("failed to fetch fare rules", response);
  //       }
  //       setLoading(false);
  //     },
  //     (error) => console.log("fare rules fetch error", error)
  //   );
  // }, []);

  // const getFareRules = () => {
  //   const params = {
  //     onwardFareId: oneSelectedFareId,
  //     returnFareId: requestObj?.mode === "ROUND" ? retSelectedFareId : "",
  //     sessionId: sessionId,
  //   };

  //   setLoading(true);
  //   WebApi.getFlightFareRules(
  //     params,
  //     (response) => {
  //       if (response.isSuccess == true) {
  //         //   console.log(response?.onwardResp[0]?.fares[0].rule);
  //         setFareRulesOnw(response?.onwardResp[0]?.fares[0].rule);

  //         if (requestObj?.mode === "ROUND") {
  //           setFareRulesRet(response?.returnResp[0]?.fares[0].rule);
  //         }
  //       } else {
  //         console.log("failed to fetch fare rules", response);
  //       }
  //       setLoading(false);
  //     },
  //     (error) => console.log("fare rules fetch error", error)
  //   );
  // };

  const initiateFareDetails = () => {
    if (requestObj?.mode == "ROUND") {
      const fareDetalRet = returnObj?.fares[0];
      // console.log("fareDetalRet", fareDetalRet);
      setReturnFareDetail(fareDetalRet);
      // setFareRulesRet(returnObj?.fares[0]?.rule);
    }

    // props.setInitFlag(true, "/flight/booking");
    const segmentsOnw = onwardObj?.onwardResp;
    const lsOnw = segmentsOnw?.length - 1;

    //console.log(segmentsOnw);

    const depDateRawOnw =
      segmentsOnw && new Date(segmentsOnw[0].departDetails.dateTime);
    const arrDateRawOnw =
      segmentsOnw && new Date(segmentsOnw[lsOnw].arriveDetails.dateTime);
    const flightCodeOnw =
      segmentsOnw && segmentsOnw[0]?.carrierDetails.carrierCode;
    const oneSelectedFareObj = onwardObj?.fares.filter((v) => {
      return v.fareId === props?.history?.location?.state?.oneSelectedFareId;
    })[0];

    let totalLayoverOnw = 0;
    let stopAirportsOnw = "";
    let totalJourneyTimeOnw = onwardObj.journeyTime;

    segmentsOnw &&
      segmentsOnw.map((value, index) => {
        totalLayoverOnw += parseInt(value.layover);

        if (index > 0) {
          const sep = segmentsOnw.length - 1 === index ? "" : ", ";
          stopAirportsOnw += value.departDetails.airportCode + sep;
        }
      });

    setOnwardFlightDetails({
      flightCode: flightCodeOnw,
      airLineName: segmentsOnw && segmentsOnw[0]?.carrierDetails?.carrierName,
      flightNumber: segmentsOnw && segmentsOnw[0]?.carrierDetails?.flightNumber,
      depAirportName: segmentsOnw && segmentsOnw[0]?.departDetails?.airportName,
      depAirportCode: segmentsOnw && segmentsOnw[0]?.departDetails?.airportCode,
      departTime: dateFnsFormat(depDateRawOnw, "HH:mm"),
      departDate: segmentsOnw && dateFnsFormat(depDateRawOnw, "dd MMM"),
      departDay: segmentsOnw && days[depDateRawOnw.getDay()],
      arrAirportName:
        segmentsOnw && segmentsOnw[lsOnw]?.arriveDetails?.airportName,
      arrAirportCode:
        segmentsOnw && segmentsOnw[lsOnw]?.arriveDetails?.airportCode,
      arrivalTime: dateFnsFormat(arrDateRawOnw, "HH:mm"),
      arrivalDate: segmentsOnw && dateFnsFormat(arrDateRawOnw, "dd MMM"),
      arrivalDay: segmentsOnw && days[arrDateRawOnw.getDay()],
      ruleArray: oneSelectedFareObj && oneSelectedFareObj?.rule?.split("|"),
      layover:
        totalLayoverOnw > 0
          ? (Math.floor(totalLayoverOnw / 60) > 0
              ? Math.floor(totalLayoverOnw / 60) + " hr "
              : "") +
            Math.floor(totalLayoverOnw % 60) +
            " min"
          : 0,
      journeyTime:
        totalJourneyTimeOnw > 0
          ? Math.floor(totalJourneyTimeOnw / 60) +
            " hr " +
            Math.floor(totalJourneyTimeOnw % 60) +
            " min"
          : 0,
      stopAirports: stopAirportsOnw,
    });

    if (
      (requestObj?.mode == "ROUND" || requestObj?.mode == "ROUND-SP") &&
      returnObj != null && !isDomestic
    ) {
      const segmentsRet = returnObj?.returnResp;
      const lsRet = segmentsRet?.length - 1;

      const depDateRawRet =
        segmentsRet && new Date(segmentsRet[0]?.departDetails?.dateTime);
      const arrDateRawRet =
        segmentsRet && new Date(segmentsRet[lsRet]?.arriveDetails?.dateTime);
      const flightCodeRet =
        segmentsRet && segmentsRet[0]?.carrierDetails?.carrierCode;
      const retSelectedFareObj = returnObj?.fares.filter((v) => {
        return v.fareId === props?.history?.location?.state?.retSelectedFareId;
      })[0];

      let totalLayoverRet = 0;
      let stopAirportsRet = "";
      let totalJourneyTimeRet = returnObj.journeyTime;

      segmentsRet &&
        segmentsRet.map((value, index) => {
          totalLayoverRet += parseInt(value.layover);
          if (index > 0) {
            const sep = segmentsRet.length - 1 === index ? "" : ", ";
            stopAirportsRet += value.departDetails.airportCode + sep;
          }
        });

      setReturnFlightDetails({
        flightCode: flightCodeRet,
        airLineName: segmentsRet && segmentsRet[0]?.carrierDetails?.carrierName,
        flightNumber:
          segmentsRet && segmentsRet[0]?.carrierDetails?.flightNumber,
        depAirportName:
          segmentsRet && segmentsRet[0]?.departDetails?.airportName,
        depAirportCode:
          segmentsRet && segmentsRet[0]?.departDetails?.airportCode,
        departTime: dateFnsFormat(depDateRawRet, "HH:mm"),
        departDate: segmentsRet && dateFnsFormat(depDateRawRet, "dd MMM"),
        departDay: segmentsRet && days[depDateRawRet.getDay()],
        arrAirportName:
          segmentsRet && segmentsRet[lsRet]?.arriveDetails?.airportName,
        arrAirportCode:
          segmentsRet && segmentsRet[lsRet]?.arriveDetails?.airportCode,
        arrivalTime: dateFnsFormat(arrDateRawRet, "HH:mm"),
        arrivalDate: segmentsRet && dateFnsFormat(arrDateRawRet, "dd MMM"),
        arrivalDay: segmentsRet && days[arrDateRawRet.getDay()],
        ruleArray: retSelectedFareObj && retSelectedFareObj?.rule?.split("|"),
        layover:
          totalLayoverRet > 0
            ? (Math.floor(totalLayoverRet / 60) > 0
                ? Math.floor(totalLayoverRet / 60) + " hr "
                : "") +
              Math.floor(totalLayoverRet % 60) +
              " min"
            : 0,
        journeyTime:
          totalJourneyTimeRet > 0
            ? Math.floor(totalJourneyTimeRet / 60) +
              " hr " +
              Math.floor(totalJourneyTimeRet % 60) +
              " min"
            : 0,
        stopAirports: stopAirportsRet,
      });

      setIsRoundTrip(true);
    }
  };

  React.useEffect(() => {
    initiateFareDetails();
  }, []);

  // console.log(onwardFlightDetails)
  // console.log(
  //   "🚀 ~ file: FlightDetails.js:250 ~ FlightDetails ~ onwardFlightDetails:",
  //   onwardFlightDetails
  // );

  // console.log(onwardFlightDetails);

  return (
    <div className={classes.root}>
      {onwardFlightDetails && onwardFlightDetails.stopAirports === "" && (
        <div className="details-container">
          <Grid container justifyContent="space-between">
      

            <Grid item md={2}>
              <Box className="flight-identity">
                <Box>
                  <img
                    src={
                      image_base +
                      "?fileName=" +
                      onwardFlightDetails?.flightCode +
                      ".png&type=AIRLINE_IMAGES"
                    }
                    alt="Airline Image"
                  />
                </Box>
                <Box className="font2">
                  <Typography>{onwardFlightDetails?.airLineName}</Typography>
                </Box>
                <Box className="font4">
                  <Typography>
                    {onwardFlightDetails?.flightCode}-
                    {onwardFlightDetails?.flightNumber}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={3} style={{ textAlign: "center" }}>
              <Box className="departure-section">
                <Box className="font1">
                  <Typography>{onwardFlightDetails?.departTime}</Typography>
                </Box>
                <Box className="font2">
                  <Typography className="flightDay">
                    {onwardFlightDetails?.departDay},{" "}
                  </Typography>
                  <Typography>{onwardFlightDetails?.departDate}</Typography>
                </Box>
                <Box className="font3" style={{ marginTop: 0 }}>
                  <Typography className="flightDay">
                    {onwardFlightDetails?.depAirportName}
                  </Typography>
                  <Typography>
                    ({onwardFlightDetails?.depAirportCode})
                  </Typography>
                </Box>
                <Box className="font4">
                  <Typography className="flightDay">
                    {onwardFlightDetails?.depAirportName} Airport
                  </Typography>
                  {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                </Box>
              </Box>
            </Grid>

            <Grid item md={4}>
              <Box className="duration-section">
                <Box className="font2">
                  <Typography style={{ textAlign: "center" }}>
                    <i className="fa fa-clock-o"></i>{" "}
                    {/* {onwardFlightDetails?.journeyTime} */}
                    {Math.floor(onwardObj?.onwardResp[0]?.flightTime / 60) +
                        " hr " +
                        Math.floor(onwardObj?.onwardResp[0]?.flightTime % 60) +
                        " min"}
                  </Typography>
                </Box>
                <div className="dash-line"></div>
                <Box className="font4">
                  <Typography style={{ textAlign: "center" }}>
                    {/* 30 min layover at chennai */}
                    {onwardFlightDetails?.layover != 0
                      ? (onwardFlightDetails?.layover != 0
                          ? onwardFlightDetails?.layover
                          : "0 hr") +
                        " layover at " +
                        onwardFlightDetails?.stopAirports
                      : "Non-Stop"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item md={3} style={{ textAlign: "center" }}>
              <Box className="arrival-section">
                <Box className="font1">
                  <Typography>{onwardFlightDetails?.arrivalTime}</Typography>
                </Box>
                <Box className="font2">
                  <Typography className="flightDay">
                    {onwardFlightDetails?.arrivalDay},{" "}
                  </Typography>
                  <Typography>{onwardFlightDetails?.arrivalDate}</Typography>
                </Box>
                <Box className="font3" style={{ marginTop: 0 }}>
                  <Typography className="flightDay">
                    {onwardFlightDetails?.arrAirportName}
                  </Typography>
                  <Typography>
                    ({onwardFlightDetails?.arrAirportCode})
                  </Typography>
                </Box>
                <Box className="font4">
                  <Typography className="flightDay">
                    {onwardFlightDetails?.arrAirportName} Airport
                  </Typography>
                  {/* <Typography>Delhi, India</Typography> */}
                </Box>
              </Box>
            </Grid>

            {/* ENDS */}
          </Grid>

          {/* {width > breakpoint && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <Collapse in={openAirlineTnCOnw}>
                  <FareRuleComponent
                    onClickFareRule={onClickFareRuleOnw}
                    fareRules={fareRulesOnw}
                    loading={loading}
                  />
                </Collapse>
              </Grid>
            </Grid>
          )} */}
          {/* <FareRulePopup
            open={openAirlineTnCOnw}
            onClose={() => setOpenAirlineTnCOnw(false)}
            onClickFareRule={onClickFareRuleOnw}
            fareRules={fareRulesOnw}
            loading={loading}
            classes={classes}
            width={width}
            breakpoint={breakpoint}
          /> */}
        </div>
      )}
      {onwardFlightDetails &&
        onwardFlightDetails.stopAirports != "" &&
        tripMode != "MULTICITY" && (
          <div className="details-container">
            <Grid container justifyContent="space-between">
      

              {/* STARTS - 1 */}
              <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        onwardObj?.onwardResp[0]?.carrierDetails?.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>
                      {onwardObj?.onwardResp[0]?.carrierDetails?.carrierName}
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {onwardObj?.onwardResp[0]?.carrierDetails?.carrierCode}-
                      {onwardObj?.onwardResp[0]?.carrierDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[0]?.departDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.departDay},{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[0]?.departDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      ({onwardObj?.onwardResp[0]?.departDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName} Airport
                    </Typography>
                    {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {/* {onwardFlightDetails?.journeyTime} */}
                      {Math.floor(onwardObj?.onwardResp[0]?.flightTime / 60) +
                        " hr " +
                        Math.floor(onwardObj?.onwardResp[0]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                      {/* 30 min layover at chennai */}
                      {/* {onwardFlightDetails?.layover != 0
                      ? (onwardFlightDetails?.layover != 0
                          ? onwardFlightDetails?.layover
                          : "0 hr") +
                        " layover at " +
                        onwardFlightDetails?.stopAirports
                      : "Non-Stop"} */}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[0]?.arriveDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[0]?.arriveDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      {/* ({onwardFlightDetails?.arrAirportCode}) */}(
                      {onwardObj?.onwardResp[0]?.arriveDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName} Airport
                    </Typography>
                    {/* <Typography>Delhi, India</Typography> */}
                  </Box>
                </Box>
              </Grid>
              {/* ENDS - 1*/}

              <Grid
                md={12}
                xs={12}
                sm={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
                <Grid
                  style={{
                    width: 300,
                    display: "flex",
                    backgroundColor: theme.palette.background.rgba_primary,
                    borderRadius: 10,
                    padding: 4,
                    flexDirection: "column",
                    alignItems: "center",
                    fontWeight: 700,
                    fontSize: 13,
                  }}
                >
                  <Grid>
                    Change plane at {onwardFlightDetails?.stopAirports}
                  </Grid>
                  layover time:{" "}
                  {onwardFlightDetails?.layover != 0
                    ? onwardFlightDetails?.layover != 0
                      ? onwardFlightDetails?.layover
                      : "0 hr"
                    : "0 hr"}
                </Grid>
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
              </Grid>

              {/* STARTS - 2 */}
              <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        onwardObj?.onwardResp[1]?.carrierDetails?.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>
                      {" "}
                      {onwardObj?.onwardResp[1]?.carrierDetails?.carrierName}
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {onwardObj?.onwardResp[1]?.carrierDetails?.carrierCode}-
                      {onwardObj?.onwardResp[1]?.carrierDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.departDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {/* {onwardFlightDetails?.departDay},{" "} */}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.departDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      {/* ({onwardFlightDetails?.depAirportCode}) */}(
                      {onwardObj?.onwardResp[1]?.departDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName} Airport
                    </Typography>
                    {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {Math.floor(onwardObj?.onwardResp[1]?.flightTime / 60) +
                        " hr " +
                        Math.floor(onwardObj?.onwardResp[1]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                      {/* 30 min layover at chennai */}
                      {/* {onwardFlightDetails?.layover != 0
                      ? (onwardFlightDetails?.layover != 0
                          ? onwardFlightDetails?.layover
                          : "0 hr") +
                        " layover at " +
                        onwardFlightDetails?.stopAirports
                      : "Non-Stop"} */}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.arriveDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.arriveDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      {/* ({onwardFlightDetails?.arrAirportCode}) */}(
                      {onwardObj?.onwardResp[1]?.arriveDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName} Airport
                    </Typography>
                    {/* <Typography>Delhi, India</Typography> */}
                  </Box>
                </Box>
              </Grid>
              {/* ENDS -2  */}
            </Grid>

            {/* {width > breakpoint && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <Collapse in={openAirlineTnCOnw}>
                  <FareRuleComponent
                    onClickFareRule={onClickFareRuleOnw}
                    fareRules={fareRulesOnw}
                    loading={loading}
                  />
                </Collapse>
              </Grid>
            </Grid>
          )} */}
            {/* <FareRulePopup
              open={openAirlineTnCOnw}
              onClose={() => setOpenAirlineTnCOnw(false)}
              onClickFareRule={onClickFareRuleOnw}
              fareRules={fareRulesOnw}
              loading={loading}
              classes={classes}
              width={width}
              breakpoint={breakpoint}
            /> */}
          </div>
        )}
      {onwardFlightDetails &&
        onwardFlightDetails.stopAirports != "" &&
        tripMode === "MULTICITY" && (
          <div className="details-container">
            <Grid container justifyContent="space-between">
           

            

              {/* STARTS - 1 */}

              {onwardObj.segment.map((seg) => (
                <>
                  <Grid item md={2}>
                    <Box className="flight-identity">
                      <Box>
                        <img
                          src={
                            image_base +
                            "?fileName=" +
                            seg?.carrierDetails?.carrierCode +
                            ".png&type=AIRLINE_IMAGES"
                          }
                          alt="Airline Image"
                        />
                      </Box>
                      <Box className="font2">
                        <Typography>
                          {seg.carrierDetails?.carrierName}
                        </Typography>
                      </Box>
                      <Box className="font4">
                        <Typography>
                          {seg?.carrierDetails?.carrierCode}-
                          {seg.carrierDetails?.flightNumber}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={3} style={{ textAlign: "center" }}>
                    <Box className="departure-section">
                      <Box className="font1">
                        <Typography>
                          {" "}
                          {dateFnsFormat(
                            new Date(seg?.departDetails?.dateTime),
                            "HH:mm"
                          )}
                        </Typography>
                      </Box>
                      <Box className="font2">
                        <Typography className="flightDay">
                          {onwardFlightDetails?.departDay},{" "}
                        </Typography>
                        <Typography>
                          {" "}
                          {dateFnsFormat(
                            new Date(seg?.departDetails?.dateTime),
                            "EEE, dd MMM"
                          )}
                        </Typography>
                      </Box>
                      <Box className="font3" style={{ marginTop: 0 }}>
                        <Typography className="flightDay">
                          {onwardFlightDetails?.depAirportName}
                        </Typography>
                        <Typography>
                          ({seg?.departDetails?.airportCode})
                        </Typography>
                      </Box>
                      <Box className="font4">
                        <Typography className="flightDay">
                          {onwardFlightDetails?.depAirportName} Airport
                        </Typography>
                        {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={4}>
                    <Box className="duration-section">
                      <Box className="font2">
                        <Typography style={{ textAlign: "center" }}>
                          <i className="fa fa-clock-o"></i>{" "}
                          {/* {onwardFlightDetails?.journeyTime} */}
                          {Math.floor(seg?.flightTime / 60) +
                            " hr " +
                            Math.floor(seg?.flightTime % 60) +
                            " min"}
                                {/* {Math.floor(onwardObj?.onwardResp[0]?.flightTime / 60) +
                        " hr " +
                        Math.floor(onwardObj?.onwardResp[0]?.flightTime % 60) +
                        " min"} */}
                        </Typography>
                      </Box>
                      <div className="dash-line"></div>
                      <Box className="font4">
                        <Typography style={{ textAlign: "center" }}>
                          {/* 30 min layover at chennai */}
                          {/* {onwardFlightDetails?.layover != 0
                      ? (onwardFlightDetails?.layover != 0
                          ? onwardFlightDetails?.layover
                          : "0 hr") +
                        " layover at " +
                        onwardFlightDetails?.stopAirports
                      : "Non-Stop"} */}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>

                  <Grid item md={3} style={{ textAlign: "center" }}>
                    <Box className="arrival-section">
                      <Box className="font1">
                        <Typography>
                          {" "}
                          {dateFnsFormat(
                            new Date(seg?.arriveDetails?.dateTime),
                            "HH:mm"
                          )}
                        </Typography>
                      </Box>
                      <Box className="font2">
                        <Typography className="flightDay">
                          {onwardFlightDetails?.arrivalDay},{" "}
                        </Typography>
                        <Typography>
                          {" "}
                          {dateFnsFormat(
                            new Date(seg?.arriveDetails?.dateTime),
                            "EEE, dd MMM"
                          )}
                        </Typography>
                      </Box>
                      <Box className="font3" style={{ marginTop: 0 }}>
                        <Typography className="flightDay">
                          {onwardFlightDetails?.arrAirportName}
                        </Typography>
                        <Typography>
                          {/* ({onwardFlightDetails?.arrAirportCode}) */}(
                          {seg?.arriveDetails?.airportCode})
                        </Typography>
                      </Box>
                      <Box className="font4">
                        <Typography className="flightDay">
                          {onwardFlightDetails?.arrAirportName} Airport
                        </Typography>
                        {/* <Typography>Delhi, India</Typography> */}
                      </Box>
                    </Box>
                  </Grid>
                </>
              ))}
              {/* ENDS - 1*/}

              {/* <Grid
                md={12}
                xs={12}
                sm={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
                <Grid
                  style={{
                    width: 300,
                    display: "flex",
                    backgroundColor: theme.palette.background.rgba_primary,
                    borderRadius: 10,
                    padding: 4,
                    flexDirection: "column",
                    alignItems: "center",
                    fontWeight: 700,
                    fontSize: 13,
                  }}
                >
                  <Grid>
                    Change plane at {onwardFlightDetails?.stopAirports}
                  </Grid>
                  layover time:{" "}
                  {onwardFlightDetails?.layover != 0
                    ? onwardFlightDetails?.layover != 0
                      ? onwardFlightDetails?.layover
                      : "0 hr"
                    : "Non-Stop"}
                </Grid>
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
              </Grid> */}

              {/* STARTS - 2 */}
              {/* <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        onwardObj?.onwardResp[1]?.carrierDetails?.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>
                      {" "}
                      {onwardObj?.onwardResp[1]?.carrierDetails?.carrierName}
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {onwardObj?.onwardResp[1]?.carrierDetails?.carrierCode}-
                      {onwardObj?.onwardResp[1]?.carrierDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.departDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.departDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      {onwardObj?.onwardResp[1]?.departDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.depAirportName} Airport
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {Math.floor(onwardObj?.onwardResp[1]?.flightTime / 60) +
                        " hr " +
                        Math.floor(onwardObj?.onwardResp[1]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.arriveDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>
                      {dateFnsFormat(
                        new Date(
                          onwardObj?.onwardResp[1]?.arriveDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      {onwardObj?.onwardResp[1]?.arriveDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {onwardFlightDetails?.arrAirportName} Airport
                    </Typography>
                  </Box>
                </Box>
              </Grid> */}
              {/* ENDS -2  */}
            </Grid>

            {/* {width > breakpoint && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <Collapse in={openAirlineTnCOnw}>
                  <FareRuleComponent
                    onClickFareRule={onClickFareRuleOnw}
                    fareRules={fareRulesOnw}
                    loading={loading}
                  />
                </Collapse>
              </Grid>
            </Grid>
          )} */}
            {/* <FareRulePopup
              open={openAirlineTnCOnw}
              onClose={() => setOpenAirlineTnCOnw(false)}
              onClickFareRule={onClickFareRuleOnw}
              fareRules={fareRulesOnw}
              loading={loading}
              classes={classes}
              width={width}
              breakpoint={breakpoint}
            /> */}
          </div>
        )}

{isRoundTrip &&
        returnFlightDetails &&
        returnFlightDetails?.stopAirports === "" && <div style={{borderTop: "2px solid #eee "}}></div> }

      {isRoundTrip &&
        returnFlightDetails &&
        returnFlightDetails?.stopAirports === "" && (
          <div className="details-container">
            <Grid container justifyContent="space-between">
       

              <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        returnFlightDetails?.flightCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>{returnFlightDetails?.airLineName}</Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {returnFlightDetails?.flightCode}-
                      {returnFlightDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>{returnFlightDetails?.departTime}</Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.departDay},{" "}
                    </Typography>
                    <Typography>{returnFlightDetails?.departDate}</Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      ({returnFlightDetails?.depAirportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName} Airport
                    </Typography>
                    {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {/* {returnFlightDetails?.journeyTime} */}
                      {Math.floor(returnObj?.returnResp[0]?.flightTime / 60) +
                        " hr " +
                        Math.floor(returnObj?.returnResp[0]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                      {/* 30 min layover at chennai */}
                      {returnFlightDetails?.layover != 0
                        ? (returnFlightDetails?.layover != 0
                            ? returnFlightDetails?.layover
                            : "0 hr") +
                          " layover at " +
                          returnFlightDetails?.stopAirports
                        : "Non-Stop"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>{returnFlightDetails?.arrivalTime}</Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>{returnFlightDetails?.arrivalDate}</Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      ({returnFlightDetails?.arrAirportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName} Airport
                    </Typography>
                    {/* <Typography>Delhi, India</Typography> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* {width > breakpoint && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <Collapse in={openAirlineTnCRet}>
                  <FareRuleComponent
                    onClickFareRule={onClickFareRuleRet}
                    fareRules={fareRulesRet}
                    loading={loading}
                  />
                </Collapse>
              </Grid>
            </Grid>
          )} */}
            {/* {width < breakpoint && ( */}
            {/* <FareRulePopup
              open={openAirlineTnCRet}
              onClose={() => setOpenAirlineTnCRet(false)}
              onClickFareRule={onClickFareRuleRet}
              fareRules={fareRulesRet}
              loading={loading}
              classes={classes}
              width={width}
              breakpoint={breakpoint}
            /> */}
            {/* )} */}
          </div>
        )}
      {/* ROUND TRIP -2 STARTS */}
      {isRoundTrip &&
        returnFlightDetails &&
        returnFlightDetails?.stopAirports != "" && (
          <div className="details-container">
            <Grid container justifyContent="space-between">
      

              {/* STARTS 1 */}
              <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        returnObj?.returnResp[0]?.carrierDetails?.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>
                      {returnObj?.returnResp[0]?.carrierDetails?.carrierName}
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {returnObj?.returnResp[0]?.carrierDetails?.carrierCode}-
                      {returnObj?.returnResp[0]?.carrierDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[0]?.departDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.departDay},{" "}
                    </Typography>
                    <Typography>
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[0]?.departDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      ({returnObj?.returnResp[0]?.departDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName} Airport
                    </Typography>
                    {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {Math.floor(returnObj?.returnResp[1]?.flightTime / 60) +
                        " hr " +
                        Math.floor(returnObj?.returnResp[1]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                      {/* 30 min layover at chennai */}
                      {/* {returnFlightDetails?.layover != 0
                        ? (returnFlightDetails?.layover != 0
                            ? returnFlightDetails?.layover
                            : "0 hr") +
                          " layover at " +
                          returnFlightDetails?.stopAirports
                        : "Non-Stop"} */}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[0]?.arriveDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[0]?.arriveDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      ({returnObj?.returnResp[0]?.arriveDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName} Airport
                    </Typography>
                    {/* <Typography>Delhi, India</Typography> */}
                  </Box>
                </Box>
              </Grid>
              {/* ENDS */}
              <Grid
                md={12}
                xs={12}
                sm={12}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
                <Grid
                  style={{
                    width: 300,
                    display: "flex",
                    backgroundColor: theme.palette.background.rgba_primary,
                    borderRadius: 10,
                    padding: 4,
                    flexDirection: "column",
                    alignItems: "center",
                    fontWeight: 700,
                    fontSize: 13,
                  }}
                >
                  <Grid>
                    Change plane at {returnFlightDetails?.stopAirports}
                  </Grid>
                  layover time:{" "}
                  {returnFlightDetails?.layover != 0
                    ? returnFlightDetails?.layover != 0
                      ? returnFlightDetails?.layover
                      : "0 hr"
                    : "0 hr"}{" "}
                </Grid>
                <Divider
                  style={{
                    backgroundColor: theme.palette.background.rgba_primary,
                    width: "30%",
                  }}
                />
              </Grid>
              {/* STARTS */}
              <Grid item md={2}>
                <Box className="flight-identity">
                  <Box>
                    <img
                      src={
                        image_base +
                        "?fileName=" +
                        returnObj?.returnResp[1]?.carrierDetails?.carrierCode +
                        ".png&type=AIRLINE_IMAGES"
                      }
                      alt="Airline Image"
                    />
                  </Box>
                  <Box className="font2">
                    <Typography>
                      {returnObj?.returnResp[1]?.carrierDetails?.carrierName}
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography>
                      {returnObj?.returnResp[1]?.carrierDetails?.carrierCode}-
                      {returnObj?.returnResp[1]?.carrierDetails?.flightNumber}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="departure-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[1]?.departDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.departDay},{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[1]?.departDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName}
                    </Typography>
                    <Typography>
                      ({returnObj?.returnResp[1]?.departDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.depAirportName} Airport
                    </Typography>
                    {/* <Typography>Bangalore, Karnataka, India</Typography> */}
                  </Box>
                </Box>
              </Grid>

              <Grid item md={4}>
                <Box className="duration-section">
                  <Box className="font2">
                    <Typography style={{ textAlign: "center" }}>
                      <i className="fa fa-clock-o"></i>{" "}
                      {Math.floor(returnObj?.returnResp[1]?.flightTime / 60) +
                        " hr " +
                        Math.floor(returnObj?.returnResp[1]?.flightTime % 60) +
                        " min"}
                    </Typography>
                  </Box>
                  <div className="dash-line"></div>
                  <Box className="font4">
                    <Typography style={{ textAlign: "center" }}>
                      {/* 30 min layover at chennai */}
                      {/* {returnFlightDetails?.layover != 0
                        ? (returnFlightDetails?.layover != 0
                            ? returnFlightDetails?.layover
                            : "0 hr") +
                          " layover at " +
                          returnFlightDetails?.stopAirports
                        : "Non-Stop"} */}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3} style={{ textAlign: "center" }}>
                <Box className="arrival-section">
                  <Box className="font1">
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[1]?.arriveDetails?.dateTime
                        ),
                        "HH:mm"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font2">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrivalDay},{" "}
                    </Typography>
                    <Typography>
                      {" "}
                      {dateFnsFormat(
                        new Date(
                          returnObj?.returnResp[1]?.arriveDetails?.dateTime
                        ),
                        "EEE, dd MMM"
                      )}
                    </Typography>
                  </Box>
                  <Box className="font3" style={{ marginTop: 0 }}>
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName}
                    </Typography>
                    <Typography>
                      ({returnObj?.returnResp[1]?.arriveDetails?.airportCode})
                    </Typography>
                  </Box>
                  <Box className="font4">
                    <Typography className="flightDay">
                      {returnFlightDetails?.arrAirportName} Airport
                    </Typography>
                    {/* <Typography>Delhi, India</Typography> */}
                  </Box>
                </Box>
              </Grid>
              {/* ENDS */}
            </Grid>

            {/* {width > breakpoint && (
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <Collapse in={openAirlineTnCRet}>
                  <FareRuleComponent
                    onClickFareRule={onClickFareRuleRet}
                    fareRules={fareRulesRet}
                    loading={loading}
                  />
                </Collapse>
              </Grid>
            </Grid>
          )} */}
            {/* {width < breakpoint && ( */}
            {/* <FareRulePopup
              open={openAirlineTnCRet}
              onClose={() => setOpenAirlineTnCRet(false)}
              onClickFareRule={onClickFareRuleRet}
              fareRules={fareRulesRet}
              loading={loading}
              classes={classes}
              width={width}
              breakpoint={breakpoint}
            /> */}
            {/* )} */}
          </div>
        )}
      {/* ROUND TRIP -2 ENDS */}

      {/* <div className="margin-holder"></div> */}

      {/* <Grid container className="button-holder">
                <button className="action-button" onClick={() => flightDetailsSubmit()}>
                    Continue
                </button>
            </Grid> */}
    </div>
  );
};

export default FlightMoreDetailsInt;

const FareRulePopup = ({
  open,
  onClose,
  onClickFareRule,
  fareRules,
  loading,
  classes,
  width,
  breakpoint,
}) => {
  const style = {
    position: width < breakpoint ? "absolute" : "relative",
    // top: width < breakpoint ? "50%" : 250,
    // left: width < breakpoint ? "50%" : 305,
    // transform: "translate(-50%, -50%)",
    height: "100%",
    width: "100%",
    bgcolor: "background.paper",
    borderRadius: 10,
    boxShadow: 24,
    p: 2,
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.dialogPaper,
      }}
      fullScreen={width < breakpoint}
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {" "}
          <IconButton onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </Box>
        <FareRuleComponent
          onClickFareRule={onClickFareRule}
          fareRules={fareRules}
          loading={loading}
        />
      </Box>
    </Dialog>
  );
};

const FareRuleComponent = (props) => {
  const width = window.innerWidth;
  const breakpoint = 980;
  return (
    <div className="farerule-container">
      <h6 className="h6-title">
        Cancellation & Refund{" "}
        {/*width > breakpoint && (
          <IconButton onClick={props.onClickFareRule}>
            {" "}
            <CloseIcon />
          </IconButton>
        )*/}{" "}
      </h6>
      {props.loading ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        // <ul>
        //   {props.fareRules &&
        //     props.fareRules.split("|").map((rule, ind) => {
        //       if (rule != "" && rule != null) {
        //         //console.log("rule ",rule);
        //         const uriDecoded = rule;
        //         //console.log("uriDecoded ",uriDecoded);
        //         return (
        //           <li key={ind}>
        //             {HTMLReactParser(uriDecoded.replaceAll("+", " "))}
        //           </li>
        //         );
        //       } else {
        //         return "";
        //       }
        //     })}
        // </ul>
        props.fareRules &&
        props.fareRules.split("|").map((rule, ind) => {
          if (rule != "" && rule != null) {
            //console.log("rule ",rule);
            const uriDecoded = rule;
            //console.log("uriDecoded ",uriDecoded);
            return (
              <div key={ind}>
                {HTMLReactParser(uriDecoded.replaceAll("+", " "))}
              </div>
            );
          } else {
            return "";
          }
        })
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .bookOutlined": {
      marginTop: 2,
      "& .MuiSvgIcon-root": {
        width: 15,
        height: 15,
      },
    },
    "& .fareTypeText": {
      fontSize: 14,
      fontWeight: 700,
    },
    "& .farerule-container": {
      height: 250,
      padding: 10,
      overflowY: "auto",
      paddingRight: 10,
      border: `1px solid #e1e1e1`,
      borderRadius: 5,

      [theme.breakpoints.down(450)]: {
        // width: 380,
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // width: 335,
        width: "100%",
        // height: "100%",
      },
      "& .h6-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 6px",

        marginLeft: 13,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.danger,
        },
      },
      "& .loader": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // marginTop:'3rem',
      },
      "& .loader": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // marginTop:'3rem',
      },
      "& .row": {
        marginRight: -15,
        marginLeft: -15,
        display: "flex",
      },
      "& .col-xs-12": {
        width: "100%",
      },
      "& .col-lg-6, .col-md-6": {
        width: "50%",
        [theme.breakpoints.down(992)]: {
          width: "100%",
        },
      },
      "& .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        float: "left",
      },
      "& .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        position: "relative",
        minHeight: 1,
        paddingRight: 15,
        paddingLeft: 15,
      },
      [theme.breakpoints.down(992)]: {
        "& .col-md-6": {
          width: "50%",
        },
        "& .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9": {
          float: "left",
        },
      },
      "& .table-bordered": {
        border: "1px solid #CDCDCD",
      },
      "& .table": {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 20,
      },
      "& table": {
        backgroundColor: "transparent",
      },
      "& table": {
        borderCollapse: "collapse",
        borderSpacing: 0,
        display: "table",
        boxSizing: "border-box",
        textIndent: "initial",
        borderSpacing: 2,
        borderColor: "gray",
      },
      "& .table-striped>tbody>tr:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& tr": {
        display: "table-row",
        verticalAlign: "inherit",
        borderColor: "inherit",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th": {
        border: "1px solid #CDCDCD",
      },
      "& td, th": {
        padding: 0,
      },
      "& .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th": {
        padding: 8,
        lineHeight: 1.42857143,
        verticalAlign: "top",
        borderTop: "1px solid #CDCDCD",
      },
      "& td": {
        display: "table-cell",
        verticalAlign: "inherit",
      },
    },
    "& .fare-extra-info": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 10,
      paddingRight: 10,
      "& .addon-text-p1": {
        textAlign: "right",
        fontWeight: 600,
        fontSize: 14,
        [theme.breakpoints.down(450)]: {
          marginLeft: 12,
          fontSize: 11,
        },
      },
      [theme.breakpoints.down(960)]: {
        gap: 5,
      },
      [theme.breakpoints.down(600)]: {
        justifyContent: "center",
      },
    },
    "& .extra-Details": {
      alignItems: "center",
      background: theme.palette.background.primary,
      borderRadius: 25,
      padding: "5px 15px",
      marginBottom: 10,
      gap: 10,

      "& .addon-text-p1": {
        color: theme.palette.background.default,
        fontWeight: 500,
      },
      [theme.breakpoints.down(450)]: {},
    },
    "& .flight-identity": {
      padding: 10,
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down(365)]: {
        width: 80,
      },
      "& img": {
        height: 40,
      },
    },
    "& .departure-section": {
      padding: 10,
      padding: "10px 0px",
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down(450)]: {
        width: 60,
      },
    },
    "& .arrival-section": {
      padding: 10,
      padding: "10px 0px",
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down(450)]: {
        width: 60,
      },
    },
    "& .duration-section": {
      padding: 10,
      padding: "10px 0px",
      paddingTop: 0,
      paddingBottom: 0,
      "& .flightTimings": {
        [theme.breakpoints.down(960)]: {
          width: 80,
        },
      },
    },
    "& .dash-line": {
      // background: "#012F89",
      background: theme.palette.primary.darkText,
      height: 1,
      width: "100%",
      margin: "10px 0",
    },
    "& .font1": {
      "& p": {
        fontSize: 18,
        fontWeight: 500,
        lineHeight: "20px",
      },
    },
    "& .font2": {
      "& p": {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "20px",
      },
      "& .flightDay": {
        display: "none",
        [theme.breakpoints.down(450)]: {
          display: "none",
        },
      },
    },
    "& .font3": {
      "& p": {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1,
      },
      "& .flightDay": {
        display: "none",
        [theme.breakpoints.down(450)]: {
          display: "none",
        },
      },
    },
    "& .font4": {
      "& p": {
        fontSize: 11,
        fontWeight: 500,
        // lineHeight: "20px",
      },
      "& .flightDay": {
        display: "none",
        [theme.breakpoints.down(450)]: {
          display: "none",
        },
      },
    },
    "& .addon-box": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      width: "100%",
      "& .addon-icon": {
        display: "flex",
        alignItems: "center",
        "& img": {
          height: 15,
          filter: "invert(100%)",
        },
        marginRight: 10,
      },
    },

    "& .fareText": {
      [theme.breakpoints.down(450)]: {
        // width: 80,
      },
    },
    "& .addon-text-p1": {
      fontSize: 13,
      "&.f12": {
        fontSize: 12,
      },
    },
    "& .addon-text-p2": {
      fontSize: 11,
    },
    "& .button-holder": {
      marginTop: 50,
      justifyContent: "right",
    },
    "& .action-button": {
      background: theme.palette.primary.main,
      padding: "5px",
      textAlign: "center",
      color: theme.palette.primary.contrastText,
      width: 200,
      height: 45,
      borderRadius: 5,
      fontSize: 22,
      fontWeight: 500,
      appearance: "none",
      MozAppearance: "none",
      WebkitAppearance: "none",
      border: "none !important",
      outline: "none !important",
      cursor: "pointer",
    },
    "& .airline-tnc": {
      cursor: "pointer",
      color: `${theme.palette.secondary.crimson} !important`,
      border: ` 1px solid ${theme.palette.secondary.crimson}`,
      borderRadius: 6,
      padding: 5,
    },
    "& .margin-holder": {
      width: "100%",
      borderTop: `solid 2px ${theme.palette.primary.main}`,
      margin: "10px 0 20px",
    },
  },
  dialogPaper: {
    "& .farerule-container": {
      //height: 250,
      padding: 10,
      overflowY: "auto",
      paddingRight: 10,
      border: `1px solid #e1e1e1`,
      borderRadius: 5,
      [theme.breakpoints.down(980)]: {
        // width: 380,
        height: "calc(100% - 65px)",
        overflowX: "hidden",
        overflowY: "scroll",
        width: "100%",
      },
      [theme.breakpoints.down(450)]: {
        // width: 380,
        width: "100%",
      },
      [theme.breakpoints.down(400)]: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        // width: 335,
        width: "100%",
        // height: "100%",
      },
      "& .h6-title": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 6px",

        marginLeft: 13,
        "& .MuiSvgIcon-root": {
          color: theme.palette.primary.danger,
        },
      },
      "& .loader": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // marginTop:'3rem',
      },
      "& .loader": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        // marginTop:'3rem',
      },
      "& .row": {
        marginRight: -15,
        marginLeft: -15,
        display: "flex",
      },
      "& .col-xs-12": {
        width: "100%",
      },
      "& .col-lg-6, .col-md-6": {
        width: "50%",
        [theme.breakpoints.down(992)]: {
          width: "100%",
        },
      },
      "& .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        float: "left",
      },
      "& .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9": {
        position: "relative",
        minHeight: 1,
        paddingRight: 15,
        paddingLeft: 15,
      },
      [theme.breakpoints.down(992)]: {
        "& .col-md-6": {
          width: "50%",
        },
        "& .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9": {
          float: "left",
        },
      },
      "& .table-bordered": {
        border: "1px solid #CDCDCD",
      },
      "& .table": {
        width: "100%",
        maxWidth: "100%",
        marginBottom: 20,
      },
      "& table": {
        backgroundColor: "transparent",
      },
      "& table": {
        borderCollapse: "collapse",
        borderSpacing: 0,
        display: "table",
        boxSizing: "border-box",
        textIndent: "initial",
        borderSpacing: 2,
        borderColor: "gray",
      },
      "& .table-striped>tbody>tr:nth-of-type(odd)": {
        backgroundColor: "#f9f9f9",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& tr": {
        display: "table-row",
        verticalAlign: "inherit",
        borderColor: "inherit",
        "&:hover": {
          backgroundColor: "#C0BEBE",
        },
      },
      "& .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th": {
        border: "1px solid #CDCDCD",
      },
      "& td, th": {
        padding: 0,
      },
      "& .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th": {
        padding: 8,
        lineHeight: 1.42857143,
        verticalAlign: "top",
        borderTop: "1px solid #CDCDCD",
      },
      "& td": {
        display: "table-cell",
        verticalAlign: "inherit",
      },
    },
  },
}));
