import React, { useState } from 'react'
import dateFnsFormat from "date-fns/format";

import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import {
    TextField,

  } from "@material-ui/core";

const BusDateInput = ({props, type, setFieldValue}) => {
    const [startDate, setStartDate] = useState(new Date());
    const today = new Date();
    
    const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);


  return (
    <OaOutlinedDatePicker
    showDisabledMonthNavigation
    name="DateOfJourney"
    // className={classes.checkinDatePicker}
    minDate={startDate}
    customInput={
      <TextField id="standard-basic" variant="standard" />
    }
    value={props?.values?.DateOfJourney?? props.value}
    onChange={(e, v) => {
      // busDestinationAutocompleteRef.current.blur();
      setDepartureDateDialogOpen(false);
      // console.log(
      //   document.getElementById("searchActionButton")
      // );
      // document.getElementById("searchActionButton").focus();
      // setData({ ...data, departureDate: e });
      if(setFieldValue) {
        setFieldValue("DateOfJourney", e);
      }else {
        props.setField(e)
      }
    }}
    KeyboardButtonProps={{
      onFocus: (e) => {
        setDepartureDateDialogOpen(true);
      },
    }}
    PopoverProps={{
      disableRestoreFocus: true,
      onClose: () => {
        setDepartureDateDialogOpen(false);
      },
    }}
    InputProps={{
      onFocus: () => {
        setDepartureDateDialogOpen(true);
      },
    }}
    monthsShown={2}
    dateFormat="dd MMM yyyy"
    //labeltxt="DEPARTURE DATE"
    selected={props?.values?.DateOfJourney?? props.value}
    showinput={true}
  />
  )
}

export default BusDateInput
