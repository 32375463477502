import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  makeStyles,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  withWidth,
  Popper,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { localforageGetItem } from "oautils/oaForageUtils";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { connect } from "react-redux";
import { setBusCities, setBusBookingInitialTime } from "redux/action";

import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import OaFormAlerts from "pages/components/OaFormAlerts";
import _ from "lodash";
import dateFnsFormat from "date-fns/format";
import { COLORS, FONTS } from "assets/css/CssConstants";
import { AiOutlineSwap } from "react-icons/ai";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import BusLocationInput from "../../../components/CommonSearchComponents/Bus/BusLocationInput"
import BusDateInput from "../../../components/CommonSearchComponents/Bus/BusDateInput"



const useStyles = makeStyles((theme) => ({
  root: {
    "& .bus-location-input":{
      background: "transparent",
    },
    "& .MuiFormLabel-root": {
      color: theme.palette.primary.defaultText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.defaultText,
    },

    "& .MuiAutocomplete-popupIndicator": {
      display: "none",
    },
    "& .location-input": {
      padding: "10px",
      width: "100%",
      //   borderRight: '2px solid #eee',
    },

    "& .inputs-container-main": {
      background: "#fff",
      borderRadius: "4px",
      // padding: "6px",
      // boxShadow: '4px 4px 4px #c2d87e',
      "& .toform": {
        position: "relative",
        margin: "6px 0px",
        borderRadius: "4px",
        background: theme.palette.background.mobileSearch,
        border: `1.3px solid ${theme.palette.background.mobileSearchBorder}`,
        "& .lineDiv": {
          position: "absolute",
          width: "97%",
          height: "1.3px",
          top: "50%",
          left: "10px",
          backgroundColor: theme.palette.background.mobileSearchBorder,
        },
        "& .MuiAutocomplete-fullWidth": {
          marginTop: "20px",
        },
      },
      "& .swap-icon": {
        position: "absolute",
        right: "10px",
        top: "66px",
        rotate: "90deg",

        "& .MuiIconButton-label": {
          color: theme.palette.primary.defaultText,
          backgroundColor: theme.palette.primary.LightGraySec,
          borderRadius: 50,
          padding: 6,
        },
        "&.MuiIconButton-root": {
          padding: "10px",
          fontSize: "16px",
        },
      },
    },
    // "& .MuiBox-root": {
    //   [theme.breakpoints.between(599, 900)]: {
    //     padding: "0 70px",
    //   }
    // },
    "& .MuiGrid-item.MuiGrid-grid-xs-10": {
      [theme.breakpoints.down(440)]: {
        margin: 0,
        maxWidth: "100%",
        flexBasis: "100%",
      },
    },
  },
  swapIconButton: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.lightText,
      background: theme.palette.secondary.main,
      borderRadius: 50,
      padding: 2,
    },
  },
  locationIcon: {
    color: theme.palette.primary.defaultText,
  },
  departureDate: {
    "& .MuiIconButton-label": {
      color: theme.palette.primary.defaultText,
    },
  },
  FlightSectionFormContainer: {},

  FlightSectionContainer: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    padding: 10,
    border: "1px solid #b1b1b1",
  },
  toggleBox: {
    "& .MuiToggleButton-root": {
      color: theme.palette.primary.defaultText,
      textTransform: "none",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.lightText,
      backgroundColor: theme.palette.primary.main,
    },
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: theme.palette.background.light,
    },
  },
  searchButtonBox: {
    width: "100%",
    height: "60px",
    "& .MuiButton-root": {
      width: "100%",
      height: "100%",
      borderRadius: "4px",
      backgroundColor: theme.palette.secondary.main,
      "& .MuiButton-label": {
        color: theme.palette.primary.lightText,
        fontSize: "16px",
      },
    },
    "& i": {
      marginRight: "16px",
    },
  },
  BusSection_actions: {
    width: "100%",
    padding: 0,
    margin: "10px 0",
  },
  BusSection_busDet: {
    "& label": {
      fontSize: 12,
      // fontFamily: theme.palette.font.primary,
      color: theme.palette.primary.defaultText,
      textTransform: "uppercase",
      fontWeight: 500,
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none",
    },
    //  padding: "0 10px",
    "& .date-input": {
      width: "100%",
      padding: "6px 10px",
      margin: "10px 0px",
      borderRadius: "4px",
      background: theme.palette.background.mobileSearch,
      border: `1.3px solid ${theme.palette.background.mobileSearchBorder}`,
      "& .MuiSvgIcon-root": {
        // color:theme.palette.secondary.darkOrange,
        color: theme.palette.secondary.darkText,
      },

      "& .oa-form-element-card": {
        border: "none",
        paddingLeft: 0,
        paddingRight: 0,
      },
      "& .react-datepicker-popper": {
        width: "85%",
        marginLeft: "-10px",
        // inset: "0 auto auto -10px !important",
        // inset: "auto auto auto -10px !important",

        "& .react-datepicker": {
          width: "100%",
          "& .react-datepicker__day": {
            margin: "0.166rem 0.3rem",
          },
        },
      },
    },

    "& .MuiGrid-grid-xs-12": {
      [theme.breakpoints.down(600)]: {
        minWidth: "100%",
      },
    },
  },
  BusSection_quicklinks: {
    marginTop: 10,
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.down(600)]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
    "& .quick-link-item": {
      margin: 5,
      // boxShadow: '4px 4px 4px #c2d87e',
      padding: "3px 10px",
      width: 90,
      color: theme.palette.primary.defaultText,
      borderRadius: 4,
      cursor: "pointer",
      border: `solid 1px ${theme.palette.secondary.defaultText}`,
      "&:hover": {
        backgroundColor: theme.palette.secondary.darkOrange,
        color: theme.palette.primary.lightText,
        border: `solid 1px ${theme.palette.secondary.darkOrange}`,
      },
      "& .qlink-icon-wrapper": {
        textAlign: "center",
        "& i": {
          fontSize: 15,
        },
        "& .material-icons": {
          fontSize: 17,
        },
      },
      "& .qlink-label-wrapper": {
        fontSize: 10,
        textAlign: "center",
      },
    },
  },
}));

function MobileBusSection(props) {
  const busDepartureAutocompleteRef = useRef(null);
  const busDestinationAutocompleteRef = useRef(null);

  const [departureDateDialogOpen, setDepartureDateDialogOpen] = useState(false);
  // const [data, setData] = useState({
  //   departure: "",
  //   destination: "",
  //   departureDate: new Date().toISOString(),
  //   class: "economy",
  // });

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const formikRef = React.useRef();
  const [corporateType, setCorporateType] = useState(null);

  let baseUrl = "/";

  let optionsBus = [
    {
      name: "busSearch",
      label: "SEARCH BUS",
      url: baseUrl + "buses",
      icon: <i className="fas fa-search"></i>,
    },
    {
      name: "busMarkup",
      label: "MARK UP",
      url: "/admin/commission/bus",
      icon: <i className="material-icons">attach_money</i>,
    },
    // { name: 'busBank', label: 'BANK DETAILS', url: '/admin/management/bank-account', icon: (<i className="material-icons">%</i>) },
    {
      name: "busMoney",
      label: "ADD MONEY",
      url: "/admin/management/balance/master",
      icon: <i className="material-icons">post_add</i>,
    },
    {
      name: "busCancellation",
      label: "CANCELLATION",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-ban"></i>,
    },
    // { name: 'searchHotel', label: 'DISCOUNTS', url: "#", disabled: true, icon: (<LocalOfferSharp style={{ fontSize: "27px" }} />) },
    // { name: 'searchHotel', label: 'COMMISSION', url: "#", disabled: true, icon: (<AccountBalanceWalletSharp style={{ fontSize: "27px" }} />) },
    {
      name: "busLedger",
      label: "LEDGER",
      url: "/admin/ledger",
      icon: <i className="material-icons">receipt_long</i>,
    },
    // { name: 'searchBus', label: 'MANAGE AGENT', url: "#", disabled: true, icon: (<FaceSharp style={{ fontSize: "27px" }} />) }
    {
      name: "busReport",
      label: "BUS REPORT",
      url: "/admin/booking-report/bus",
      icon: <i className="fas fa-bus"></i>,
    },
  ];

  const fetchMatchingCities = (searchParams) => {
    apiCall(
      WebApi.getMatchingBusCity,
      {
        cityId: "0",
        cityName: searchParams,
      },
      (response) => {
        if (response.success === true) {
          props.setBusCities(response.data.BusCities);
          // console.log("props", props);
          // console.log(response.data.BusCities);
          // setIsLoading(false);
        } else {
          setIsError(response.success);
          setInfoText(response.message);
        }
      }
    );
  };

  useEffect(() => {
    localforageGetItem("user-details", function(err, details) {
      if (details != null) {
        setUserType(details.userType);
        setCorporateType(details.corporateType);
      }
    });
  }, []);

  const customPopper = function(props) {
    // console.log("props", props);
    return (
      <Popper
        {...props}
        // anchorEl={departAutocompleteEl.current.children[0]}
        placement="bottom-start"
        style={{}}
      />
    );
  };

  const swapDepartureDestination = () => {
    // console.log("swapping ");
    const temp = formikRef.current.values.DestinationId;
    formikRef.current.setFieldValue(
      "DestinationId",
      formikRef.current.values.OriginId
    );
    formikRef.current.setFieldValue("OriginId", temp);
  };

  const classes = useStyles();

  useEffect(() => {
    if (isLoading) {
      const length = props.busCities ? Object.keys(props.busCities).length : 0;

      // console.log(length);
      if (length > 0) {
        setIsLoading(false);
      } else {
        apiCall(WebApi.getBusCity, {}, (response) => {
          if (response.success === true) {
            props.setBusCities(response.data.BusCities);

            // console.log(response.data.BusCities);
            setIsLoading(false);
          } else {
            setIsError(response.success);
            setInfoText(response.message);
          }
        });
      }
    }
  }, [isLoading]);

  const openQuickLink = (url) => {
    // console.log("quicklink", url);
    props.history.push({
      pathname: url,
    });
  };

  return (
    <Box className={classes.root}>
      <OaFormAlerts
        isSaving={isSaving}
        isSuccess={isSuccess}
        isError={isError}
        infoText={infoText}
        setIsError={setIsError}
        setIsSuccess={setIsSuccess}
      />
      <Formik
        innerRef={formikRef}
        initialValues={{
          OriginId: "",
          DestinationId: "",
          DateOfJourney: new Date(),
          BusType: "economy",
        }}
        validationSchema={Yup.object().shape({
          OriginId: Yup.string().required("Origin is required"),
          DestinationId: Yup.string().required("Destination is required"),
        })}
        onSubmit={(values) => {
          if (values.OriginId.CityId > 0 && values.DestinationId.CityId > 0) {
            var params = {
              departureCityId: values.OriginId.CityId,
              departureCityName: values.OriginId.CityName,
              destinationCityId: values.DestinationId.CityId,
              destinationCityName: values.DestinationId.CityName,
              selectedDate: dateFnsFormat(values.DateOfJourney, "yyyy-MM-dd"),
            };
            props.setBusBookingInitialTime(null);
            window.sessionStorage.removeItem("service");
            props.history.push({
              pathname: "/bus/search-progress",
              state: params,
            });
          }
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form className={classes.FlightSectionFormContainer}>
            <Grid
              className={classes.FlightSectionContainer}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <Box className={classes.BusSection_busDet}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className="inputs-container-main"
                >
                  <Grid container className="toform">
                    <div className="lineDiv"></div>
                    {/* from */}
                    <Grid item sm={12} className="location-input" style={{}}>
                      {props.busCities && (
                        <>
                          <label>From</label>
                          {/* <Autocomplete
                            fullWidth
                            PopperComponent={customPopper}
                            name="OriginId"
                            options={props.busCities || []}
                            getOptionLabel={(option) => option.CityName}
                            // options={busDepartureList}
                            // getOptionLabel={(o) => o.title || ""}
                            value={values.OriginId}
                            openOnFocus
                            getOptionSelected={(option, value) => {
                              return option.CityName == value.CityName;
                            }}
                            onKeyUp={(event) =>
                              fetchMatchingCities(event.target.value)
                            }
                            onChange={(event, newValue) => {
                              // busDestinationAutocompleteRef.current.focus();
                              // setData({ ...data, departure: newValue });
                              setFieldValue("OriginId", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                color="secondary"
                                // label="Departure"
                                placeholder="Enter the city"
                                inputRef={busDepartureAutocompleteRef}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                            disablePortal={true}
                          /> */}

<BusLocationInput props={props} setFieldValue={setFieldValue} type={"Origin"} />
                        </>
                      )}
                      <ErrorMessage
                        name="OriginId"
                        component="div"
                        className="error"
                      />
                    </Grid>
                    {/* Swiper */}
                    <div className="swap-icon">
                      <IconButton
                        onClick={swapDepartureDestination}
                        aria-label="delete"
                        className={classes.swapIconButton}
                      >
                        <AiOutlineSwap />
                      </IconButton>
                    </div>
                    {/* to */}
                    <Grid item sm={12} className="location-input arrival-input">
                      {props.busCities && (
                        <>
                          <label>To</label>
                          {/* <Autocomplete
                            fullWidth
                            PopperComponent={customPopper}
                            name="DestinationId"
                            // options={busDestinationList}
                            // getOptionLabel={(o) => o.title || ""}
                            options={props.busCities || []}
                            getOptionLabel={(option) => option.CityName}
                            value={values.DestinationId}
                            getOptionSelected={(option, value) => {
                              return option.CityName == value.CityName;
                            }}
                            onKeyUp={(event) =>
                              fetchMatchingCities(event.target.value)
                            }
                            onChange={(event, newValue) => {
                              // setDepartureDateDialogOpen(true);
                              // setData({ ...data, destination: newValue });
                              setFieldValue("DestinationId", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                color="secondary"
                                // label="Arrival"
                                placeholder="Enter the city"
                                inputRef={busDestinationAutocompleteRef}
                                inputProps={{
                                  ...params.inputProps,
                                  autocomplete: "new-password",
                                }}
                              />
                            )}
                            disablePortal={true}
                          /> */}
<BusLocationInput props={props} setFieldValue={setFieldValue} type={"Destination"} />

                        </>
                      )}
                      <ErrorMessage
                        name="DestinationId"
                        component="div"
                        className="error"
                      />
                    </Grid>
                  </Grid>

                  {/* Date */}
                  <Grid item sm={12} className="date-input">
                    <label>Departure Date</label>
                    {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        fullWidth
                        KeyboardButtonProps={{
                          onFocus: (e) => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        PopoverProps={{
                          disableRestoreFocus: true,
                          onClose: () => {
                            setDepartureDateDialogOpen(false);
                          },
                        }}
                        InputProps={{
                          onFocus: () => {
                            setDepartureDateDialogOpen(true);
                          },
                        }}
                        name="DateOfJourney"
                        open={departureDateDialogOpen}
                        onClose={() => setDepartureDateDialogOpen(false)}
                        onOpen={() => setDepartureDateDialogOpen(true)}
                        disablePast
                        className={classes.departureDate}
                        color="secondary"
                        disableToolbar
                        variant="inline"
                        format="dd-MM-yyyy"
                        margin="normal"
                        // label="Departure Date"
                        value={values.DateOfJourney}
                        onChange={(e, v) => {
                          busDestinationAutocompleteRef.current.blur();
                          setDepartureDateDialogOpen(false);
                          // console.log(
                          //   document.getElementById("searchActionButton")
                          // );
                          // document.getElementById("searchActionButton").focus();
                          // setData({ ...data, departureDate: e });
                          setFieldValue("DateOfJourney", e);
                        }}
                      />
                    </MuiPickersUtilsProvider> */}
                    {/* <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      name="DateOfJourney"
                      className={classes.checkinDatePicker}
                      minDate={new Date()}
                      customInput={
                        <TextField id="standard-basic" variant="standard" />
                      }
                      value={values.DateOfJourney}
                      onChange={(e, v) => {
                        busDestinationAutocompleteRef.current.blur();
                        setDepartureDateDialogOpen(false);
                        // console.log(
                        //   document.getElementById("searchActionButton")
                        // );
                        // document.getElementById("searchActionButton").focus();
                        // setData({ ...data, departureDate: e });
                        setFieldValue("DateOfJourney", e);
                      }}
                      KeyboardButtonProps={{
                        onFocus: (e) => {
                          setDepartureDateDialogOpen(true);
                        },
                      }}
                      PopoverProps={{
                        disableRestoreFocus: true,
                        onClose: () => {
                          setDepartureDateDialogOpen(false);
                        },
                      }}
                      InputProps={{
                        onFocus: () => {
                          setDepartureDateDialogOpen(true);
                        },
                      }}
                      monthsShown={1}
                      dateFormat="dd MMM yyyy"
                      //labeltxt="DEPARTURE DATE"
                      selected={values.DateOfJourney}
                      showinput={true}
                    /> */}
                    <BusDateInput props={{props, values}} setFieldValue={setFieldValue} />
                    <ErrorMessage
                      name="DateOfJourney"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  {/* Search */}
                  <Grid
                    item
                    sm={12}
                    style={{ maxWidth: "100%", flexBasis: "100%" }}
                  >
                    <Box className={classes.BusSection_actions}>
                      <Box className={classes.searchButtonBox}>
                        <Button
                          id="searchActionButton"
                          size="large"
                          variant="contained"
                          color="primary"
                          type="submit"
                        >
                          <i className="fas fa-search"></i>
                          SEARCH BUS
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {userType &&
                userType == "agent" &&
                corporateType &&
                corporateType != "corporate" && (
                  <Box className={classes.BusSection_quicklinks}>
                    {optionsBus &&
                      optionsBus.map((val, ind) => (
                        <div
                          item
                          className="quick-link-item"
                          key={ind}
                          onClick={() => openQuickLink(val.url)}
                        >
                          <div className="qlink-icon-wrapper">{val.icon}</div>
                          <div className="qlink-label-wrapper">{val.label}</div>
                        </div>
                      ))}
                  </Box>
                )}
            </Grid>

            {/* {errors && _.isString(errors) && touched && _.isArray(touched) && (
              <div className="field-error">{errors}</div>
            )}
            <div
              className={"row"}
              style={{ background: "white", zIndex: "200" }}
            >
              <div className={"col-12"}>
                <code>
                  <pre>Values: {JSON.stringify(values, null, 2)}</pre>
                </code>
              </div>
              <div className={"col-12"}>
                <pre>Errors: {JSON.stringify(errors, null, 2)}</pre>
              </div>
              <div className={"col-12"}>
                <pre>Touched: {JSON.stringify(touched, null, 2)}</pre>
              </div>
            </div> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

const mapDispatchToProp = (dispatch) => {
  return {
    setBusCities: (busCities) => dispatch(setBusCities(busCities)),
    setBusBookingInitialTime: (busBookingInitialTime) =>
      dispatch(setBusBookingInitialTime(busBookingInitialTime)),
  };
};

const mapStateToProp = (state, prop) => {
  return {
    busCities: state.bus.busCities,
  };
};

export default withRouter(
  connect(
    mapStateToProp,
    mapDispatchToProp
  )(withWidth()(MobileBusSection))
);

const busDepartureList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];

const busDestinationList = [
  { title: "Mumbai", value: "mumbai" },
  { title: "Hyderabad", value: "hyderabad" },
  { title: "Bangalore", value: "bangalore" },
  { title: "Chennai", value: "chennai" },
];
