import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Typography,TextField, Checkbox} from "@material-ui/core";
import { makeStyles,useTheme } from "@material-ui/core/styles";
import { useMediaQuery} from "@material-ui/core";
import CarrierImage from "assets/img/6E.png";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { connect } from "react-redux";
import { setB2CHotelList } from "redux/action";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HotelIcon from '@material-ui/icons/Hotel';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextFields } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import OaFormAlerts from 'pages/components/OaFormAlerts';
import { basename } from "api/ApiConstants";
import HotelFareDetailsB2C from "./HotelFareDetailsB2C";



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    ///paddingTop: "40px",
    paddingBottom: "40px",
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      minHeight: 35
    },
  },
  grid:{
    background: "#fff",
    paddingBottom: "6px",
    textAlign: "center",
    // border: "1px solid #bfb6b6",
    padding: "20px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]:{
      padding: 5,
      
    }
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    width: "fit-content",
    letterSpacing: "0.5px !important",
  },
  headerRightContainer: {
    display: "flex",
    [theme.breakpoints.down(980)]:{
      flexDirection: "column"
      
    }
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]:{
 
      borderRight: "none",
    }
  },
  headerContainer: {
    "& .fas": {
      [theme.breakpoints.down(500)]:{
        
        display: "none",
      }
    }
  },
  headerRightText: {
    fontSize: "13px",
    borderRight: "1px solid",
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]:{
 
      borderRight: "none",
      textAlign: "left"
    }
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: theme.palette.secondary.main,
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: theme.palette.secondary.sub,
    },
  },
}));

const MyBookingsHotel = (props) => {
  const classes = useStyles();
  // const [hotelList,setHotelList]=useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [passengers, setPassengers] = useState([]);
  const [open, setOpen] = useState(false);
  const [allSeatSelected, setAllSeatSelected] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [tid, setTid] = useState(0);
  const [cancellationChargePerSeat, setCancellationChargePerSeat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const[bookingAmount,setBookingAmount] = useState("")
  const [openFareDetails, setOpenFareDetails] = useState(false)


  const checkAllOnClick = () => {
    alert("Only full cancellation is allowed at this moment");
  };
  const checkOnClick = (SeatId) => {
    alert("Only full cancellation is allowed at this moment");
  };
  
  const handleClose = () => {
    setOpen(false);
    setPassengers([]);
  };

  const handleConfirmClose = () => {
    let _seatIds = [];
    passengers.map((value) => {
      if (value.checked) _seatIds.push(value.Seat?.SeatId);
    });

    if (remarks.length == 0) {
      alert("Please add remarks");
  } else if (_seatIds.length == 0) {
      alert("Please select atleast one seat");
  } else {
      setIsLoading(true);
      apiCall(WebApi.getHotelBookingCancel, { tid: tid, seatIds: _seatIds, remarks: remarks }, (response) => {
          setIsLoading(false);
          setOpen(false);
          if (response.success === true) {
              setInfoText(response.message)
              setIsSuccess(true);

              setTimeout(()=>{
                  props.target(props.searchParams);
              }, 5000);
              
          } else {
              setIsError(true);
              setInfoText(response.message)
          }
      });
  }

    
  };

  const cancelBtn = (e,data)=> {

    let _passengers = [...data.guestDetails];
    _passengers.map((value, key) => {
        value.checked = true;
        })
    setPassengers(_passengers);
    setCancellationPolicy(data.cancellationPolicy);
    setCancellationChargePerSeat(data.cancellationChargePerSeat);
    setTid(data.tid);
    setOpen(true)
    setBookingAmount(data.bookingAmount)
    // console.log(data,"hfsdhfa")
  }

  // console.log(props);

  useEffect(() => {
    if(props.usertype === "G"){
      console.log(props.location.state)
      WebApi.getB2CHotelList({ userType: props.usertype, refId : props.location.state.tid}, (response) => {
        props.setB2CHotelList(response.data);
      });
    }else{
      WebApi.getB2CHotelList({ userType: props.usertype,}, (response) => {
        props.setB2CHotelList(response.data);
      });
    }
  }, [ props.usertype]);

  // console.log("props.hotelList",props.hotelList);
  // console.log("props.usertype",props.usertype);
  return (
    <>
      { props.hotelList && props.hotelList.length > 0 && (
        <Grid
          item
          md={12}
          xs={12}
          sm={12}
          
          className={classes.grid}
        >
           <GridItem md={12}>
                    <OaFormAlerts
                        isSaving={isSaving}
                        isSuccess={isSuccess}
                        isError={isError}
                        infoText={infoText}
                        setIsError={setIsError}
                        setIsSuccess={setIsSuccess}
                    />
                </GridItem>
          { props.hotelList && props.hotelList.map((hotel, index) => (
            <Grid
              container
              key={index}
              item
              md={12}
              style={{
                background: "#fff",
                // border: "1px solid #dcdcdc",
                borderRadius: "5px",
                boxShadow: "0px 0px 6px -1px #b3b3b3",
                marginBottom: 20,
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className={classes.headerContainer}
                style={{
                  background: "#e2e2e2",
                  padding:isMobile?10:"10px",
                }}
              >
                <Grid item container md={4} xs={6} style={{textAlign:isMobile && "left", paddingLeft: 10}}>
                  <Grid item>
                    {/* <i className="fas fa-hotel"></i> */}
                    <i
                      className="fas fa-hotel"
                      aria-hidden="true"
                      style={{ opacity: 0.5, fontSize: 22 }}
                    ></i>
                    
                  </Grid>
                  <Grid item>
               
                    <Typography className={classes.typo1} >
                    {/* <HotelIcon  style={{ opacity: 0.5, fontSize: 22 }}/> */}
                      Total Guest(s):{" "}
                      {/* {hotel.roomDetails.details.map(
                        (traveler) =>
                          Number(traveler.adult) +
                          Number(traveler.child) +
                          Number(traveler.infant)
                      )} */}
                      {hotel.roomDetails.details[0] &&
                        Number(
                          Number(hotel.roomDetails.details[0].adult) +
                              Number(hotel.roomDetails.details[0].child) +  Number (hotel.roomDetails.details[0].infant)
                         
                        ) }
                    </Typography>

                    <Typography
                      className={classes.typo1}
                      style={{ opacity: 0.7 }}
                    >
                      {`ADULT:${hotel.roomDetails.details[0] &&
                        Number(
                          hotel.roomDetails.details[0].adult
                        )}  CHILD:${hotel.roomDetails.details[0] &&
                        Number(
                          hotel.roomDetails.details[0] &&
                            hotel.roomDetails.details[0].child
                        )}  INFANT:${hotel.roomDetails.details[0] &&
                        Number(
                          hotel.roomDetails.details[0] &&
                            hotel.roomDetails.details[0].infant
                        )}`}
                    </Typography>
                  </Grid>
                </Grid>
                <div className={classes.headerRightContainer}>
      
                    
                      <div className={classes.headerRightText}>
                        Check In:{" "}
                        {hotel.roomDetails.details[0] &&
                          moment(
                            hotel.roomDetails.details[0].checkinDate
                          ).format("DD MMM'YY")}
                      </div>
                      <div className={classes.headerRightText}>
                        Booking Id:{" "}
                        {hotel.roomDetails.details[0] &&
                          hotel.roomDetails.details[0].bookingId}
                      </div>
             
                  
                </div>
              </Grid>
              <Grid container style={{ padding: 15 }}>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  style={{
                    borderRight:isMobile?"none":"1px solid #ababab",
                    paddingRight:isMobile?0: 25,
                    paddingBottom: 25,
                  }}
                >
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={6}>
                      <Grid container justifyContent="flex-start" alignItems="center">
                        {/* <Grid
                          item
                          xs={6}
                          md={2}
                          style={{ textAlign: "center" }}
                        >
                          <img src={CarrierImage} width={28} />
                        </Grid> */}
                        <Grid
                          item
                          xs={12}
                          md={10}
                          style={{ textAlign: "left" }}
                        >
                          <Typography className={classes.typo4}>
                            {hotel.roomDetails.details[0] &&
                              hotel.roomDetails.details[0].hotelName}
                          </Typography>
                          {/* <Typography className={classes.typo5}>
                            Economy
                          </Typography> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      {/* <Typography className={classes.typo4}>
                        PNR : H8E9GS
                      </Typography> */}
                      <Typography className={classes.typo5}>
                        {hotel.roomDetails.details[0] &&
                          hotel.roomDetails.details[0].refundStatus}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: "15px" }}
                  >
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                        Check In
                      </Typography>
                      <Typography className={classes.typo7}>
                        {hotel.roomDetails.details[0] &&
                          moment(
                            hotel.roomDetails.details[0].checkinDate
                          ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      {/* <i
                        className="fa fa-long-arrow-right"
                        aria-hidden="true"
                        style={{ fontSize: "35px", color: "#4e4c4c" }}
                      ></i> */}
                        <ArrowForwardIcon style={{ fontSize: "35px", color: "#4e4c4c" }}/>
                    </Grid>
                    <Grid item xs={5} md={3}>
                      <Typography className={classes.typo6}>
                        Check Out
                      </Typography>
                      <Typography className={classes.typo7}>
                        {hotel.roomDetails.details[0] &&
                          moment(
                            hotel.roomDetails.details[0].checkoutDate
                          ).format("DD MMM'YY")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: 15 }}
                  >
                    <Grid item md={3} xs={6}>
                      <Typography className={classes.typo8}>
                        {hotel.guestDetails[0] &&
                          hotel.guestDetails[0].givenname +
                            " " +
                            hotel.guestDetails[0].surname}
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={6}>
                      <Typography className={classes.typo8}>
                        Status{" "}
                        <span style={{ color: "#007bff" }}>{hotel.status}</span>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid continer item xs={12} md={4} style={{ paddingLeft: 15 }}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.button}
                      style={{ width: "12rem" }}
                      onClick={() => setOpenFareDetails(true)}
                    >
                      Fare Details
                    </Button>
                  </Grid>
{/* 
                  <Grid item xs={12} style={{ marginTop: 20 }}>
                    {hotel.roomDetails.details[0] &&
                    hotel.roomDetails.details[0].refundStatus ===
                      "Not Refunded" ? null : (
                      <Button
                        className={classes.button}
                        style={{ width: "12rem" }}
                      >
                        Check Refund Status
                      </Button>
                    )}
                  </Grid> */}
                </Grid>
              </Grid>
              {hotel.status && (hotel.status != "Pending" && hotel.status != "SUCCESSCan") &&
                <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{
                  borderTop: "1px solid #9e9e9e",
                  margin: "0px 15px",
                  padding: "15px 0px",
                }}
              >
                <Grid
                  item
                  style={{ padding: "0px 10px", cursor: "pointer" }}
                  onClick={() => {
                    // props.history.push("/print/hotelticket/" + hotel.tid);
                    window.open(basename+ "/print/hotelticket/" + hotel.tid,  "_blank");
                  }}
                >
                  {/* <i
                    className="fa fa-ticket"
                    aria-hidden="true"
                    style={{
                      textAlign: "center",
                      fontSize: "19px",
                      paddingBottom: "7px",
                      color: "#6f6f6f",
                    }}
                  ></i> */}

                    <PrintIcon style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}/>
                  <Typography className={classes.typo9}>
                     Voucher
                  </Typography>
                </Grid>

                <Grid
                  item
                  style={{ padding: "0px 10px", cursor: "pointer" }}
                  onClick={() => {
                    // props.history.push("/print/hotelticket/" + hotel.tid);
                    window.open(basename+"/print/hotelticket/" + hotel.tid,  "_blank");
                  }}
                  
                >

                  {/* <i
                    className="fa fa-download"
                    aria-hidden="true"
                    style={{
                      textAlign: "center",
                      fontSize: "19px",
                      paddingBottom: "7px",
                      color: "#6f6f6f",
                    }}
                  ></i> */}
                  <ArrowDownwardIcon  style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}/>
                  <Typography className={classes.typo9}>
                     Voucher
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ padding: "0px 10px", cursor: "pointer" }}
                  onClick={() => {
                    // props.history.push(
                    //   "/print/voucher/hotel-user/" + hotel.tid
                    // );
                    window.open(basename+"/print/voucher/hotel-user/" + hotel.tid,  "_blank");

                  }}
                >
                  {/* <i
                    className="fa fa-sticky-note-o"
                    aria-hidden="true"
                    style={{
                      textAlign: "center",
                      fontSize: "19px",
                      paddingBottom: "7px",
                      color: "#6f6f6f",
                    }}
                  ></i> */}
                  <DescriptionIcon  style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}/>
                  <Typography className={classes.typo9}>
                    Print Invoice
                  </Typography>
                </Grid>

                {/* <Grid item={false} style={{ padding: "0px 10px",cursor:"pointer" }}
               
                onClick={(e)=>cancelBtn(e,hotel)}
                >
            
                    <CancelIcon  style={{
                        textAlign: "center",
                        fontSize: "25px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}/>
                  <Typography className={classes.typo9}>Cancel</Typography>
                </Grid> */}
              </Grid>}
              <Dialog
        open={openFareDetails}
        onClose={() => {
          setOpenFareDetails(false)
        }}
        fullWidth={true}
        // maxWidth={"md"}
      >
            <HotelFareDetailsB2C hotel={hotel} />
      </Dialog>
  
            </Grid>
          ))}
         

         
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Passenger Name
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              {/* Seat No */}
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Price
            </GridItem>
            <GridItem
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Checkbox
                color="primary"
                checked={allSeatSelected}
                onChange={checkAllOnClick}
              />
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            passengers &&
            passengers.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={5}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value?.title} {value?.givenname} {value?.surname}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* {value.SeatName} */}
                  </GridItem>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* &#x20B9;{value.bookingAmount} */}
                    &#x20B9;{bookingAmount}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {value.isCancelled ? (
                      <span>{"Cancelled"}</span>
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={value.checked || false}
                        onClick={() => {
                          checkOnClick(value.SeatId);
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              );
            })}
          <Divider />
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation Remarks
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation charge per room
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setCancellationChargePerSeat(e.target.value)}
                value={cancellationChargePerSeat}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                fontWeight: "bold",
              }}
            >
              Cancellation Policy
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Charge
            </GridItem>
            <GridItem md={3} style={{ display: "flex", alignItems: "center" }}>
              Charge Type
            </GridItem>
            <GridItem md={7} style={{ display: "flex", alignItems: "center" }}>
              Policy
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            cancellationPolicy &&
            cancellationPolicy.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationCharge}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationChargeTypeString}
                  </GridItem>
                  <GridItem
                    md={7}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.policyString} + &#8377;{cancellationChargePerSeat}{" "}
                    max handling charge
                  </GridItem>
                </GridContainer>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleClose}
            color="primary"
            disabled={isLoading}
          >
            Disagree
          </Button>
          <Button
            startIcon={
              isLoading ? <CircularProgress size="24px" /> : <DoneIcon />
            }
            onClick={handleConfirmClose}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>


    </>
  );
};

function mapStateToProps(state, props) {
  return {
    hotelList: state.hotelList.hotelList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setB2CHotelList: (hotelList) => dispatch(setB2CHotelList(hotelList)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyBookingsHotel)
);
