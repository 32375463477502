import React, { Component} from 'react';
import ReactTable from "react-table";
import WebApi from "api/ApiConstants";
import { localforageGetItem } from "oautils/oaForageUtils";
/**
 * Opening balance = balance - credit + debit
 */
class CancellationSearchResult extends Component {

    cancellationPageUrl = '/admin/module/flights/trn/cancellation/';
    constructor(props){
        super(props);
        this.state = {
            data: [],
            tabledata: [],
            rowCount: 4,
            loading: true,
            finishedLoading: false,
            noDataText: " "
        }
    }

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    })

    onRowClick = (state, rowInfo, column, instance) => {
        return{
        onClick: e => {
            console.log(rowInfo);
        }}
    }

    componentDidMount() {
        this.tableLoader();
    }

    tableLoader() {
        localforageGetItem("user-details", (err, details) => {
            if (details) {
                this.props.searchParams.agencyId = details.userId;
                this.props.searchParams.userType = details.userTypeAbv;

                WebApi.getNonPNRCancellationRequestList(this.props.searchParams, (resp) => {
                    if(resp != null && resp.success){
                        var tableData = [];
                        var data = [];
                        if(resp.data.hasOwnProperty("tableData")){
                            tableData = resp.data.tableData;
                            data = resp.data;
                        }else{
                            tableData = resp.data;
                        }
        
                        this.setState({tabledata: tableData});
                        this.setState({data: data});
                        this.setState({
                            rowCount: tableData.length === 0 ? 4 : tableData.length,
                            noDataText: tableData.length === 0 ? "No results" : tableData.length,
                            loading: false,
                            finishedLoading: true
                        });
                    } 
                    },(error)=>{
                        this.setState({
                            data: [],
                            rowCount: 4,
                            noDataText: "ERROR ",
                            loading: false,
                            finishedLoading: true
                        });
                });
            }
        });
    }

    render(){
        return(
            <>
            <ReactTable
                columns={[
                    {
                        Header: '#',
                        Cell: props => <div >{props.index + 1}</div>,
                        width: 35,
                        filterable: false
                    }, {
                        Header: "Can Id",
                        accessor: "canId",
                        width: 70,
                        filterable: false,
                        Cell: props => (
                            <div >
                                {props.value}
                            </div>
                        )
                    }, {
                        Header: "TID",
                        accessor: "transactionId",
                        width: 70,
                        filterable: false,
                        Cell: props => (
                            <div>
                                <b>{props.value}</b>
                            </div>
                        )
                    },{
                        Header: "Trip",
                        accessor: "domesticInternational",
                        width: 50,
                        filterable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        ),
                    },
                    {
                        Header: "Agency Name",
                        accessor: "agentName",
                        width: 150,
                        Cell: props => (
                            <div >{props.value}</div>
                        ),
                    },{
                        Header: "PNR",
                        accessor: "eTicketNO",
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Booking Date",
                        accessor: "transactionDate",
                        filterable: false,
                        width: 210,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Travel Date",
                        accessor: "travelDateTime",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Can Request Date",
                        accessor: "cancellationDateTime",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Journey Type",
                        accessor: "journeyType",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Amount",
                        accessor: "flightFare",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{this.formatter.format(props.value)}</div>
                        )
                    },{
                        Header: "Status",
                        accessor: "transactionStatus",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Passenger Number",
                        accessor: "passengerMobileNumber",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Passenger Name",
                        accessor: "passengerName",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Cancellation Remark",
                        accessor: "cancellationRemark",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{props.value}</div>
                        )
                    },{
                        Header: "Group Booking",
                        accessor: "agentName",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >N</div>
                        )
                    },{
                        Header: "Airline Cancellation Charge",
                        accessor: "airlineCancellationCharge",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{this.formatter.format(props.value)}</div>
                        )
                    },{
                        Header: "Cancellation Charge",
                        accessor: "travlogyCancellationCharge",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{this.formatter.format(props.value)}</div>
                        )
                    },{
                        Header: "Refund Amount",
                        accessor: "finalRefundAmount",
                        filterable: false,
                        sortable: false,
                        Cell: props => (
                            <div >{this.formatter.format(props.value)}</div>
                        )
                    }, {
                        Header: "Cancellation Remarks",
                        width: 200,
                        accessor: "transactionId",
                        filterable: false,
                        sortable: false,
                        // Cell: props => (<div style={{ height: 44, display: 'table-cell', verticalAlign: 'middle' }}>
                        //     <FlightCancellationRemarks trnId={props.value}></FlightCancellationRemarks>
                        // </div>)
                    }
                ]}
                data={this.state.tabledata}
                defaultFilterMethod={this.filterMethod}
                filterable={true}
                showPagination={false}
                minRows={this.state.rowCount}
                loading= {this.state.loading}
                pageSize={this.state.rowCount}
                noDataText={this.state.noDataText}  
                className="-striped -highlight"
                getTdProps={this.onRowClick}
     
            />
            </>
        )
    }
}

export default CancellationSearchResult;
