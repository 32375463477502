import React, { useState } from 'react'
import dateFnsFormat from "date-fns/format";

import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import {
    TextField,

  } from "@material-ui/core";

const InsuranceDateInput = ({props, type, setFieldValue}) => {
    const [startDate, setStartDate] = useState(new Date());
    const today = new Date();

    console.log(type)
    
    const [dateDialogOpen, setDateDialogOpen] = useState(false);


  return (
    <OaOutlinedDatePicker
    showDisabledMonthNavigation
    name="DateOfJourney"
    // className={classes.checkinDatePicker}
    minDate={type === "travelStartDate" ? startDate : props.values.travelStartDate}
    disabled={type === "travelEndDate" && props.values.planType !== 1}
    // minDate={startDate}
    customInput={
      <TextField id="standard-basic" variant="standard" />
    }
    value={props.values.DateOfJourney}
    onChange={(e, v) => {
      setDateDialogOpen(false);
      if(type === "travelStartDate"){
        setFieldValue(`travelStartDate`, e);
        if (
          new Date(v).getTime() >
          new Date(props.values.travelStartDate).getTime()
        ) {
          props.values.travelStartDate = new Date(
            new Date(v).getTime() + 24 * 60 * 60 * 1000
          ).toISOString();
        }
      }
      setFieldValue(`travelEndDate`, e);
      if (
        new Date(v).getTime() >
        new Date(props.values.travelEndDate).getTime()
      ) {
        props.values.travelEndDate = new Date(
          new Date(v).getTime() + 24 * 60 * 60 * 1000
        ).toISOString();
      }
    }}
    KeyboardButtonProps={{
      onFocus: (e) => {
        setDateDialogOpen(true);
      },
    }}
    PopoverProps={{
      disableRestoreFocus: true,
      onClose: () => {
        setDateDialogOpen(false);
      },
    }}
    InputProps={{
      onFocus: () => {
        setDateDialogOpen(true);
      },
    }}
    monthsShown={1}
    dateFormat="dd MMM yyyy"
    //labeltxt="DEPARTURE DATE"
    selected={props.values[type]}
    showinput={true}
  />
  )
}

export default InsuranceDateInput
