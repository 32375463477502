import { MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import arrowDown from "assets/icons/arrow_down.png"

const InsuranceCategory = ({values, setFieldValue}) => {

    const [inputValue, setInputValue] = useState();
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [busCityList, setBusCityList] = React.useState();
    const [isOpen, setIsOpen] = useState(false);
    
    const autocompleteRef = useRef(null);


    const planCategoryOptions = [
        { value: 1, label: "Domestic Travel Policy" },
        { value: 2, label: "Overseas Travel Insurance" },
        { value: 3, label: "Student Overseas Insurance" },
        { value: 4, label: "Schengen Overseas Insurance" },
        { value: 5, label: "Inbound Travel Policy" },
        { value: 6, label: "Cancellation Insurance" },
      ];
      

      useEffect(() => {
        
            setFilteredOptions(planCategoryOptions)
            setInputValue(planCategoryOptions[0].label)
        
      }, [])

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, []);


    const handleSelectOption = (value) => {
        setFieldValue("planCategory", value.value)
        // console.log(`${type}Id`)
        // setFieldValue(`${type}Id`, value);
        setInputValue(value.label)
        // setFilteredOptions([]);
      };
    

  return (
    <Root ref={autocompleteRef} className="insuranceCat-input-container" 
>
      <input
        type="text"
        value={inputValue}
        className='insuranceCat-input'
        onClick={() => {
            setIsOpen(!isOpen)
        }}
        // onChange={handleInputChange}
        placeholder="Search."
      />
   <img src={arrowDown} className='clear-icon' />
      {/* {showClear && <img src={cross} className='clear-icon' onClick={() => clearInputHandler()} />} */}
      {isOpen && filteredOptions.length > 0 &&  <ul      className='insuranceCat-lists'>
        {filteredOptions.map((option, index) => (
          <li  className='insuranceCat-list' key={index} onClick={() => handleSelectOption(option)}>
            
            <div>
              <div className='insuranceCat-input-popup'>
                <div>{option.label}</div>
                
              </div>
              
            </div>
          </li>
        ))}
      </ul>}
     
    </Root>
  )
}

export default InsuranceCategory

const Root = styled.div`
position: relative;
font-family: poppins;
.insuranceCat-input {
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 0px;
  caret-color: transparent;
  white-space: nowrap;      
  overflow: hidden;           
  text-overflow: ellipsis; 
  caret-color: transparent;
  font-size: 17px;
  cursor: pointer;
//   font-family: poppins;
  color: #000;
  font-weight: 700;
  width: 100%;
  position: relative;
}
.clear-icon{
  // display: none;
  height: 10px;
  width: 13px;
  position: absolute;
  right: 10px;
  top: 12px;
  opacity: 50%;
  cursor: pointer;

}
.insuranceCat-lists {
  position: absolute;
  width: 100%;
  background-color: #fff;
  margin: 0px;
  list-style: none;
  z-index:10;
  padding: 0px;
  overflow-y: scroll;
  max-height: 250px;
}
.insuranceCat-list {
  font-family: "poppins";
  padding: 10px;
  font-size: 14px;
  font-family: "poppins";
  color: #838383;
  font-weight: 500;

  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
}
.insuranceCat-input-popup {
  display: flex;
  justify-content: space-between;
}

`;
