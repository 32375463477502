import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import CarrierImage from "assets/img/6E.png";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import { connect } from "react-redux";
import { setB2CInsuranceList } from "redux/action";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PrintIcon from "@material-ui/icons/Print";
import DescriptionIcon from "@material-ui/icons/Description";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextFields } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { basename } from "api/ApiConstants";
import InsuranceFareDetailsB2C from "./InsuranceFareDetailsB2C";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "auto",
    //paddingTop: "40px",
    paddingBottom: "40px",
    "& .MuiBox-root": {
      padding: 0,
    },
    "& .MuiInputBase-input": {
      minHeight: 35
    },
  },
  grid: {
    background: "#fff",
    paddingBottom: "6px",
    textAlign: "center",
    // border: "1px solid #bfb6b6",
    padding: "20px",
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      padding: 5,
    },
  },
  grid1: {
    background: "#e2e2e2",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  headerRightContainer: {
    display: "flex",
    [theme.breakpoints.down(980)]:{
      flexDirection: "column"
      
    }
  },
  headerRightText: {
    fontSize: "13px",
    borderRight: "1px solid",
    paddingRight: 10,
    paddingLeft: 10,
    [theme.breakpoints.down("sm")]:{
      textAlign: "left",
      borderRight: "none",
    }
  },
  grid2: {
    borderRight: "1px solid #ababab",
    paddingRight: 25,
    paddingBottom: 25,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      borderRight: "none",
    },
  },
  typo1: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "1.5",
    color: "#212529",
    marginLeft: 5,
    letterSpacing: "0.5px !important",
  },
  typo2: {
    fontSize: "13px",
    paddingRight: "10px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    borderRight: "1px solid",
    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  typo3: {
    fontSize: "13px",
    fontWeight: 700,
    color: "#212529",
    letterSpacing: "0.5px !important",
    paddingLeft: "10px",
  },
  typo4: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  typo5: {
    fontSize: 11,
    color: "#000",
    fontWeight: 300,
    opacity: 0.7,
  },
  typo6: {
    fontSize: 16,
    color: "#000",
    fontWeight: 400,
  },
  typo7: {
    fontSize: 12,
    color: "grey",
    fontWeight: 400,
  },
  typo8: {
    fontSize: 11,
    fontWeight: 300,
  },
  typo9: {
    color: "#6f6f6f",
    fontSize: 12,
  },
  typo10: {
    fontSize: 12,
    color: "#828080",
    fontWeight: 500,
  },
  button: {
    background: "#2b5a85",
    color: "#fff",
    fontSize: 13,
    textAlign: "center",
    padding: 8,
    borderRadius: 10,
    textTransform: "capitalize",
    "&:hover": {
      background: "#2b5a85",
    },
  },
}));

const MyBookingsInsurance = (props) => {
  const classes = useStyles();
  // const [hotelList,setHotelList]=useState([]);
  // console.log(props);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [passengers, setPassengers] = useState([]);
  const [open, setOpen] = useState(false);
  const [allSeatSelected, setAllSeatSelected] = useState(true);
  const [remarks, setRemarks] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState([]);
  const [tid, setTid] = useState(0);
  const [cancellationChargePerSeat, setCancellationChargePerSeat] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [bookingAmount, setBookingAmount] = useState(0);
  const [openFareDetails, setOpenFareDetails] = useState(false)

  const checkAllOnClick = () => {
    alert("Only full cancellation is allowed at this moment");
  };
  const checkOnClick = (SeatId) => {
    alert("Only full cancellation is allowed at this moment");
  };

  const handleClose = () => {
    setOpen(false);
    setPassengers([]);
  };

  const handleConfirmClose = () => {
    let _seatIds = [];
    passengers.map((value) => {
      if (value.checked) _seatIds.push(value.Seat?.SeatId);
    });

    if (remarks.length == 0) {
      alert("Please add remarks");
    } else if (_seatIds.length == 0) {
      alert("Please select atleast one Pax");
    } else {
      setIsLoading(true);
      apiCall(
        WebApi.doInsuranceCancel,
        {
          tid: tid,
          seatIds: _seatIds,
          remarks: remarks,
          cancellationChargePerSeat: cancellationChargePerSeat,
        },
        (response) => {
          setIsLoading(false);
          setOpen(false);
          if (response.success) {
            setInfoText(
              response.message +
                " " +
                (response.SendChangeRequestResult
                  ? response.SendChangeRequestResult.NetRefund
                  : "")
            );
            setIsSuccess(true);

            setTimeout(() => {
              props.target(props.searchParams);
            }, 5000);
          } else {
            setIsError(true);
            setInfoText(response.message);
          }
        }
      );
    }
  };

  const cancelBtn = (e, data) => {
    let _passengers = [...data.passengerDetails];
    _passengers.map((value, key) => {
      value.checked = true;
    });
    setPassengers(_passengers);
    setCancellationPolicy(data.cancellationPolicy);
    setCancellationChargePerSeat(data.cancellationChargePerSeat);
    setTid(data.tid);
    setOpen(true);
    setBookingAmount(data.bookingAmount);
    // console.log(data,"hfsdhfa")
  };

  useEffect(() => {
    if (props.usertype === "G") {
      WebApi.getB2CInsuranceList(
        { userType: props.usertype, refId: props.location.state.refId },
        (response) => {
          props.setB2CInsuranceList(response.data);
          console.log("response.data ", response.data);
        },
        (error) => {
          console.log("getB2CInsuranceList error", error);
        }
      );
    } else {
      WebApi.getB2CInsuranceList(
        { userType: props.usertype },
        (response) => {
          props.setB2CInsuranceList(response.data);
          console.log("response.data ", response.data);
        },
        (error) => {
          console.log("getB2CInsuranceList error", error);
        }
      );
    }
  }, [props.usertype]);

  return (
    <>
      {props.insuranceList && props.insuranceList.length > 0 && (
        <Grid item md={12} xs={12} sm={12} className={classes.grid}>
          <GridItem md={12}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </GridItem>
          {props.insuranceList &&
            props.insuranceList.map((bus, index) => (
              <Grid
                container
                key={index}
                item
                xs={12}
                md={12}
                style={{
                  background: "#fff",
                  // border: "1px solid #dcdcdc",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 6px -1px #b3b3b3",
                  marginBottom: 20,
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.grid1}
                >
                  <Grid item container md={4} xs={6}>
                    <Grid item>
                      <i
                        className="fas fa-bus"
                        aria-hidden="true"
                        style={{ opacity: 0.5, fontSize: 22 }}
                      ></i>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.typo1}>
                        Total Passenger(s):{" "}
                        {bus.passengerDetails && bus.passengerDetails.length}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  
                    
                    <div className={classes.headerRightContainer}>
                      <div className={classes.headerRightText}>
                          Departure:{" "}
                          {bus.insuranceDetails.departDate &&
                            moment(bus.insuranceDetails.departDate).format(
                              "DD MMM'YY"
                            )}
                        </div>
                        <div className={classes.headerRightText}>
                          Policy No:{" "}
                          {bus.insuranceDetails.policyNumber &&
                            bus.insuranceDetails.policyNumber}
                        </div>
                      </div>
                    
                  
                </Grid>
                <Grid container style={{ padding: 15 }}>
                  <Grid container item xs={12} md={8} className={classes.grid2}>
                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs={6}>
                        <Grid
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid
                            item
                            xs={12}
                            md={12}
                            style={{ textAlign: "left" }}
                          >
                            <Typography className={classes.typo4}>
                              {bus.insuranceDetails.planName &&
                                bus.insuranceDetails.planName}
                            </Typography>
                            {/* <Typography className={classes.typo5}>
                            Economy
                          </Typography> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item md={3} xs={6}>
                        {/* <Typography className={classes.typo4}>
                          PNR :{" "}
                          {bus.insuranceDetails.pnrNumber && bus.insuranceDetails.pnrNumber}
                        </Typography> */}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ marginTop: "15px" }}
                    >
                      <Grid item xs={5} md={3}>
                        <Typography className={classes.typo6}>
                          {bus.insuranceDetails.planDescription &&
                            bus.insuranceDetails.planDescription}
                        </Typography>
                        <Typography className={classes.typo7}>
                          {bus.insuranceDetails.departDate &&
                            moment(bus.insuranceDetails.departDate).format(
                              "DD MMM'YY"
                            )}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {/* <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                          style={{ fontSize: "35px", color: "#4e4c4c" }}
                        ></i> */}
                        <ArrowForwardIcon
                          style={{ fontSize: "35px", color: "#4e4c4c" }}
                        />
                      </Grid>
                      <Grid item xs={5} md={3}>
                        <Typography className={classes.typo7}>
                          {bus.insuranceDetails.returnDate &&
                            moment(bus.insuranceDetails.returnDate).format(
                              "DD MMM'YY"
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      justifyContent="space-between"
                      alignItems="center"
                      style={{ marginTop: 15 }}
                    >
                      <Grid item md={3} xs={6}>
                        <Typography className={classes.typo8}>
                          {bus.passengerDetails[0] &&
                            bus.passengerDetails[0].firstName +
                              " " +
                              bus.passengerDetails[0].lastName}
                        </Typography>
                      </Grid>
                      <Grid item md={3} xs={6}>
                        <Typography className={classes.typo8}>
                          Status
                          <span style={{ color: "#007bff" }}>{bus.status}</span>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    continer
                    item
                    xs={12}
                    md={4}
                    style={{ paddingLeft: 15 }}
                  >
                    <Grid item xs={12}>
                      <Button
                        className={classes.button}
                        style={{ width: "12rem" }}
                        // onClick={() => props.history.push("/b2c/faredetails")}
                        onClick={() => setOpenFareDetails(true)}
                      >
                        Fare Details
                      </Button>
                    </Grid>

                    {/* <Grid item xs={12} style={{ marginTop: 20 }}>
                    {hotel.roomDetails.details[0] &&
                    hotel.roomDetails.details[0].refundStatus ===
                      "Not Refunded" ? null : (
                      <Button
                        className={classes.button}
                        style={{ width: "12rem" }}
                      >
                        Check Refund Status
                      </Button>
                    )}
                  </Grid> */}
                  </Grid>
                </Grid>

                {bus.status &&
                  (bus.status != "Pending" && bus.status != "SUCCESSCan") && (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      style={{
                        borderTop: "1px solid #9e9e9e",
                        margin: "0px 15px",
                        padding: "15px 0px",
                      }}
                    >
                      <Grid
                        item
                        style={{ padding: "0px 10px", cursor: "pointer" }}
                        onClick={() => {
                          // props.history.push("/print/insuranceticket/" + bus.tid);
                          window.open(
                            basename + "/print/insuranceticket/" + bus.tid,
                            "_blank"
                          );
                        }}
                      >
                        {/* <i
                      className="fa fa-ticket"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
                        <PrintIcon
                          style={{
                            textAlign: "center",
                            fontSize: "25px",
                            paddingBottom: "7px",
                            color: "#6f6f6f",
                          }}
                        />
                        <Typography className={classes.typo9}>
                          Print Ticket
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        style={{ padding: "0px 10px", cursor: "pointer" }}
                        onClick={() => {
                          // props.history.push("/print/insuranceticket/" + bus.tid);
                          window.open(
                            basename + "/print/insuranceticket/" + bus.tid,
                            "_blank"
                          );
                        }}
                      >
                        {/* <i
                      className="fa fa-download"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}
                        <ArrowDownwardIcon
                          style={{
                            textAlign: "center",
                            fontSize: "25px",
                            paddingBottom: "7px",
                            color: "#6f6f6f",
                          }}
                        />
                        <Typography className={classes.typo9}>
                           Ticket
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ padding: "0px 10px", cursor: "pointer" }}
                        onClick={() => {
                          window.open(
                            basename +
                              "/print/voucher/insurance-user/" +
                              bus.tid,
                            "_blank"
                          );
                        }}
                      >
                        <DescriptionIcon
                          style={{
                            textAlign: "center",
                            fontSize: "25px",
                            paddingBottom: "7px",
                            color: "#6f6f6f",
                          }}
                        />
                        {/* <i
                      className="fa fa-sticky-note-o"
                      aria-hidden="true"
                      style={{
                        textAlign: "center",
                        fontSize: "19px",
                        paddingBottom: "7px",
                        color: "#6f6f6f",
                      }}
                    ></i> */}

                        <Typography className={classes.typo9}>
                          Invoice
                        </Typography>
                      </Grid>

                      {/* <Grid
                        item
                        style={{ padding: "0px 10px", cursor: "pointer" }}
                      
                        onClick={(e) => cancelBtn(e, bus)}
                      >
                      
                        <CancelIcon
                          style={{
                            textAlign: "center",
                            fontSize: "25px",
                            paddingBottom: "7px",
                            color: "#6f6f6f",
                          }}
                        />
                        <Typography className={classes.typo9}>
                          Cancel
                        </Typography>
                      </Grid> */}
                      <Dialog
        open={openFareDetails}
        onClose={() => {
          setOpenFareDetails(false)
        }}
        fullWidth={true}
        maxWidth={"md"}
      >
             <InsuranceFareDetailsB2C insurance={bus} />  
      </Dialog>
                    </Grid>
                    
                  )}
              </Grid>
              
            ))}
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogContent>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Passenger Name
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Seat No
            </GridItem>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Price
            </GridItem>
            <GridItem
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Checkbox
                color="primary"
                checked={allSeatSelected}
                onChange={checkAllOnClick}
              />
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            passengers &&
            passengers.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={5}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.Title} {value.firstName} {value.lastName}
                  </GridItem>
                  {/* <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.Seat?.SeatName}
                  </GridItem> */}
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    &#x20B9;{bookingAmount}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {value.isCancelled ? (
                      <span>{"Cancelled"}</span>
                    ) : (
                      <Checkbox
                        color="primary"
                        checked={value.checked || false}
                        onClick={() => {
                          checkOnClick(value.passengerId);
                        }}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              );
            })}
          <Divider />
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation Remarks
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setRemarks(e.target.value)}
                value={remarks}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem md={5} style={{ display: "flex", alignItems: "center" }}>
              Cancellation charge per seat
            </GridItem>
            <GridItem md={6} style={{ display: "flex", alignItems: "center" }}>
              <TextField
                variant="outlined"
                placeholder={"Enter your remarks here"}
                onChange={(e) => setCancellationChargePerSeat(e.target.value)}
                value={cancellationChargePerSeat}
              />
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 50,
                fontWeight: "bold",
              }}
            >
              Cancellation Policy
            </GridItem>
          </GridContainer>
          <Divider />
          <GridContainer>
            <GridItem md={2} style={{ display: "flex", alignItems: "center" }}>
              Charge
            </GridItem>
            <GridItem md={3} style={{ display: "flex", alignItems: "center" }}>
              Charge Type
            </GridItem>
            <GridItem md={7} style={{ display: "flex", alignItems: "center" }}>
              Policy
            </GridItem>
          </GridContainer>
          <Divider />
          {open &&
            cancellationPolicy &&
            cancellationPolicy.map((value, index) => {
              return (
                <GridContainer key={index}>
                  <GridItem
                    md={2}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationCharge}
                  </GridItem>
                  <GridItem
                    md={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.cancellationChargeTypeString}
                  </GridItem>
                  <GridItem
                    md={7}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {value.policyString} + &#8377;{cancellationChargePerSeat}{" "}
                    max handling charge
                  </GridItem>
                </GridContainer>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CloseIcon />}
            onClick={handleClose}
            color="primary"
            disabled={isLoading}
          >
            Disagree
          </Button>
          <Button
            startIcon={
              isLoading ? <CircularProgress size="24px" /> : <DoneIcon />
            }
            onClick={handleConfirmClose}
            color="primary"
            autoFocus
            disabled={isLoading}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    insuranceList: state.insuranceList.insuranceList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setB2CInsuranceList: (insuranceList) =>
      dispatch(setB2CInsuranceList(insuranceList)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyBookingsInsurance)
);
