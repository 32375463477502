import React, {useState, useEffect} from 'react'
import OaCard from 'oahoc/OaCard';
import {getQueryVar, getCurrentTab, getAgencyId} from 'oautils/oaCommonUtils.js';
import OaTabBar from 'oahoc/OaTabBar';
import AgentDetails from 'pages/agent/AgentDetails';

import FlightSearchForm from 'pages/report/flight/FlightSearchForm';
import FlightSearchResult from 'pages/report/flight/FlightSearchResult';
import dateFnsFormat from 'date-fns/format';

export const Flight = (props) => {

    const [agencyId, setAgencyId] = useState(getAgencyId(props));
    const [isLoading, setIsLoading] = useState(true);

    let d = new Date;
    let fromDate = d.getDate() > 1 ? new Date(d.getFullYear(), d.getMonth(), 1) : new Date(d.getFullYear(), d.getMonth() - 1, 1);

    let initialParams = {
        agencyId: agencyId,
        flightBookingFromDateDisplay: fromDate,
        flightBookingToDateDisplay: new Date(),
        flightBookingFromDate: dateFnsFormat(fromDate, 'dd-MM-yyyy'),
        flightBookingToDate: dateFnsFormat(new Date(), 'dd-MM-yyyy'),
        flightPNR: '',
        type: ' '
    };

    const [searchParams, setSearchParams] = useState(initialParams);
    const [tableKey, setTableKey] = useState(0);
    

    function flightReportLoader(searchParams){
        setSearchParams(searchParams);
        setTableKey(tableKey + 1);
    }

    let tabs = [
        { name: 'flight', label: 'Flight', url: '/admin/booking-report/flight' },
    ];

    let currentTab = 'flight';

    useEffect(() => {
        let aId = getAgencyId(props)
        setAgencyId(aId);
        setSearchParams({...searchParams, agencyId: aId});
        setTableKey(tableKey + 1);
        setIsLoading(false);
    }, [props]);

    return (
        <>
        {!isLoading &&
            <OaCard className={'oa-card-primary'}>
                <OaTabBar tabs={tabs} currentTab={currentTab} type='primary'/>
                {agencyId && 
                    (
                        <OaCard>
                            <AgentDetails agencyId={agencyId} />
                        </OaCard>
                    )
                }
                <OaCard>
                    <FlightSearchForm 
                        target={flightReportLoader} 
                        agencyId={agencyId}
                        searchParams={searchParams}
                        setIsLoading={setIsLoading}
                    />
                </OaCard>

                <OaCard>
                    <FlightSearchResult key={tableKey} searchParams={searchParams} agencyId={agencyId}/>
                </OaCard>
            </OaCard>
        }
        </>
    )
}
export default Flight;
