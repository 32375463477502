import React, {useState} from 'react';
import OaFormTextField from 'oahoc/OaFormTextField';
import GridContainer from "components/Grid/GridContainer.js";
// import Button from "components/CustomButtons/Button.js";
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { Formik, Form, ErrorMessage } from 'formik';
import GridItem from 'components/Grid/GridItem';
import * as Yup from 'yup';

import {apiCall} from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import OaFormAlerts from 'pages/components/OaFormAlerts';

import FormControl from "@material-ui/core/FormControl";
import { withRouter } from 'react-router';
import OaFormSelectComboField from 'oahoc/OaFormSelectComboField';

import OaDmtConfirmationAlert from 'pages/components/OaDmtConfirmationAlert';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import EvStation from '@material-ui/icons/EvStation';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            background: theme.palette.primary.silverWhite,
        },
        
        background: theme.palette.primary.main,
        borderRadius: 3,
        border: 0,
        color: theme.palette.primary.contrastText,
        height: 40,
        padding: '0 30px',
        boxShadow: '0 0px 5px 2px rgba(0, 0, 0, .3)',
        margin: 5,
    },
}));


const OperatorSearchForm = (props) => {
    const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState('');
    const [alertTitle, setAlertTitle] = useState('');
    const [message, setMessage] = useState('');
    
    const [alertCallbackParam, setAlertCallbackParam] = useState({});
    const [alertAdditionalData, setAlertAdditionalData] = useState({});
    const [alertMsg, setAlertMsg] = useState('');
    const [primaryButtonText, setPrimaryButtonText] = useState('');
    const [secondaryButtonText, setSecondaryButtonText] = useState('');
    const [hideSecondaryButton, setHideSecondaryButton] = useState(false);
    const [dialogRef, setDialogRef] = useState('');
    const [isDmtConfirmationPrompt, setIsDmtConfirmationPrompt] = useState(false);
    const formikRef = React.useRef();
    const classes = useStyles();

    function changeHandler(event, selectedObject, setFieldValue) {
        const id = event.currentTarget.id;

        setMessage(selectedObject.subCategoryDescription);
        // console.log(selectedObject.subCategoryDescription)
        setFieldValue('optional', (selectedObject.subCategoryDescription && selectedObject.subCategoryDescription.toLowerCase().indexOf("optional") !== -1 ? '' : 'na'));
     }


     function transactionHelper(buttonText, alertCallbackParam,additionalData,dialogRef) {
        if(dialogRef == 'dialog1' && buttonText == 'YES' && isSaving === true) {
            apiCall(WebApi.doRechargeTransact, alertCallbackParam, (response) => {
                setIsError(!response.status);
                setIsSuccess(response.status);

                formikRef.current.setSubmitting(false);
                setIsSaving(false);
                setInfoText(response.message);
            });
        }
        
        else if(buttonText == 'NO') {
            // console.log(buttonText);
            setIsSaving(false);
            formikRef.current.setSubmitting(false);
        }
    }

    function getIcon() {
        return !isSaving? <EvStation/>: <CircularProgress size="24px"/>
    }

    return (
        <>
        {props.operators.length == 0 && <div>No operator present, please contact admin</div>}
        <OaFormAlerts 
            isSaving={isSaving} 
            isSuccess={isSuccess} 
            isError={isError} 
            infoText={infoText} 
            setIsError={setIsError} 
            setIsSuccess={setIsSuccess} 
            alertTitle={alertTitle}
        />

        <OaDmtConfirmationAlert isPrompt={isDmtConfirmationPrompt} 
            callback={transactionHelper} 
            callbackParam={alertCallbackParam} 
            msg={alertMsg}
            title={alertTitle}
            dialogRef={dialogRef}
            additionalData={alertAdditionalData}
            primaryButtonText={primaryButtonText}
            secondaryButtonText={secondaryButtonText}
            hideSecondaryButton={hideSecondaryButton}
            togglePrompt={()=>{setIsDmtConfirmationPrompt(!isDmtConfirmationPrompt)}}>
        </OaDmtConfirmationAlert>

        <Formik 
            innerRef={formikRef}
            initialValues={{ 
                rnum: '',
                ramt:'',
                type:props.type,
                optr:'',
                optional:'na'
            }}
            validationSchema={Yup.object().shape({
                rnum: Yup.string()
                    .required("Value is required")
                    .matches(phoneRegex, 'Invalid mobile number'),
                ramt: Yup.number()
                    .required("Amount is required"),
                type: Yup.string()
                    .required("Type is required"),
                optr: Yup.string()
                    .required("Operator is required")
            })}
            onSubmit= {(searchParams, {setSubmitting, resetForm, enableReinitialize}) => {
                setSubmitting(true);
                setIsSaving(true);

                setIsDmtConfirmationPrompt(true);
                setAlertCallbackParam(searchParams);
                setHideSecondaryButton(false);
                setDialogRef('dialog1');

                apiCall(WebApi.getRechargeCommissionCharge, searchParams, (response) => {
                    setAlertAdditionalData({
                        header: {
                            "field1Label": 'Actual Amount',
                            "field2Label": 'Additional Service Charge',
                            "field3Label": 'Gross'
                        },
                        transactions : [{
                            "actualAmount":searchParams.ramt,
                            "serviceCharge": parseFloat(response.grossProductPrice-searchParams.ramt),
                            "total": parseFloat(response.grossProductPrice)
                        }],
                        total: {
                            "totalAmount":searchParams.ramt,
                            "serviceCharge": parseFloat(response.grossProductPrice-searchParams.ramt),
                            "total": parseFloat(response.grossProductPrice)
                        }
                    });
                });

                setIsDmtConfirmationPrompt(true);
            }}>
            {({ values, isSubmitting, setFieldValue,enableReinitialize, resetForm  }) => (
                <Form>
                    <GridContainer md={12} sm={12} xs={12} style={{marginTop:20}}>
                        <GridItem md={12} sm={12} xs={12} style={{display:'none'}}>
                            <OaFormTextField name="type" label="Type"  disabled={true}/>
                            <ErrorMessage name="type" component="div" className="error"/>
                        </GridItem>

                        <GridItem md={4} sm={6} xs={12}>
                            <OaFormSelectComboField 
                                label="Operator"
                                name="optr"
                                value={values.optr}
                                options={props.operators}
                                optionValue={'subCategoryCode'} 
                                optionLabel={['subCategoryName', 'subCategoryCode']}
                                icon={true}
                                required={true}
                                setFieldValue={setFieldValue}
                                changeHandler={changeHandler}
                             />
                           <ErrorMessage name="optr" component="div" className="error"/>
                        </GridItem> 

                        <GridItem md={4} sm={6} xs={12}>
                            <OaFormTextField name="rnum" label= {message || "Enter 10 digit mobile/subscriber/customer number"} />
                            <ErrorMessage name="rnum" component="div" className="error"/>
                        </GridItem>

                        <GridItem md={2} sm={6} xs={12}>
                            <OaFormTextField name="ramt" label="Amount(Rs.)" />
                            <ErrorMessage name="ramt" component="div" className="error"/>
                        </GridItem>

                        <GridItem md={2} sm={6} xs={12}>
                            <FormControl>
                                <Button 
                                    type="submit" 
                                    variant="contained" 
                                    color="primary"
                                    size="large" 
                                    startIcon={getIcon()}
                                    disabled={isSubmitting}
                                    classes={{
                                        root: classes.root,
                                    }}
                                >
                                    Submit
                                </Button>
                            </FormControl> 
                        </GridItem>
                    </GridContainer>
                    {
                        message && message.toLowerCase().indexOf("optional") !== -1 &&
                        <GridContainer md={12} style={{marginTop:20}}>
                            <GridItem md={4} >
                                <OaFormTextField name="optional" label="Optional" value={null}/>
                            </GridItem>
                        </GridContainer>
                    }
                </Form>
            )}
        </Formik>
        </>
       
    )
}

export default withRouter(OperatorSearchForm);
