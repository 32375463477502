import { useTheme } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const TrainSearch = (props) => {
    const theme = useTheme();
    console.log("theme", theme)
    return (
        <RootContainer theme={theme}>
            <div className="full-width flex-box justify-content-center">
                <div className="form-container-wrapper">
                    <div className="form-container">
                        <div className="form-row full-width">
                            <div className="form-element btn-element">
                                <a className="btn btn-link" href="https://www.irctc.co.in/" target="_blank">
                                    IRCTC Booking
                                </a>
                            </div>
                            <div className="form-element btn-element">
                                <a className="btn btn-link" href="https://www.irctc.co.in/" target="_blank">
                                    IRCTC Agent Interface
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </RootContainer>
    );
};

export default TrainSearch;

const RootContainer = styled.div`
display: flex;
flex-grow: 1;
width: 100%;
border-radius: 8px;
background-color: ${prop => prop.theme.palette.background.default};
box-shadow: 2px 2px 10px 0px rgba(133, 131, 133, 1);
-webkit-box-shadow: 2px 2px 10px 0px rgba(133, 131, 133, 1);
-moz-box-shadow: 2px 2px 10px 0px rgba(133, 131, 133, 1);
.indicator {
  background-color: ${prop => prop.theme.palette.background.default};
}

.full-width {
    width: 100%;
}

.search-section-container {
    box-sizing: border-box;
}
.form-container-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    .form-container {
        width: 100%;
        min-height: 101px;
        position: relative;
    }
}
.form-row {
    display: flex;
    justify-content: center;
}
.form-element {
    padding: 20px 10px;
    text-align: center;
    width: 300px
}
.btn-element {
    position: relative;
    padding: 35px 10px;
}
.btn-link {
    text-transform: uppercase;
    display: block;
    text-decoration: none;
    width: 250px;
    padding: 10px 15px;
    border-radius: 4px;
    font-size: 17px;
    font-weight: 500;
    background: ${prope => prope.theme.palette.primary.main};
    color: ${prope => prope.theme.palette.primary.contrastText};
    border: solid 1px ${prope => prope.theme.palette.primary.main};
    &:disabled {
        cursor: not-allowed;
    }
}
`;
