import React, { useEffect, useState } from "react";
import AadhaarServicesForm from "./Aadhaar/AadhaarServicesForm";
import AepsIcon from "assets/img/icons/aeps_icon_cropped.png";
import AadhaarpayIcon from "assets/img/icons/aadhaarpayover.png";
import { makeStyles, Box } from "@material-ui/core";
import { Route, Switch, useHistory } from "react-router-dom";
import { localforageGetItem } from "oautils/oaForageUtils";

const innerPageUrl = "/aadhaarpay/";

const AadhaarServicesSection = (props) => {
    const history = useHistory();
    const classes = useStyles();
    const [userType, setUserType] = useState(null);
    const [corporateType, setCorporateType] = useState(null);

    const optionsQuicklinks = [
        {
            name: "transactionEnquiry",
            label: "Transaction Enquiry",
            // url: innerPageUrl + "transaction-enquiry",
            url: "",
            icon: <i className="fas fa-search"></i>,
        },
        {
            name: "transactionReports",
            label: "Transaction Reports",
            url: "/admin/transaction-report/aeps",
            icon: <i className="fas fa-list"></i>,
        }
    ];

    const openQuickLink = (url) => {
        history.push({
            pathname: url,
        });
    };

    useEffect(() => {
        localforageGetItem("user-details", function (err, details) {
            if (details != null) {
                setUserType(details.userType);
                setCorporateType(details.corporateType);
            }
        });
    }, []);

    return (
        <div className={classes.root}>
            <div className="service-content-wrapper">
                <Switch>
                    <Route path="/aadhaarpay/aeps" component={AadhaarServicesForm} />
                    <Route path="/aadhaarpay/aadhaarpay" component={AadhaarServicesForm} />
                    <Route component={AadhaarServicesForm} />
                </Switch>
            </div>

            {/* <div className="service-quick-links">
                {serviceQuickLinks && serviceQuickLinks.map((val, ind) => (
                    <div className="quick-link-item" 
                        key={ind} onClick={() => openQuickLink(val.url)} 
                        title={val.toolTip}
                    >
                        <div className="qlink-icon-wrapper">
                            {val.icon}
                        </div>
                        <div className="qlink-label-wrapper">{val.label}</div>
                    </div>
                ))}
            </div> */}
            {userType &&
                userType == "agent" &&
                corporateType &&
                corporateType != "corporate" && (
                    <div className="quick-links-box">
                        {optionsQuicklinks &&
                            optionsQuicklinks.map((val, ind) => (
                                <div
                                    item
                                    className="quick-link-item"
                                    key={ind}
                                    onClick={() => openQuickLink(val.url)}
                                >
                                    <div className="qlink-icon-wrapper">{val.icon}</div>
                                    <div className="qlink-label-wrapper">{val.label}</div>
                                </div>
                            ))}
                    </div>
                )
            }
        </div>
    );
};

export default AadhaarServicesSection;

const AepsImage = () => <span className="quicklink-icon"><img src={AepsIcon} /></span>;

const AadhaarpayImage = () => <span className="quicklink-icon"><img src={AadhaarpayIcon} /></span>;

// const serviceQuickLinks = [
//     {
//         name: "aeps",
//         label: "AEPS",
//         toolTip: "Transfer funds via AEPS",
//         url: innerPageUrl + "aeps",
//         icon: <AepsImage/>
//     },
//     {
//         name: "aadhaarpay",
//         label: "Aadhaar Pay",
//         toolTip: "Transfer funds via Aadhaar Pay",
//         url: innerPageUrl + "aadhaarpay",
//         icon: <AadhaarpayImage/>
//     }
// ];

const useStyles = makeStyles(theme => ({
    root: {
        padding: 0,
        [theme.breakpoints.down(1025)]: {
            marginRight: 0
        },
        "& .service-content-wrapper": {
            width: "100%",
            padding: 15,
            boxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
            WebkitBoxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
            MozBoxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
            background: theme.palette.background.light,
            borderRadius: 5,
            [theme.breakpoints.down(960)]: {
                minWidth: "100vw",
                padding: 20,
            },
        },
        "& .service-quick-links": {
            borderTop: `solid 0.5px ${theme.palette.primary.disabled}`,
            background: theme.palette.tertiary.main,
            borderRadius: "0 0 5px 5px",
            padding: "0 20px",
            display: "flex",
            justifyContent: "flex-start",
            [theme.breakpoints.down(600)]: {
                flexWrap: "wrap",
                justifyContent: "center",
            }
        },
        "& .quick-links-box": {
            display: "flex",
            alignItems: "center",
        },
        "& .quick-link-item": {
            margin: 5,
            padding: "2px 5px",
            color: theme.palette.primary.contrastText,
            fontWeight: 500,
            backgroundColor: theme.palette.primary.main,
            border: 'none',
            borderRadius: 4,
            cursor: "pointer",
            width: 130,
            lineHeight: 1.2,
            border: `solid 1px ${theme.palette.background.default}`,
            [theme.breakpoints.down(600)]: {
                width: 97,
            },
            "&:hover": {
                border: `solid 1px ${theme.palette.primary.main}`,
            },
            "& .quicklink-icon": {
                "& img": {
                    height: 18
                }
            },
            "& .qlink-icon-wrapper": {
                textAlign: "center",
                marginTop: 3,
                "& .MuiSvgIcon-root": {
                    fontSize: 15,
                },
                "& i": {
                    fontSize: 15,
                }
            },
            "& .qlink-label-wrapper": {
                fontSize: 10,
                textAlign: "center",
                whiteSpace: "nowrap",
                textTransform: 'uppercase',
                [theme.breakpoints.down(600)]: {
                    whiteSpace: "break-spaces",
                },
            },
        },
        "& .appli-type-form-wrapper": {
            padding: "20px 25px"
        },
        "& .pan-after-service-form-wrapper": {
            padding: "20px 25px",
            "& .pas-form-row": {
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
                marginBottom: 10
            },
            "& .pas-form-group": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                "& p": {
                    fontWeight: 600
                },
                "& .fs-radio-group": {
                    display: "flex",
                    flexDirection: "row"
                }
            }
        },
        "& .open-draft-form-wrapper": {
            padding: "20px 25px",
            "& .form-group": {
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "flex-start"
            }
        },
        "& .form-container": {
            gap: 25,
            flexWrap: "nowrap",
            [theme.breakpoints.down(992)]: {
                flexWrap: "wrap",
            }
        },
        "& .action-group": {
            display: "flex",
            alignItems: "flex-end",
            "& .MuiButton-root": {
                borderRadius: 25,
                height: 35,
                width: 110,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: theme.palette.buttons.quarternary,
                "& .MuiButton-label": {
                    color: theme.palette.buttons.quarternaryContrastText,
                }
            }
        },
        "& .col-25": {
            width: "25%"
        },
        "& .ai-center": {
            alignItems: "center !important"
        }
    }
}));
