import LoadingPage from "pages/login/LoadingPage.js";
import Dashboard from "pages/dashboard/Dashboard.js";
import Ledger from "pages/report/ledger/Ledger";
import BankAccount from "pages/management/bank-account-details/BankAccount.js";
import MasterBalanceManager from "pages/management/balance/master/MasterBalanceManager";
import GroupBooking from "pages/groupBooking/GroupBooking";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Reports from "@material-ui/icons/InsertChart";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import PaymentIcon from "@material-ui/icons/Payment";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import B2bLoginPage from "components/LandingPage/Gostree/login/B2bLoginPage";
import { AppConstant } from "appConstant";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
    hidden: false,
  },

  {
    collapse: true,
    name: "Transactions",
    icon: Reports,
    state: "reportsCollapse",
    views: [],
  },
  {
    name: "Account Ledger",
    icon: LibraryBooksIcon,
    component: Ledger,
    path: "/ledger",
    layout: "/admin",
  },
  {
    name: "Group booking",
    icon: LibraryBooksIcon,
    component: GroupBooking,
    path: "/groupbooking",
    layout: "/admin",
    hidden: true,
  },
  {
    collapse: true,
    name: "Fund Management",
    icon: AccountBalanceWalletIcon,
    state: "masterBalanceCollapse",
    views: [
      {
        path: "/management/balance/master",
        name: "My Fund Request",
        icon: PaymentIcon,
        component: MasterBalanceManager,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: false,
    name: "Bank Accounts",
    icon: AccountBalanceIcon,
    component: BankAccount,
    path: "/management/bank-account",
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Markup",
    icon: MoneyOffIcon,
    state: "markupCollapse",
    views: [],
  },
  {
    path: "/login-page",
    name: AppConstant.ccName,
    mini: "L",
    component: B2bLoginPage,
    layout: "/auth",
    hidden: true,
  },

  {
    path: "/loading-page",
    name: "GEEBAKU",
    mini: "L",
    component: LoadingPage,
    layout: "/auth",
    hidden: true,
  },
  {
    path: "/login",
    name: AppConstant.name,
    mini: "L",
    layout: "/auth",
    hidden: true,
  }
];

export default dashRoutes;
