import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { getQueryVar } from "oautils/oaCommonUtils";
import OaCard from "oahoc/OaCard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/ticket-custom-div-print.css";
import { scrollToTop } from "oautils/oaCommonUtils";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import {
  CircularProgress,
  TextField,
  FormControl,
  Typography,
} from "@material-ui/core";
import logo from "assets/lookMyTicket/images/company_logo.png";

import Save from "@material-ui/icons/Save";
import Send from "@material-ui/icons/Send";
import { AppStateContext } from "layouts/AppStateProvider";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import "./voucher.css";
import { AppConstant } from "appConstant";
import { useSelector } from "react-redux";
import dateFnsFormat from "date-fns/format";
import OaLoading from "pages/components/OaLoading";
import OaButtonDownloadPDF from "pages/components/OaButtonDownloadPDF";
import { localforageGetItem } from "oautils/oaForageUtils";
import { DOWNLOAD_HOTEL_VOUCHER_PDF } from "api/ApiConstants";

const useStyles = makeStyles(styles);

const HotelVoucher = ({ location, history, ...props }) => {
  const [bookingDetails, setBookingDetails] = useState(
    location.state ? location.state.bookingDetails : null
  );

  // console.log(location, props, history);

  const { values, hotelSearch } = {
    ...location.state,
  };
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const {
    setConfirmation,
    isConfirmed,
    setIsConfirmed,
    setOpen,
  } = React.useContext(AppStateContext);

  const ticketId = history?.location?.state?.tid
    ? history?.location?.state?.tid
    : getQueryVar({ ...props, q: "ticketId" });

  const [isUpdateMarkup, setIsUpdateMarkup] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [markupTemp, setMarkupTemp] = useState(0);
  const [loggedInUserId, setLoggedInUserId] = useState(0);
  const { userType } = useContext(AppStateContext);
  const theme = useTheme();
  const userTypeInfo = useSelector((state) => state.usertype);
  // const [isMobile,setIsMobile]=useState(useMediaQuery(theme.breakpoints.down("sm")))
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isMounted = React.useRef(false);
  const [totalPaid, setTotalPaid] = React.useState(0);
  const [bookingId, setBookingId] = React.useState(null);
  const [totalAdult, setTotalAdult] = React.useState(1);
  const [totalChild, setTotalChild] = React.useState(0);
  const [failed, setFailed] = React.useState(false);
  // console.log(ticketId);
  // console.log("traceID", response.data.bookResult.traceId);

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        setLoggedInUserId(value.userId);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  const calculateTotalPaid = () => {
    // let total = Number(
    //   bookingDetails.hotelRoomsDetails.reduce(
    //     (total, room) => {
    //       return (
    //         total +
    //         room.price.commissionCharge
    //           .grossProductPrice -
    //         room.price.commissionCharge.agentMarkup
    //       );
    //     },
    //     0
    //   )
    // ) + Number(markup);
    let total = Number(bookingDetails.totalAmount) + Number(markup);
    setTotalPaid(
      bookingDetails?.discountVoucherValue
        ? total - bookingDetails.discountVoucherValue
        : total
    );
  };

  useEffect(() => {
    isMounted.current = true;
    if (!bookingDetails) {
      // console.log("bookingDetails", bookingDetails);
      // console.log("ticketId", ticketId);
      WebApi.getHotelBookDetails(
        {
          // traceId: response.data.bookResult.traceId,
          // bookingId: response.data.bookResult.bookingId,
          // confirmationNo: response.data.bookResult.confirmationNo,
          // firstName: "Amit",
          // lastName: "Jee",
          tid: ticketId,
        },
        (response) => {
          // setIsSuccess(true);
          // setInfoText(response.message);
          if (response.success) {
            // console.log(response.data.getBookingDetailResult);
            let adults = 0,
              childs = 0,
              roomCount = 0;
            if (
              response.data.getBookingDetailResult?.hotelRoomsDetails.length > 0
            ) {
              for (let room of response.data.getBookingDetailResult
                ?.hotelRoomsDetails) {
                adults += room.adultCount;
                childs += room.childCount;
                roomCount++;
              }
            }
            console.log("roomCount", roomCount);
            console.log("adults", adults);
            console.log("childs", childs);
            setTotalAdult(adults);
            setTotalChild(childs);
            setBookingDetails(response.data.getBookingDetailResult);
            setMarkup(response.data.getBookingDetailResult.agentMarkup);
            setMarkupTemp(response.data.getBookingDetailResult.agentMarkup);
            setReceiverEmail(
              response.data.getBookingDetailResult.agencyInfo
                ? response.data.getBookingDetailResult.agencyInfo.email
                : ""
            );
            setBookingId(response.data.getBookingDetailResult.bookingId);
            setIsLoading(false);
          } else {
            console.log("response", response);
            setIsSuccess(false);
            setInfoText(response.message);
            setFailed(true);
            setIsLoading(false);
          }
        },
        (error) => {
          console.error("Error getting booking details", error);
          setIsLoading(false);
        }
      );
    } else {
      setMarkup(location.state.bookingDetails.agentMarkup);
      setMarkupTemp(location.state.bookingDetails.agentMarkup);
      setReceiverEmail(
        location.state.bookingDetails.agencyInfo
          ? location.state.bookingDetails.agencyInfo.email
          : ""
      );
      setIsLoading(false);
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
  }, [isMounted.current]);

  const hotelVoucherDownloadRequest = {
    loggedInUserId: loggedInUserId,
    tid: ticketId,
    isIslandHopper: "false",
  };

  const printClick = () => {
    window.print();
  };

  const handleMarkupSave = () => {
    setIsSaving(true);
    const markup1 = markup == "" ? 0 : markup;
    setMarkupTemp(markup1);
    apiCall(
      WebApi.doHotelMarkupUpdate,
      {
        markup: markup1,
        tid: ticketId ? ticketId : location.state.bookingDetails.tid,
      },
      function(response) {
        if (response.success === true) {
          setIsError(false);
          setIsSuccess(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        } else {
          setIsError(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        }
      }
    );
  };

  function getIcon() {
    return !isSaving ? <Send /> : <CircularProgress size="24px" />;
  }

  function getIcon1() {
    return !isSaving ? <Save /> : <CircularProgress size="24px" />;
  }

  function downloadETicket(e) {
    e.target.setAttribute("disabled", "disabled");
    pdfDownload(
      "section-to-print",
      "p",
      "evoucher-" + (ticketId ? ticketId : bookingDetails.tid)
    );
    e.target.removeAttribute("disabled");
  }

  const invoiceClick = () => {
    // console.log(ticketId);
    // console.log(bookingDetails.tid);
    // console.log("################ redirecting to invoice #############");
    history.push(
      "/admin/voucher/hotel-user/" + ticketId ? ticketId : bookingDetails.tid
    );
  };

  function sendEmail() {
    if(receiverEmail) {
      setIsSaving(true);
      const emailReqParams = {...hotelVoucherDownloadRequest};
      emailReqParams.email = receiverEmail;

      WebApi.emailHotelTicketPdf(emailReqParams, (response) => {
        response?.message ? setInfoText(response?.message) : setInfoText("internal error!");
        setIsSuccess(response.success);
        setIsError(!response.success);
        setIsSaving(false);
      }, (error) => {
        console.error("Email error", error);
        setIsSaving(false);
      });
    }
  }

  // const cancelBookingClick = () => {
  //   setIsSaving(true);
  //   setConfirmation("Are you sure want to cancel?");
  //   setOpen(true);
  //   console.log("before if block",isConfirmed)
  //   if (isConfirmed) {
  //     setIsConfirmed(false);
  //     console.log("after if block",isConfirmed)

  //   }

  // };

  useEffect(() => {
    if (isConfirmed) {
      setIsConfirmed(false);

      apiCall(
        WebApi.getHotelBookingCancel,
        { tid: ticketId ? ticketId : bookingDetails.tid, bookingId: bookingId },
        function(response) {
          if (response.success === true) {
            setIsError(false);
            setIsSuccess(true);
            setIsSaving(false);
            setInfoText(response.message);
            scrollToTop(0);
          } else {
            setIsError(true);
            setIsSaving(false);
            setInfoText(response.message);
            scrollToTop(0);
          }
        }
      );
    }
  }, [isConfirmed]);

  React.useEffect(() => {
    if (bookingDetails) {
      calculateTotalPaid();
    }
  }, [bookingDetails]);

  return isLoading ? (
    <OaLoading />
  ) : failed ? (
    <FailedView message="Booking details not found!" responseText={infoText} />
  ) : (
    <>
      <OaCard className="section-not-to-print">
        <GridContainer justifyContent="left">
          <GridItem md={12}>
            <OaFormAlerts
              isSaving={isSaving}
              isSuccess={isSuccess}
              isError={isError}
              infoText={infoText}
              setIsError={setIsError}
              setIsSuccess={setIsSuccess}
            />
          </GridItem>
          <GridItem md={12} xs={12}>
            {isMobile ? (
              <IconButton
                onClick={() => {
                  setIsSendEmail(!isSendEmail);
                  setIsUpdateMarkup(false);
                }}
              >
                <EmailIcon />
              </IconButton>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  setIsSendEmail(!isSendEmail);
                  setIsUpdateMarkup(false);
                }}
                // className={classes.registerButton}
                fullWidth={isMobile}
              >
                Email
              </Button>
            )}

            {isMobile ? (
              <IconButton onClick={printClick}>
                <PrintIcon />
              </IconButton>
            ) : (
              <Button
                color="primary"
                onClick={printClick}
                fullWidth={isMobile}
                // className={classes.registerButton}
              >
                Print EVoucher
              </Button>
            )}
            {isMobile ? (
              <IconButton onClick={downloadETicket}>
                <GetAppIcon />
              </IconButton>
            ) : (
              // <Button
              //   color="primary"
              //   onClick={downloadETicket}
              //   fullWidth={isMobile}
              //   // className={classes.registerButton}
              // >
              //   Download EVoucher
              // </Button>
              <OaButtonDownloadPDF
                btnLabel="Download EVoucher"
                api={DOWNLOAD_HOTEL_VOUCHER_PDF}
                searchParams={hotelVoucherDownloadRequest}
                defaultFileName="hotel-evoucher"
              />
            )}
            {/* <Button
              color="primary"
              onClick={invoiceClick}
              // className={classes.registerButton}
            >
              Invoice
            </Button> */}
            {/* {isMobile ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    setConfirmation("Are you sure want to cancel?");
                    setOpen(true);
                  }}
                >
                  <CancelIcon />
                </IconButton>
              ) : (
                <Button
                  color="primary"
                  // onClick={cancelBookingClick}
                  onClick={() => {
                    setConfirmation("Are you sure want to cancel?");
                    setOpen(true);
                  }}
                  fullWidth={isMobile}
                  disabled={bookingDetails?.hotelBookingStatus === "CANCELLED"}
                // className={classes.registerButton}
                >
                  Cancel Booking
                </Button>
              )} */}
            {(userTypeInfo.userType === "agent" ||
              userTypeInfo.userType === "direct_agent") &&
              bookingDetails &&
              bookingDetails.agencyInfo &&
              (userTypeInfo && userTypeInfo.corporateType != "corporate") && (
                <Button
                  color="primary"
                  onClick={() => {
                    setIsUpdateMarkup(!isUpdateMarkup);
                    setIsSendEmail(false);
                  }}
                  // className={classes.registerButton}
                >
                  Update Markup
                </Button>
              )}
          </GridItem>
        </GridContainer>
        {isUpdateMarkup && (
          <GridContainer>
            <GridContainer justifyContent="center">
              <GridItem>
                <h4 style={{ color: theme.palette.primary.main }}>
                  UPDATE MARKUP
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="center">
              <GridItem>
                <FormControl fullWidth={true}>
                  <TextField
                    variant="outlined"
                    name="markup"
                    label="Markup Value"
                    md={4}
                    value={markup}
                    onChange={(event) => {
                      const { value } = event.target;
                      setMarkup(value);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  className={classes.registerButton}
                  endIcon={getIcon1()}
                  disabled={isSaving}
                  onClick={handleMarkupSave}
                >
                  UPDATE
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        )}

        {isSendEmail && (
          <GridContainer>
            <GridContainer justifyContent="center">
              <GridItem>
                <h4 style={{ color: theme.palette.primary.main }}>
                  SEND EMAIL
                </h4>
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent="center">
              <GridItem>
                <FormControl fullWidth={true}>
                  <TextField
                    variant="outlined"
                    name="receiverEmail"
                    label="Receiver Email"
                    md={4}
                    value={receiverEmail}
                    onChange={(event) => {
                      const { value } = event.target;
                      setReceiverEmail(value);
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem>
                <Button
                  color="primary"
                  onClick={sendEmail}
                  className={classes.registerButton}
                  endIcon={getIcon()}
                  disabled={isSaving}
                >
                  SEND
                </Button>
              </GridItem>
            </GridContainer>
          </GridContainer>
        )}
      </OaCard>
      {!isMobile ? (
        <div>
          {bookingDetails && (
            <div id="section-to-print" className="section-to-print">
              <style>
                {`
                  body {
                    font-family: "${theme.palette.font.primary}", sans-serif;
                    font-size: 0.8rem;
                  }
                  .center-align {
                    text-align: center;
                  }
                  .line {
                    background-color: ${theme.palette.primary.main};
                    height: 10px !important;
                    margin: 0px;
                    opacity: 1;
                    border-radius: 50px;
                    margin-bottom: 20px;
                  }
                  .theme-color {
                    color: ${theme.palette.primary.main};
                    font-size: 1rem;
                  }
                  .table-text-align {
                    vertical-align: middle;
                    padding: 1rem;
                    text-align: left;
                  }
                  .table {
                    --bs-table-bg: transparent;
                    --bs-table-striped-color: #007A92;
                    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
                    --bs-table-active-color: #007A92;
                    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
                    --bs-table-hover-color: #007A92;
                    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
                    width: 100%;
                    margin-bottom: 1.5rem;
                    color: #007A92;
                    vertical-align: top;
                    border-color: #dee2e6;
                  }
                  .table-border {
                    border: 1px solid #dee2e6;
                  }
                  .table th, td {
                    color: ${theme.palette.primary.defaultText}
                  }
                `}
              </style>
              <div style={{ margin: isMobile ? 0 : "20px 100px 100px 100px" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="center-align" style={{ width: "20%" }}>
                    <img
                      src={
                        bookingDetails?.agencyInfo?.logo !== "" &&
                        bookingDetails.agencyInfo?.agencyId &&
                        bookingDetails?.agencyInfo?.logo !== "NA" &&
                        bookingDetails?.agentInfo?.logo != "N/A" &&
                        bookingDetails?.agencyInfo?.logo !== null
                          ? GET_FILE_UPLOAD +
                            "?fileName=" +
                            bookingDetails.agencyInfo.logo +
                            "&type=AGENT_LOGO&loggedInUserId=" +
                            bookingDetails.agencyInfo.agencyId
                          : props.companylogo
                      }
                      style={{
                        height: "50px",
                        float: "left",
                        verticalAlign: "middle",
                        borderStyle: "none",
                      }}
                    />
                  </div>
                  <div
                    className="center-align"
                    style={{ alignSelf: "center", width: "25%" }}
                  >
                    <div
                      style={{
                        fontSize: "2rem",
                        fontWeight: 600,
                        color: theme.palette.primary.aqua,
                      }}
                    >
                      Hotel Voucher
                    </div>
                  </div>
                </div>
                <hr className="line" />
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%" }}>
                    <div className="theme-color" style={{ fontWeight: 700 }}>
                      Hotel Details
                    </div>
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className="theme-color" style={{ fontWeight: 700 }}>
                      Room Details
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div style={{ width: "50%" }}>
                    <table
                      style={{ width: "95%" }}
                      className="table"
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="table-text-align table-border "
                          >
                            Hotel <br />
                            Name
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.hotelName}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="table-text-align table-border "
                          >
                            Address
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.addressLine1}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="table-text-align table-border "
                          >
                            City
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.city}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="table-text-align table-border "
                          >
                            Contact <br />
                            Number
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.addressLine2}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div style={{ width: "50%" }}>
                    <table
                      style={{ width: "100%" }}
                      className="table"
                      cellSpacing={0}
                      cellPadding={0}
                    >
                      <tbody>
                        <tr>
                          <th
                            style={{
                              width: "50%",
                              backgroundColor: theme.palette.background.dark,
                              color: theme.palette.primary.lightText,
                            }}
                            className="table-text-align table-border "
                          >
                            Number of Room
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails?.noOfRooms} ({totalAdult} Adults,{" "}
                            {totalChild} childs)
                            {/* {bookingDetails.hotelRoomsDetails.map(
                                (room, index) => {
                                  return (
                                    <span>
                                      ({room.adultCount} Adults, {room.childCount}{" "}
                                      childs)
                                    </span>
                                  );
                                }
                              )} */}
                          </td>
                        </tr>

                        <tr>
                          <th
                            style={{
                              width: "50%",
                              backgroundColor: theme.palette.background.dark,
                              color: theme.palette.primary.lightText,
                            }}
                            className="table-text-align table-border "
                          >
                            Type of Room
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.hotelRoomsDetails.map(
                              (room, index) => {
                                return (
                                  <p>
                                    {index + 1}. {room.roomTypeName}
                                  </p>
                                );
                              }
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              width: "50%",
                              backgroundColor: theme.palette.background.dark,
                              color: theme.palette.primary.lightText,
                            }}
                            className="table-text-align table-border "
                          >
                            Booking Date
                          </th>
                          <td className="table-text-align table-border">
                            {dateFnsFormat(
                              new Date(bookingDetails.bookingDate),
                              "do MMM yyyy"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              width: "50%",
                              backgroundColor: theme.palette.background.dark,
                              color: theme.palette.primary.lightText,
                            }}
                            className="table-text-align table-border "
                          >
                            Meal Plan
                          </th>
                          <td className="table-text-align table-border">
                            Meal
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{
                              width: "50%",
                              backgroundColor: theme.palette.background.dark,
                              color: theme.palette.primary.lightText,
                            }}
                            className="table-text-align table-border "
                          >
                            Number of Guest
                          </th>
                          <td className="table-text-align table-border">
                            {bookingDetails.hotelRoomsDetails.reduce(
                              (total, room) => {
                                return (
                                  total + room.adultCount + room.childCount
                                );
                              },
                              0
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div
                  className="theme-color"
                  style={{ fontWeight: 700, marginBottom: "15px" }}
                >
                  Booking Details
                </div>
                <div style={{ width: "100%" }}>
                  <table
                    style={{ width: "100%" }}
                    className="table"
                    cellSpacing={0}
                    cellPadding={0}
                  >
                    <tbody>
                      <tr>
                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                          }}
                          className="table-text-align table-border "
                        >
                          Guest Name
                        </th>
                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                            textAlign: "right",
                          }}
                          className="table-text-align table-border "
                        >
                          Booking ID
                        </th>

                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                            textAlign: "right",
                          }}
                          className="table-text-align table-border "
                        >
                          Status
                        </th>
                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                            textAlign: "right",
                          }}
                          className="table-text-align table-border "
                        >
                          Checkin Date
                        </th>
                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                            textAlign: "right",
                          }}
                          className="table-text-align table-border "
                        >
                          Checkout Date
                        </th>
                        <th
                          style={{
                            backgroundColor: theme.palette.background.dark,
                            color: theme.palette.primary.lightText,
                            textAlign: "right",
                          }}
                          className="table-text-align table-border "
                        >
                          Amount Paid
                        </th>
                      </tr>
                      <tr>
                        <td className="table-text-align table-border">
                          {
                            bookingDetails.hotelRoomsDetails[0]
                              .hotelPassenger[0].firstName
                          }{" "}
                          {
                            bookingDetails.hotelRoomsDetails[0]
                              .hotelPassenger[0].lastName
                          }
                        </td>
                        <td
                          className="table-text-align table-border"
                          style={{ textAlign: "right" }}
                        >
                          {bookingDetails.bookingId}
                        </td>
                        <td
                          className="table-text-align table-border"
                          style={{ textAlign: "right" }}
                        >
                          {bookingDetails.hotelBookingStatus}
                        </td>

                        <td
                          className="table-text-align table-border"
                          style={{ textAlign: "right" }}
                        >
                          {dateFnsFormat(
                            new Date(
                              parseInt(
                                bookingDetails.checkInDate.substring(0, 4)
                              ),
                              parseInt(
                                bookingDetails.checkInDate.substring(5, 7)
                              ) - 1,
                              parseInt(
                                bookingDetails.checkInDate.substring(8, 10)
                              )
                            ),
                            "do MMM yyyy"
                          )}
                        </td>
                        <td
                          className="table-text-align table-border"
                          style={{ textAlign: "right" }}
                        >
                          {dateFnsFormat(
                            new Date(
                              parseInt(
                                bookingDetails.checkOutDate.substring(0, 4)
                              ),
                              parseInt(
                                bookingDetails.checkOutDate.substring(5, 7)
                              ) - 1,
                              parseInt(
                                bookingDetails.checkOutDate.substring(8, 10)
                              )
                            ),
                            "do MMM yyyy"
                          )}
                        </td>
                        <td
                          className="table-text-align table-border"
                          style={{ textAlign: "right" }}
                        >
                          {totalPaid}
                          /-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="theme-color"
                  style={{ fontWeight: 700, marginBottom: "15px" }}
                >
                  Terms And Conditions
                </div>
                <div>
                  <p>
                    a. Refund policy mentioned above is indicative. The actual
                    cancellation charges are determinedby hotel operators and
                    hotel providers at the actual time of cancellation. Paytm
                    has no role ingoverning cancellation charges
                  </p>
                  <p>
                    b. Cancellation charges are calculated on the actual fare of
                    the voucher, if any discount couponsare used while
                    purchasing the voucher, the discounted value would be used
                    to calculate the refundamount when a voucher is cancelled
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {bookingDetails && (
            <div
              id="section-to-print"
              className="section-to-print"
              style={{ width: "100%", paddingTop: 10 }}
            >
              <Grid container>
                <Grid item xs={6}>
                  {bookingDetails.agencyInfo &&
                  bookingDetails.agencyInfo.logo ? (
                    <img
                      src={
                        GET_FILE_UPLOAD +
                        "?fileName=" +
                        bookingDetails.agencyInfo.logo +
                        "&type=AGENT_LOGO&loggedInUserId=" +
                        bookingDetails.agencyInfo.agencyId
                      }
                      alt="fight icon"
                      style={{
                        height: "50px",
                        float: "left",
                        verticalAlign: "middle",
                        borderStyle: "none",
                      }}
                    />
                  ) : (
                    <img
                      src={logo}
                      style={{
                        height: "50px",
                        float: "left",
                        verticalAlign: "middle",
                        borderStyle: "none",
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography
                    style={{ color: "rgb(199, 31, 31)", fontWeight: 700 }}
                  >
                    Hotel Voucher
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    Hotel Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <table id="voucher">
                    <tbody>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Hotel <br />
                          Name
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.hotelName}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Address
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.addressLine1}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          City
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.city}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Contact <br />
                          Number
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.addressLine2}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    Room Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <table id="voucher">
                    <tbody>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Number of Room
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.noOfRooms} ({totalAdult} Adults,{" "}
                          {totalChild} childs)
                          {/* {bookingDetails.hotelRoomsDetails.map(
                              (room, index) => {
                                return (
                                  <span>
                                    ({room.adultCount} Adults, {room.childCount}{" "}
                                    childs)
                                  </span>
                                );
                              }
                            )} */}
                        </td>
                      </tr>

                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Type of Room
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.hotelRoomsDetails.map(
                            (room, index) => {
                              return (
                                <p>
                                  {index + 1}. {room.roomTypeName}
                                </p>
                              );
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Booking Date
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {dateFnsFormat(
                            new Date(bookingDetails.bookingDate),
                            "do MMM yyyy"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Meal Plan
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          Meal
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Number of Guest
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.hotelRoomsDetails.reduce(
                            (total, room) => {
                              return total + room.adultCount + room.childCount;
                            },
                            0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <Typography
                    style={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    Booking Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <table id="voucher">
                    <tbody>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Guest Name
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {
                            bookingDetails.hotelRoomsDetails[0]
                              .hotelPassenger[0].firstName
                          }{" "}
                          {
                            bookingDetails.hotelRoomsDetails[0]
                              .hotelPassenger[0].lastName
                          }
                        </td>
                      </tr>

                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Booking ID
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.bookingId}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Status
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {bookingDetails.hotelBookingStatus}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Checkin Date
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {dateFnsFormat(
                            new Date(bookingDetails.checkInDate),
                            "do MMM yyyy"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Checkout Date
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {dateFnsFormat(
                            new Date(bookingDetails.checkOutDate),
                            "do MMM yyyy"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="table-text-align table-border mtable-label">
                          Amount Paid
                        </th>
                        <td className="table-text-align table-border mtable-text">
                          {Number(
                            bookingDetails.hotelRoomsDetails.reduce(
                              (total, room) => {
                                return (
                                  total +
                                  room.price.commissionCharge
                                    .grossProductPrice -
                                  room.price.commissionCharge.agentMarkup
                                );
                              },
                              0
                            )
                          ) + Number(markup)}
                          /-
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    className="theme-color"
                    style={{
                      fontWeight: 700,
                      marginBottom: "15px",
                      marginTop: 15,
                    }}
                  >
                    Terms And Conditions
                  </div>
                  <div>
                    <p>
                      a. Refund policy mentioned above is indicative. The actual
                      cancellation charges are determinedby hotel operators and
                      hotel providers at the actual time of cancellation. Paytm
                      has no role ingoverning cancellation charges
                    </p>
                    <p>
                      b. Cancellation charges are calculated on the actual fare
                      of the voucher, if any discount couponsare used while
                      purchasing the voucher, the discounted value would be used
                      to calculate the refundamount when a voucher is cancelled
                    </p>
                  </div>
                </Grid>
              </Grid>
              <style>{`
              @media (max-width: 959px) {
                .mtable-label { width: 40% }
                .matable-text { width: 60% }
              }
              `}</style>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HotelVoucher;

const FailedView = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <h1 style={{ fontWeight: 500 }}>{props.message}</h1>
    <p>{props.responseText}</p>
  </div>
);
