import React, { useEffect, useState, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Button, Paper } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
//import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Card from "components/Card/Card";
//import { theme } from 'Theme';
//import CloseIcon from '@material-ui/icons/Close';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // padding: 20,
    "& .MuiTypography-body2": {
      fontSize: "0.678rem",
    },
    "& .bus-type-list-item": {
      "& .MuiListItemText-root": {
        width: "100%",
        overflowX: "hidden",
      },
      "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        width: "100%",
      },
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  button: {
    background: theme.palette.background.light,
    color: theme.palette.secondary.black,
    width: "100%",
    boxShadow: "0 1px 4px 0 rgb(0 0 0 / 14%)",
    "&:hover": {
      background: theme.palette.background.light,
    },
  },
  paper: {
    [theme.breakpoints.up("xl")]: {
      paddingLeft: "6%",
    },
  },
  Checkbox: {
    [theme.breakpoints.up("xl")]: {
      // paddingLeft: "90%",
    },
  },
  busFilterRoot: {
    maxHeight: "100vh",
    overflowY: "scroll",
    overflowX: "hidden",
    scrollbarWidth: "thin",
    position: "relative",
    [theme.breakpoints.down(600)]: {
      paddingBottom: 100,
    },

    // "& .close-icon":{
    //   position:'fixed',
    //   top:'10px',
    //   right:16,
    // }
  },
}));

const AirbnbSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.golden,
    width: 200,
    backgroundColor: theme.palette.background.light,
    margin: 10,
    marginLeft: 20,
    marginRight: 20,
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: theme.palette.background.light,
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: `${theme.palette.secondary.mercury} 0 2px 2px`,
    "&:focus, &:hover, &$active": {
      boxShadow: `${theme.palette.secondary.chineseSilver} 0 2px 3px 1px`,
    },
    "& .bar": {
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 3,
  },
  rail: {
    color: theme.palette.secondary.disabled,
    opacity: 1,
    height: 3,
  },
}))(Slider);

function AirbnbThumbComponent(props) {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}

export default function BusResultFilter(props) {
  const classes = useStyles();
  const [openBusType, setOpenBusType] = useState(true);
  const [openPrice, setOpenPrice] = useState(true);
  const [openTiming, setOpenTiming] = useState(true);
  const [openSortBy, setOpenSortBy] = useState(true);
  const [busTypes, setBusTypes] = useState([]);
  const [busTiming, setBusTiming] = useState([]);

  const [value, setValue] = useState([20, 37]);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([0]);
  const [height, setHeight] = useState(0);

  const handleToggleNew = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleChange = (event, newValue) => {
    props.setPrice(newValue);
    event.preventDefault();
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  // console.log(height)

  useEffect(() => {
    let busType = new Set();
    let timing = new Set();
    let maxPrice = 1;
    const newHeight = window.innerWidth;
    setHeight(newHeight);
    // console.log("meow",newHeight)

    busType.add("A/C");
    busType.add("Non A/C");
    busType.add("Seater");
    busType.add("Sleeper");

    props.data.map((value) => {
      busType.add(value.BusType);
      if (value.timing) {
        timing.add(value.timing);
      }

      if (maxPrice < value.BusPrice.CommissionCharge.grossProductPrice) {
        maxPrice = value.BusPrice.CommissionCharge.grossProductPrice;
      }
    });
    setBusTypes([...busType]);
    setBusTiming([...timing]);
    props.setMaxPrice(maxPrice);
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div className={classes.busFilterRoot}>
      {/* {
        props.icon && 
      <div className="close-icon">
        <CloseIcon/>
      </div>
      } */}

      <GridContainer style={{ display: "flex", position: "static" }}>
        <GridItem xs={12} md={12}>
          <GridContainer spacing={2}>
            <GridItem md={12} xs={12}>
              {/* <ClickAwayListener onClickAway={() => { setOpenBusType(false) }}> */}

              <Button
                className={classes.button}
                onClick={() => {
                  setOpenBusType(!openBusType);
                }}
              >
                Bus Type
                {
                  <div
                    style={{
                      paddingLeft: "63%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {openBusType ? <ExpandLess /> : <ExpandMore />}
                  </div>
                }
              </Button>
              <Collapse in={openBusType}>
                <Card>
                  <Paper className={classes.paper}>
                    <List dense className={classes.root}>
                      {busTypes &&
                        busTypes.map((value) => {
                          // const labelId = `openBusType`;
                          return (
                            <ListItem
                              key={value}
                              button
                              className="bus-type-list-item"
                            >
                              <ListItemText primary={`${value}`} />
                              <ListItemSecondaryAction>
                                <Checkbox
                                  className={classes.Checkbox}
                                  edge="end"
                                  onChange={props.handleBusTypeToggle(value)}
                                  checked={props.busType.indexOf(value) !== -1}
                                />
                              </ListItemSecondaryAction>
                            </ListItem>
                          );
                        })}
                    </List>
                  </Paper>
                </Card>
              </Collapse>

              {/* </ClickAwayListener> */}
            </GridItem>

            <GridItem md={12} xs={12}>
              {/* <ClickAwayListener onClickAway={() => { setOpenPrice(false) }}> */}
              <div>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenPrice(!openPrice);
                  }}
                >
                  Prices
                  {
                    <div style={{ paddingLeft: "68%", display: "flex" }}>
                      {openPrice ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>

                {/* <Collapse in={height<600 ?!openPrice:openPrice} >  */}
                <Collapse in={openPrice}>
                  <Card>
                    <Paper
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ marginLeft: 20 }}>
                        {(props.price[0] * props.maxPrice) / 100}
                      </span>
                      <AirbnbSlider
                        ThumbComponent={AirbnbThumbComponent}
                        getAriaLabel={(index) =>
                          index === 0 ? "Minimum price" : "Maximum price"
                        }
                        value={props.price}
                        onChange={handleChange}
                      />
                      <span style={{ marginRight: 20 }}>
                        {(props.price[1] * props.maxPrice) / 100}
                      </span>
                    </Paper>
                  </Card>
                </Collapse>
              </div>
              {/* </ClickAwayListener> */}
            </GridItem>

            <GridItem md={12} xs={12}>
              {/* <ClickAwayListener onClickAway={() => { setOpenTiming(false) }}> */}
              <div>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenTiming(!openTiming);
                  }}
                >
                  Timing
                  {
                    <div style={{ paddingLeft: "67%", display: "flex" }}>
                      {openTiming ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>

                {/* <Collapse in={height<600 ?!openTiming:openTiming}  > */}
                <Collapse in={openTiming}>
                  <Card>
                    <Paper className={classes.paper}>
                      <List dense className={classes.root}>
                        {busTiming &&
                          busTiming.map((value) => {
                            // const labelId = `openBusType`;
                            return (
                              <ListItem key={value} button>
                                <ListItemText primary={`${value}`} />
                                <ListItemSecondaryAction>
                                  <Checkbox
                                    className={classes.Checkbox}
                                    edge="end"
                                    onChange={props.handleTimingToggle(value)}
                                    checked={props.timing.indexOf(value) !== -1}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                      </List>
                    </Paper>
                  </Card>
                </Collapse>
              </div>
              {/* </ClickAwayListener> */}
            </GridItem>

            <GridItem md={12} xs={12} style={{ display: "none" }}>
              {/* <ClickAwayListener onClickAway={() => { setOpenSortBy(false) }}> */}
              <div>
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenSortBy(!openSortBy);
                  }}
                >
                  Sort By
                  {
                    <div style={{ paddingLeft: "63%", display: "flex" }}>
                      {openSortBy ? <ExpandLess /> : <ExpandMore />}
                    </div>
                  }
                </Button>

                <Collapse in={height < 600 ? !openSortBy : openSortBy}>
                  <Card>
                    <Paper className={classes.paper}>
                      <List dense className={classes.root}>
                        <ListItem button>
                          <ListItemText primary={`BusType`} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className={classes.Checkbox}
                              edge="end"
                              onChange={props.handleSortByToggle(`BusType`)}
                              checked={props.sortBy.indexOf(`BusType`) !== -1}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button>
                          <ListItemText primary={`Departure`} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className={classes.Checkbox}
                              edge="end"
                              onChange={props.handleSortByToggle(`Departure`)}
                              checked={props.sortBy.indexOf(`Departure`) !== -1}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem button>
                          <ListItemText primary={`Arrival`} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className={classes.Checkbox}
                              edge="end"
                              onChange={props.handleSortByToggle(`Arrival`)}
                              checked={props.sortBy.indexOf(`Arrival`) !== -1}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem button>
                          <ListItemText primary={`Price`} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className={classes.Checkbox}
                              edge="end"
                              onChange={props.handleSortByToggle(`Price`)}
                              checked={props.sortBy.indexOf(`Price`) !== -1}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>

                        <ListItem button>
                          <ListItemText primary={`Duration`} />
                          <ListItemSecondaryAction>
                            <Checkbox
                              className={classes.Checkbox}
                              edge="end"
                              onChange={props.handleSortByToggle(`Duration`)}
                              checked={props.sortBy.indexOf(`Duration`) !== -1}
                              // inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </Paper>
                  </Card>
                </Collapse>
              </div>
              {/* </ClickAwayListener> */}
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
