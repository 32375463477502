import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

const OaConfirm = (props) => {
    
    const { open, title, oncan, onok, confmsg, cantxt, oktxt, ...other } = props;

    return (
        <Dialog
            maxWidth="xs"
            // onEntering={handleEntering}
            aria-labelledby="confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>

            <DialogContent dividers>
                {confmsg}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={oncan} color="primary">
                    {cantxt ? cantxt : "No"}
                </Button>
                <Button onClick={onok} color="primary">
                    {oktxt ? oktxt : "Yes"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

OaConfirm.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    oncan: PropTypes.func.isRequired,
    onok: PropTypes.func.isRequired,
    confmsg: PropTypes.string.isRequired,
    cantxt: PropTypes.string,
    oktxt: PropTypes.string,
};

export default OaConfirm;