import React from "react";
import $ from "jquery";
import { Button, TextField, makeStyles } from "@material-ui/core";
import image from "assets/img/MFS100.png";
import image1 from "assets/img/completed.png";
import mfsError from "assets/img/MFS100_ERROR.png";

const MantraRdService = (props) => {
    const classes = useStyles();
    const [thrownError, setThrownError] = React.useState(null);
    const [methodVal, setMethodVal] = React.useState("");
    const [infoVal, setInfoVal] = React.useState("");
    const [deviceInfo, setDeviceInfo] = React.useState(null);
    const [showRawData, setShowRawData] = React.useState(false);
    const [captureAvdmBm, setCaptureAvdmBm] = React.useState(false);

    let finalUrl = "";
    let MethodInfo = "";
    let MethodCapture = "";
    let OldPort = false;
    let DString = '';
    let device = "mantra";

    const discoverAvdm = () => {
        var GetCustomDomName = "127.0.0.1";
        var SuccessFlag = 0;
        var primaryUrl = "http://" + GetCustomDomName + ":";

        try {
            var protocol = window.location.href;
            if (protocol.indexOf("https") >= 0) {
                primaryUrl = "https://" + GetCustomDomName + ":";
            }
        } catch (e) { }

        var url = "";

        SuccessFlag = 0;
        // for (var i = 11100; i <= 11112; i++) {
        for (var i = 11100; i <= 11103; i++) {
            console.log("Discovering RD service on port : " + i.toString());

            var res;
            $.support.cors = true;
            var httpStaus = false;

            $.ajax({
                type: "RDSERVICE",
                async: false,
                crossDomain: true,
                url: primaryUrl + i.toString(),
                contentType: "text/xml; charset=utf-8",
                processData: false,
                cache: false,
                crossDomain: true,

                success: function (data) {
                    httpStaus = true;
                    res = {
                        httpStaus: httpStaus,
                        data: data
                    };
                    // //alert(data);
                    // $("#txtDeviceInfo").val(data);
                    setDeviceInfo(data);
                    finalUrl = primaryUrl + i.toString();
                    var $doc = $.parseXML(data);//$data
                    var CmbData1 = $($doc).find('RDService').attr('status');
                    var CmbData2 = $($doc).find('RDService').attr('info');

                    if (RegExp('\\b' + 'Mantra' + '\\b').test(CmbData2) == true || RegExp('\\b' + 'Morpho_RD_Service' + '\\b').test(CmbData2) == true || RegExp('\\b' + 'SecuGen India Registered device Level 0' + '\\b').test(CmbData2) == true || RegExp('\\b' + 'Precision - Biometric Device is ready for capture' + '\\b').test(CmbData2) == true || RegExp('\\b' + 'RD service for Startek FM220 provided by Access Computech' + '\\b').test(CmbData2) == true || RegExp('\\b' + 'NEXT' + '\\b').test(CmbData2) == true) {
                        console.log($($doc).find('Interface').eq(0).attr('path'));

                        if (RegExp('\\b' + 'Mantra' + '\\b').test(CmbData2) == true) {

                            if ($($doc).find('Interface').eq(0).attr('path') == "/rd/capture") {
                                MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            }
                            if ($($doc).find('Interface').eq(1).attr('path') == "/rd/capture") {
                                MethodCapture = $($doc).find('Interface').eq(1).attr('path');
                            }
                            if ($($doc).find('Interface').eq(0).attr('path') == "/rd/info") {
                                MethodInfo = $($doc).find('Interface').eq(0).attr('path');
                            }
                            if ($($doc).find('Interface').eq(1).attr('path') == "/rd/info") {
                                MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                            }
                        } else if (RegExp('\\b' + 'Morpho_RD_Service' + '\\b').test(CmbData2) == true) {
                            MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                        } else if (RegExp('\\b' + 'SecuGen India Registered device Level 0' + '\\b').test(CmbData2) == true) {
                            MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                        } else if (RegExp('\\b' + 'Precision - Biometric Device is ready for capture' + '\\b').test(CmbData2) == true) {
                            MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                        } else if (RegExp('\\b' + 'RD service for Startek FM220 provided by Access Computech' + '\\b').test(CmbData2) == true) {
                            MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                        } else if (RegExp('\\b' + 'NEXT' + '\\b').test(CmbData2) == true) {
                            MethodCapture = $($doc).find('Interface').eq(0).attr('path');
                            MethodInfo = $($doc).find('Interface').eq(1).attr('path');
                        }

                        if (CmbData1 == 'READY') {

                            // $('#method').val(finalUrl + MethodCapture);
                            // $('#info').val(finalUrl + MethodInfo);
                            setMethodVal(finalUrl + MethodCapture);
                            setInfoVal(finalUrl + MethodInfo);

                            SuccessFlag = 1;
                            return false;
                        }
                        else if (CmbData1 == 'USED') {
                            // $('#method').val(finalUrl + MethodCapture);
                            // $('#info').val(finalUrl + MethodInfo);
                            setMethodVal(finalUrl + MethodCapture);
                            setInfoVal(finalUrl + MethodInfo);

                            SuccessFlag = 1;
                            return false;
                        }


                        else if (CmbData1 == 'NOTREADY') {
                            props.setInfoText("RD Service discovery failed");
                            props.setSeveritySnackbar({
                                ...props.severitySnackbar,
                                iserror: true
                            });
                            return false;
                        }
                    }

                },
                error: function (jqXHR, ajaxOptions, thrownError) {
                    setThrownError(thrownError);
                    if (i == "8005" && OldPort == true) {
                        OldPort = false;
                        i = "11099";
                    }
                },

            });

            if (SuccessFlag == 1) {
                props.setAvdmActive(true);
                props.setInfoText("Scanner RDSERVICE Detected Successfully");
                props.setSeveritySnackbar({
                    ...props.severitySnackbar,
                    issuccess: true,
                    iserror: false
                });
                break;
            }
        }

        if (SuccessFlag == 0) {
            props.setAvdmActive(null);
            props.setInfoText("Connection failed! Please check if hardware is connected");
            props.setSeveritySnackbar({
                ...props.severitySnackbar,
                iserror: true
            });
        }
        
        // $("select#ddlAVDM").prop('selectedIndex', 0);
        props.setIsLoading(false);
        return res;
    };

    const scanFingerprint = () => {
        setCaptureAvdmBm(true);
    };

    const deviceInfoAvdm = () => {
        var GetCustomDomName = "127.0.0.1";
        var SuccessFlag = 0;
        var primaryUrl1 = "http://" + GetCustomDomName + ":";

        try {
            var protocol = window.location.href;
            if (protocol.indexOf("https") >= 0) {
                primaryUrl1 = "https://" + GetCustomDomName + ":";
            }
        } catch (e) { }
        SuccessFlag = 0;

        var res;
        $.support.cors = true;
        var httpStaus = false;
        ;
        $.ajax({
            type: "DEVICEINFO",
            async: false,
            crossDomain: true,
            url: infoVal,
            contentType: "text/xml; charset=utf-8",
            processData: false,
            success: function (data) {
                httpStaus = true;
                res = { httpStaus: httpStaus, data: data };
                // $('#txtDeviceInfo').val(data);
                setDeviceInfo(data);
            },
            error: function (jqXHR, ajaxOptions, thrownError) {
                setThrownError(thrownError);
                res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
            },
        });

        return res;

    };

    const CaptureAvdm = () => {
        
        if(props.mode === "transaction" && props.fingerPrintCaptured){
            props.setCustFingerPrintCaptured(false);
        } else {
            props.setFingerPrintCaptured(false);
        }
        
        props.setIsLoading(true);
        var strWadh = "";
        var strOtp = "";

        var XML = `<?xml version="1.0"?> <PidOptions ver="1.0"> <Opts fCount="1" fType="2" iCount="0" pCount="0" format="0" pidVer="2.0" timeout="10000" posh="UNKNOWN" env="P" />${DString}<CustOpts><Param name="mantrakey" value="" /></CustOpts> </PidOptions>`;
        var res;
        $.support.cors = true;
        var httpStaus = false;

        $.ajax({
            type: "CAPTURE",
            async: false,
            crossDomain: true,
            url: methodVal,
            data: XML,
            contentType: "text/xml; charset=utf-8",
            processData: false,
            success: function (data) {
                if (device == "morpho") {
                    var xmlString = (new XMLSerializer()).serializeToString(data);  //morpho
                } else if (device == "mantra") {
                    var xmlString = data;  //mantra
                    console.log("mantra xml string", xmlString)
                } else if (device == "secugen") {
                    var xmlString = (new XMLSerializer()).serializeToString(data);  //secugen
                } else if (device == "precision") {
                    var xmlString = (new XMLSerializer()).serializeToString(data);  //precision
                } else if (device == "startek") {
                    var xmlString = (new XMLSerializer()).serializeToString(data);  //startek
                } else if (device == "nextrd") {
                    var xmlString = (new XMLSerializer()).serializeToString(data);  //next rd
                }
                httpStaus = true;
                res = { httpStaus: httpStaus, data: xmlString };
                // console.log("xml string 258", xmlString)
                // $('#txtPidData').val(xmlString);
                props.setPIDData(xmlString);
                var $doc = data;
                var Message = $($doc).find('Resp').attr('errInfo');
                var errorcode = $($doc).find('Resp').attr('errCode');
                if (errorcode == 0) {

                    var $doc = $.parseXML(data);
                    var Message = $($doc).find('Resp').attr('errInfo');

                    if(props.mode === "transact" && props.fingerPrintCaptured && props.merAuthTxnId) {
                        props.setCustFingerPrintCaptured(true);
                    } else {
                        props.setFingerPrintCaptured(true);
                    }
                    
                    props.setInfoText(Message);
                    props.setSeveritySnackbar({
                        ...props.severitySnackbar,
                        issuccess: true
                    });

                } else {
                    $('#loaderbala').css("display", "none");
                    props.setInfoText('Capture Failed '+Message);
                    props.setFingerPrintCaptured(false);
                    if(props.mode === "transact" && props.fingerPrintCaptured && props.merAuthTxnId) {
                        props.setCustFingerPrintCaptured(false);
                    }
                    props.setSeveritySnackbar({
                        ...props.severitySnackbar,
                        iserror: true
                    });
                }

            },
            error: function (jqXHR, ajaxOptions, thrownError) {
                //$('#txtPidOptions').val(XML);
                setThrownError(thrownError);
                res = { httpStaus: httpStaus, err: getHttpError(jqXHR) };
                if(props.mode === "transact" && props.fingerPrintCaptured && props.merAuthTxnId) {
                    props.setCustFingerPrintCaptured(false);
                } else {
                    props.setFingerPrintCaptured(false);
                }
            },
        });

        setCaptureAvdmBm(false);
        props.setIsLoading(false);
        return res;
    };

    const getHttpError = (jqXHR) => {
        var err = "Unhandled Exception";
        if (jqXHR.status === 0) {
            err = 'Service Unavailable';
        } else if (jqXHR.status == 404) {
            err = 'Requested page not found';
        } else if (jqXHR.status == 500) {
            err = 'Internal Server Error';
        } else if (thrownError === 'parsererror') {
            err = 'Requested JSON parse failed';
        } else if (thrownError === 'timeout') {
            err = 'Time out error';
        } else if (thrownError === 'abort') {
            err = 'Ajax request aborted';
        } else {
            err = 'Unhandled Error';
        }
        return err;
    };

    React.useEffect(() => {
        if(!props.avdmActive && props.isLoading) {
            discoverAvdm();
        }
    }, [props.avdmActive, props.isLoading]);

    React.useEffect(() => {
        if(captureAvdmBm) {
            CaptureAvdm();
        }
    }, [captureAvdmBm]);

    // React.useEffect(() => { 
    //     if(props.pIDData && props.fingerPrintCaptured) { 
    //         console.log("props.pIDData", props.pIDData)
    //     }
    // }, [props.pIDData, props.fingerPrintCaptured]);
    
    return(
        <div className={classes.root}>
            {props.pIDData  && ((props.mode === "transact" && props.custFingerPrintCaptured) 
            || (props.mode === "auth" && props.fingerPrintCaptured)) ?
            <div className="rd-row">
                <h5
                    style={{
                        padding: 10,
                        fontWeight: 500
                    }}
                >
                    Scanning done, processing transaction ...
                </h5>
                <img src={image1} width="200" onClick={() => setShowRawData(false)}/>
            </div>
            : props.avdmActive ?
                <div className="rd-row">
                    <h5
                        style={{
                            padding: 10,
                            fontWeight: 500
                        }}
                    >
                        Capture {props.mode === "transact" && props.fingerPrintCaptured && !props.custFingerPrintCaptured ? 
                        "CUSTOMER" : "RETAILER"} fingerprint.
                    </h5>
                    <div className="rd-col">
                        <img src={image} width="200" />
                        <Button
                            type="button"
                            onClick={scanFingerprint}
                            disabled={!props.avdmActive}
                        >
                            Capture / Recapture
                        </Button>
                    </div>
                    <a style={{cursor: "pointer", display: "none", fontWeight: "bold"}} onClick={() => setShowRawData(!showRawData)}>Adv</a>
                </div>
                :
                <>
                    <h5
                        style={{
                            padding: 10,
                            fontWeight: 500
                        }}
                    >
                        Hardware not detected please click
                        <a className="header-uninit" onClick={props.reInitDevice}>&nbsp;here&nbsp;</a>
                        to reinitialize.
                    </h5>
                    <img src={mfsError} width="200" className="image-uninit"
                        onClick={props.reInitDevice}
                    />
                </>
            }
            <div className="inner-container" style={{display: showRawData ? "flex" : "none"}}>
                <div className="action-container">
                    <Button className="btn btn-danger" onClick={discoverAvdm}>CHECK DEVICE</Button>
                    <Button className="btn btn-warning" onClick={CaptureAvdm}>SCAN</Button>
                    <Button className="btn btn-warning" onClick={deviceInfoAvdm}>info</Button>
                </div>

                <div className="data-output">
                    <div className="data-output-col">
                        <h1>DEVICE INFO</h1>
                        {/* <textarea id="txtDeviceInfo" rows="10" cols="50"  height="1000">{deviceInfo}</textarea> */}
                        <TextField value={deviceInfo} variant="outlined" multiline fullWidth/>
                    </div>

                    <div className="data-output-col">
                        <h1>CAPTURE DATA</h1>
                        <TextField value={props.pIDData} variant="outlined" multiline fullWidth/>
                        {/* <textarea id="txtPidData" rows="20" cols="100" height="1000">{props.pIDData}</textarea> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MantraRdService;

const useStyles = makeStyles(theme => ({
    root: {
        // display: "none",
        width: 1200,
        margin: "10px 0",
        "& button": {
            background: theme.palette.buttons.secondary,
            color: theme.palette.buttons.secondaryContrastText,
            border: `solid 0.5px ${theme.palette.buttons.secondary}`,
            padding: "5px 10px",
            width: 150,
            fontSize: 10,
            "&:hover": {
                background: theme.palette.buttons.secondaryContrastText,
                color: theme.palette.buttons.secondary,
            }
        },
        "& .inner-container": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
        },
        "& .action-container": {
            display: "flex",
            justifyContent: "center",
            gap: 3,
            width: "100%"
        },
        "& .data-output": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%"
        },
        "& .data-output-col": {
            width: "100%"
        },
        "& .rd-row, .rd-col": {
            textAlign: "center"
        },
        "& .header-uninit": {
            cursor: "pointer"
        },
        [theme.breakpoints.down(1200)]: {
            width: "100%"
        }
    }
}));
