
export const seatTypes = [
    {
     "seatTypeLabel": "Not set",
     "seatTypeValue": 0,
     "margin": "none",
    },
    {
     "seatTypeLabel": "Window",
     "seatTypeValue": 1,
     "margin": "none",
    },
    {
     "seatTypeLabel": "Aisle",
     "seatTypeValue": 2,
     "margin": "right",
    },
    {
     "seatTypeLabel": "Middle",
     "seatTypeValue": 3,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowRecline",
     "seatTypeValue": 4,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowWing",
     "seatTypeValue": 5,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowExitRow",
     "seatTypeValue": 6,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowReclineWing",
     "seatTypeValue": 7,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowReclineExitRow",
     "seatTypeValue": 8,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowWingExitRow",
     "seatTypeValue": 9,
     "margin": "none",
    },
    {
     "seatTypeLabel": "AisleRecline",
     "seatTypeValue": 10,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleWing",
     "seatTypeValue": 11,
     "margin": "right",
    },
    {
     "seatTypeLabel": "AisleExitRow",
     "seatTypeValue": 12,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleReclineWing",
     "seatTypeValue": 13,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleReclineExitRow",
     "seatTypeValue": 14,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleWingExitRow",
     "seatTypeValue": 15,
     "margin": "left",
    },
    {
     "seatTypeLabel": "MiddleRecline",
     "seatTypeValue": 16
    },
    {
     "seatTypeLabel": " MiddleWing",
     "seatTypeValue": 17,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleExitRow",
     "seatTypeValue": 18,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleReclineWing",
     "seatTypeValue": 19,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleReclineExitRow",
     "seatTypeValue": 20,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleWingExitRow",
     "seatTypeValue": 21,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowReclineWingExitRow",
     "seatTypeValue": 22,
     "margin": "none",
    },
    {
     "seatTypeLabel": "AisleReclineWingExitRow",
     "seatTypeValue": 23,
     "margin": "left",
    },
    {
     "seatTypeLabel": "MiddleReclineWingExitRow",
     "seatTypeValue": 24,
     "margin": "left",
    },
    {
     "seatTypeLabel": "WindowBulkhead",
     "seatTypeValue": 25,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowQuiet",
     "seatTypeValue": 26,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowBulkheadQuiet",
     "seatTypeValue": 27,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleBulkhead",
     "seatTypeValue": 28,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleQuiet",
     "seatTypeValue": 29,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleBulkheadQuiet",
     "seatTypeValue": 30,
     "margin": "none",
    },
    {
     "seatTypeLabel": "AisleBulkhead",
     "seatTypeValue": 31,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleQuiet",
     "seatTypeValue": 32,
     "margin": "left",
    },
    {
     "seatTypeLabel": "AisleBulkheadQuiet",
     "seatTypeValue": 33,
     "margin": "left",
    },
    {
     "seatTypeLabel": "CentreAisle",
     "seatTypeValue": 34,
     "margin": "left",
    },
    {
     "seatTypeLabel": "CentreMiddle",
     "seatTypeValue": 35,
     "margin": "none",
    },
    {
     "seatTypeLabel": "CentreAisleBulkHead",
     "seatTypeValue": 36,
     "margin": "right",
    },
    {
     "seatTypeLabel": "CentreAisleQuiet",
     "seatTypeValue": 37,
     "margin": "left",
    },
    {
     "seatTypeLabel": "CentreAisleBulkHeadQuiet",
     "seatTypeValue": 38,
     "margin": "left",
    },
    {
     "seatTypeLabel": "CentreMiddleBulkHead",
     "seatTypeValue": 39,
     "margin": "none",
    },
    {
     "seatTypeLabel": "CentreMiddleQuiet",
     "seatTypeValue": 40,
     "margin": "none",
    },
    {
     "seatTypeLabel": "CentreMiddleBulkHeadQuiet",
     "seatTypeValue": 41,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowBulkHeadWing",
     "seatTypeValue": 42,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowBulkHeadExitRow",
     "seatTypeValue": 43,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleBulkHeadWing",
     "seatTypeValue": 44,
     "margin": "none",
    },
    {
     "seatTypeLabel": "MiddleBulkHeadExitRow",
     "seatTypeValue": 45,
     "margin": "none",
    },
    {
     "seatTypeLabel": "AisleBulkHeadWing",
     "seatTypeValue": 46,
     "margin": "none",
    },
    {
     "seatTypeLabel": "AisleBulkHeadExitRow",
     "seatTypeValue": 47,
     "margin": "none",
    },
    {
     "seatTypeLabel": "NoSeatAtThisLocation",
     "seatTypeValue": 48,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowAisle",
     "seatTypeValue": 49,
     "margin": "none",
    },
    {
     "seatTypeLabel": "NoSeatRow",
     "seatTypeValue": 50,
     "margin": "none",
    },
    {
     "seatTypeLabel": "NoSeatRowExit",
     "seatTypeValue": 51,
     "margin": "none",
    },
    {
     "seatTypeLabel": "NoSeatRowWing",
     "seatTypeValue": 52,
     "margin": "none",
    },
    {
     "seatTypeLabel": "NoSeatRowWingExit",
     "seatTypeValue": 53,
     "margin": "none",
    },
    {
     "seatTypeLabel": "WindowAisleRecline",
     "seatTypeValue": 54,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleWing",
     "seatTypeValue": 55,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleExitRow",
     "seatTypeValue": 56,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleReclineWing",
     "seatTypeValue": 57,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleReclineExitRow",
     "seatTypeValue": 58,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleWingExitRow",
     "seatTypeValue": 59,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleBulkhead",
     "seatTypeValue": 60,
     "margin": "right",
    },
    {
     "seatTypeLabel": "WindowAisleBulkheadWing",
     "seatTypeValue": 61,
     "margin": "right",
    }
   ]
   