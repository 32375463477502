import React, { useState, useEffect, useRef, useContext } from "react";
// import Grid from "components/Grid/Grid.js";
import { Formik, Form, ErrorMessage, FieldArray } from "formik";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import {
  Box,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Grid, Link, Button, withWidth } from "@material-ui/core";
import "assets/css/MainContent.css";
import "assets/css/css-variable.css";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { localforageGetItem } from "oautils/oaForageUtils";
import OaOutlinedDatePicker from "oahoc/OaOutlinedDatepicker";
import { useHistory, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setInsuranceBookingInitialTime } from "redux/action";
import InsuranceTab from "components/CommonSearchComponents/Insurance/InsuranceTab"
import InsuranceDestination from "components/CommonSearchComponents/Insurance/InsuranceDestination"
import InsuranceCategory from "components/CommonSearchComponents/Insurance/InsuranceCategory"
import InsuranceDateInput from "components/CommonSearchComponents/Insurance/InsuranceDateInput"
import InsurancePaxDuration from "components/CommonSearchComponents/Insurance/InsurancePaxDuration"


import * as Yup from "yup";

//import _ from "lodash";

const InsuranceSection = (props) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const today = new Date();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [departDateDialogOpen, setDepartDateDialogOpen] = useState(false);
  const [dropdownAge, setDropdownAge] = useState(false);
  const [passCount, setPassCount] = useState(1);
  const [userType, setUserType] = useState();

  const [paxCount, setPaxCount] = useState();
  const [val, setVal] = useState();

  const formikRef = useRef();
  const classes = useStyles();
  const history = useHistory();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const diffDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const modifyPassengerCount = (type, val) => {
    if (type === "adult") {
      val === 1
        ? formikRef.current.values.paxAge.push(18)
        : formikRef.current.values.paxAge.pop();
      formikRef.current.setFieldValue(
        "adultPax",
        Number(formikRef.current.values.adultPax) + val
      );
    }
    if (type === "child") {
      formikRef.current.setFieldValue(
        "childPax",
        Number(formikRef.current.values.childPax) + val
      );
      val === 1
        ? formikRef.current.values.paxAge.push(17)
        : formikRef.current.values.paxAge.pop();
    }
    if (type === "infant") {
      formikRef.current.setFieldValue(
        "infantPax",
        Number(formikRef.current.values.infantPax) + val
      );
      val === 1
        ? formikRef.current.values.paxAge.push(2)
        : formikRef.current.values.paxAge.pop();
    }
    formikRef.current.setFieldValue(
      "paxCount",
      formikRef.current.values.paxAge.length
    );
  };

  const incrCount = () => setVal(1);
  const decrCount = () => setVal(-1);

  // useEffect(() => {
  //   WebApi.getInsuranceBookingDetails(
  //     {
  //       tid: 146751,
  //       EndUserIp: "",
  //       TokenId: "",
  //       TraceId: "",
  //       bookingId: 0,
  //       loggedInUserId: 415,
  //     },
  //     (response) => {
  //       console.log(response);
  //       if (response.message == "success") {
  //         // props.history.push({
  //         //   pathname: "/insurance/booking/payment-success",
  //         //   state: {
  //         //     // ...propState,
  //         //     data: response.data,
  //         //     tid: tid,
  //         //   },
  //         // });
  //       }
  //     },
  //     (error) => console.log("error", error)
  //   );
  // }, []);

  // useEffect(() => {
  //   localforageGetItem("user-details", function(err, value) {
  //     if (err == null && value != null) {
  //       // console.log(value);
  //       // setLoggedInUserId(value.userId);
  //       setUserType(value.userType);
  //       // value.userType
  //     }
  //   });
  // }, []);

  return (
    <div className={classes.root}>
      <div className="insurance-container">
        <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
        />
        <Formik
          innerRef={formikRef}
          initialValues={{
            // paxAge: Array(1).fill(18),
            paxAge: "",
            paxAgeDef: 18,
            paxCount: 1,
            adultPax: 1,
            childPax: 0,
            infantPax: 0,
            planCategory: 1,
            planCoverage: 4,
            planType: 1,
            travelStartDate: startDate,
            travelEndDate: endDate,
            duration: 1,
          }}
          validationSchema={Yup.object().shape({
            paxAge: Yup.string().required("Enter Age"),
            // travelEndDate: Yup.string().required(
            //     " return date is Required"
            // ),
          })}
          onSubmit={(searchParams, { setSubmitting, resetForm }) => {
            localforageGetItem("user-details", function(err, details) {
              if (details != null) {
                // console.log("user-details", details);
                searchParams.partnerId = details.partnerId;
                searchParams.clientId = details.userId;
                searchParams.clientPassword = "";
                searchParams.clientType = details.userType;
                switch (details.userTypeAbv) {
                  case "S": {
                    searchParams.clientType = "Corporate";
                    break;
                  }
                  case "G": {
                    searchParams.clientType = "Normal";
                    break;
                  }
                  default: {
                    searchParams.clientType = "Normal";
                    break;
                  }
                }
              }
            });
            setSubmitting(true);
            props.setInsuranceBookingInitialTime(null);
            window.sessionStorage.removeItem("service");
            history.push({
              pathname: "/insurance/search",
              state: {
                searchParams,
              },
            });
          }}
        >
          {({
            values,
            isSubmitting,
            setFieldValue,
            handleChange,
            errors,
            touched,
          }) => (
            <Form className="formContainer">
              <Grid container>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  className="form-group toggle-button-group"
                >
                  {/* <label>Trip Type</label> */}
                  {/* <div className="input-element">
                    <ToggleButtonGroup
                      name="planType"
                      size="small"
                      exclusive
                      value={values.planType}
                      onChange={(e, v) => {
                        if (v) {
                          setFieldValue("planType", v);
                        }
                      }}
                    >
                      <ToggleButton value={1}>Single Trip</ToggleButton>
                      <ToggleButton value={2}>Annual Multi Trip</ToggleButton>
                    </ToggleButtonGroup>
                  </div> */}
                  <InsuranceTab values={values} setFieldValue={setFieldValue} />
                  <ErrorMessage
                    name="planType"
                    component="div"
                    className="error"
                  />
                </Grid>
              </Grid>
              <Grid md={12} lg={12} className="searchBoxContainer">
                <Grid className="searchBoxUpper">
                  {/* DESTINATION */}
                  <Grid
                    md={3}
                    sm={6}
                    xs={12}
                    className="form-group select-group"
                  >
                    <label>Destination</label>
                    {/* <div className="input-element">
                      <Select
                        label="Destination"
                        name="planCoverage"
                        value={values.planType == 1 ? values.planCoverage : 3}
                        onChange={(event) =>
                          setFieldValue("planCoverage", event.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        // inputProps={{ IconComponent: () => null }}
                      >
                        {values.planType == 1 &&
                          planCoverageOptions.map((val, ind) => (
                            <MenuItem value={val.value} key={ind}>
                              {val.label}
                            </MenuItem>
                          ))}
                        {values.planType == 2 &&
                          planCoverageOptions2.map((val, ind) => (
                            <MenuItem value={val.value} key={ind}>
                              {val.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </div> */}
                    <InsuranceDestination values={values} setFieldValue={setFieldValue}  />
                    <ErrorMessage
                      name="planCoverage"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  {/* INSURANCE CATEGORY */}
                  <Grid
                    md={4}
                    sm={6}
                    xs={12}
                    className="form-group select-group"
                    // style={{ width: values.planType == 1 ? 290 : 400 }}
                  >
                    <label>Insurance Category</label>
                    {/* <div className="input-element">
                      <Select
                        name="planCategory"
                        value={values.planCategory}
                        onChange={(event) =>
                          setFieldValue("planCategory", event.target.value)
                        }
                        variant="outlined"
                        fullWidth
                      >
                        {planCategoryOptions.map((val, ind) => (
                          <MenuItem value={val.value} key={ind}>
                            {val.lable}
                          </MenuItem>
                        ))}
                      </Select>
                    </div> */}
                               <InsuranceCategory values={values} setFieldValue={setFieldValue}  />
                    <ErrorMessage
                      name="planCoverage"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  {/* DEPARTURE CATEGORY */}
                  <Grid md={3} sm={6} xs={12} className="form-group date-group">
                    <label>Departure</label>
                    {/* <div className="input-element">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        inputVariant="outlined"
                        name={`travelStartDate`}
                        disablePast
                        fullWidth
                        color="secondary"
                        disableToolbar
                        format="dd-MM-yyyy"
                        margin="normal"
                        value={values.travelStartDate}
                        onChange={(e, v) => {
                          setFieldValue(`travelStartDate`, e);
                        }}
                        invalidDateMessage="invalid date"
                        minDate={startDate}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <ErrorMessage
                    name="travelStartDate"
                    component="div"
                    className="error"
                  /> */}

                    <InsuranceDateInput props={{props,values} } setFieldValue={setFieldValue} type={"travelStartDate"}  />
                    {/* <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      name={`travelStartDate`}
                      // minDate={startDate}
                      minDate={startDate}
                      customInput={
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          // fullWidth={isMobileScreen}
                        />
                      }
                      selected={values.travelStartDate}
                      onChange={(e, v) => {
                        setDepartDateDialogOpen(false);
                        setFieldValue(`travelStartDate`, e);
                        if (
                          new Date(v).getTime() >
                          new Date(values.travelStartDate).getTime()
                        ) {
                          values.travelStartDate = new Date(
                            new Date(v).getTime() + 24 * 60 * 60 * 1000
                          ).toISOString();
                        }
                        setFieldValue(`travelEndDate`, e);
                        if (
                          new Date(v).getTime() >
                          new Date(values.travelEndDate).getTime()
                        ) {
                          values.travelEndDate = new Date(
                            new Date(v).getTime() + 24 * 60 * 60 * 1000
                          ).toISOString();
                        }
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      // secondarytxt={
                      //   days[values.sectorArray[0].onwardDateDisplay.getDay()]
                      // }
                      showinput={true}
                    /> */}
                    <ErrorMessage
                      name={`travelStartDate`}
                      component="div"
                      className="error"
                    />
                  </Grid>

                  {/* RETURN CATEGORY */}

                  <Grid
                    md={3}
                    sm={6}
                    xs={12}
                    // style={{ borderRight: "none" }}
                    className={
                      values.planType == 1
                        ? "form-group date-group"
                        : "form-group date-group disabled-input"
                    }
                  >
                    <label>Return</label>

                    {/* <OaOutlinedDatePicker
                      showDisabledMonthNavigation
                      name={`travelEndDate`}
                      // minDate={startDate}
                      minDate={values.travelStartDate}
                      disabled={values.planType !== 1}
                      customInput={
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          disabled={values.planType !== 1}

                          // fullWidth={isMobileScreen}
                        />
                      }
                      selected={values.travelEndDate}
                      onChange={(e, v) => {
                        setDepartDateDialogOpen(false);
                        setFieldValue(`travelEndDate`, e);
                        if (
                          new Date(v).getTime() >
                          new Date(values.travelEndDate).getTime()
                        ) {
                          values.travelEndDate = new Date(
                            new Date(v).getTime() + 24 * 60 * 60 * 1000
                          ).toISOString();
                        }
                      }}
                      monthsShown={2}
                      dateFormat="dd MMM yyyy"
                      // secondarytxt={
                      //   days[values.sectorArray[0].onwardDateDisplay.getDay()]
                      // }
                      showinput={true}
                    /> */}
                <InsuranceDateInput props={{props,values} } setFieldValue={setFieldValue} type={"travelEndDate"}  />
                    <ErrorMessage
                      name="travelEndDate"
                      component="div"
                      className="error"
                    />
                  </Grid>
                </Grid>

                <Grid className="searchBoxLower">
                  {/* NO OF PASS CATEGORY */}
                  <Grid
                    md={6}
                    sm={6}
                    xs={12}
                    className="form-group text-field-group"
                  >
                    <label>Pax & Duration</label>
                    <div className="input-element input-element--dropdown age-input">
                      <InsurancePaxDuration setFieldValue={setFieldValue} props={{values}} formikRef={formikRef} />
                      {/* <Box
                        className="input-element--textField"
                        onClick={() => setDropdownAge(true)}
                      >
                        <p>
                          <span>{values.paxCount}</span>{" "}
                          {values.paxCount > 1 ? "Passengers" : "Passenger "} &{" "}
                          <br></br>
                          Duration of{" "}
                          <span>
                            {diffDays(
                              values.travelStartDate,
                              values.travelEndDate
                            )}
                          </span>{" "}
                          {diffDays(
                            values.travelStartDate,
                            values.travelEndDate
                          ) > 1
                            ? "days "
                            : "day  "}
                          <i className="fas fa-chevron-down"></i>
                          <ErrorMessage
                            name="paxAge"
                            component="div"
                            className="error"
                          />
                        </p>
                      </Box> */}

                      {/* {dropdownAge && (
                        <Grid
                          className="ageModal"
                          container
                          direction="column"
                          justifyContent="space-evenly"
                          alignItems="center"
                          spacing={2}
                        >
                   
                          <Box className="ageModal__paxCountContainer">
                            <div className="ageModal__paxCountLabel">
                              Pax Count
                            </div>
                            <Box className="ageModal__paxCountInput">
                              <Box className="ageModal__inputButton">
                                <IconButton
                                  aria-label="decrement"
                                  onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;
                                    if (value === 0) return;
                                    if (value > 1) value--;

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}
                                >
                                  <RemoveIcon
                                    className="icon"
                                    fontSize="small"
                                  />
                                </IconButton>
                              </Box>
                              <Box className="ageModal__inputValue">
                                <Typography variant="h6">
                                  {formikRef.current.values.paxCount}
                                </Typography>
                              </Box>
                              <Box className="ageModal__inputButton">
                                <IconButton
                                  aria-label="increament"
                                  onClick={(e) => {
                                    let value = +formikRef.current.values
                                      .paxCount;

                                    if (value > 0 && value < 6) {
                                      // console.log(value);
                                      value++;
                                    }

                                    setFieldValue("paxCount", value);
                                    setFieldValue(
                                      "paxAge",
                                      value
                                      // Array(value).fill(18)
                                    );
                                  }}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>

                          <form
                            // onSubmit={handleSubmit}
                            className="ageModal__form"
                          >
                            <div className="ageModal__ageLabel">
                              Age(0.5-70)
                            </div>
                            {Array.from({
                              length: formikRef.current.values.paxCount,
                            }).map((inputField, ind) => (
                              <div
                                key={ind + 1}
                                className="ageModal__passengerContainer"
                              >
                                <label>Pax {ind + 1} </label>
                                <TextField
                                  name={`paxAge[${ind}]`}
                                  className="passengerInput"
                                  type="number"
                                  defaultValue={values.paxAge[ind] || ""}
                                  InputProps={{
                                    max: 70,
                                    min: 1,
                                    maxLength: 2,
                                    disableUnderline: true,
                                  }}
                                  onChange={(e) => {
                                    if (!e.target.value) return;
                                    var value = parseInt(e.target.value);
                                    if (value > 70) value = 70;
                                    if (value < 0.5) value = 1;

                                    setFieldValue(`paxAge[${ind}]`, value);
                                  }}
                                />
                              </div>
                            ))}
                            <Button
                              className="ageModal__submitBtn"
                              variant="contained"
                              color="primary"
                              type="submit"
                              // endIcon={<Icon>send</Icon>}
                              onClick={() => {
                                setDropdownAge(false);
                              }}
                            >
                              Save
                            </Button>
                          </form>
                        </Grid>
                      )} */}
                    </div>
                    <ErrorMessage
                      name="paxCount"
                      component="div"
                      className="error"
                    />
                  </Grid>

                  {/* NO OF PASS CATEGORY */}
                  {/* <Grid
                  md={3}
                  sm={6}
                  xs={12}
                  className="form-group text-field-group"
                >
                  <label>No of Pax</label>
                  <div className="input-element input-element--dropdown age-input">
            
                    <Typography
                      className="passengerInput"
                      onClick={() => setDropdownAge(!dropdownAge)}
                    >
                      {values.adultPax + values.childPax + values.infantPax}
                    </Typography>
                    {dropdownAge && (
                      <Grid
                        className="ageModal"
                        container
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={2}
                      >
                        <Box className="counter-box">
                          <PassengerCount
                            title="Adults"
                            type="adult"
                            val={values.adultPax}
                            setVal={modifyPassengerCount}
                            name="adultPax"
                          />
                          <ErrorMessage
                            name="adultPax"
                            component="div"
                            className="error"
                          />
                        </Box>
                        <Box className="counter-box">
                          <PassengerCount
                            title="Children"
                            type="child"
                            val={values.childPax}
                            setVal={modifyPassengerCount}
                            name="childPax"
                          />
                          <ErrorMessage
                            name="childPax"
                            component="div"
                            className="error"
                          />
                        </Box>
                        <Box className="counter-box">
                          <PassengerCount
                            title="Infants"
                            type="infant"
                            val={values.infantPax}
                            setVal={modifyPassengerCount}
                            name="infantPax"
                          />
                          <ErrorMessage
                            name="noInfant"
                            component="div"
                            className="error"
                          />
                        </Box>
                      </Grid>
                    )}
                  </div>
                  <ErrorMessage
                    name="paxCount"
                    component="div"
                    className="error"
                  />
                </Grid> */}

                  {/* AGES CATEGORY */}
                  {/* <Grid
                  md={3}
                  sm={12}
                  xs={12}
                  className="form-group text-field-group"
                >
                  <label>Ages (yrs)</label>
                </Grid> */}

                  {/* DURATION CATEGORY */}
                  {/* {values.planType == 1 && (
                  <Grid md={3} sm={6} xs={12} className="form-group">
                    <label>Duration</label>
                    <div className="input-element">
                      <TextField
                        name="duration"
                        value={diffDays(
                          values.travelStartDate,
                          values.travelEndDate
                        )}
                      />
                    </div>
                    <ErrorMessage
                      name="duration"
                      component="div"
                      className="error"
                    />
                  </Grid>
                )} */}

                  {/* SUBMIT CATEGORY */}
                  <Grid
                    md={6}
                    sm={6}
                    xs={12}
                    className="form-group button-group"
                    style={{ borderRight: "none", padding: 0, borderRadius: 0 }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"

                      // disabled={isSubmitting}
                    >
                      Insurance Search
                    </Button>
                  </Grid>
                </Grid>

                {/* <FieldArray name="paxAge">
                {({ push, remove }) => (
                  <>
                    <GridContainer>
                      <Grid
                        md={3}
                        sm={6}
                        xs={12}
                        className="form-group text-field-group"
                      >
                        <label>No. of Pax</label>
                        <div className="input-element">
                          <TextField
                            name="paxCount"
                            value={values.paxCount}
                            type="number"
                            variant="outlined"
                            onChange={(event) => {
                              setFieldValue("paxAge", [0]);
                              setFieldValue("paxCount", event.target.value);
                              if (event.target.value > 1) {
                                for (let i = 1; i < event.target.value; i++) {
                                  push(0);
                                }
                              }
                            }}
                            inputProps={{
                              autoComplete: "new-password",
                              form: {
                                autoComplete: "off",
                              },
                            }}
                            onInput={(e) => {
                              e.target.value = Math.abs(e.target.value);
                            }}
                            fullWidth
                          />
                        </div>
                        <ErrorMessage
                          name="paxCount"
                          component="div"
                          className="error"
                        />
                      </Grid>
                    </GridContainer>

                    <GridContainer>
                      <Grid
                        md={12}
                        sm={12}
                        xs={12}
                        className="form-group text-field-group"
                      >
                        <label>Ages (yrs)</label>
                        <GridContainer>
                          {values.paxAge.map((val, ind) => (
                            <Grid md={2} sm={4} xs={6} key={ind}>
                              <div className="input-element">
                                <TextField
                                  name={`paxAge[${ind}]`}
                                  value={values.paxAge[ind]}
                                  type="number"
                                  variant="outlined"
                                  onChange={(event) =>
                                    setFieldValue(
                                      `paxAge[${ind}]`,
                                      parseInt(event.target.value)
                                    )
                                  }
                                  inputProps={{
                                    autoComplete: "new-password",
                                    form: {
                                      autoComplete: "off",
                                    },
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.abs(e.target.value);
                                  }}
                                  fullWidth
                                />
                              </div>
                              <ErrorMessage
                                name={`paxAge[${ind}]`}
                                component="div"
                                className="error"
                              />
                            </Grid>
                          ))}
                        </GridContainer>
                      </Grid>
                    </GridContainer>
                  </>
                )}
              </FieldArray> */}
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    setInsuranceBookingInitialTime: (insuranceBookingInitialTime) =>
      dispatch(setInsuranceBookingInitialTime(insuranceBookingInitialTime)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWidth()(InsuranceSection));

const planCoverageOptions = [
  { value: 4, label: "Domestic" },
  { value: 1, label: "US" },
  { value: 2, label: "Non-US" },
  { value: 5, label: "Asia" },
  { value: 6, label: "Canada" },
  { value: 7, label: "Australia" },
  { value: 8, label: "Schenegen Countries" },
];

const planCoverageOptions2 = [{ value: 3, label: "Worlwide" }];

const planCategoryOptions = [
  { value: 1, lable: "Domestic Travel Policy" },
  { value: 2, lable: "Overseas Travel Insurance" },
  { value: 3, lable: "Student Overseas Insurance" },
  { value: 4, lable: "Schengen Overseas Insurance" },
  { value: 5, lable: "Inbound Travel Policy" },
  { value: 6, lable: "Cancellation Insurance" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& .insuranceDest-input-container": {
      "& .insuranceDest-input": {
        paddingLeft:0,
        marginTop: 5,
        background: "transparent",
        paddingRight: 30,

      },
      "& .clear-icon":{
        top: 22,
        // right: 40
      }
    },
    "& .insuranceCat-input-container": {
      "& .insuranceCat-input": {
        paddingLeft:0,
        marginTop: 5,
        background: "transparent",
        paddingRight: 35,
      },
      "& .clear-icon":{
        top: 22,
        // right: 10
      }
    },

    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& .MuiInput-underline:before, .MuiInput-underline:after, .MuiInput-underline:hover, .MuiInput-underline:focus": {
      borderBottom: "none",
      // [theme.breakpoints.down(600)]: {
      //   borderBottom: `solid 1px ${theme.palette.primary.darkText} !important`,
      // },
    },
    "& fieldset": { border: "none" },
    "& .MuiOutlinedInput-input": {
      padding: "10px 0",
    },
    width: "100%",
    "& .insurance-container": {
      margin: "0 !important",
    },

    "& .form-group": {
      // margin: "10px 5px",
      padding: "10px 20px",
      [theme.breakpoints.down(950)]: {
        padding: "10px 10px",
      },

      borderRight: "solid 0.5px #D8D8D8",
      cursor: "pointer",
      "& .oa-form-element-card": {
        border: "none",
        marginTop: 10,
        paddingLeft: 0,
      },
      "&:hover": {
        background: theme.palette.background.rgba_primary,
      },
      "& button": {},
      "& label": {
        fontWeight: 500,
        color: theme.palette.primary.lightGray,
        textTransform: "uppercase",
      },

      "& .input-element": {
        marginTop: "8px",
        "& .MuiToggleButton-sizeSmall": {
          height: 30,
          border: "none",
          marginRight: 10,
          padding: "5px 20px",
        },
        "& .Mui-selected": {
          backgroundColor: "#fff",
          marginRight: 10,
          borderRadius: 24,
          height: 30,
        },
      },
      "& .input-element--dropdown": {
        "& .input-element--textField": {
          display: "flex",
        },
        "& input": {
          width: 30,
          fontSize: "25px",
          // paddingTop: 5,
          paddingBottom: 5,
          // borderBottom: "1px solid #000",
          marginRight: 5,
        },
        "& p": {
          fontWeight: 700,
          minWidth: 200,
          [theme.breakpoints.down(1100)]: {
            fontSize: 11,
          },
        },
        "& span": {
          fontWeight: 700,
          fontSize: 18,
        },
      },
      "& .MuiButton-contained": {
        color: theme.palette.primary.lightText,
        backgroundColor: theme.palette.secondary.main,
        height: "100%",
        width: "100%",

        "&:hover, &:focus": {
          // backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "& .toggle-button-group": {
      borderRight: "none",
      paddingLeft: 0,
      background: "none !important",
      "& .form-group": {
        [theme.breakpoints.down(500)]: {
          width: "100% !important",
          borderTop: "none",
          padding: "10px 10px",
        },
      },

      "& .Mui-selected": {
        color: theme.palette.primary.lightText,
        backgroundColor: theme.palette.primary.main,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& .MuiToggleButton-root": {
        color: theme.palette.primary.defaultText,
        textTransform: "none",
      },
      "& .MuiToggleButtonGroup-root": {
        backgroundColor: theme.palette.background.light,
      },
      "& .MuiToggleButton-sizeSmall": {
        height: 24,
        minWidth: 75,
        [theme.breakpoints.down(840)]: {
          minWidth: 0,
        },
        [theme.breakpoints.down(614)]: {
          minWidth: 75,
        },
      },
      "& .MuiToggleButtonGroup-root": {
        [theme.breakpoints.down(358)]: {
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    "& .select-group": {},
    "& .formContainer": {
      width: "60%",
      minWidth: 1200,
      margin: "0 auto",

      [theme.breakpoints.down(1250)]: {
        width: "90%",
        minWidth: 400,
      },
      [theme.breakpoints.down(500)]: {
        width: "80%",
        minWidth: "80%",
      },
    },
    "& .searchBoxContainer": {
      display: "flex",
      [theme.breakpoints.down(700)]: {
        flexDirection: "column",
      },
      "& .form-group": {
        [theme.breakpoints.down(500)]: {
          width: "100% !important",
          borderTop: "solid 0.5px #D8D8D8",
          padding: "10px 20px",
        },
      },
      "& .MuiInputBase-input": {
        fontSize: "18px !important",
        fontWeight: 700,
        width: "90%",
        [theme.breakpoints.down(1100)]: {
          fontSize: "14px !important",
        },
      },
      boxShadow: "2px 2px 10px 0px rgb(133 131 133)",
      backgroundColor: theme.palette.background.default,
      borderRadius: 5.3,
      display: "flex",
      "& label": {
        [theme.breakpoints.down(1100)]: {
          fontSize: 11,
        },
      },
      // flexDirection: "column",
      // padding: "10px 20px",
      "& .searchBoxUpper": {
        display: "flex",
        "& .disabled-input": {
          opacity: 0.4,
          cursor: "not-allowed",

          "& label": { cursor: "not-allowed" },
          "& .MuiInputBase-input": {
            cursor: "not-allowed",
          },
        },
        "& .disabled-input:hover": {
          backgroundColor: theme.palette.background.default,
        },
        [theme.breakpoints.down(1250)]: {
          width: "70%",
        },
        [theme.breakpoints.down(950)]: {
          width: "65%",
        },
        [theme.breakpoints.down(700)]: {
          width: "100%",
        },
        [theme.breakpoints.down(500)]: {
          flexDirection: "column",
        },
      },
      "& .searchBoxLower": {
        display: "flex",

        [theme.breakpoints.down(950)]: {
          width: "35%",
        },
        [theme.breakpoints.down(700)]: {
          width: "100%",
          height: 75,
          borderTop: "solid 0.5px #D8D8D8",
          "& br": {
            display: "none",
          },
        },
        [theme.breakpoints.down(500)]: {
          flexDirection: "column",
          borderTop: "none",
          marginBottom: 20,
        },
        "& .age-input": {
          position: "relative",
          cursor: "pointer",
          "& .passengerInput": {
            fontWeight: 700,
          },
        },
        "& .ageModal": {
          position: "absolute",
          backgroundColor: "#fff",
          zIndex: 999,
          boxShadow: "0 0 20px 0 rgb(0 0 0 / 45%)",
          borderRadius: 4,
          width: 200,
          top: 57,
          left: 10,
          zIndex: 999,
          padding: "20px 0px",
          "& .ageModal__form": {
            width: "100%",
            padding: "0px 20px",
          },
          "& .ageModal__submitBtn": {
            marginTop: 10,
            height: 25,
            "& .MuiButton-label": {
              fontSize: 14,
            },
          },
          "& .ageModal__passengerContainer": {
            height: 35,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 8,
            // marginBottom: 8,
            paddingLeft: 10,
            "& .MuiInputBase-input": {
              fontSize: "14px !important",
              border: "1px solid #dcdcdc",
              borderRadius: 5,
              padding: "2px 0px",
              paddingLeft: 7,
              paddingBottom: 0,
            },
            "& .passengerInput": {
              width: 40,
            },
            "& label": {
              fontWeight: 700,
            },
          },
          "& .ageModal__paxCountContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            padding: "0 20px",
            marginBottom: 15,
          },
          "& .ageModal__ageLabel": {
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "solid 2px #eee",
            fontWeight: 500,
            paddingTop: 10,
            color: "#7E7979",
            fontSize: 12,
          },
          "& .ageModal__paxCountLabel": {
            fontSize: 14,
            fontWeight: 600,
          },
          "& .ageModal__paxCountInput": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: "1px solid #dcdcdc",
            borderRadius: 4,
            "& .ageModal__inputButton": {
              "& .MuiSvgIcon-root": {
                fontSize: "12px",
              },
              "& .MuiIconButton-root": {
                padding: 5,
              },
            },
            "& .ageModal__inputValue": {
              width: "30px",
              borderLeft: "1px solid #dcdcdc",
              fontFamily: theme.palette.font.primary,
              borderRight: "1px solid #dcdcdc",
              backgroundColor: "#f6f9ff",
              textAlign: "center",
              "& .MuiTypography-h6": {
                fontSize: "13px",
                fontFamily: theme.palette.font.primary,
                paddingTop: "3px",
              },
            },
          },
        },
      },
    },
  },
}));
