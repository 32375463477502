import React, { useState, useEffect } from 'react';
import OaCard from 'oahoc/OaCard';
import OperatorSearchForm from './OperatorSearchForm';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { apiCall } from 'oautils/oaDataUtils';
import WebApi from "api/ApiConstants";
import { GET_FILE_UPLOAD } from "api/ApiConstants";
import OaLoading from 'pages/components/OaLoading';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    boxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
    WebkitBoxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
    MozBoxShadow: "2px 2px 10px 0px rgba(133, 131, 133, 1)",
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    backgroundColor: theme.palette.background.light,
  }
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const OperatorSearch = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [rechargeType, setRechargeType] = useState([]);
  const [isloading, setIsloading] = useState(true);

  const [type, setType] = useState('');
  const [operators, setOperators] = useState([]);

  const handleChange = (event, newTabIndex) => {
    setValue(newTabIndex);
    setOperators(rechargeType[newTabIndex].subCategory);
    setType(rechargeType[newTabIndex].categoryCode);
  }

  useEffect(() => {
    setIsloading(true);
    apiCall(WebApi.getRechargeType, {}, (response) => {
      if (response.data.category) {
        response.data.category.sort(function (a, b) {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });
        setRechargeType(response.data.category);
        setType(response.data.category[0].categoryCode);
        setOperators(response.data.category[0].subCategory);
        setIsloading(false);
      }
    });
  }, []);

  return (
    <OaCard style={{ 'minHeight': 200, 'textAlign': 'center' }}>
      {isloading && (<OaLoading />)}
      {!isloading &&
        <div className={classes.root}>
          <AppBar
            position="static"
            color="default"
            className="recharge-tabs">
            <Tabs
              value={value} onChange={handleChange}
              classes={{
                indicator: classes.indicator
              }}

              textColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              {rechargeType.map((type, index) => {
                // const temp_file_path = "http://localhost:8080/travlogy_ws/api/v1/view-file";
                return (<Tab key={index} icon={<img className="plane-icon" src={GET_FILE_UPLOAD + "?fileName=" + type.icon + "&type=EMAIL_IMAGES"} />}
                  label={type.categoryName} aria-label="phone"  {...a11yProps(index)} />)
                // return (<Tab key={index} icon={<img className="plane-icon" src={temp_file_path + "?fileName="+type.icon+"&type=EMAIL_IMAGES"}/>} 
                // label={type.categoryName} aria-label="phone"  {...a11yProps(index)} />)
              })}
            </Tabs>
          </AppBar>

          {rechargeType.map((typ, index) => {
            return (<TabPanel value={value} index={index} key={index}>
              <OperatorSearchForm type={type} operators={operators}></OperatorSearchForm>
            </TabPanel>)
          })}
          <style>{`
            .MuiAppBar-colorDefault {
              background-color: ${theme.palette.primary.main};
            }
          `}</style>
        </div>
      }
    </OaCard>
  )
}

export default OperatorSearch;
