import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  useTheme,
} from "@material-ui/core";
import styled from "styled-components";
import PassengerCounter from "./PassengerCounter";
import { ErrorMessage } from "formik";

const PassengerClass = (props) => {
  const theme = useTheme();
  const [dropdownTraveler, setDropdownTraveler] = React.useState(false);

  return (
    <RootContainer theme={theme}>
      <div className="pax-input-div">
        <div
          className="passenger_div"
          onClick={() => setDropdownTraveler(!dropdownTraveler)}
        >
          <p className="trvl-count">
            <span className="trval-tnum">{props.totalPax}</span>
            <span className="trval-txt">
              Passenger{props.totalPax > 1 && "s"},
            </span>{" "}
          </p>
          <p className="trvl-class">
            {props.values.preferredclass === "E"
              ? "Economy"
              : props.values.preferredclass === "ER"
              ? "Premium Economy"
              : props.values.preferredclass === "B"
              ? "Business"
              : ""}
          </p>
        </div>

        {dropdownTraveler && (
          <div className="passenger_div-show">
            <Grid
              item
              sm={12}
              // className={classes.FlightSection_passdet}
              className="pax-flight-det"
            >
              <Grid
                // className={classes.gridRespColPassDet}
                className="pax-flight-det-col"
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                spacing={2}
              >
                <Box className="counter-box">
                  <PassengerCounter
                    title="Adults"
                    type="adult"
                    val={props.values.adultPax}
                    setVal={props.modifyPassengerCount}
                    name="adultPax"
                    totalPax={
                      props.values.adultPax +
                      props.values.childPax +
                      props.values.infantPax
                    }
                  />
                  <ErrorMessage
                    name="adultPax"
                    component="div"
                    className="error"
                  />
                </Box>
                <Box className="counter-box">
                  <PassengerCounter
                    title="Children"
                    type="child"
                    val={props.values.childPax}
                    setVal={props.modifyPassengerCount}
                    name="childPax"
                    totalPax={
                      props.values.adultPax +
                      props.values.childPax +
                      props.values.infantPax
                    }
                  />
                  <ErrorMessage
                    name="childPax"
                    component="div"
                    className="error"
                  />
                </Box>
                <Box className="counter-box">
                  <PassengerCounter
                    title="Infants"
                    type="infant"
                    val={props.values.infantPax}
                    setVal={props.modifyPassengerCount}
                    name="infantPax"
                    totalPax={
                      props.values.adultPax +
                      props.values.childPax +
                      props.values.infantPax
                    }
                  />
                  <ErrorMessage
                    name="noInfant"
                    component="div"
                    className="error"
                  />
                </Box>
              </Grid>

              <div className="cabinType-dev">
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="cabinType"
                    value={props.values.preferredclass}
                    name="preferredclass"
                    onChange={(e, v) =>
                      v && props.setFieldValue("preferredclass", v)
                    }
                  >
                    <FormControlLabel
                      value="E"
                      control={<Radio />}
                      label="Economy"
                    />
                    <FormControlLabel
                      value="ER"
                      control={<Radio />}
                      label="Premium Economy"
                    />
                    <FormControlLabel
                      value="B"
                      control={<Radio />}
                      label="Business"
                    />
                  </RadioGroup>
                  <ErrorMessage
                    name="preferredclass"
                    component="div"
                    className="error"
                  />
                </FormControl>
                <Button onClick={() => setDropdownTraveler(!dropdownTraveler)}>
                  Done
                </Button>
              </div>
            </Grid>
          </div>
        )}
      </div>
    </RootContainer>
  );
};

export default PassengerClass;

const RootContainer = styled.div`
  background: ${(prop) => prop.theme.palette.background.default};
  border-radius: 8px;
  padding: 6px 8px;
  height: 45px;
  & .pax-input-div {
    position: relative;
  }
  & .passenger_div {
    display: flex;
    gap: 10px;
    padding: 5px 0 0 5px;
    color: #838383;
    @media all and (max-width: 1280px) {
      font-size: 12px;   
      margin-top: 3px;
      font-weight: 500;
    }
    & .trvl-count {
      display: flex;
      gap: 5px;
    }
    & .trvl-count,
    .trvl-class {
      color: #838383;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
      font-weight: 600;
    }
  }
  & .passenger_div-show {
    position: absolute;
    top: 40px;
    left: 0;
    width: 280px;
    z-index: 9;
    background: ${(prop) => prop.theme.palette.background.default};
    padding: 20px 15px 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(161, 161, 161, 1);
    -moz-box-shadow: 0px 0px 5px 0px rgba(161, 161, 161, 1);
    box-shadow: 0px 0px 5px 0px rgba(161, 161, 161, 1);
    & .MuiFormControlLabel-label {
      color: ${(prop) => prop.theme.palette.background.defaultContrast};
    }
    & .cabinType-dev {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .MuiFormControlLabel-root {
        height: 20px;
      }
      & .MuiRadio-root {
        &.Mui-checked {
          & .MuiSvgIcon-root {
            color: ${(prop) => prop.theme.palette.primary.main};
          }
        }
      }
      & button {
        background: ${(prop) => prop.theme.palette.primary.main};
        color: ${(prop) => prop.theme.palette.primary.contrastText};
      }
    }
  }
  // @media all and (max-width: 1279px) {
  //   border-radius: 25px;
  //   border: solid 1px ${(prop) => prop.theme.palette.background.primary};
  // }
`;
