import React, { useEffect, useState } from "react";
import OaLoading from "pages/components/OaLoading";
import { Button, makeStyles, Typography } from "@material-ui/core";
import {
  ErrorOutlineRounded,
  CheckCircle,
  List,
  ListAltOutlined,
} from "@material-ui/icons";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { useHistory } from "react-router-dom";
import { AppConstant } from "appConstant";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
const BusPaymentStatus = (props) => {
  let status = props?.match?.params?.status.toLowerCase();
  const history = useHistory();
  const classes = useStyles();
  const [bookingProcess, setBookingProcess] = useState(status);
  const [isLoading, setIsLoading] = useState(true);
  const [bookingStatus, setBookingStatus] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [refId, setRefId] = React.useState(0);
  const [trnCreationError, setTrnCreationError] = React.useState(false);

  let tid = history?.location?.state?.Tid
    ? history?.location?.state?.Tid
    : props.match.params?.tid;

  // console.log(tid);

  const goToTicketPrint = () => {
    props.history.push({
      pathname: "/bus/bus-ticket",
      state: props?.history?.location?.state,
    });
  };

  React.useEffect(() => {
    setIsLoading(true);
    setRefId(props.match.params.tid);
    if (props.match.params?.status == "success") {
      // if (props?.match?.params?.status.slice(0, 7) == "success") {
      setIsLoading(false);
      setBookingStatus(true);
      setPaymentStatus("Success");
    } else {
      //props?.match?.params?.tid == 0 &&

      setTrnCreationError(true);
      setIsLoading(false);
      setBookingStatus(false);
      setPaymentStatus("Failed");
    }
  }, [props.match.params.tid, props.match.params.status]);

  const LoaderView = () => {
    return (
      <div className="loader-view">
        <OaLoading loadertext="please wait ..." />
      </div>
    );
  };

  const getTicketData = (transactionId) => {
    return new Promise((resolve, reject) => {
      WebApi.getBusSeatBookingDetails(
        { tid: transactionId },
        (response) => {
          if (response !== null && response.success === true) {
            resolve(response);
          }
        },
        (error) => {
          reject([]);
          // console.log(error);
        }
      );
    });
  };

  return (
    <div className={classes.root}>
      <div className="bookingStatusContainer">
        <div
          style={{
            backgroundColor:
              bookingProcess === "success" ? "#E5FFDA" : "#ffb09c",
          }}
          className="bookingStatusInfoContainer"
        >
          <div className="bookStatusInfoTop">
            <div className="bookStatusHeader">
              Booking Status:{" "}
              {bookingProcess === "success"
                ? "Booking Confirmed"
                : "Booking Failed"}
              {/* {bookingProcess === "confirmed"
                ? "Booking Confirmed"
                : bookingProcess === "processing"
                ? "Pending From Bus"
                : bookingProcess === "failed"
                ? "Booking Failed"
                : "Unknown Status"} */}
            </div>
            <div className="bookStatusBookingId">Booking ID: {refId}</div>
          </div>
          <div className="bookStatusStatusInfo">
            {bookingProcess === "success" ? (
              <CheckCircle style={{ color: "green" }} />
            ) : (
              <AccessTimeIcon style={{ color: "grey" }} />
            )}
            Thanks for transacting with {AppConstant.name}.{" "}
            {bookingProcess === "success"
              ? "We have sent an e-ticket to your registered email address"
              : "Booking Failed"}
          </div>
        </div>
      </div>

      <div className="bookingStatusStepContainer">
        <div className="bookingStatusProcess">
          <div className="bookingStatusStep">
            <div className="stepIcon">
              {" "}
              <CheckCircle style={{ color: "#48AB64" }} />
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">Payment Received</div>
              <div className="stepInfoText">We have received your payment</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor: "#48AB64",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              //     style={{
              // bookingProcess: "#48AB64"
              //     }}
            >
              <CheckCircle style={{ color: "#48AB64" }} />
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">
                Blocking a Seat{" "}
                {bookingProcess === "blocking" && (
                  <CircularProgress size={20} className="loader" />
                )}{" "}
              </div>
              <div className="stepInfoText">It will take approx few mins.</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor: "#48AB64",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              // style={{
              //   backgroundColor:
              //     bookingProcess === "booking" ? "#eabb31" : "#D8D8D8",
              // }}
            >
              <CheckCircle style={{ color: "#48AB64" }} />
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">
                Booking a Seat{" "}
                {bookingProcess === "booking" && (
                  <CircularProgress size={20} className="loader" />
                )}
              </div>
              <div className="stepInfoText">It will take approx few mins.</div>
            </div>
          </div>
          <div
            className="progressBar"
            style={{
              backgroundColor:
                bookingProcess === "success" ? "#48AB64" : "#ffb09c",
            }}
          ></div>
          <div className="bookingStatusStep">
            <div
              className="stepIcon"
              style={{
                backgroundColor:
                  bookingProcess === "success" ? "transparent" : "#ffb09c",
              }}
            >
              {bookingProcess === "success" ? (
                <CheckCircle style={{ color: "#48AB64" }} />
              ) : (
                4
              )}
            </div>
            <div className="stepInfoTextContainer">
              <div className="stepInfoHeader">
                Booking {bookingProcess === "success" ? "Confirmed" : "Failed"}
              </div>
              <div className="stepInfoText">
                Your Booking{" "}
                {bookingProcess === "success" ? "is Confirmed" : "Failed"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {bookingProcess === "success" && (
        <div className="bookingProcessAssets">
          {/* <Button onClick={sendEmail}>Email</Button> */}
          <Button
            onClick={() =>
              props.history.push({
                pathname: "/bus/bus-ticket",
                state: props?.history?.location?.state,
              })
            }
          >
            VIEW ETICKET
          </Button>

          {/* <OaButtonDownloadPDF
              btnLabel="Download ETicket"
              api={DOWNLOAD_FLIGHT_TICKET_PDF}
              searchParams={flightTicketDownloadRequest}
              defaultFileName="flight-eticket"
            /> */}
        </div>
      )}

      {!isLoading && bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <CheckCircle />
            <Typography>
              Reference id : <b>{refId}</b>
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
            <Typography>
              Booking Successful. Please click the button below to go to voucher
              details.
            </Typography>
          </div>
          <div className="action-group">
            <Button onClick={goToTicketPrint}>
              <ListAltOutlined /> Ticket
            </Button>
          </div>
        </div>
      )}

      {/* {!isLoading && !bookingStatus && (
        <div className="section-container" style={{ paddingBottom: "8px" }}>
          <div className="icon-group">
            <ErrorOutlineRounded style={{ color: "orange" }} />
            <Typography>
              Booking Failed. Please check after some time
              {!trnCreationError &&
                ` or contact administrator with reference id (${refId})`}
              .
            </Typography>
            <Typography>
              Status : <b>{paymentStatus}</b>
            </Typography>
          </div>
        </div>
      )} */}
      {/* {isLoading && (
        <div className="section-container">
          {" "}
          <LoaderView />
        </div>
      )} */}
    </div>
  );
};

export default BusPaymentStatus;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .loader-view": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      flexDirection: "column",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 103,
      background: "rgba(0,0,0,0.5)",
      "& img": {
        height: "7rem",
      },
      "& .loader-text": {
        color: theme.palette.primary.main,
        fontWeight: 600,
        marginLeft: 10,
      },
    },
    "& .section-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      minHeight: "60vh",
    },
    "& .icon-group": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "50px 0",
      "& .MuiSvgIcon-root": {
        fontSize: "10vw",
        color: theme.palette.primary.success,
      },
      "& p": {
        fontWeight: 500,
        fontFamily: theme.palette.font.primary,
        marginTop: 20,
      },
    },
    "& .bookingStatusContainer": {
      padding: "10px 15px",
      width: "100%",

      "& .bookingStatusInfoContainer": {
        border: "1px solid #D8D8D8",
        padding: 10,
        backgroundColor: "#E5FFDA",
      },
      "& .bookStatusInfoTop": {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
      },
      "& .bookStatusHeader": {
        fontSize: 17,
        fontWeight: 700,
      },
      "& .bookStatusBookingId": {
        fontSize: 15,
        fontWeight: 500,
      },
      "& .MuiSvgIcon-root": {
        height: 40,
        width: 40,
        color: theme.palette.primary.success,
      },
      "& .bookStatusStatusInfo": {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        fontSize: 13,
      },
      "& .bookingStatusStep": {
        display: "flex",
      },
    },
    "& .bookingStatusStepContainer": {
      padding: "5px 15px",
      width: "100%",
    },
    "& .bookingStatusProcess": {
      display: "flex",
      border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      gap: 10,
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.down(700)]: {
        gap: 10,
        flexDirection: "column",
        alignItems: "center",
      },
      "& .bookingStatusStep": {
        display: "flex",
        alignItems: "center",
        gap: 10,
        [theme.breakpoints.down(700)]: {
          width: 240,
          marginBottom: 10,
        },
      },
      "& .progressBar": {
        width: 40,
        height: 5,
        borderRadius: 4,
      },
      "& .stepIcon": {
        // backgroundColor: "#48AB64",
        height: 30,
        width: 30,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        borderRadius: 50,
        fontWeight: 700,
        "& svg": {
          width: 35,
          height: 35,
        },
      },
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    "& .bookingStatusProcessContainer": {
      border: "1px solid #D8D8D8",
      padding: "10px 15px",
      width: "100%",
      justifyContent: "space-between",
      "& .stepInfoHeader": {
        fontWeight: 500,
        fontSize: 16,
        display: "flex",
        alignItems: "center",
        gap: 10,
      },
      "& .stepInfoText": {
        fontWeight: 400,
        fontSize: 12,
      },
    },
    "& .action-group": {
      "& button": {
        cursor: "pointer",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));
