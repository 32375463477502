import styled from "styled-components";
import WebApi from "api/ApiConstants";
import OaTable from "components/OaTable";
import React, { useEffect, useState } from "react";
import { localforageGetItem } from "oautils/oaForageUtils";

const FlightRollbackReportResults = (props) => {
    const [userType, setUserType] = useState(null);
    const [params, setParams] = useState(null);

    const getUserType = () => {
        localforageGetItem("user-details", function (err, value) {
            if (value) {
                setUserType(value.userTypeAbv);
            }
        });
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'INR',
    });

    useEffect(() => {
        getUserType();
    }, []);

    useEffect(() => {
        if(userType) {
            let temp = {...props.searchParams};
            temp.userType = userType;
            setParams(temp);
        }
    }, [userType]);

    let cols = [
        {
            Header: '#',
            Cell: props => <div >{props.index + 1}</div>,
            width: 40,
            filterable: false
        }, {
            Header: "Can Id",
            accessor: "canId",
            width: 70,
            Cell: props => (
                <div >
                    {props.value}
                </div>
            )
        }, {
            Header: "TID",
            accessor: "transactionId",
            width: 70,
            Cell: props => (
                <div>
                    <b>{props.value}</b>
                </div>
            )
        }, {
            Header: "PNR",
            accessor: "eTicketNO",
            sortable: false,
            width: 70,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Request Date",
            accessor: "cancellationDateTime",
            width: 90,
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Booking Date",
            accessor: "transactionDate",
            filterable: false,
            width: 90,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Travel Date",
            accessor: "travelDateTime",
            width: 90,
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Refund Status",
            accessor: "refundStatus",
            filterable: false,
            sortable: false,
            width: 90,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Rollback Remark",
            accessor: "cancellationRemark",
            filterable: false,
            sortable: false,
            width: 100,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Amount",
            accessor: "flightFare",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{formatter.format(props.value)}</div>
            )
        },{
            Header: "Refund Amount",
            accessor: "finalRefundAmount",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{formatter.format(props.value)}</div>
            )
        },{
            Header: "Journey Type",
            accessor: "journeyType",
            width: 70,
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Trip",
            accessor: "domesticInternational",
            width: 50,
            filterable: false,
            Cell: props => (
                <div >{props.value}</div>
            ),
        }, {
            Header: "Passenger Number",
            accessor: "passengerMobileNumber",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Passenger Name",
            accessor: "passengerName",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{props.value}</div>
            )
        },{
            Header: "Group Booking",
            accessor: "agentName",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >N</div>
            )
        },{
            Header: "Airline Cancellation Charge",
            accessor: "airlineCancellationCharge",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{formatter.format(props.value)}</div>
            )
        },{
            Header: "Cancellation Charge",
            accessor: "travlogyCancellationCharge",
            filterable: false,
            sortable: false,
            Cell: props => (
                <div >{formatter.format(props.value)}</div>
            )
        }
    ];

    return(
        <RootContainer>
            {userType && params && 
                <OaTable
                    columns={cols}
                    search={params}
                    loader={WebApi.getNonPNRCancellationRequestList}
                    filterable={false}
                    rows
                />
            }
        </RootContainer>
    );
};

export default FlightRollbackReportResults;

const RootContainer = styled.div``;
