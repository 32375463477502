import WebApi, { basename } from "api/ApiConstants";
import { SERVER_URL } from "api/ApiConstants";
import { setUserType } from "redux/action";
import { localforageClear, localforageGetItemAsync, localforageSetItem } from "./oaForageUtils";

const resetUser = (dispatch, callback, isCorporate) => {
    WebApi.getAgentInfo({ userType: "G" }, (response) => {
        if (response != null && response.success === true) {
            localforageClear(() => {
                localforageSetItem("user-id", 0);
                localforageSetItem("user-details", response.data, () => {
                    dispatch(setUserType(response.data));
                    localforageSetItem("access-key", response.accesskey, () => {
                        if(!isCorporate) {
                            callback(false);
                        } else {
                            // window.location.href(SERVER_URL+basename+"/auth/login-page");
                            // window.location.assign(SERVER_URL+basename+"/auth/login-page");
                            window.location.assign(SERVER_URL+basename);
                        }
                    });
                });
            });
        }
    }, error => console.log("error getAgentInfo in oaAuthUtils", error));
};

export const refreshUserSession = (dispatch, callback) => {
    (async () => {
        const userDetails = await localforageGetItemAsync("user-details");
       // console.log("user details",userDetails);
        callback(true);
        if (!userDetails) {
            resetUser(dispatch, callback, false);
        } else {
            const checkValidation = new Promise((resolve, reject) => {
                WebApi.getValidatedAuth({}, response => {
                    if(response.success) {
                        resolve(response.success)
                    } else {
                        reject("validation failed");
                    }
                }, error => { reject(error) });
            });

            checkValidation.then(validationStatus => {
                dispatch(setUserType(userDetails));
                callback(false);
            })
            .catch(error => {
                console.log("error", error);
                if(userDetails.userTypeAbv == "S" &&
                    (userDetails.userType == "agent" ||
                    userDetails.userType == "direct_agent")
                ) {
                    resetUser(dispatch, callback, true);
                } else {
                    WebApi.getAgentInfo({ userType: "G" }, (response) => {
                        localforageSetItem("access-key", response.accesskey, () => {
                            callback(false);
                        });
                    });
                }
            });
        }
    })();
}
