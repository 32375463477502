import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
// import image1 from 'assets/img/geebaku-loading.gif';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// import dateFnsFormat from 'date-fns/format';
import WebApi from "api/ApiConstants";
import "assets/css/BusTicket.css";
// import {Typography, Divider} from '@material-ui/core'
import BusTicketResult from "./BusTicketResult";
import {
  CircularProgress,
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";

// core components
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import FlightTicketResult from "pages/report/flight/FlightTicketResult";
import OaCard from "oahoc/OaCard";
import OaTabBar from "oahoc/OaTabBar";
import { apiCall } from "oautils/oaDataUtils";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import OaFormAlerts from "pages/components/OaFormAlerts";
import Send from "@material-ui/icons/Send";
import Save from "@material-ui/icons/Save";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { getQueryVar } from "oautils/oaCommonUtils";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import { pdfDownload, getImageData } from "oautils/oaPdfUtils";

import { scrollToTop } from "oautils/oaCommonUtils";

import "assets/css/print-portrait.css";
//import 'assets/css/FlightTicket.css';
// import Card from '../components/Card/Card.js';
// import CardBody from '../components/Card/CardBody.js';
import { useHistory } from "react-router-dom";
import { AppStateContext } from "layouts/AppStateProvider";
import { useSelector, useDispatch } from "react-redux";
import { AppConstant } from "appConstant";
import OaButtonDownloadPDF from "pages/components/OaButtonDownloadPDF";
import { localforageGetItem } from "oautils/oaForageUtils";
import { DOWNLOAD_BUS_TICKET_PDF } from "api/ApiConstants";

const useStyles = makeStyles(styles);

const BusTicket = (props) => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const pxToMm = (px) => {
    return Math.floor(px / document.getElementById("myMm").offsetHeight);
  };
  const mmToPx = (mm) => {
    return document.getElementById("myMm").offsetHeight * mm;
  };

  const range = (start, end) => {
    return Array(end - start)
      .join(0)
      .split(0)
      .map(function(val, id) {
        return id + start;
      });
  };

  let tabs = [
    { name: "flight", label: "Bus", url: "/admin/booking-report/flight" },
  ];

  let currentTab = "flight";

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [isUpdateMarkup, setIsUpdateMarkup] = useState(false);
  const [markup, setMarkup] = useState(0);
  const [markupTemp, setMarkupTemp] = useState(0);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [receiverEmail, setReceiverEmail] = useState("");
  const [state, setState] = useState({
    isAddressHide: false,
    isFareHide: false,
    isPerPassenger: false,
    isGSTHide: false,
  });
  const [loggedInUserId, setLoggedInUserId] = useState(0);
  const ticketId = history?.location?.state?.tid
    ? history?.location?.state?.tid
    : getQueryVar({ ...props, q: "ticketId" });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const isMounted = React.useRef(false);

  const { userType } = useContext(AppStateContext);
  const userTypeInfo = useSelector((state) => state.usertype);
  //   console.log(userTypeInfo)

  //   const getTicketData = (transactionId) => {
  //       return new Promise((resolve, reject) => {
  //           WebApi.getTicketData({"transactionId": transactionId} , (response) => {
  //               if(response !== null && response.success){
  //                   resolve(response.data);
  //               }
  //           },(error)=>{
  //               reject([]);
  //               console.log(error);
  //           })
  //       })
  //   }

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function(err, value) {
      if (mountStatus && value) {
        setLoggedInUserId(value.userId);
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  function getIcon() {
    return !isSaving ? <Send /> : <CircularProgress size="24px" />;
  }

  function getIcon1() {
    return !isSaving ? <Save /> : <CircularProgress size="24px" />;
  }

  useEffect(() => {
    if (isLoading) {
      if (
        !(props.location.state && props.location.state.GetBookingDetailResult)
      ) {
        // console.log(" ############ %%%%%%%%%% bus ticket !props.location.state %%%%%%%%%%% ##########")
        apiCall(
          WebApi.getBusSeatBookingDetails,
          { tid: ticketId },
          (response) => {
            setInfoText(response.message);
            if (response.success === true) {
              // setIsSuccess(true);
              setData(response.data);
              if (
                response.data.GetBookingDetailResult &&
                response.data.GetBookingDetailResult.agencyInfo
              ) {
                setMarkup(response.data.GetBookingDetailResult.agentMarkup);
                setMarkupTemp(response.data.GetBookingDetailResult.agentMarkup);
              }
              setIsLoading(false);
            } else {
              setIsError(true);
              // setIsLoading(false);
            }
          }
        );
      } else {
        // console.log(" ############ %%%%%%%%%% bus ticket props.location.state %%%%%%%%%%% ##########")
        setData(props.location.state);
        if (
          props.location.state.GetBookingDetailResult &&
          props.location.state.GetBookingDetailResult.agencyInfo
        ) {
          setMarkup(props.location.state.GetBookingDetailResult.agentMarkup);
          setMarkupTemp(
            props.location.state.GetBookingDetailResult.agentMarkup
          );
        }

        setIsLoading(false);
      }
    }
  }, [isLoading]);

  const invoiceClick = () => {
    // console.log(ticketId)
    // props.history.push('admin/voucher/bus-user/871')
    // props.history.push('/admin/voucher/bus-user/' + ticketId?ticketId:props.location.state.GetBookingDetailResult.tid);
  };

  const printClick = () => {
    window.print();
  };

  const classes = useStyles();

  const { isAddressHide, isFareHide, isPerPassenger, isGSTHide } = state;

  const busTicketDownloadRequest = {
    loggedInUserId: loggedInUserId,
    tid: ticketId,
    isAddressHide: isAddressHide,
    isFareHide: isFareHide,
    isPerPassenger: isPerPassenger,
    isGSTHide: isGSTHide,
  };

  const handleMarkupSave = () => {
    setIsSaving(true);
    const markup1 = markup == "" ? 0 : markup;
    setMarkupTemp(markup1);
    apiCall(
      WebApi.doBusMarkupUpdate,
      {
        markup: markup1,
        tid: ticketId
          ? ticketId
          : props.location.state.GetBookingDetailResult.tid,
      },
      function(response) {
        if (response.success) {
          setIsError(false);
          setIsSuccess(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        } else {
          setIsError(true);
          setIsSaving(false);
          setInfoText(response.message);
          scrollToTop(0);
        }
      }
    );
  };

  function downloadETicket(e) {
    e.target.setAttribute("disabled", "disabled");
    pdfDownload(
      "section-to-print",
      "p",
      "eticket-" +
        (ticketId ? ticketId : props.location.state.GetBookingDetailResult.tid)
    );
    e.target.removeAttribute("disabled");
  }

  function sendEmail() {
    if(receiverEmail) {
      setIsSaving(true);
      const emailReqParams = {...busTicketDownloadRequest};
      emailReqParams.email = receiverEmail;

      WebApi.emailBusTicketPdf(emailReqParams, (response) => {
        response?.message ? setInfoText(response?.message) : setInfoText("internal error!");
        setIsSuccess(response.success);
        setIsError(!response.success);
        setIsSaving(false);
      }, (error) => {
        console.error("Email error", error);
        setIsSaving(false);
      });
    }

    // setIsSaving(true);
    // getImageData("section-to-print", "p", function(imageData) {
    //   apiCall(
    //     WebApi.emailETicket,
    //     {
    //       subject: `${AppConstant.name} Bus Ticket`,
    //       email: receiverEmail,
    //       body:
    //         "<!DOCTYPE html><html><body><img src='" +
    //         imageData +
    //         "'/></body></html> ",
    //     },
    //     function(response) {
    //       if (response.success === true) {
    //         setIsError(false);
    //         setIsSuccess(true);
    //         setIsSaving(false);
    //         setInfoText(`Email Sent to ${receiverEmail}`);
    //       } else {
    //         setIsError(true);
    //         setIsSaving(false);
    //         setInfoText("Email server busy try after sometime");
    //       }
    //     }
    //   );
    // });
  }

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getUserDetails(isMounted.current);
  }, [isMounted.current]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "-webkit-fill-available",
          }}
        >
          <CircularProgress disableShrink />
        </div>
      ) : (
        <OaCard
          className={"oa-card-primary"}
          bodystyle={{ padding: isMobile ? 0 : "" }}
        >
          <OaTabBar tabs={tabs} currentTab={currentTab} type="primary" />
          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />
          <OaCard bodystyle={{ padding: isMobile ? 0 : "" }}>
            <div>
              <OaCard
                className="section-not-to-print"
                bodystyle={{ padding: isMobile ? 0 : "" }}
              >
                <GridContainer>
                  {data.GetBookingDetailResult &&
                    data.GetBookingDetailResult.agencyInfo && (
                      <GridItem md={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isAddressHide}
                              onChange={handleChange("isAddressHide")}
                              value="isAddressHide"
                            />
                          }
                          label="Hide Address"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isFareHide}
                              onChange={handleChange("isFareHide")}
                              value="isFareHide"
                            />
                          }
                          label="Hide Fare"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isGSTHide}
                              onChange={handleChange("isGSTHide")}
                              value="isGSTHide"
                            />
                          }
                          label="Hide GST"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={isPerPassenger}
                              onChange={handleChange("isPerPassenger")}
                              value="isPerPassenger"
                            />
                          }
                          label="Passenger Wise"
                        />
                      </GridItem>
                    )}

                  <GridItem md={12}>
                    <Button color="primary" onClick={printClick}>
                      Print ETicket
                    </Button>
                    {/* <Button color="primary" onClick={downloadETicket} >Download</Button> */}
                    <OaButtonDownloadPDF
                      btnLabel="Download ETicket"
                      api={DOWNLOAD_BUS_TICKET_PDF}
                      searchParams={busTicketDownloadRequest}
                      defaultFileName="bus-eticket"
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        setIsSendEmail(!isSendEmail);
                        setIsUpdateMarkup(false);
                      }}
                    >
                      Email
                    </Button>
                    {/* <Button color="primary" onClick={invoiceClick} >Invoice</Button> */}
                    {!(
                      userTypeInfo.userType === null ||
                      userTypeInfo.userType === "b2c_user"
                    ) &&
                      (userTypeInfo &&
                        userTypeInfo.corporateType != "corporate") && (
                        <Button
                          color="primary"
                          onClick={() => {
                            setIsUpdateMarkup(!isUpdateMarkup);
                            setIsSendEmail(false);
                          }}
                        >
                          Update Markup
                        </Button>
                      )}
                  </GridItem>
                </GridContainer>

                {isUpdateMarkup && (
                  <GridContainer>
                    <GridContainer justifyContent="center">
                      <GridItem>
                        <h4
                          style={{ color: theme.palette.secondary.lightIndigo }}
                        >
                          UPDATE MARKUP
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center">
                      <GridItem>
                        <FormControl fullWidth={true}>
                          <TextField
                            variant="outlined"
                            name="markup"
                            label="Markup Value"
                            md={4}
                            value={markup}
                            onChange={(event) => {
                              const { value } = event.target;
                              setMarkup(value);
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="primary"
                          className={classes.registerButton}
                          endIcon={getIcon1()}
                          disabled={isSaving}
                          onClick={handleMarkupSave}
                        >
                          UPDATE
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                )}

                {isSendEmail && (
                  <GridContainer>
                    <GridContainer justifyContent="center">
                      <GridItem>
                        <h4
                          style={{ color: theme.palette.secondary.lightIndigo }}
                        >
                          SEND EMAIL
                        </h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer justifyContent="center">
                      <GridItem>
                        <FormControl fullWidth={true}>
                          <TextField
                            variant="outlined"
                            name="receiverEmail"
                            label="Receiver Email"
                            md={4}
                            value={receiverEmail}
                            onChange={(event) => {
                              const { value } = event.target;
                              setReceiverEmail(value);
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="primary"
                          onClick={sendEmail}
                          className={classes.registerButton}
                          endIcon={getIcon()}
                          disabled={isSaving}
                        >
                          SEND
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                )}
              </OaCard>
              <GridContainer
                justifyContent="center"
                id="section-to-print"
                className="section-to-print"
                style={{ maxWidth: 1050 }}
              >
                <GridItem col={6}>
                  {isPerPassenger ? (
                    <>
                      {" "}
                      {data.GetBookingDetailResult.Itinerary.Passenger &&
                        data.GetBookingDetailResult.Itinerary.Passenger.map(
                          (passenger, passengerindex) => (
                            <BusTicketResult
                              data={data}
                              state={state}
                              markup={markupTemp}
                              passenger={passenger}
                              key={passengerindex}
                              companylogo={props.companylogo}
                            ></BusTicketResult>
                          )
                        )}
                    </>
                  ) : (
                    <BusTicketResult
                      data={data}
                      state={state}
                      markup={markupTemp}
                      companylogo={props.companylogo}
                    ></BusTicketResult>
                  )}
                </GridItem>
              </GridContainer>
            </div>
          </OaCard>
        </OaCard>
      )}
    </>
  );
};

export default withRouter(BusTicket);
