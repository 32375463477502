import React, { useEffect } from "react";
import HomePage from "components/LandingPage/Gostree/HomePage";
import PlanYourTripMobile from "components/LandingPage/Gostree/HomePageMobile";
import { localforageGetItem } from "oautils/oaForageUtils";

const LandingPageBase = (props) => {
  const width = window.innerWidth;
  const breakpoint = 980;
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  useEffect(() => {
    localforageGetItem("user-details", function(err, value) {
      if (value) {
        if (value.userTypeAbv == "S") {
          setIsLoggedIn(true);
        }
      } else {
        console.log("error getting user details", err);
      }
    });
  }, []);

  return width < breakpoint ? (
    isLoggedIn ? (
      <PlanYourTripMobile />
    ) : (
      <HomePage />
    )
  ) : (
    <HomePage />
  );
};

export default LandingPageBase;
