import React, { useState, useEffect } from 'react';
import ReactTable from "react-table";
import { AppBar, Tab, Tabs, Box, Typography, FormControl } from '@material-ui/core';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import OaCard from 'oahoc/OaCard';
import OaFormSaveButton from "oahoc/OaFormSaveButton";
import OaFormAlerts from "pages/components/OaFormAlerts";
import WebApi from "api/ApiConstants";
import { apiCall } from "oautils/oaDataUtils";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { scrollToTop } from "oautils/oaCommonUtils";
import { FileCopy } from "@material-ui/icons";
import cx from "classnames";

const MarkupTable = (props) => {
  const classes = useStyles();
  const [domesticMarkup, setDomesticMarkup] = useState([]);
  const [domesticMarkupArray, setDomesticMarkupArray] = useState([]);
  const [internationalMarkup, setInternationalMarkup] = useState([]);
  const [internationalMarkupArray, setInternationalMarkupArray] = useState([]);
  const [sotoMarkup, setSotoMarkup] = useState([]);
  const [sotoMarkupArray, setSotoMarkupArray] = useState([]);
  const [Tabvalue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [formInitialValues, setFormInitialValues] = useState({});
  const [domMarkupRep, setDomMarkupRep] = useState(0);
  const [intMarkupRep, setIntMarkupRep] = useState(0);
  const [sotoMarkupRep, setSotoMarkupRep] = useState(0);

  const formikRef = React.useRef();

  function validateNumber(value) {
    let error = "";
    if (isNaN(value) || value < 0) {
      error = "Must be valid number";
    }
    return error;
  }

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  function setInitialValues() {
    let initialValues = {};
    for (let [key, value] of Object.entries(props.markupList)) {
      if (value.Markups != undefined) {
        value.Markups.map(function (rows, ikey) {
          if (rows.markUpValues !== undefined) {
            rows.markUpValues.map((row, ikeys) => {
              initialValues[key + "__" + ikey + "__" + ikeys] =
                row.actualTransactionValue;
            });
          }
        });
      }
    }

    setFormInitialValues(initialValues);
  }


  const dataArrDomestic = () => {
    const newArr = [];

    domesticMarkup && domesticMarkup.map((rows, idx) => {
      rows.markUpValues.map((row, index) => {
        let supplier = row.supplier;
        switch (supplier) {
          case "Tripjack": {
            supplier = "Supplier 1";
            break;
          }
          case "tbo": {
            supplier = "Supplier 2";
            break;
          }
          case "kafila": {
            supplier = "Supplier 3";
            break;
          }
        }
        let obj = {
          id: index,
          carrier: `${row.carrier}`,
          airline: `${row.airlines}`,
          supplier: `${supplier}`,
          fareType: `${row.fareType}`,
          markupType: `${row.markupType.charAt(0) === "F" ? "FIXED" : row.markupType.charAt(0) === "P" ? "Percentage" : ""}`,
          markupAmount: `${row.actualTransactionValue}`,
          formFieldName: "domesticCarrierMarkupValues" + "__" + idx + "__" + index,
        }
        newArr.push(obj);
      })
    });
    setDomesticMarkupArray(newArr);
  };

  const dataArrInternational = () => {
    const newArr = [];
    internationalMarkup && internationalMarkup.map((rows, idx) => {
      rows.markUpValues.map((row, index) => {
        let supplier = row.supplier;
        switch (supplier) {
          case "Tripjack": {
            supplier = "Supplier 1";
            break;
          }
          case "tbo": {
            supplier = "Supplier 2";
            break;
          }
          case "kafila": {
            supplier = "Supplier 3";
            break;
          }
        }
        newArr.push({
          id: index,
          carrier: `${row.carrier}`,
          airline: `${row.airlines}`,
          supplier: `${supplier}`,
          fareType: `${row.fareType}`,
          markupType: `${row.markupType.charAt(0) === "F" ? "FIXED" : row.markupType.charAt(0) === "P" ? "Percentage" : ""}`,
          markupAmount: `${row.actualTransactionValue}`,
          formFieldName: "internationalCarrierMarkupValues" + "__" + idx + "__" + index,
        })
      })
    })
    setInternationalMarkupArray(newArr)
  }

  const dataArrSoto = () => {
    const newArr = [];
    sotoMarkup && sotoMarkup.map((rows, idx) => {
      rows.markUpValues.map((row, index) => {
        let supplier = row.supplier;
        switch (supplier) {
          case "Tripjack": {
            supplier = "Supplier 1";
            break;
          }
          case "tbo": {
            supplier = "Supplier 2";
            break;
          }
          case "kafila": {
            supplier = "Supplier 3";
            break;
          }
        }
        newArr.push({
          id: index,
          carrier: `${row.carrier}`,
          airline: `${row.airlines}`,
          supplier: `${supplier}`,
          fareType: `${row.fareType}`,
          markupType: `${row.markupType.charAt(0) === "F" ? "FIXED" : row.markupType.charAt(0) === "P" ? "Percentage" : ""}`,
          markupAmount: `${row.actualTransactionValue}`,
          formFieldName: "sotoCarrierMarkupValues" + "__" + idx + "__" + index,
        })
      })
    })
    setSotoMarkupArray(newArr)
  }

  const filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(String(row[id]).toLowerCase()).startsWith(filter.value.toLowerCase()) : true;
  }

  const getDomMarkupRep = (event) => {
    setDomMarkupRep(event.target.value);
  };

  const getIntMarkupRep = (event) => {
    setIntMarkupRep(event.target.value);
  };

  const getSotoMarkupRep = (event) => {
    setSotoMarkupRep(event.target.value);
  };

  const replicateDomesticMarkupValues = () => {
    if (formikRef.current) {
      for (let fieldName in formikRef.current.values) {
        if (fieldName.includes("domesticCarrierMarkupValues")) {
          formikRef.current.setFieldValue(fieldName, domMarkupRep);
        }
      }
    }
  };

  const replicateInternationalMarkupValues = () => {
    if (formikRef.current) {
      for (let fieldName in formikRef.current.values) {
        if (fieldName.includes("internationalCarrierMarkupValues")) {
          formikRef.current.setFieldValue(fieldName, intMarkupRep);
        }
      }
    }
  };

  const replicateSotoMarkupValues = () => {
    if (formikRef.current) {
      for (let fieldName in formikRef.current.values) {
        if (fieldName.includes("sotoCarrierMarkupValues")) {
          formikRef.current.setFieldValue(fieldName, sotoMarkupRep);
        }
      }
    }
  };

  const columns = [
    {
      Header: "Code",
      accessor: 'carrier',
      Cell: props => <div style={{ verticalAlign: 'middle' }}>{props.original.carrier}</div>,
      width: 83,
      filterMethod: filterMethod,
      filterable: true,

    },
    {
      Header: "Airline",
      accessor: 'airline',
      filterMethod: filterMethod,
      filterable: true,
    },


    {
      Header: "Supplier",
      filterable: true,
      accessor: 'supplier',
      width: 93,
      filterMethod: filterMethod,
    },
    {
      Header: "Fare Type",
      accessor: 'fareType'
    },
    {
      Header: "Markup Type",
      accessor: 'markupType',
      width: 120,
    },
    {
      Header: "Amount (₹)",
      accessor: 'markupAmount',
      width: 150,
      Cell: props => {
        return <FormControl >
          <Field
            name={props.original.formFieldName}
            placeholder={`${props.original.markupAmount}`}
            validate={validateNumber}
            style={{ width: '100%', height: 30 }}
          />
          <ErrorMessage name={props.original.formFieldName} component="div" className="error" />
        </FormControl>
      }
    },
  ];

  useEffect(() => {
    setDomesticMarkup(props.markupList.domesticCarrierMarkupValues.Markups);
    setInternationalMarkup(props.markupList.internationalCarrierMarkupValues.Markups);
    setSotoMarkup(props.markupList.sotoCarrierMarkupValues.Markups);
    setIsLoading(false);
    setInitialValues();
  }, []);

  useEffect(() => {
    dataArrDomestic();
    dataArrInternational();
    dataArrSoto();
  }, [formInitialValues]);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <OaCard>

          <OaFormAlerts
            isSaving={isSaving}
            isSuccess={isSuccess}
            isError={isError}
            infoText={infoText}
            setIsError={setIsError}
            setIsSuccess={setIsSuccess}
          />

          {!isLoading && (
            <Formik
              innerRef={formikRef}
              initialValues={formInitialValues}
              onSubmit={(
                searchParams,
                { setSubmitting, resetForm, enableReinitialize }
              ) => {
                // console.log(searchParams, "searchParams");
                let params = props.markupList;

                let id = "";
                let rowNo = "";
                let markupRowNo = "";
                for (let [key, value] of Object.entries(searchParams)) {
                  // console.log("object", key, value);
                  [id, rowNo, markupRowNo] = key.toString().split("__");
                  params[id].Markups[rowNo].markUpValues[
                    markupRowNo
                  ].actualTransactionValue = value;
                }
                setIsError(false);
                setIsSuccess(false);
                setSubmitting(true);
                setIsSaving(true);
                scrollToTop(0);
                apiCall(WebApi.doUpdateFlightMarkup, params, function (
                  response
                ) {
                  setIsError(!response.success);
                  setIsSuccess(response.success);
                  setSubmitting(false);
                  setIsSaving(false);
                  setInfoText(response.message);
                });
              }}
            >
              {({ values, setFieldValue, enableReinitialize, resetForm }) => (
                <Form>
                  <OaCard style={{ padding: "0 imported" }}>
                    <OaFormSaveButton
                      isSaving={isSaving}
                      exReset={enableReinitialize}
                      inReset={resetForm}
                    />

                    <div className={classes.root}>
                      <AppBar position="static" style={{ backgroundColor: "rgb(68 68 68)", margin: '1rem 0' }}>
                        <Tabs value={Tabvalue} onChange={handleChangeTab} aria-label="simple tabs example">
                          <Tab label="Domestic Carriers" {...a11yProps(0)} />
                          <Tab label="International Carriers" {...a11yProps(1)} />
                          <Tab label="SOTO Fare" {...a11yProps(2)} />
                        </Tabs>
                      </AppBar>

                      <TabPanel value={Tabvalue} index={0}>
                        <ReplicateButton
                          onClick={replicateDomesticMarkupValues}
                          label="Replicate"
                          onChange={getDomMarkupRep}
                          value={domMarkupRep}
                        />
                        <ReactTable
                          columns={columns}
                          data={domesticMarkupArray}
                          className="-striped -highlight"
                          showPagination={false}
                          pageSize={domesticMarkupArray.length < 10 ? 10 : domesticMarkupArray.length}
                        />

                      </TabPanel>

                      <TabPanel value={Tabvalue} index={1}>
                        <ReplicateButton
                          onClick={replicateInternationalMarkupValues}
                          label="Replicate"
                          onChange={getIntMarkupRep}
                          value={intMarkupRep}
                        />
                        <ReactTable
                          columns={columns}
                          data={internationalMarkupArray}
                          className="-striped -highlight"
                          showPagination={false}
                          pageSize={internationalMarkupArray.length < 10 ? 10 : internationalMarkupArray.length}

                        />
                      </TabPanel>

                      <TabPanel value={Tabvalue} index={2}>
                        <ReplicateButton
                          onClick={replicateSotoMarkupValues}
                          label="Replicate"
                          onChange={getSotoMarkupRep}
                          value={sotoMarkupRep}
                        />
                        <ReactTable
                          columns={columns}
                          data={sotoMarkupArray}
                          className="-striped -highlight"
                          showPagination={false}
                          pageSize={sotoMarkupArray.length < 10 ? 10 : sotoMarkupArray.length}

                        />
                      </TabPanel>

                    </div>

                    <OaFormSaveButton
                      isSaving={isSaving}
                      exReset={enableReinitialize}
                      inReset={resetForm}
                    />
                  </OaCard>
                </Form>
              )}
            </Formik>
          )}
        </OaCard>
      </GridItem>
    </GridContainer>
  )
};

export default MarkupTable;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ReplicateButton = props => {
  return (
    <div className="replicate-wrapper">
      {/* <h5>Replicate Markup :</h5>

      <a className={cx("replicate-button", props.disabled && "disabled")} onClick={props.onClick} disabled={props.disabled}>
        <FileCopy /> <span>{props.label}</span>
      </a>
      <input
        onChange={props.onChange}
        value={props.value}
      /> */}
      <span style={{ fontSize: 16, fontWeight: 500 }}>Replicate Markup :</span>


      <input
        onChange={props.onChange}
        value={props.value}
      />
      <a className={cx("replicate-button", props.disabled && "disabled")} onClick={props.onClick} disabled={props.disabled}>
        <FileCopy /> <span>{props.label}</span>
      </a>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      minHeight: 30
    },
    "& .MuiTab-root": {
      color: `${theme.palette.text.light} !important`,
      "&.Mui-selected": {
        color: `${theme.palette.text.secondary} !important`
      }
    },
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after ": {
      border: "none",
    },
    "& .MuiInput-underline": {
      border: "1px solid #000",
    },
    "& .MuiInputBase-input": {
      padding: "2px 5px",
    },
    "& MuiFormControl-root": {
      margin: 0,
    },
    "& .MuiFormControl-root": {
      // paddingLeft: '9.16px'
    },
    "& .ReactTable .rt-th, .ReactTable .rt-td": {
      padding: "2px 5px",
    },
    "& .replicate-wrapper": {
      display: "flex",
      alignItems: "center",
      gap: 10,
      marginBottom: 10,
      "& input": {
        color: theme.palette.text.default,
        height: 40,
        width: 100,
        paddingRight: 5,
        borderRadius: 4,
        border: 'solid 1px #333',
        fontSize: "1rem",
        fontWeight: 600,
        textAlign: "right"
      },
    },
    "& .replicate-button": {
      cursor: "pointer",
      minHeight: 40,
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: 140,
      borderRadius: 4,
      boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      "&.disabled": {
        cursor: "not-allowed"
      },
      "&:hover": {
        background: theme.palette.primary.sub,
      },
      "& .MuiSvgIcon-root": {
        marginRight: 6,
        fontSize: "1.2rem"
      },
      "& span": {
        textTransform: "uppercase",
        fontFamily: theme.palette.font.secondary,
        fontWeight: 600,
        fontSize: '0.9rem',
      }
    }
  },
}));
