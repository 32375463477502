import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { getQueryVar, getCurrentTab, getAgencyId } from 'oautils/oaCommonUtils.js';
import { makeStyles } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Gostree";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import PageFooter from "components/Footer/PageFooter";
import Container from '@material-ui/core/Container';
import AadhaarpayProgress from 'pages/module/aadhaarpay/AadhaarpayProgress';
import AadhaarpayOnBoardCallback from 'pages/module/aadhaarpay/AadhaarpayOnBoardCallback';
import InvoiceAadhaarPay from 'pages/voucher/InvoiceAadhaarPay';
import AadhaarServicesTransact from 'pages/module/aadhaarpay/AadhaarServicesTransact';
import AadhaarpayTransactionStatus from 'pages/module/aadhaarpay/AadhaarpayTransactionStatus';
import AadhaarpayMiniStatement from 'pages/module/aadhaarpay/AadhaarpayMiniStatement';

const useStyles = makeStyles(styles);

export default function AadhaarpayLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper} style={{'backgroundColor':"#fff", width:`100%`, height:`100%` }}>
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true}   isTimerShow={true} />
      <Container fixed style={{ marginBottom: 20 }}>
        <div className={classes.container}>
          <Switch>
            <Route path="/aadhaarpay/progress" exact component={AadhaarServicesTransact}/>
            <Route path="/aadhaarpay/getonboardcallback"  component={AadhaarpayOnBoardCallback}/>
            <Route path="/aadhaarpay/voucher/:type/:ref" exact component={InvoiceAadhaarPay}/>
            <Route path="/aadhaarpay/transaction-enquiry" exact component={AadhaarpayTransactionStatus}/>
            <Route path="/aadhaarpay/mini-statement" exact component={AadhaarpayMiniStatement}/>
          </Switch>
        </div>
      </Container>
      <PageFooter white />
    </div>
  );
}
