import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import OaCard from "oahoc/OaCard";
import OaIconTabBar from "oahoc/OaIconTabBar";
import {
  getQueryVar,
  getCurrentTab,
  getAgencyId,
} from "oautils/oaCommonUtils.js";
import { makeStyles } from "@material-ui/core/styles";
import PostLoginNavBar from "components/Navbars/PostLoginNavBar.Gostree";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { FooterSection } from "components/LandingPage/Gostree/FooterSection";
import BannerArea from "components/BannerArea/BannerArea";
import Container from "@material-ui/core/Container";
import OaActionButton from "oahoc/OaActionButton";
import Refund from "pages/module/recharge/Refund";
import login from "assets/img/bg-prelogin-2.jpg";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";

import Flights from "assets/img/flights.png";
import FlightsOver from "assets/img/flightsover.png";
import Hotels from "assets/img/hotels.png";
import HotelsOver from "assets/img/hotelsover.png";
import MoneyTransfer from "assets/img/moneytransfer.png";
import MoneyTransferOver from "assets/img/moneytransferover.png";
import Recharge from "assets/img/recharge.png";
import RechargeOver from "assets/img/rechargeover.png";
import Buses from "assets/img/buses.png";
import BusesOver from "assets/img/busesover.png";

const useStyles = makeStyles(styles);

export default function RechargeLayout(props) {
  const { ...rest } = props;
  const classes = useStyles();
  const [agencyId, setAgencyId] = useState(undefined);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  let baseUrl = "/";
  let rechargeInnerPageUrl = "/recharge/";

  let tabs = [
    {
      name: "flight",
      label: "Flights",
      url: "#",
      icon: <img height={33} src={Flights} />,
      iconover: <img height={33} src={FlightsOver} />,
    },
    {
      name: "moneytransfer",
      label: "Money Transfer",
      url: baseUrl + "dmt",
      icon: <img height={33} src={MoneyTransfer} />,
      iconover: <img height={33} src={MoneyTransferOver} />,
    },
    {
      name: "recharge",
      label: "Recharge",
      url: baseUrl + "recharge",
      icon: <img height={33} src={Recharge} />,
      iconover: <img height={33} src={RechargeOver} />,
    },
    {
      name: "hotel",
      label: "Hotels",
      url: "#",
      disabled: true,
      icon: <img height={33} src={Hotels} />,
      iconover: <img height={33} src={HotelsOver} />,
    },
    // { name: 'train', label: 'Trains', url: "#", disabled: true, icon: <img height={33} src={Trains}/>, iconover: <img height={33} src={TrainsOver}/>},
    {
      name: "bus",
      label: "Buses",
      url: "#",
      disabled: true,
      icon: <img height={33} src={Buses} />,
      iconover: <img height={33} src={BusesOver} />,
    },
  ];

  let options = [
    {
      name: "querytransaction",
      label: "Query \nRecharge",
      url: rechargeInnerPageUrl + "query-recharge",
      icon: <InfoOutlinedIcon style={{ fontSize: "27px" }} />,
    },
    {
      name: "dmtreport",
      label: "Recharge Report",
      url: "/admin/booking-report/recharge",
      icon: <AttachMoneyOutlinedIcon style={{ fontSize: "27px" }} />,
    },
  ];

  let defaultTab = "recharge";
  let queryVar = getQueryVar({ ...props, q: "priTab" });
  let currentTab = getCurrentTab(queryVar, defaultTab);

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    let qAgencyId = getAgencyId(props);
    setAgencyId(qAgencyId);
    return function cleanup() {};
  }, [agencyId, props]);

  return (
    <div className={classes.wrapper}>
      <PostLoginNavBar {...rest} isSearchPage={true} showServices={true} />

      <div style={{minHeight: "90vh"}}>
        <Container fixed style={{ marginBottom: 20 }}>
          <OaCard className={"oa-card-search"} style={{ marginTop: 50 }}>
            <div className={classes.container} style={{ paddingTop: 24 }}>
              <Switch>
                <Route
                  path="/recharge/query-recharge"
                  exact
                  component={Refund}
                />
              </Switch>
            </div>
          </OaCard>
        </Container>
      </div>
      {/* <PageFooter white {...props}/> */}
      <FooterSection />
    </div>
  );
}
