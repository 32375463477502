import React, { useEffect } from "react";

import dateFnsFormat from "date-fns/format";
import calendarIcon from "assets/img/info_images/calendar_icon.png";
import { TextField } from "@material-ui/core";
import LeftAdornedDatePicker from "../../DatePickers/LeftAdornedDatePicker";

const FlightDateInput = ({ props, index, type, showDay }) => {
  //  useEffect(() => {
  //   if(!props.setFieldValue){
  //     props.setFieldValue = props.setfieldvalue
  //   }
  //  },[])

  return (
    <div>
      <LeftAdornedDatePicker
        showDisabledMonthNavigation
        className="date-picker"
        name={`sectors[${index}].${type}DateDisplay`}
        minDate={
          type === "depart"
            ? new Date()
            : props.values.sectors[0].departDateDisplay
        }
        // minDate={props.values.sectors[0].departDateDisplay}
        customInput={
          <TextField id="standard-basic" variant="standard" fullWidth />
        }
        selected={
          type === "depart"
            ? props.values.sectors[index][`${type}DateDisplay`]
            : props.values.returnDateDisplay
        }
        onChange={(e, v) => {
          //   console.log("props.values.mode " + props.values.mode);
          if (type === "depart") {
            if (props.values.mode === "MULTICITY") {
              for (let i = index; i < props.values.sectors.length; i++) {
                const t1 = new Date(e).getTime();
                const t2 = new Date(
                  props.values.sectors[i][`${type}DateDisplay`]
                ).getTime();
                if (t2 < t1) {
                  if (props.setFieldValue) {
                    props.setFieldValue(`sectors[${i}][${type}DateDisplay]`, e);
                    props.setFieldValue(
                      `sectors[${i}][${type}Date]`,
                      dateFnsFormat(e, "yyyy-MM-dd")
                    );
                  } else {
                    props.setfieldvalue(`sectors[${i}][${type}DateDisplay]`, e);
                    props.setfieldvalue(
                      `sectors[${i}][${type}Date]`,
                      dateFnsFormat(e, "yyyy-MM-dd")
                    );
                  }
                }
              }
            } else {
              if (props.setFieldValue) {
                props.setFieldValue(`sectors[${index}][${type}DateDisplay]`, e);
                props.setFieldValue(
                  `sectors[${index}][${type}Date]`,
                  dateFnsFormat(e, "yyyy-MM-dd")
                );
              } else {
                props.setfieldvalue(`sectors[${index}][${type}DateDisplay]`, e);
                props.setfieldvalue(
                  `sectors[${index}][${type}Date]`,
                  dateFnsFormat(e, "yyyy-MM-dd")
                );
              }
            }
            if (Math.abs(props.values.returnDateDisplay) < Math.abs(e)) {
              if (props.setFieldValue) {
                props.setFieldValue(`returnDateDisplay`, e);
                props.setFieldValue(
                  `returnDate`,
                  dateFnsFormat(e, "yyyy-MM-dd")
                );
              } else {
                props.setfieldvalue(`returnDateDisplay`, e);
                props.setfieldvalue(
                  `returnDate`,
                  dateFnsFormat(e, "yyyy-MM-dd")
                );
              }
            }
          } else {
            if (props.setFieldValue) {
              props.setFieldValue(`returnDateDisplay`, e);
              props.setFieldValue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
            } else {
              props.setfieldvalue(`returnDateDisplay`, e);
              props.setfieldvalue(`returnDate`, dateFnsFormat(e, "yyyy-MM-dd"));
            }
          }
        }}
        monthsShown={2}
        hidedate={type === "return" && props.values.mode !== "ROUND"}
        dateFormat="dd MMM yy"
        // adnicon={calendarIcon}
      />
      {showDay && (
        <div className="day-label">
          {type === "depart" &&
            days[
              props?.values?.sectors?.[index][`${type}DateDisplay`].getDay()
            ]}
          {type === "return" &&
            props.values.mode === "ROUND" &&
            days[props.values.returnDateDisplay.getDay()]}
        </div>
      )}
    </div>
  );
};

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default FlightDateInput;
