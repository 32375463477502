import React from "react";
import * as Yup from "yup";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import { Formik, Form, ErrorMessage } from "formik";
import { localforageGetItem } from "oautils/oaForageUtils";

const AadhaarServicesMerchantOnboarding = ({ data }) => {
    const classes = useStyles();
    const formikRef = React.useRef();
    const [isSaving, setIsSaving] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [infoText, setInfoText] = React.useState("");
    const [alertTitle] = React.useState("");
    const [agentId, setAgentId] = React.useState(null);
    const isMounted = React.useRef(false);

    const initialValues = {
        firm: data.firm,
        mobile: data.mobile,
        email: data.email,
        amount: data.amount
    };

    const validationSchema = Yup.object().shape({
        // amount: Yup.number().required("Amount cannot be blank"),
        mobile: Yup.string().required("Mobile number cannot be blank"),
        firm: Yup.string().required("Firm name is required"),
        email: Yup.string().required("Email is required")
    });

    const onChangeFirmName = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("firm", event.target.value);
        }
    };

    const onChangeMobile = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("mobile", event.target.value);
        }
    };

    const onChangeEmail = (event) => {
        if(formikRef.current) {
            formikRef.current.setFieldValue("email", event.target.value);
        }
    };

    const handleSubmit = (searchParams, { setSubmitting }) => {
        if(!agentId) return;
        setIsError(false);
        setIsSuccess(false);
        setSubmitting(true);
        setIsSaving(true);

        const params = {
            ...data
        };
        params.mobile = searchParams.mobile;
        params.email = searchParams.email;
        params.firm = searchParams.firm;

        WebApi.aadhaarPayOnBoardAgent(params, response => {
            if (response.success) {
                // console.log(response.data.redirecturl)

                window.open(response.data.redirecturl, "_self")
            } else {
                setIsError(true);
                setInfoText(response.message);
            }
        }, error => console.error("onBoarding error", error));
    };

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    
    React.useEffect(() => {
        if (isMounted.current) {
            localforageGetItem("user-details", (err, value) => {
                // console.log(value)
                if (value) {
                    setAgentId(value.userId);
                }
            });
        }
    }, [isMounted.current]);

    return (
        <div className={classes.root}>
            <p> Checklist for E - KYC </p>
            <ul>
                <li> Merchant Original Pancard image </li>
                <li> Merchant Original AADHAR image(front and back). </li>
                <li> Onboarding STATE SHOULD match Aadhaar address </li>
                <li> Minimum balance in your account should be &#8377;{data.amount} </li>
            </ul>
            <hr />
            <OaFormAlerts
                isSaving={isSaving}
                isSuccess={isSuccess}
                isError={isError}
                infoText={infoText}
                setIsError={setIsError}
                setIsSuccess={setIsSuccess}
                alertTitle={alertTitle}
            />
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(searchParams, { setSubmitting }) => handleSubmit(searchParams, { setSubmitting })}
            >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form>
                        <h4
                            className={"oa-form-header"}
                            style={{
                                transform: " scale(0.85)",
                                transformOrigin: "left",
                                paddingTop: 30,
                                paddingLeft: 20
                            }}
                        >
                            Please verify your below details and then click `Submit` button
                        </h4>
                        <GridContainer>
                            <GridItem md={4} className="input-form-group">
                                <TextField
                                    fullWidth
                                    className="input-landing-page"
                                    name="firm"
                                    label="Firm Name *"
                                    onChange={onChangeFirmName}
                                    variant="outlined"
                                    value={values.firm}
                                />
                                <ErrorMessage name="firm" component="div" className="error" />
                            </GridItem>
                            <GridItem md={4} className="input-form-group">
                                <TextField
                                    fullWidth
                                    className="input-landing-page"
                                    name="mobile"
                                    label="Mobile Number *"
                                    onChange={onChangeMobile}
                                    variant="outlined"
                                    value={values.mobile}
                                />
                                <ErrorMessage name="mobile" component="div" className="error" />
                            </GridItem>
                            <GridItem md={4} className="input-form-group">
                                <TextField
                                    fullWidth
                                    className="input-landing-page"
                                    name="email"
                                    label="E-mail *"
                                    onChange={onChangeEmail}
                                    variant="outlined"
                                    value={values.email}
                                />
                                <ErrorMessage name="email" component="div" className="error" />
                            </GridItem>
                        </GridContainer>
                        <Grid container className="action-group-container" style={{marginBottom: 30}}>
                            <GridItem
                                md={4}
                                className="action-group align-flxe"
                            >
                                <Button color="primary" variant="contained" type="submit" className="submit-btn">
                                    Submit
                                </Button>
                            </GridItem>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AadhaarServicesMerchantOnboarding;

const useStyles = makeStyles(theme => ({
    root: {
        "& .input-form-group": {
            margin: "5px 0"
        },
        "& .action-group-container": {
            justifyContent: "flex-end"
        },
        "& .align-flxe": {
            justifyContent: "flex-end"
        } 
    }
}));
