import React, { useState, useEffect } from "react";
import clsx from "clsx";
import {
  Grid,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import indigoLogo from "assets/img/6E.png";
import dateFnsFormat from "date-fns/format";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import { useHistory } from "react-router-dom";
import { BASE_URL_IMAGE_VIEW } from "api/ApiConstants";
import demoResponse from "pages/module/flight/JSON_SAMPLE_RESPONSES/domestic/dom-round-pricing.json";

import OaAlert from "oahoc/OaAlert";

import { seatTypes } from "data/seatTypes.js"

const FlightSearchFooter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const segmentsOne = props?.flightDataOne?.segment;
  const fareDetailsOne = props?.flightDataOne?.fares;
  const oneSelectedFareId = props?.oneSelectedFareId;
  const tripMode = props?.request?.mode;

  const [isSaving, setIsSaving] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [infoText, setInfoText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertPrompt, setIsAlertPrompt] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const [primaryButtonText, setPrimaryButtonText] = React.useState("");
  const [secondaryButtonText, setSecondaryButtonText] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");


  const [departAirportOne, setDepartAirportOne] = useState(null);
  const [arrivalAirportOne, setArrivalAirportOne] = useState(null);
  const [departTimeOne, setDepartTimeOne] = useState(null);
  const [arrivalTimeOne, setArrivalTimeOne] = useState(null);
  const [departDateOne, setDepartDateOne] = useState(null);
  const [arrivalDateOne, setArrivalDateOne] = useState(null);
  const [oneFlightCode, setOneFlightCode] = useState(null);
  const [oneFlightNumber, setOneFlightNumber] = useState(null);
  const [oneFlightName, setOneFlightName] = useState(null);
  const [onwardFare, setOnwardFare] = useState(0);

  const [departAirportRet, setDepartAirportRet] = useState(null);
  const [arrivalAirportRet, setArrivalAirportRet] = useState(null);
  const [departTimeRet, setDepartTimeRet] = useState(null);
  const [arrivalTimeRet, setArrivalTimeRet] = useState(null);
  const [departDateRet, setDepartDateRet] = useState(null);
  const [arrivalDateRet, setArrivalDateRet] = useState(null);
  const [retFlightCode, setRetFlightCode] = useState(null);
  const [retFlightNumber, setRetFlightNumber] = useState(null);
  const [retFlightName, setRetFlightName] = useState(null);
  const [returnFare, setReturnFare] = useState(0);


  const [onwardArrivalDateTime, setOnwardArrivalDateTime] = useState(0);
  const [returnDepartDateTime, setReturnDepartDateTime] = useState(0);


  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const image_base = BASE_URL_IMAGE_VIEW + "agency/view-file";

  useEffect(() => {
    const depSeg = segmentsOne[0];
    const arrSeg = segmentsOne[segmentsOne.length - 1];

    const depDateRaw = new Date(depSeg.departDetails.dateTime);
    const arrDateRaw = new Date(arrSeg.arriveDetails.dateTime);

    setOnwardArrivalDateTime(arrDateRaw);

    setDepartAirportOne(depSeg.departDetails.airportName);
    setArrivalAirportOne(arrSeg.arriveDetails.airportName);
    setDepartTimeOne(dateFnsFormat(depDateRaw, "HH : mm"));
    setArrivalTimeOne(dateFnsFormat(arrDateRaw, "HH : mm"));
    setDepartDateOne(
      days[depDateRaw.getDay()] +
      ", " +
      dateFnsFormat(depDateRaw, "dd MMM yyyy")
    );
    setArrivalDateOne(
      days[arrDateRaw.getDay()] +
      ", " +
      dateFnsFormat(arrDateRaw, "dd MMM yyyy")
    );
    setOneFlightCode(depSeg.carrierDetails.carrierCode);
    setOneFlightNumber(depSeg.carrierDetails.flightNumber);
    setOneFlightName(depSeg.carrierDetails.carrierName);

    const selectedFareObj = fareDetailsOne.filter((v) => {
      return v.fareId === oneSelectedFareId;
    })[0];
    setOnwardFare(
      parseFloat(selectedFareObj.baseFare) + parseFloat(selectedFareObj.tax)
    );

    if (props.flightDataRet !== null && props.retSelectedFareId !== null) {
      const segmentsRet = props.flightDataRet?.segment;
      const fareDetailsRet = props.flightDataRet?.fares;
      const retSelectedFareId = props.retSelectedFareId;

      const depSeg = segmentsRet[0];
      const arrSeg = segmentsRet[segmentsRet.length - 1];

      const depDateRaw = new Date(depSeg.departDetails.dateTime);
      const arrDateRaw = new Date(arrSeg.arriveDetails.dateTime);
      setReturnDepartDateTime(depDateRaw);
      setDepartAirportRet(depSeg.departDetails.airportName);
      setArrivalAirportRet(arrSeg.arriveDetails.airportName);
      setDepartTimeRet(dateFnsFormat(depDateRaw, "HH : mm"));
      setArrivalTimeRet(dateFnsFormat(arrDateRaw, "HH : mm"));
      setDepartDateRet(
        days[depDateRaw.getDay()] +
        ", " +
        dateFnsFormat(depDateRaw, "dd MMM yyyy")
      );
      setArrivalDateRet(
        days[arrDateRaw.getDay()] +
        ", " +
        dateFnsFormat(arrDateRaw, "dd MMM yyyy")
      );
      setRetFlightCode(depSeg.carrierDetails.carrierCode);
      setRetFlightNumber(depSeg.carrierDetails.flightNumber);
      setRetFlightName(depSeg.carrierDetails.carrierName);

      const selectedFareObj = fareDetailsRet.filter((v) => {
        return v.fareId === retSelectedFareId;
      })[0];
      setReturnFare(
        parseFloat(selectedFareObj.baseFare) + parseFloat(selectedFareObj.tax)
      );
    }
  }, [
    props.flightDataOne,
    props.oneSelectedFareId,
    props.flightDataRet,
    props.retSelectedFareId,
  ]);

  const bookNowSubmit = (repricingResponse) => {
    // console.log("props", props);

    const paxInfo = {
      adultPax: [],
      childPax: [],
      infantPax: [],
      country_dial_code: "+91",
      phoneno: "",
      emailUser: "",
      gstCompany: "",
      gstCompanyAddress: "",
      gstCompanyNumber: "",
      gstEmail: "",
      gstNumber: "",
      discontCoupon: "",
      discontCouponAmount: 0,
      hasGst: false,
      isDomestic: props?.request?.isDomestic,
      adultCount: props?.request?.adultPax,
      childCount: props?.request?.childPax,
      infantCount: props?.request?.infantPax,
      seatStatus: [],
      mealStatus: [],
      baggStatus: [],
      seatStatusRt: [],
      mealStatusRt: [],
      baggStatusRt: [],
      additionalFare: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
      additionalFareRt: {
        seat: 0,
        meal: 0,
        baggage: 0,
      },
    };

    repricingResponse.onwardResp[0].segment.map((val, idx) => {
      paxInfo.seatStatus.push({ seatsFull: false });
      paxInfo.mealStatus.push({ mealsFull: false });
      idx == 0 && paxInfo.baggStatus.push({ baggsFull: false });
    });

    repricingResponse.returnResp[0].segment.map((val, idx) => {
      paxInfo.seatStatusRt.push({ seatsFull: false });
      paxInfo.mealStatusRt.push({ mealsFull: false });
      idx == 0 && paxInfo.baggStatusRt.push({ baggsFull: false });
    });

    for (var i = 0; i < props.request.adultPax; i++) {
      const additionalOne = [];
      repricingResponse.onwardResp[0].segment &&
        repricingResponse.onwardResp[0].segment.map((val, idx) => {
          additionalOne.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      const additionalRet = [];
      repricingResponse.returnResp[0].segment &&
        repricingResponse.returnResp[0].segment.map((val, idx) => {
          additionalRet.push({
            frequentAirline: val.carrierDetails.carrierCode,
            frequentAirlineName: val.carrierDetails.carrierName,
            airlineNo: val.carrierDetails.flightNumber,
            frequentFlyerNo: "",
            meal: null,
            baggage: null,
            seat: null,
          });
        });

      paxInfo.adultPax.push({
        initial: null,
        firstname: "",
        surname: "",
        visatype: "",
        passportno: "",
        passportidate: null,
        passportexpire: null,
        passporticountry: "",
        passporticobj: { name: "India", dial_code: "+91", code: "IN" },
        dob: "",
        dobDisplay: null,
        paxSave: false,
        additionalOnward: additionalOne,
        additionalReturn: additionalRet,
      });
    }

    if (props.request.childPax > 0) {
      for (var i = 0; i < props.request.childPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        const additionalRet = [];
        repricingResponse.returnResp[0].segment &&
          repricingResponse.returnResp[0].segment.map((val, idx) => {
            additionalRet.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.childPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: additionalRet,
        });
      }
    }

    if (props.request.infantPax > 0) {
      for (var i = 0; i < props.request.infantPax; i++) {
        const additionalOne = [];
        repricingResponse.onwardResp[0].segment &&
          repricingResponse.onwardResp[0].segment.map((val, idx) => {
            additionalOne.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        const additionalRet = [];
        repricingResponse.returnResp[0].segment &&
          repricingResponse.returnResp[0].segment.map((val, idx) => {
            additionalRet.push({
              frequentAirline: val.carrierDetails.carrierCode,
              frequentAirlineName: val.carrierDetails.carrierName,
              airlineNo: val.carrierDetails.flightNumber,
              frequentFlyerNo: "",
              meal: null,
              baggage: null,
              seat: null,
            });
          });

        paxInfo.infantPax.push({
          initial: null,
          firstname: "",
          surname: "",
          visatype: "",
          passportno: "",
          passportidate: null,
          passportexpire: null,
          passporticountry: "",
          passporticobj: { name: "India", dial_code: "+91", code: "IN" },
          dob: "",
          dobDisplay: null,
          paxSave: false,
          additionalOnward: additionalOne,
          additionalReturn: additionalRet,
        });
      }
    }

    history.push({
      pathname: "/flight/booking",
      state: {
        request: repricingResponse?.request,
        requestObj: props?.history?.location?.state?.requestObj,
        onwardResp: repricingResponse?.onwardResp[0],
        returnResp: repricingResponse?.returnResp[0],
        oneSelectedFareId: repricingResponse?.onwardResp[0]?.fares[0]?.fareId,
        retSelectedFareId: repricingResponse?.returnResp[0]?.fares[0]?.fareId,
        sessionId: repricingResponse?.sessionId,
        availSessionId: props?.sessionid,
        isPriceChanged: false,
        paxInfo: paxInfo,
        couponObj: { voucher_code: "", voucher_value: 0, description: "" },
        totalPayable: 0,
      },
    });
  };

  const rePrice = () => {
    //console.log("props", props);

    // console.log(arrivalDateOne + "--------" + departDateRet);
    // console.log(props.request.sectors[0].departDate + "--------" + props.request.returnDate);

    let properFlightSelected = true;
    if (props.request.sectors[0].departDate === props.request.returnDate) {
      // console.log("Same Day Return ");
      //console.log( returnDepartDateTime + "-----" +onwardArrivalDateTime);

      var msDiff = returnDepartDateTime.getTime() - onwardArrivalDateTime.getTime();    //Future date - current date
      var minsDifference = Math.floor(msDiff / (1000 * 60));
      // console.log(minsDifference);


      if (minsDifference < 0) {
        properFlightSelected = false;
        setIsError(true);
        setPrimaryButtonText("OK");
        setIsAlertPrompt(true);
        setAlertMsg("The Depart time of return journey should be atleast 2 hrs after the attival time of onward flights. choose another flight.!");
        setAlertTitle("Select Flights");
      } else if (minsDifference < 120) {
        properFlightSelected = false;
        setIsError(true);
        setPrimaryButtonText("OK");
        setIsAlertPrompt(true);
        setAlertMsg("The Depart time of return journey should be atleast 2 hrs after the attival time of onward flights. choose another flight.!");
        setAlertTitle("Select Flights");
      }

    }

    if (properFlightSelected) {
      const params = {
        onwardFareId: props?.oneSelectedFareId,
        returnFareId: props?.retSelectedFareId,
        sessionId: props?.sessionid,
      };
      // return bookNowSubmit(demoResponse);
      props.setIsFlightBookLoading(true);
      // return
      WebApi.getFlightRepricing(
        params,
        (response) => {
          props.setIsFlightBookLoading(false);
          const responseData =  JSON.parse(JSON.stringify(response))

           /* eslint-disable no-unused-expressions */
           responseData?.onwardResp[0]?.additionalServices?.seatMapping?.map((seatObj) => seatObj.seat.forEach(obj1 => {
            // console.log(obj1)
            let match = seatTypes.find(obj2 => obj2.seatTypeValue === +obj1.seatType);
            if (match) {
              Object.assign(obj1, match);
            }
          }))

          console.log("REACHED 1")

          responseData?.onwardResp[0]?.additionalServices?.seatMapping?.map((seatObj) => {
            let count = 0;
            seatObj.seat.forEach((obj1,ind) => {
            // console.log(obj1)
          //  console.log(obj1.seatTypeLabel.toLowerCase().includes("aisle"))
           if(obj1.seatTypeLabel.toLowerCase().includes("aisle") && ind !==0){
            count++
            obj1.aisleCount = count;
           } else {
            obj1.aisleCount = 0;
           }
          })})
          console.log("REACHED 2")

          if(tripMode === "ROUND"){
            responseData?.returnResp[0]?.additionalServices?.seatMapping?.map((seatObj) => seatObj.seat.forEach(obj1 => {
              // console.log(obj1)
              let match = seatTypes.find(obj2 => obj2.seatTypeValue === +obj1.seatType);
              if (match) {
                Object.assign(obj1, match);
              }
            }))
            console.log("REACHED 3")
  
            responseData?.returnResp[0]?.additionalServices?.seatMapping?.map((seatObj) => {
              let count = 0;
              seatObj.seat.forEach((obj1,ind) => {
              // console.log(obj1)
            //  console.log(obj1.seatTypeLabel.toLowerCase().includes("aisle"))
             if(obj1.seatTypeLabel.toLowerCase().includes("aisle") && ind !== 0){
              count++
              obj1.aisleCount = count;
             } else {
              obj1.aisleCount = 0;
             }
            })})
          }

          console.log("SECOND _-----------------")
          console.log(responseData?.returnResp[0]?.additionalServices?.seatMapping[0])
          if (response.isSuccess === true) {
            if (response.error == "Fare difference") {
              window.confirm("fares have changed, do you want to continue?") &&
                bookNowSubmit(responseData);
            } else {
              bookNowSubmit(responseData);
            }
          } else {
            //setIsError(true);
            //setInfoText("Error on repricing! please retry booking.");
            setIsError(true);
            setPrimaryButtonText("OK");
            setIsAlertPrompt(true);
            setAlertMsg("Error on repricing! please choose another flight.!");
            setAlertTitle("Reprice Error");
          }
        },
        (error) => console.log("error", error)
      );
    }

  };

  return (
    <div className={classes.root}>
      <Box className="footer-section-container">
        {/* <OaFormAlerts
          isSaving={isSaving}
          isSuccess={isSuccess}
          isError={isError}
          infoText={infoText}
          setIsError={setIsError}
          setIsSuccess={setIsSuccess}
          style={{ width: "100%" }}
        /> */}
        {isError && (
          <div item md={12} xs={12}>
            <OaAlert
              //callback={transactionHelper}
              isPrompt={isAlertPrompt}
              //msg={isError}
              //title={infoText}
              msg={alertMsg}
              title={alertTitle}
              isError={isError}
              primaryButtonText={primaryButtonText}
              secondaryButtonText={secondaryButtonText}
              togglePrompt={() => {
                setIsAlertPrompt(!isAlertPrompt);
              }}
            ></OaAlert>

          </div>
        )}
        <Grid
          className={classes.footerSummary}
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          {/* onward section start */}
          {props.flightDataOne && (
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={4}
                className={classes.footerSummary_Component}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    // spacing={4}
                    justifyContent="space-between"
                  >
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <img
                        className={classes.FlightImg}
                        src={
                          image_base +
                          "?fileName=" +
                          oneFlightCode +
                          ".png&type=AIRLINE_IMAGES"
                        }
                        alt=""
                        height={24}
                        width={24}
                      />
                      <Typography>
                        <span className={classes.font16_700}>
                          {oneFlightName}
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          className={classes.font12_400}
                          style={{ marginTop: "-4px" }}
                        >
                          {oneFlightCode}-{oneFlightNumber}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font12_400}>
                        {departAirportOne}
                      </Typography>
                      <Typography className={classes.font16_700} mb={2}>
                        {departTimeOne}
                      </Typography>
                      <Typography className={classes.font12_400}>
                        {departDateOne}
                      </Typography>
                    </Grid>
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font12_400}>
                        {arrivalAirportOne}
                      </Typography>
                      <Typography className={classes.font16_700} mb={2}>
                        {arrivalTimeOne}
                      </Typography>
                      <Typography className={classes.font12_400}>
                        {arrivalDateOne}
                      </Typography>
                    </Grid>
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font16_700}>
                        ₹{onwardFare}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* onward section end */}

          {/* return section start */}
          {props.flightDataRet && (
            <Grid item>
              <Grid
                container
                direction="column"
                spacing={4}
                className={classes.footerSummary_Component}
              >
                {/* +' ' + classes.returnMarginleft */}
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    className={classes.FlightDetailsComp}
                  >
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <img
                        className={classes.FlightImg}
                        src={
                          image_base +
                          "?fileName=" +
                          retFlightCode +
                          ".png&type=AIRLINE_IMAGES"
                        }
                        alt=""
                        height={24}
                        width={24}
                      />
                      <Typography>
                        <span className={classes.font16_700}>
                          {retFlightName}
                        </span>
                      </Typography>
                      <Typography>
                        <span
                          className={classes.font12_400}
                          style={{ marginTop: "-4px" }}
                        >
                          {retFlightCode}-{retFlightNumber}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font12_400}>
                        {departAirportRet}
                      </Typography>
                      <Typography className={classes.font16_700} mb={2}>
                        {departTimeRet}
                      </Typography>
                      <Typography className={classes.font12_400}>
                        {departDateRet}
                      </Typography>
                    </Grid>
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font12_400}>
                        {arrivalAirportRet}
                      </Typography>
                      <Typography className={classes.font16_700} mb={2}>
                        {arrivalTimeRet}
                      </Typography>
                      <Typography className={classes.font12_400}>
                        {arrivalDateRet}
                      </Typography>
                    </Grid>
                    <Grid
                      className={
                        classes.airlineDetail + " " + classes.footerMarginRight
                      }
                      item
                    >
                      <Typography className={classes.font16_700}>
                        ₹{returnFare}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* return section end */}

          {/* total and action section start */}
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="space-around"
            // spacing={4}
            >
              <Grid item>
                <Grid
                  container
                  direection="row"
                  justifyContent="space-between"
                  spacing={2}
                  className={classes.totalInfo}
                >
                  <Grid item>
                    <Typography className={classes.font16_700 + " bg-highlight"}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.font16_700 + " bg-highlight"}>
                      ₹{parseFloat(onwardFare) + parseFloat(returnFare)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Box
                  className={classes.footerBookNowAction}
                  onClick={
                    tripMode === "ROUND" && props.retSelectedFareId === null
                      ? () => { }
                      : () => rePrice()
                  }
                  style={{
                    background:
                      tripMode === "ROUND" && props.retSelectedFareId === null
                        ? theme.palette.primary.faddedGray
                        : theme.palette.buttons.tertiary,
                    cursor:
                      tripMode === "ROUND" && props.retSelectedFareId === null
                        ? "not-allowed"
                        : "pointer",
                    display:
                      tripMode === "ROUND" && props.retSelectedFareId === null
                        ? "none"
                        : "block",
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.buttons.tertiaryContrastText,
                    }}
                  >
                    Book Now
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* total and action section end */}
        </Grid>
      </Box>
    </div>
  );
};

export default FlightSearchFooter;

const useStyles = makeStyles((theme) => {
  // console.log(theme);
  return {
    root: {
      backgroundColor: theme.palette.background.light,
      // border: "2px solid",
      // borderColor: theme.palette.buttons.tertiary,
      boxShadow: "0px 0px 25px 0px rgba(102,101,102,1)",
      width: "100%",
      height: "auto",
      color: theme.palette.primary.default,
      position: "fixed",
      bottom: 0,
      right: 0,
      left: 0,
      zIndex: "999",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& .footer-section-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.background.light,
        // border: "2px solid" ,
        // borderColor : theme.palette.buttons.tertiary,
        padding: "16px",
        width: 1440,
        [theme.breakpoints.down(1440)]: {
          width: "100%"
        },
      },
      "& .MuiFormControlLabel-label": {
        color: theme.palette.primary.default,
      },
      [theme.breakpoints.down(1080)]: {
        "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
          // padding: "12px 8px",
        },
      },
      [theme.breakpoints.down(500)]: {
        "& .bg-highlight": {
          // animation: "blinking 1s infinite"
          color: "red",
          fontSize: 20
        }
      }
    },

    font16_700: {
      fontSize: "16px",
      fontWeight: "700",
      marginRight: "6px",
      [theme.breakpoints.down(1080)]: {
        fontSize: "12px",
      },
    },
    font12_400: {
      fontSize: "12px",
      fontWeight: "400",
      [theme.breakpoints.down(1080)]: {
        fontSize: "8px",
      },
    },
    footerMarginRight: {
      marginRight: "20px",
    },
    returnMarginleft: {
      paddingRight: "2px",
      paddingLeft: "2px",
      borderLeft: "1px solid #000000",
    },
    footerBookNowAction: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: "5px 24px",
      borderRadius: "6px",
      cursor: "pointer",
      "& p": {
        textAlign: "center",
        fontWeight: "700",
      },
    },

    footerSummary: {
      gap: "70px",
      maxWidth: "100%",
      //justifyContent: "space-between",

      [theme.breakpoints.down(1080)]: {
        display: "grid",
        flexDirection: "column",
        gap: "32px",
        paddingLeft: 16,
      },
      [theme.breakpoints.down(550)]: {
        gap: "14px",
        justifyContent: "center",
        "& .MuiGrid-container": {
          flexWrap: "nowrap",
        },
        "& .MuiGrid-item": {
          // padding: 0,
        },
        "& .totalInfo": {
          paddingBottom: 10,
        },
      },
    },
    footerSummary_Component: {
      [theme.breakpoints.down(500)]: {
        "& .airlineDetail.MuiGrid-item": {
          padding: "0px 4px",
        },
        "&.airlineDetail.MuiGrid-spacing-xs-8": {
          width: "unset",
          margin: "unset",
        },
      },
      [theme.breakpoints.down(1080)]: {
        flexDirection: "row",
        gap: "12px 8px",
      },
    },
  };
});
