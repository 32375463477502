import React, { useEffect, useState } from "react";
import { apiCall } from "oautils/oaDataUtils";
import WebApi from "api/ApiConstants";
import OaFormAlerts from "pages/components/OaFormAlerts";
import Button from "components/CustomButtons/Button.js";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";

const AadhaarpayOnBoardCallback = (props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [infoText, setInfoText] = useState("");
    const [alertTitle] = useState("");

useEffect(()=>{

  
const params = new URLSearchParams(window.location.search);
console.log()
const data = params.get("data");

apiCall(WebApi.aadhaarPayOnBoardAgentCallback, {data: data}, response => {
  if (response.success === true) {
   setIsSuccess(response.success)
   setInfoText(response.message)
  } else {
    setIsError(true);
    setInfoText(response.message)
  }
});

}, [])


    return <><OaFormAlerts
    isSaving={isSaving}
    isSuccess={isSuccess}
    isError={isError}
    infoText={infoText}
    setIsError={setIsError}
    setIsSuccess={setIsSuccess}
    alertTitle={alertTitle}
  />

  {
    !isSuccess && !isError && 
    <Grid container justify="center" alignItems="center">Please wait.. Processing data... </Grid>
  }

{
    !isSuccess && isError && 
    <Grid container justify="center" alignItems="center">
    <Button 
                    color="primary"
                    onClick={() => {
                      props.history.push("/landingpage/aadhaarpay");
                    }}>Try Again</Button></Grid>
  }

{
    isSuccess && !isError && 
    <Grid container justify="center" alignItems="center">

<Button
                    color="primary"
                    onClick={() => {
                      props.history.push("/landingpage/aadhaarpay");
                    }}
                   
                  >
                   Make Transactions
                  </Button>


    </Grid>
  }
  
  </>
}

export default withRouter(AadhaarpayOnBoardCallback);