import React, { useState, useEffect } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
// import OaLoading from "pages/components/OaLoading";
import { localforageGetItem } from "oautils/oaForageUtils";
import { refreshUserSession } from "oautils/oaAuthUtils";
import { useSelector, useDispatch } from "react-redux";
import { AppConstant } from "appConstant";

const FlightFareBreakup = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMounted = React.useRef(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openBaseFare, setOpenBaseFare] = React.useState(false);
  const [openBaseFareRt, setOpenBaseFareRt] = React.useState(false);
  const [onwardFare, setOnwardFare] = React.useState(null);
  const [returnFare, setReturnFare] = React.useState(null);
  const [openAirlineTax, setOpenAirlineTax] = useState(false);
  const [openTotalBreakup, setOpenTotalBreakup] = useState(false);
  const [openAirlineTaxRt, setOpenAirlineTaxRt] = useState(false);
  const [openTotalBreakupRt, setOpenTotalBreakupRt] = useState(false);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [openAdditionalRt, setOpenAdditionalRt] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const [flightMode, setFlightMode] = useState(null);

  const [passengerCount, setPassengerCount] = React.useState({
    adultPaxCount: 0,
    childPaxCount: 0,
    infantPaxCount: 0,
  });

  const callbackRefreshToken = (flag) => {
    setIsLoading(flag);
  };

  const getUserDetails = (mountStatus) => {
    localforageGetItem("user-details", function (err, value) {
      if (mountStatus && value) {
        if (value.userTypeAbv == "S") {
          // console.log("value", value)
          setIsLoggedIn(true);
        }
      } else {
        console.log("error getting user details", err);
      }
    });
  };

  React.useEffect(() => {
    isMounted.current = true;
    refreshUserSession(dispatch, callbackRefreshToken);
    getUserDetails(isMounted.current);
    return () => (isMounted.current = false);
  }, []);

  // console.log(isLoggedIn,"ash")

  const LoaderView = () => {
    return (
      <div className="loader-view">
        {/* <OaLoading loadertext="please wait ..." /> */}
        {/* <div>
          <img src={props.comloader} />
        </div> */}
        <div>
          <span className="loader-text">please wait ...</span>
        </div>
      </div>
    );
  };

  function round(value, decimals) {
    return Number(Math.round(value + "e" + decimals) + "e-" + decimals).toFixed(
      decimals
    );
  }

  React.useEffect(() => {
    setFlightMode(props.flightMode);
  }, [props.flightMode]);

  React.useEffect(() => {
    setOnwardFare(props.onwardFare);
    setReturnFare(props.returnFare);
  }, [props.onwardFare, props.returnFare]);

  React.useEffect(() => {
    setPassengerCount({
      adultPaxCount: props.adultPaxCount,
      childPaxCount: props.childPaxCount,
      infantPaxCount: props.infantPaxCount,
    });
  }, [props.adultPaxCount, props.childPaxCount, props.infantPaxCount]);


  //console.log("value.userTypeAbv==", userTypeAbv);
  // console.log("propsssss", onwardFare)
  // console.log("propsssss", returnFare)
  // console.log("flightMode", flightMode);

  return (
    <div className={classes.root}>
      {isLoading && <LoaderView />}

      {!isLoading && (
        <div>
          {/* onward fare breakup start */}
          <div className="fare-breakup-component">
            <Grid container>
              <Grid item md={12} xs={12} className="section-type">
                <Typography>{props.isdomestic && "Onward"}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              onClick={() => setOpenBaseFare(!openBaseFare)}
              className="breakup-title-collapse"
            >
              <Grid item md={7} xs={7}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Grid
                    item
                    md={7}
                    xs={7}
                  // style={{ minWidth: "120px !important" }}
                  >
                    <Typography>Base Fare</Typography>
                  </Grid>
                  <span>
                    <i
                      className={
                        openBaseFare
                          ? "fas fa-chevron-up"
                          : "fas fa-chevron-down"
                      }
                      style={{ marginTop: 6, marginLeft: 10 }}
                    ></i>
                  </span>
                </div>
              </Grid>
              <Grid item md={5} xs={5}>
                <Typography style={{ textAlign: "right" }}>
                  <Rs />
                  {onwardFare?.baseFare}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="fare-breakup-collapse">
              <Grid item md={12} xs={12}>
                <Collapse in={openBaseFare}>
                  <Grid container>
                    <Grid item md={7} xs={7}>
                      <Typography>Adults</Typography>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs />
                        {onwardFare?.baseFareAdult}
                      </Typography>
                    </Grid>
                  </Grid>
                  {passengerCount.childPaxCount > 0 && (
                    <Grid container>
                      <Grid item md={7} xs={7}>
                        <Typography>Child</Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <Typography style={{ textAlign: "right" }}>
                          <Rs /> {onwardFare?.baseFareChild}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {passengerCount.infantPaxCount > 0 && (
                    <Grid container>
                      <Grid item md={7} xs={7}>
                        <Typography>Infant </Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <Typography style={{ textAlign: "right" }}>
                          <Rs /> {onwardFare?.baseFareInfant}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Collapse>
              </Grid>
            </Grid>
            <Grid
              container
              onClick={() => setOpenAirlineTax(!openAirlineTax)}
              className="breakup-title-collapse"
            >
              <Grid item md={7} xs={7}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Typography>Airline Tax and Fee</Typography>
                  <span>
                    <i
                      className={
                        openAirlineTax
                          ? "fas fa-chevron-up"
                          : "fas fa-chevron-down"
                      }
                      style={{ marginTop: 6, marginLeft: 10 }}
                    ></i>
                  </span>
                </div>
              </Grid>
              <Grid item md={5} xs={5}>
                <Typography style={{ textAlign: "right" }}>
                  <Rs />
                  {/* {round(
                    (parseFloat(onwardFare?.airlineTaxAndFees?.total) +
                      ((props.isdomestic || flightMode === "ONE") ? (parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees))
                        :
                        (parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees) + parseFloat(returnFare?.airlineTaxAndFees?.conveyanceFees))))
                    ,
                    2
                  )} */}
                  {parseFloat(onwardFare?.airlineTaxAndFees?.total)}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="fare-breakup-collapse">
              <Grid item md={12} xs={12}>
                <Collapse in={openAirlineTax}>
                  <Grid container>
                    <Grid item md={7} xs={7}>
                      <Typography>Airline Tax</Typography>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.airlineTaxAndFees?.airlineGst}
                      </Typography>
                    </Grid>
                  </Grid>
                  {props.calcFlConv?.flag === "docalc" && <>
                    {/* <Grid container>
                      <Grid item md={7} xs={7}>
                        <Typography>Convenience Fee</Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <Typography style={{ textAlign: "right" }}>
                          <Rs /> {((props.isdomestic || flightMode === "ONE") ? (parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees))
                            :
                            (parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees) + parseFloat(returnFare?.airlineTaxAndFees?.conveyanceFees)))}
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid container>
                      <Grid item md={7} xs={7}>
                        <Typography>Other Taxes</Typography>
                      </Grid>
                      <Grid item md={5} xs={5}>
                        <Typography style={{ textAlign: "right" }}>
                          <Rs /> {onwardFare?.airlineTaxAndFees?.otherTaxes}
                        </Typography>
                      </Grid>
                    </Grid></>}
                </Collapse>
              </Grid>
            </Grid>

            <Grid
              container
              onClick={() => setOpenAdditional(!openAdditional)}
              className="breakup-title-collapse"
            >
              <Grid item md={7} xs={7}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Typography>Additional Services</Typography>
                  <span>
                    <i
                      className={
                        openAdditional
                          ? "fas fa-chevron-up"
                          : "fas fa-chevron-down"
                      }
                      style={{ marginTop: 6, marginLeft: 10 }}
                    ></i>
                  </span>
                </div>
              </Grid>
              <Grid item md={5} xs={5}>
                <Typography style={{ textAlign: "right" }}>
                  <Rs /> {onwardFare?.additional?.total}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="fare-breakup-collapse">
              <Grid item md={12} xs={12}>
                <Collapse in={openAdditional}>
                  <Grid container>
                    <Grid item md={7} xs={7}>
                      <Typography>Seat</Typography>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.additional?.seat}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} xs={7}>
                      <Typography>Meal</Typography>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.additional?.meal}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} xs={7}>
                      <Typography>Baggage</Typography>
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.additional?.baggage}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {/* <Grid container className="separator-wrapper">
              <Grid item md={7} xs={7} className="separator1"></Grid>
              <Grid item md={5} xs={5} className="separator1"></Grid>
            </Grid> */}

            <Grid
              container
              onClick={() =>
                isLoggedIn && setOpenTotalBreakup(!openTotalBreakup)
              }
              className="breakup-title-collapse total"
            >
              <Grid item md={7} xs={7}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <Typography>Total</Typography>
                  <span>
                    {isLoggedIn && (
                      <i
                        className={
                          openTotalBreakup
                            ? "fas fa-chevron-up"
                            : "fas fa-chevron-down"
                        }
                        style={{ marginTop: 6, marginLeft: 10 }}
                      ></i>
                    )}
                  </span>
                </div>
              </Grid>
              <Grid item md={5} xs={5}>
                <Typography style={{ textAlign: "right" }}>
                  {/* <Rs /> {onwardFare?.totalFare} */}
                  <Rs /> {parseFloat(onwardFare?.totalFare) - (parseFloat(onwardFare?.airlineTaxAndFees?.conveyanceFees))}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="fare-breakup-collapse">
              <Grid item md={12} xs={12}>
                <Collapse in={openTotalBreakup}>
                  <Grid container>
                    <Grid item md={7} sx={7}>
                      <Typography>Commission</Typography>
                    </Grid>
                    <Grid item md={5} sx={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.totalCommission}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} sx={7}>
                      <Typography>Markup</Typography>
                    </Grid>
                    <Grid item md={5} sx={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.tMarkup}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} sx={7}>
                      <Typography>TDS</Typography>
                    </Grid>
                    <Grid item md={5} sx={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.tds}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={7} sx={7}>
                      <Typography>Net Fare</Typography>
                    </Grid>
                    <Grid item md={5} sx={5}>
                      <Typography style={{ textAlign: "right" }}>
                        <Rs /> {onwardFare?.netFare}
                      </Typography>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </div>
          {/* onward fare breakup end */}

          {/* return fare breakup start */}
          {(flightMode == "ROUND" || flightMode == "ROUND-SP") &&
            returnFare &&
            props.isdomestic && (
              <div className="fare-breakup-component">
                <Grid container>
                  <Grid item md={12} xs={12} className="section-type">
                    <Typography>{props.isdomestic && "Return"}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  onClick={() => setOpenBaseFareRt(!openBaseFareRt)}
                  className="breakup-title-collapse"
                >
                  <Grid item md={7} xs={7}>
                    <div style={{ display: "flex", flexWrap: "nowrap" }}>
                      <Grid item md={7} xs={7}>
                        <Typography>Base Fare</Typography>
                      </Grid>
                      <span>
                        <i
                          className={
                            openBaseFareRt
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          }
                          style={{ marginTop: 6, marginLeft: 10 }}
                        ></i>
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <Typography style={{ textAlign: "right" }}>
                      <Rs />
                      {returnFare?.baseFare}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="fare-breakup-collapse">
                  <Grid item md={12} xs={12}>
                    <Collapse in={openBaseFareRt}>
                      <Grid container>
                        <Grid item md={7} xs={7}>
                          <Typography>Adults</Typography>
                        </Grid>
                        <Grid item md={5} xs={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs />
                            {returnFare?.baseFareAdult}
                          </Typography>
                        </Grid>
                      </Grid>
                      {passengerCount.childPaxCount > 0 && (
                        <Grid container>
                          <Grid item md={7} xs={7}>
                            <Typography>Child</Typography>
                          </Grid>
                          <Grid item md={5} xs={5}>
                            <Typography style={{ textAlign: "right" }}>
                              <Rs /> {returnFare?.baseFareChild}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                      {passengerCount.infantPaxCount > 0 && (
                        <Grid container>
                          <Grid item md={7} xs={7}>
                            <Typography>infant </Typography>
                          </Grid>
                          <Grid item md={5} xs={5}>
                            <Typography style={{ textAlign: "right" }}>
                              <Rs /> {returnFare?.baseFareInfant}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Collapse>
                  </Grid>
                </Grid>

                <Grid
                  container
                  onClick={() => setOpenAirlineTaxRt(!openAirlineTaxRt)}
                  className="breakup-title-collapse"
                >
                  <Grid item md={7} xs={7}>
                    <div style={{ display: "flex", flexWrap: "nowrap" }}>
                      <Typography>Airline Tax and Fee</Typography>
                      <span>
                        <i
                          className={
                            openAirlineTaxRt
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          }
                          style={{ marginTop: 6, marginLeft: 10 }}
                        ></i>
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <Typography style={{ textAlign: "right" }}>

                      <Rs />
                      {/* {round(
                        parseFloat(returnFare?.airlineTaxAndFees?.total) +
                        parseFloat(returnFare?.airlineTaxAndFees?.conveyanceFees),
                        2
                      )} */}
                      {returnFare?.airlineTaxAndFees?.total}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="fare-breakup-collapse">
                  <Grid item md={12} xs={12}>
                    <Collapse in={openAirlineTaxRt}>
                      <Grid container>
                        <Grid item md={7} xs={7}>
                          <Typography>Airline Tax</Typography>
                        </Grid>
                        <Grid item md={5} xs={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.airlineTaxAndFees?.airlineGst}
                          </Typography>
                        </Grid>
                      </Grid>
                      {props.calcFlConv?.flag === "docalc" && <>
                        {/* <Grid container>
                          <Grid item md={7} xs={7}>
                            <Typography>Convenience Fee</Typography>
                          </Grid>
                          <Grid item md={5} xs={5}>
                            <Typography style={{ textAlign: "right" }}>
                              <Rs />{" "}
                              {returnFare?.airlineTaxAndFees?.conveyanceFees}
                            </Typography>
                          </Grid>
                        </Grid> */}
                        <Grid container>
                          <Grid item md={7} xs={7}>
                            <Typography>Other Taxes</Typography>
                          </Grid>
                          <Grid item md={5} xs={5}>
                            <Typography style={{ textAlign: "right" }}>
                              <Rs /> {returnFare?.airlineTaxAndFees?.otherTaxes}
                            </Typography>
                          </Grid>
                        </Grid></>}
                    </Collapse>
                  </Grid>
                </Grid>

                <Grid
                  container
                  onClick={() => setOpenAdditionalRt(!openAdditionalRt)}
                  className="breakup-title-collapse"
                >
                  <Grid item md={7} xs={7}>
                    <div style={{ display: "flex", flexWrap: "nowrap" }}>
                      <Typography>Additional Services</Typography>
                      <span>
                        <i
                          className={
                            openAdditionalRt
                              ? "fas fa-chevron-up"
                              : "fas fa-chevron-down"
                          }
                          style={{ marginTop: 6, marginLeft: 10 }}
                        ></i>
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <Typography style={{ textAlign: "right" }}>
                      <Rs /> {returnFare?.additional?.total}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="fare-breakup-collapse">
                  <Grid item md={12} xs={12}>
                    <Collapse in={openAdditionalRt}>
                      <Grid container>
                        <Grid item md={7} xs={7}>
                          <Typography>Seat</Typography>
                        </Grid>
                        <Grid item md={5} xs={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.additional?.seat}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={7} xs={7}>
                          <Typography>Meal</Typography>
                        </Grid>
                        <Grid item md={5} xs={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.additional?.meal}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={7} xs={7}>
                          <Typography>Baggage</Typography>
                        </Grid>
                        <Grid item md={5} xs={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.additional?.baggage}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>

                {/* <Grid container className="separator-wrapper">
                <Grid item md={7} xs={7} className="separator1"></Grid>
                <Grid item md={5} xs={5} className="separator1"></Grid>
              </Grid> */}

                <Grid
                  container
                  onClick={() =>
                    isLoggedIn && setOpenTotalBreakupRt(!openTotalBreakupRt)
                  }
                  className="breakup-title-collapse total"
                >
                  <Grid item md={7} xs={7}>
                    <div style={{ display: "flex", flexWrap: "nowrap" }}>
                      <Typography style={{ marginRight: 10 }}>Total</Typography>
                      <span>
                        {isLoggedIn && (
                          <i
                            className={
                              openTotalBreakupRt
                                ? "fas fa-chevron-up"
                                : "fas fa-chevron-down"
                            }
                            style={{ marginTop: 6, marginLeft: 10 }}
                          ></i>
                        )}
                      </span>
                    </div>
                  </Grid>
                  <Grid item md={5} xs={5}>
                    <Typography style={{ textAlign: "right" }}>
                      <Rs /> {returnFare?.totalFare}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className="fare-breakup-collapse">
                  <Grid item md={12} xs={12}>
                    <Collapse in={openTotalBreakupRt}>
                      <Grid container>
                        <Grid item md={7} sx={7}>
                          <Typography>Commission</Typography>
                        </Grid>
                        <Grid item md={5} sx={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.totalCommission}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={7} sx={7}>
                          <Typography>Markup</Typography>
                        </Grid>
                        <Grid item md={5} sx={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.tMarkup}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={7} sx={7}>
                          <Typography>TDS</Typography>
                        </Grid>
                        <Grid item md={5} sx={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.tds}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={7} sx={7}>
                          <Typography>Net Price</Typography>
                        </Grid>
                        <Grid item md={5} sx={5}>
                          <Typography style={{ textAlign: "right" }}>
                            <Rs /> {returnFare?.netFare}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </Grid>
              </div>
            )}
          {/* return fare breakup end */}
        </div>
      )}
    </div>
  );
};

export default FlightFareBreakup;

const Rs = () => {
  return <span style={{ fontFamily: "Roboto" }}>₹</span>;
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .breakup-title collapse": {
        "& p": {
          minWidth: 100,
        },
      },
      width: "100%",
      paddingBottom: "20px",
      "& .loader-view": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 103,
        background: "rgba(0,0,0,0.5)",
        "& img": {
          height: "7rem",
        },
        "& .loader-text": {
          color: theme.palette.primary.main,
          fontWeight: 600,
          textAlign: "center",
          marginLeft: 10,
        },
      },
    },
  };
});
