import React from "react";
import styled from "styled-components";
import searchIcon from "assets/Tickat/images/home_pix/search_icon.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { IconButton, TextField, useTheme } from "@material-ui/core";

const PreferredAirline = (props) => {
  const theme = useTheme();
  const [airlines, setAirlines] = React.useState([]);
  const [showPaSearch, setShowPaSearch] = React.useState(false);

  const renderOptionsComp = (optionsData, propes) => {
    // console.log("render options", optionsData);
    // console.log("render options props", propes);
    return <li className="pa-options-list">{optionsData.label}</li>;
  };

  const onChangePreferredAirline = (e, v) => {
    let prefAir = "";
    for (let [index, carriers] of v.entries()) {
      prefAir += carriers.value + (index + 1 === v.length ? "" : ",");
    }
    props.setFieldValue("preferredAirline", [prefAir]);
  };

  const openPaSearch = () => {
    setShowPaSearch(true);
  };

  const closePaSearch = () => {
    setShowPaSearch(false);
  };

  React.useEffect(() => {
    if (props.allairlines) {
      const carriers = [
        { label: "All Airlines", value: props.allairlines },
        { label: "GDS Airlines", value: props.gdsairlines },
        { label: "LCC Airlines", value: props.lccairlines },
      ];
      for (let flight of props.allairlines.split(",")) {
        carriers.push({ label: flight, value: flight });
      }
      setAirlines(carriers);
    }
  }, []);

  return (
    <RootContainer theme={theme}>
      <div className="preffered-airline-wrap" onClick={openPaSearch}>
        {/* <span className="search-icon">
          <img src={searchIcon} />
        </span>
        <span className="search-label">Preferred Airline</span> */}
        <label>Prefered Airline</label>
        <img src={searchIcon} className="search-icon" />
        <input className="input-preferredClass"></input>
      </div>
      {showPaSearch && (
        <div className="preferred-airline-search-box">
          <div className="pa-inner-container">
            <IconButton
              className="pa-search-close"
              onClick={closePaSearch}
              title="close"
            >
              x
            </IconButton>
            {airlines && (
              <Autocomplete
                multiple
                options={airlines}
                getOptionLabel={(option) => option.label}
                onChange={onChangePreferredAirline}
                defaultValue={[
                  { label: "All Airlines", value: props.allairlines },
                ]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="search"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password",
                    }}
                  />
                )}
                renderOption={(optionsData, propes) =>
                  renderOptionsComp(optionsData, propes)
                }
              />
            )}
          </div>
        </div>
      )}
    </RootContainer>
  );
};

export default PreferredAirline;

const RootContainer = styled.div`
  position: relative;
  width: 100%;
  & .search-icon {
    position: absolute;
    top: 37px;
    left: 15px;
  }
  & .preffered-airline-wrap {
    display: flex;
    flex-direction: column;
    // align-items: center;
    & .search-icon {
      margin-right: 10px;
      & img {
        height: 20px;
      }
    }
    & .search-label {
      color: ${(prop) => prop.theme.palette.text.default};
      font-weight: 500;
      font-family: ${(prop) => prop.theme.palette.font.secondary};
    }
  }
  & .input-preferredClass {
    height: 42px;
    margin-top: 7px;
    border-radius: 7px;
    border: 1px solid #fff;
  }
  & .preferred-airline-search-box {
    position: absolute;
    // width: 250px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 4;
    background: ${(prop) => prop.theme.palette.background.default};
    top: 35px;
    left: -15px;
    padding: 10px;
    border-radius: 8px;
    & .pa-inner-container {
      width: 100%;
      position: relative;
    }
    & .pa-search-close {
      position: absolute;
      top: -25px;
      right: -25px;
      background: ${(prop) => prop.theme.palette.background.default};
      color: ${(prop) => prop.theme.palette.background.defaultContrast};
      border-radius: 50%;
      font-size: 15px;
      width: 18px;
      height: 18px;
    }
  }
  & .MuiInput-input {
    color: ${(prop) => prop.theme.palette.background.defaultContrast};
    font-family: ${(prop) => prop.theme.palette.font.secondary};
    font-weight: 600;
  }
  & .MuiInput-underline:after,
  & .MuiInput-underline:hover:not(.Mui-disabled):before,
  .MuiInput-underline:before {
    border-bottom: none;
  }
  & .MuiInput-colorSecondary.MuiInput-underline:after {
    border-bottom-color: unset;
  }
`;
